import { DEC } from '../../Utilities/decimal.js';

import GameFeature from '../../Core/GameFeature.js';

export default class ForgeUpgrade extends GameFeature {
	constructor(eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, specialVar1,specialVar2,specialVar3) {
		super(eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active);
		this.featureType = "forgeUpgrade";
		this.specialVar1 = specialVar1;
		this.specialVar2 = specialVar2;
		this.specialVar3 = specialVar3;
	}

	
	updateFeatureValues(isNewLvl, gameManager){
		let gameContent = gameManager.gameContent;
		
		this.costNextSingle = this.calcCostNextSingle();
		this.prodNextSingle = this.calcProdNextSingle();

		//dont update values if inactive || (level 0 & not being levelled up)
		if (!this.active || (this.level.eq(0) && !isNewLvl)) {
			return;
		}
		// handle if an active feature's multiplier is being updated but not its level
			// aka being upgraded or a mod is activated
		// also handles evolutions
		else if (this.active && !isNewLvl) {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
		//handle features that are being levelled manually or generator autopurchase
		else {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}

		switch (this.prodType) {
			//AUTOMATION UPGRADE HANDLING
			case 'autoArtifact':
				for (const artifact of gameManager.gameContent.artifacts){
					artifact.autoUnlocked = true;
					if (artifact.active){
						artifact.autoToggle = true;
						gameManager.automationManager.artifactAutobuys.push(artifact);
					}
				}
				break;
			
			//Training Automation
			case 'autoForceTrain':
				for (const training of gameManager.gameContent.trainings.filter(training => training.realmID === 10)){
					training.autoUnlocked = true;
					gameManager.automationManager.forceHeap.add(training);
					training.autoToggle = true;
				}
				break;
			case 'autoWisdomTrain':
				for (const generator of gameManager.gameContent.generators.filter(generator => generator.parentGenChain.realmID === 20)){
					generator.autoUnlocked = true;
					gameManager.automationManager.wisdomHeap.add(generator);
					generator.autoToggle = true;
				}
				break;
			case 'autoEnergyTrain':
				for (const training of gameManager.gameContent.trainings.filter(training => training.realmID === 30)){
					training.autoUnlocked = true;
					gameManager.automationManager.energyHeap.add(training);
					training.autoToggle = true;
				}
				break;
			case 'autoDivineTrain':
				for (const generator of gameManager.gameContent.generators.filter(generator => generator.parentGenChain.realmID === 40)){
					generator.autoUnlocked = true;
					gameManager.automationManager.divineHeap.add(generator);
					generator.autoToggle = true;
				}
				break;
			
			// Training Upgrade Automation
			case 'autoForceUpgrade':
				for (const upgrade of gameManager.gameContent.realmUpgrades.filter(upgrade => upgrade.realmID === 10)){
					upgrade.autoUnlocked = true;
					gameManager.automationManager.forceHeap.add(upgrade);
					upgrade.autoToggle = true;
				}
				break;
			case 'autoWisdomUpgrade':
				for (const upgrade of gameManager.gameContent.realmUpgrades.filter(upgrade => upgrade.realmID === 20)){
					upgrade.autoUnlocked = true;
					gameManager.automationManager.wisdomHeap.add(upgrade);
					upgrade.autoToggle = true;
				}
				break;
			case 'autoEnergyUpgrade':
				for (const upgrade of gameManager.gameContent.realmUpgrades.filter(upgrade => upgrade.realmID === 30)){
					upgrade.autoUnlocked = true;
					gameManager.automationManager.energyHeap.add(upgrade);
					upgrade.autoToggle = true;
				}
				break;
			case 'autoDivineUpgrade':
				for (const upgrade of gameManager.gameContent.realmUpgrades.filter(upgrade => upgrade.realmID === 40)){
					upgrade.autoUnlocked = true;
					gameManager.automationManager.divineHeap.add(upgrade);
					upgrade.autoToggle = true;
				}
				break;

			//NON-AUTOMATION SUPER UPGRADE HANDLING
			case 'modifyGameContentValue':
				this.eventManager.dispatchEvent('updateCurrencyMult', { valueType: this.specialVar1, valueAmount: this.specialVar2 });
				break;
			case 'unspentCurrency':
				gameManager.gameContent.synergyUpgrades[this.specialVar1] = DEC(this.specialVar2);
				break;
			default:
				// console.error("Error - forgeUpgrade prodType not found");
		}
	}
}