import skillData from '../../assets/gameData/skillData.json';
import skillIconImages from '../../assets/icons//skillIcons/*.png';


import Unlock from '../Core/Unlock.js';
import Skill from '../Features/Skills/Skill.js';
import SkillNode from '../Features/Skills/SkillNode.js';

export default class SkillBuilder {
	constructor(builder) {
		this.builder = builder;
		this.skills = [];
    }

    initSkills() {
		skillData.forEach(data => {
			const { id, name, skillType = "normal", note, iconFilename, description, color, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate,active, unlocked = true, connections, mods } = data;
			const skill = new Skill(
				this.builder.eventManager,
				id, name, skillType, note, iconFilename, description, color, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, unlocked, connections
			);

			//grab mods declared in JSON and add them to the mod waiting array to be processed after all objects are initialized
			if (mods){
				mods.forEach(modData => {
					modData.id = skill.id + 1000;
					modData.name = "skillMod" + skill.id;
					if (!modData.sourceID){
						modData.sourceID = skill.id;
					}
					this.builder.modsWaiting.push(modData);

					// discontinued this until skills have multiple levels again
					// skill.description = `${modData.targetType} ${modData.type} ${modData.runningCalcType} (${modData.value} ${modData.sourceCalcType} skill level)`;
					
					//create skill description based off of mod values
					skill.description = `${modData.targetType} ${modData.type} ${modData.runningCalcType} ${modData.value}`;
				});
			}

			//iterate through
			if (skill.iconFilename){
                skill.image = skillIconImages[iconFilename];
            }

			this.skills.push(skill);
			this.builder.gameManager.gameContent.skillTree.skills.push(skill);
			this.builder.gameManager.gameContent.skills.push(skill);
			this.builder.gameManager.gameContent.idToObjectMap.set(id, skill);
		});
		
		this.buildSkillTree();
	}

    buildSkillTree() {
		const baseSkill = this.builder.gameManager.gameContent.skillTree.skills.find(s => s.id === 40001);
		const baseSkillNode = new SkillNode(baseSkill, 100, 200);
		baseSkill.node = baseSkillNode;

		const processConnectedNodes = (skill) => {
			for (const direction in skill.connections) {
			  const connectedSkillId = skill.connections[direction];
			  const connectedSkill = this.builder.gameManager.gameContent.skillTree.skills.find(s => s.id === connectedSkillId);
			  skill.connectionSkillIds.push(connectedSkillId);
	  
			  if (connectedSkill && !connectedSkill.node) {
				const position = this.calculateNodePosition(skill.node, direction);
				const skillNode = new SkillNode(connectedSkill, position.x, position.y);
				connectedSkill.node = skillNode;
	  
				processConnectedNodes(connectedSkill);
			  }
			}
		  };

		processConnectedNodes(baseSkill);

		this.builder.gameManager.gameContent.skillTree.skills.forEach(skill => {
			for (const direction in skill.connections) {
				const targetSkillId = skill.connections[direction];
				const targetSkill = this.builder.gameManager.gameContent.skillTree.skills.find(
					s => s.id === targetSkillId
				);
				if (targetSkill) {
					skill.node.connections[direction] = targetSkill.node;
					targetSkill.node.connections[this.oppositeDirection(direction)] = skill.node;
				}
			}
		});
	}

	initSkillConnectionUnlocks() {
		let unlockID = 42001;
		let unlocksDone = [];
		this.skills.forEach(skill => {
			for (const direction in skill.connections) {
				const unlock = new Unlock(unlockID, "id", null,  skill.id, skill.connections[direction], "level", 1, "setActive", null);
	
				//don't push unlock if a target skill is already unlocked (to handle bidirectional unlock directions) - also don't create any unlock for sk1
				if (!unlocksDone.some(existingUnlock => existingUnlock.targetID === unlock.targetID) && unlock.targetID !== 40001) {
	
					//don't auto-create unlocks for otherwise unlocked skill paths
					if (!Array.from(this.builder.unlocks.values()).find(u => u.targetID === skill.connections[direction])) {

						//save reference to unlock on the unlocked skill for refunding skill and re-engaging unlock
						let targetSkill = this.builder.gameManager.findObjectById(skill.connections[direction]);
						targetSkill.unlockingID = unlockID;
						skill.unlockedConnections.push(targetSkill);

						this.builder.unlocks.set(unlockID, unlock);
						this.builder.gameManager.unlockManager.unlocks.set(unlockID, unlock);
						this.builder.gameManager.gameContent.unlocks.set(unlockID, unlock);
						this.builder.gameManager.gameContent.idToObjectMap.set(unlockID, unlock);
						unlockID++;
					}
				}
			}
		});
	}

	calculateNodePosition(skillNode, direction) {
		const fixedLineLength = 50; // Adjust this value to control the spacing between nodes
	
		let x, y;
		switch (direction) {
		  case 'north':
			x = skillNode.x;
			y = skillNode.y - fixedLineLength;
			break;
		  case 'south':
			x = skillNode.x;
			y = skillNode.y + fixedLineLength;
			break;
		  case 'east':
			x = skillNode.x + fixedLineLength;
			y = skillNode.y;
			break;
		  case 'west':
			x = skillNode.x - fixedLineLength;
			y = skillNode.y;
			break;
		}
		
	
		return { x, y };
	  }

	// Helper function to get the opposite direction
	oppositeDirection(direction) {
		switch (direction) {
			case 'north': return 'south';
			case 'south': return 'north';
			case 'east': return 'west';
			case 'west': return 'east';
			default: return null;
		}
	}
}