import { GAME_VERSION }  from '../Utilities/config.js';
import { DEC } from '../Utilities/decimal.js';
import Decimal from '../Utilities/break_eternity.min.js'; // large number library
const LZString = require('lz-string'); // save file string optimization library


import RealmState from './RealmState.js';
import TrainingState from './TrainingState.js';
import RealmUpgradeState from './RealmUpgradeState.js';
import GeneratorState from './GeneratorState.js';
import AchievementState from './AchievementState.js';
import ForgeUpgradeState from './ForgeUpgradeState.js';
import SkillsState from './SkillsState.js';
import EssenceUpgradesState from './EssenceUpgradesState.js';
import RadianceUpgradeState from './RadianceUpgradeState.js';
import ArtifactState from './ArtifactState.js';
import UnlockState from './UnlockState.js';
import ZoneState from './ZoneState.js';
import RegionState from './RegionState.js';
import WorldState from './WorldState.js';
import ModState from './ModState.js';
import TournamentState from './TournamentState.js';
import FighterTierState from './FighterTierState.js';
import FighterState from './FighterState.js';


import DisplayState from './_DisplayState.js';


import GameContentState from './GameContentState.js';


import Rebirth1StateHandler from './Rebirth1StateHandler.js';


export default class StateManager {
	constructor(eventManager, gameManager) {
		this.eventManager = eventManager;
		this.gameManager = gameManager;
		this.gameContent = gameManager.gameContent;

		this.ui = null;
		this.lastSaveTime = null;
		this.gameData;

		this.versionMismatch = false;
		this.localStorageRetrieved;
		this.stateModules = [];
		this.initStateModules();

		//grab and set stateType from local storage save file .saveStateType if it exists
		this.retrieveLocalStorageGameData();
		// this.saveStateType = this.gameData.saveStateType;
	}


	initStateModules(){
		this.RealmState = new RealmState(this.gameManager, this);
		this.trainingState = new TrainingState(this.gameManager, this);
		this.realmUpgradeState = new RealmUpgradeState(this.gameManager, this);
		this.generatorState = new GeneratorState(this.gameManager, this);
		this.achievementState = new AchievementState(this.gameManager, this);
		this.forgeUpgradeState = new ForgeUpgradeState(this.gameManager, this);
		this.skillsState = new SkillsState(this.gameManager, this);
		this.essenceUpgradesState = new EssenceUpgradesState(this.gameManager, this);
		this.radianceUpgradeState = new RadianceUpgradeState(this.gameManager, this);
		this.artifactState = new ArtifactState(this.gameManager, this);
		this.unlockState = new UnlockState(this.gameManager, this);
		this.zoneState = new ZoneState(this.gameManager, this);
		this.regionState = new RegionState(this.gameManager, this);
		this.worldState = new WorldState(this.gameManager, this);
		this.modState = new ModState(this.gameManager, this);
		this.fighterTierState = new FighterTierState(this.gameManager, this);
		this.fighterState = new FighterState(this.gameManager, this);
		this.tournamentState = new TournamentState(this.gameManager, this);

		this.gameContentState = new GameContentState(this.gameManager, this);

		this.rebirth1StateHandler = new Rebirth1StateHandler(this.gameManager, this);

		this.displayState = new DisplayState(this.gameManager, this.eventManager, this);

		

		this.stateModules = [
			this.gameContentState, this.RealmState, this.trainingState,this.realmUpgradeState, this.forgeUpgradeState, this.generatorState, this.skillsState, this.artifactState, this.zoneState, this.regionState, this.worldState, this.fighterTierState, this.fighterState, this.tournamentState, this.essenceUpgradesState, this.radianceUpgradeState, this.rebirth1StateHandler, this.modState, this.unlockState, this.achievementState,this.displayState
		]
	}

	initializeGameDataObject(saveTime){
		this.gameData = {
			version: GAME_VERSION,
			gameContentData: {},
			saveTimeStamp: saveTime
		};
	}

	saveState(state){
		this.lastSaveTime = Date.now();
		
		this.initializeGameDataObject(this.lastSaveTime);

		if (state === -1){
			this.gameData.saveStateType = -1;
			this.setLocalStorageGameData();
		}
		else {
			

			if (state === 1){
				this.gameManager.gameContent.rebirth1Count = this.gameManager.gameContent.rebirth1Count.plus(1);
			}

			for (const stateModule of this.stateModules){
				stateModule.saveState(state, this.gameData);
			}
			this.gameData.saveStateType = state;

			this.setLocalStorageGameData();
			this.clearGameDataObject();

			
			//if rebirth (state > 0), immediately restart game and load rebirth state
			if (state > 0) {
				this.eventManager.dispatchEvent('restart', state);
			}
		}
	}

	loadState(state){
		if (state === -1){
			return;
		}
		this.retrieveLocalStorageGameData();
		if (this.localStorageRetrieved){
			if (this.gameData.version){
				this.validateVersion(this.gameData.version);
				if (this.versionMismatch){
					return;
				}
			}
		}
		else {
			return;
		}

		this.saveStateType = this.gameData.saveStateType;

		this.lastSaveTime = new Date(this.gameData.saveTimeStamp);

		for (const stateModule of this.stateModules){
			stateModule.loadState(state, this.gameData);
		}

		//had to add this in so mod trees will be sure to recalculate
		this.propogateObserverUpdates();
	}

	exportGameState() {
		this.saveState(0);
	
		const gameDataJson = localStorage.getItem('saveGame');
		const compressed = LZString.compressToEncodedURIComponent(gameDataJson);
	
		const blob = new Blob([compressed], { type: 'text/plain;charset=utf-8' });
		const url = URL.createObjectURL(blob);
	
		const a = document.createElement('a');
		a.href = url;
		a.download = 'gameSave.txt';
		a.click();
	
		URL.revokeObjectURL(url);
	}
	
	importGameState(importedData) {
		const decompressed = LZString.decompressFromEncodedURIComponent(importedData);
		if (importedData.length < 1000){
			return;
		}
		localStorage.setItem('saveGame', decompressed);
		this.loadState(0);
	}

	propogateObserverUpdates(){
		// orupdating observers of all upgrades that would propagate mods
		for (const upgrade of this.gameContent.realmUpgrades.concat(this.gameContent.essenceUpgrades).concat(this.gameContent.forgeUpgrades).concat(this.gameContent.artifacts)){
			if (upgrade.active){
				upgrade.updateObservers();
			}
		}
	}

	populateSaveData(featureType, properties) {
		// Ensure the feature type exists in the game content
		if (!this.gameManager.gameContent[featureType]) {
			console.error(`${featureType} is not a valid feature type in gameContent.`);
			return;
		}
	
		// Map each item in the feature type to its saved data format
		const featureData = this.gameManager.gameContent[featureType].map(item => {
			const data = { id: item.id };
	
			// Iterate over the properties and handle Decimal conversion
			properties.forEach(property => {
				if (typeof item[property] === 'object' && item[property] instanceof Decimal) {
					// Convert Decimal properties to strings
					data[property] = item[property].toString();
				} else {
					// Directly copy other property types
					data[property] = item[property];
				}
			});
	
			return data;
		});
	
		// Assign the mapped data to the corresponding key in gameData
		this.gameData[featureType] = featureData;
	}

	// General purpose loadign data function
	applyLoadData(featureType, properties) {
		if (!this.gameData[featureType]) {
			console.error(`${featureType} data is missing in gameData.`);
			return;
		}
	
		this.gameData[featureType].forEach(data => {
			const item = this.gameManager.gameContent[featureType].find(t => t.id === data.id);
	
			if (!item) {
				console.error(`${featureType} data does not exist for ID:`, data.id);
				return;
			}
	
			properties.forEach(property => {
				if (!data.hasOwnProperty(property)) return;
	
				// Check if the item property currently is a Decimal instance
				const isDecimal = item[property] && typeof item[property].mag !== 'undefined';
	
				// Apply the logic to handle Decimal, number, or direct assignment
				if (typeof data[property] === 'string') {
					if (!isNaN(data[property])) {
						// Numeric string, convert to number or Decimal
						item[property] = isDecimal ? DEC(data[property]) : Number(data[property]);
					} else {
						// Non-numeric string, assign directly
						item[property] = data[property];
					}
				} else {
					// Direct assignment for non-string types
					item[property] = data[property];
				}
			});
		});
	}

	autosave(){
		this.saveState(0);
	}

	clearGameDataObject(){
		this.gameData = {};
	}

	setLocalStorageGameData(){
		const gameDataJson = JSON.stringify(this.gameData);
		localStorage.setItem('saveGame', gameDataJson);
	}

	retrieveLocalStorageGameData(){
		const gameDataJson = localStorage.getItem('saveGame');
		if (!gameDataJson) {
			console.error("No save game file located");
			this.saveStateType = 0; //set to default save state type
			this.localStorageRetrieved = false;
			return;
		}
		this.localStorageRetrieved = true;
		this.gameData = JSON.parse(gameDataJson);
		this.saveStateType = this.gameData.saveStateType;
	}

	validateVersion(savedVersion){
		if (savedVersion === GAME_VERSION) {
			// The version matches the current one, no action needed
			return;
		}
		// if (!COMPATIBLE_VERSIONS.includes(savedVersion)){
			console.error("Save version mismatch. Resetting game state");
			this.versionMismatch = true;
		// }
		// If want to perform additional actions for compatible but outdated versions, add them here.
	}

	// takes a collection of items and maps each item to a new object containing only its ID
	mapAndSaveIds(collection) {
		return collection.map(item => this.createIdObject(item));
	}

	createIdObject(item) {
		return { id: item.id };
	}

	stringifyObjectArrays(objOrMap) {
		let result = {};
		if(objOrMap instanceof Map) {
			for (const [key, value] of objOrMap) {
				result[key] = value.toString();
			}
		} else {
			for (const [key, value] of Object.entries(objOrMap)) {
				result[key] = value.toString();
			}
		}
		return result;
	}
}