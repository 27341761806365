import { DEC } from '../Utilities/decimal.js';

export default class UnlockManager {
	constructor(eventManager,gameManager, injectAdminSettings) {
		this.eventManager = eventManager;
		this.gameManager = gameManager;
		this.unlocks = new Map(); // Map to store unlock conditions
		this.completedUnlocks = new Map(); //map to store completed unlocks
		this.injectAdminSettings = injectAdminSettings;

		this.isChecking = false;
		this.checkLock = Promise.resolve();

		this.eventManager.addListener('reEngage-unlock', this.reEngageUnlock.bind(this));
		this.eventManager.addListener('check-unlocks', this.checkUnlocks.bind(this));
		this.eventManager.addListener('complete-all-unlocks', this.completeAllUnlocks.bind(this));

		// TIME TRACKING
		if (this.injectAdminSettings){
			this.unlockTimes = []; // Array to store unlock times
			this.logInterval = 60000; // Log interval in milliseconds (e.g., 10 seconds)
			this.lastLogTime = 0; // Timestamp of the last log
		}
		// TIME TRACKING
	}

	async checkUnlocks(){
		if (this.isChecking){
			return;
		}
		this.isChecking = true;

		// TIME TRACKING
		let startTime;
		if (this.injectAdminSettings){
			startTime = performance.now(); // Start tracking time
		}
		// TIME TRACKING

		this.checkLock = new Promise((resolve) => {
			let tempCompletedUnlocks = this.processConditions();
			if (tempCompletedUnlocks.size > 0){
				this.processTriggers(tempCompletedUnlocks);
			}

			resolve();
		});
		await this.checkLock;
		this.isChecking = false;


		// TIME TRACKING
		if (this.injectAdminSettings){
			const endTime = performance.now();
			const elapsedTime = endTime - startTime;
			this.unlockTimes.push(elapsedTime);
			const currentTime = performance.now();
			if (currentTime - this.lastLogTime >= this.logInterval) {
				this.logUnlockTimes();
				this.lastLogTime = currentTime;
			}
		}
		// TIME TRACKING
	}

	//TIME TRACKING
	logUnlockTimes() {
		const count = this.unlockTimes.length;
		if (count === 0) {
			return;
		}

		const sum = this.unlockTimes.reduce((a, b) => a + b, 0);
		const avg = sum / count;
		const min = Math.min(...this.unlockTimes);
		const max = Math.max(...this.unlockTimes);

		console.log(`Check Unlocks times (last ${count} runs) - Average: ${avg.toFixed(2)} ms, Minimum: ${min.toFixed(2)} ms, Maximum: ${max.toFixed(2)} ms`);

		this.unlockTimes = []; // Reset the unlock times array
	}
	//TIME TRACKING

	processConditions(){
		let tempCompletedArray = new Map();
		const allResources = this.gameManager.gameContent.queryPropertyValues("all");

		for (let [id, unlock] of this.unlocks) {
			let isCompleted = false;
			if (unlock.category === "id") {
				switch (unlock.conditionType) {
					
					case 'maxLevel':
						if (unlock.dependent.level.gte(unlock.dependent.maxLevel)){
							isCompleted = true;
						}
						break;
					case 'level':
					case 'manualLevel':
						if (unlock.dependent[unlock.conditionType].gte(unlock.conditionValue)) {
							isCompleted = true;
						}
						break;
					case 'evolutionTier':
						if (unlock.dependent[unlock.conditionType].gte(unlock.conditionValue)) {
							isCompleted = true;
						}
						break;
					case 'active':
					case 'isCompleted':
					case 'isDefeated':
					case 'isProgressed':
					case 'isClaimed':
						if (unlock.dependent[unlock.conditionType] === unlock.conditionValue) {
							isCompleted = true;
						}
						break;
				}
			}
			else if (unlock.category === "stat") {
				let matchingResource = allResources.find(resource => resource.type === unlock.conditionType);

				if (unlock.conditionValue.lte(matchingResource.value)) {
					isCompleted = true;
				}
			}
			else if (unlock.category === "statInverse") {
				let matchingResource = allResources.find(resource => resource.type === unlock.conditionType);

				if ((matchingResource.value).lte(unlock.conditionValue)) {
					isCompleted = true;
				}
			}

			if (isCompleted) {
				tempCompletedArray.set(id, unlock);
				this.unlocks.delete(id);
			  }
		}

		return tempCompletedArray;
	}

	processTriggers(tempCompletedArray, notification = true){
		for (let [id, unlock] of tempCompletedArray){
			if(this[unlock.triggerType]) {
				//process the unlock trigger function
				this[unlock.triggerType](unlock);
				//add completed and processed unlocks to the primary completed unlocks array
				this.completedUnlocks.set(id, unlock);

				if (notification){
					this.triggerNotification(unlock);
				}
			}
			this.unlocks.delete(unlock.id);
		}
	}
	
	triggerTutorialPopup(unlock){
		this.eventManager.dispatchEvent('triggerPopup', { 
			title: unlock.triggerValues[0], 
			message: unlock.triggerValues[1],
			tab: unlock.triggerValues[2], // optional
			tabButtonContent: unlock.triggerValues[3] // optional
		});
	}

	triggerNotification(unlock) {
		if (!this.gameManager.notificationsToggled){
			return;
		}
		
		if (!unlock.target) {
			return;
		}
		
		if (unlock.target && unlock.target.featureType === "zone"){
			return;
		}
		

		let targetName;
		let targetConstructor;

		targetName = unlock.target.name;
		targetConstructor = unlock.target.constructor.name;
		
		
		//filter out some spam unlock notifications
		if (targetConstructor === "Mod" || targetConstructor === "Skill" || targetConstructor === "Array"){
			return;
		}


		//extra formatting
		if (unlock.target.featureType){
			if (unlock.target.featureType === "zone" && unlock.target.zoneType === "legendaryBoss"){
				targetType = "Legendary Boss";
			}
		}
		this.eventManager.dispatchEvent('triggerNotification', {
			targetType: targetConstructor,
			targetName: targetName,
			triggerType: unlock.triggerType
		});
	}

	addPointerIndicator(unlock) {
		let target = document.querySelector(unlock.triggerValue);
		// Check if the target element exists
		if (!target) {
			console.error('Target element not found for unlock:', unlock.triggerValue);
			return;
		}
		target.classList.add('tutorial-arrow-message');
		target.classList.add('retro-pulsate');

		if (unlock.triggerValue2){
			target.classList.add(unlock.triggerValue2);
		}
	}
	removePointerIndicator(unlock){
		let target = document.querySelector(unlock.triggerValue);
		target.classList.remove('tutorial-arrow-message');
		target.classList.remove('retro-pulsate');

		if (unlock.triggerValue2){
			target.classList.remove(unlock.triggerValue2);
		}
	}

	elementDisplay(unlock){
		let target = document.querySelector(unlock.triggerValue);
		target.style.display = unlock.triggerValue2;
	}

	elementOpacity(unlock){
		let target = document.querySelector(unlock.triggerValue);
		target.style.opacity = unlock.triggerValue2;
	}

	buttonEnable(unlock){
		let button = document.getElementById(unlock.triggerValue);
		button.disabled = false;
		button.classList.remove("disabled");
		button.classList.add("enabled");
		button.style.opacity = 1;
	}

	tabEnable(unlock){
		//need to set tab targets at this stage for now, since not accessible on init

		//set tab active
		unlock.target = this.gameManager.findObjectById(unlock.targetID);
		unlock.target.setActive();

		if (unlock.target.parentTab){
			unlock.target.parentTab.hasBeenOpened = false;
		}

		//if triggerValue, this indicates a realm tab unlock, so then set realm active
		if (unlock.triggerValue){
			let realm = this.gameManager.findObjectById(unlock.triggerValue.mag);
			realm.setActive();
		}
	}

	transferUnlockToCompleted(unlock){
		this.completedUnlocks.push(unlock);
		this.unlocks.delete(unlock.id);
		console.error(unlock.target);
	}

	evolve(unlock) {
		unlock.target.evolve();
	}

	headbandLevelActivate(unlock) {
		//set headband mod active
		unlock.target.setActive();
		//level up headband pseudo object
		let pseudoObject = this.gameManager.findObjectById(800);
		pseudoObject.active = true;
		pseudoObject.level = pseudoObject.level.plus(1);

		

		//headband mods generally have type targets, in which case they cant just update observers normally
		unlock.target.modTreeReferences.forEach(modTree => {
			if (modTree.parent.active) {
				modTree.buildTree();
					this.eventManager.dispatchEvent('updateFeatureValues', { target: modTree.parent, isNewLvl: false 
				});
			}
		});
	}

	setUnlocked(unlock){
		if (Array.isArray(unlock.target)) {
			unlock.target.forEach(target => {
				if (target && typeof target.setUnlocked === 'function') {
					target.setUnlocked();
				}
			});
		} else if (unlock.target && typeof unlock.target.setUnlocked === 'function') {
			unlock.target.setUnlocked();
		}
	}

	setActive(unlock) {
		if (Array.isArray(unlock.target)) {
			unlock.target.forEach(target => {
				if (target && typeof target.setActive === 'function') {
					target.setActive();
				}
			});
		} else if (unlock.target && typeof unlock.target.setActive === 'function') {
			unlock.target.setActive();
		}
	}

	completeAllUnlocks(){
		//Unlock Tabs first
		let tempCompletedUnlocks = new Map();
		for (let [id, unlock] of this.unlocks) {
			if (unlock.triggerType === "tabEnable" || (unlock.id <= 11010 && unlock.id >= 11000)){

			tempCompletedUnlocks.set(id, unlock);
			this.unlocks.delete(id);
			}
		}
		this.processTriggers(tempCompletedUnlocks, false);

		// Then unlock the rest
		let tempUnlocks2 = new Map();
		for (let [id, unlock] of this.unlocks) {
			tempUnlocks2.set(id, unlock);
			this.unlocks.delete(id);
		}
		
		this.processTriggers(tempUnlocks2, false);

		const D = (num) => DEC(num);
		let resourceUpdates = [
			{ property: "powerLevel", value: D(100000), operation: 'add' },
			{ property: "force",      value: D(100000), operation: 'add' },
			{ property: "wisdom",     value: D(100000), operation: 'add' },
			{ property: "energy",     value: D(100000), operation: 'add' },
			{ property: "divine",     value: D(100000), operation: 'add' },
			{ property: "crystal",    value: D(100000), operation: 'add' },
			{ property: "radiance",   value: D(100000), operation: 'add' }
		];

		
		this.eventManager.dispatchEvent('batchUpdatePropertyValues', resourceUpdates);
	}

	async reEngageUnlock(event) {
		await this.checkLock;
		
		this.isChecking = true;
		if (event.detail.type === "rank"){
			for (const unlock of this.completedUnlocks.values()){
				if (!unlock.target || !unlock.target.name){
					return;
				}
				if (unlock.dependentID === event.detail.id && unlock.target.name.includes("rank")){
					//set rank mod inactive
					unlock.target.active = false;
	
					//rebuild rank object's buildtree to remove mod's mult
					unlock.dependent.modTreesMap.get("production").buildTree();
	
					// Remove the unlock from the completedUnlocks map and re-add to the unlocks map
					this.completedUnlocks.delete(unlock.id);
					this.unlocks.set(unlock.id, unlock);
				}
			}
		}
	
		else if (event.detail.type === "realm-feature-unlock"){
			for (const unlock of this.completedUnlocks.values()){
				//realm feature unlocks manually aded by ID
				if ((unlock.id >= 1200 && unlock.id <= 1209) || (unlock.id >= 621 && unlock.id <= 629) || (unlock.id >= 2201 && unlock.id <= 2209) || (unlock.id >= 721 && unlock.id <= 729)){
					unlock.target.active = false;
	
					// Remove the unlock from the completedUnlocks map and re-add to the unlocks map
					this.completedUnlocks.delete(unlock.id);
					this.unlocks.set(unlock.id, unlock);
				}
			}
		}
	
		else{
			// Find the unlock with the given targetID in the completedUnlocks map
			for (const [id, unlock] of this.completedUnlocks.entries()) {
				if (unlock.targetID === event.detail.id) {
					// Remove the unlock from the completedUnlocks map
					this.completedUnlocks.delete(id);
						
					// Re-add the unlock to the unlocks map
					this.unlocks.set(id, unlock);
					
					// Optionally break the loop after finding and processing the first matching unlock
					// break;
				}
			}
		}
		this.isChecking = false;
	}
}