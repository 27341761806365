export default class WorldDisplayManager {
    constructor(eventManager, ui, gameContent, regionDisplayManager) {
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.worldManager = this.gameContent.worldManager;
        this.regionDisplayManager = regionDisplayManager;

        this.currentViewType; //world or region
        this.currentlyViewedWorld;
        this.currentlyViewedRegion;

        this.eventManager.addListener('changeWorldMap', this.changeWorldMap.bind(this));
    }

    populateWorldTabButtons(container) {
        let worldsTabButtonsContainer = this.ui.createElement('div', `Odyssey-tab-buttons`);
        this.worldManager.worlds.forEach((world) => {
            this.createWorldTabButton(world, worldsTabButtonsContainer);
        });
        container.appendChild(worldsTabButtonsContainer);
    }

    createWorldTabButton(world, container) {
        let worldTabButton = document.getElementById(`worldTabButton-${world.id}`);
        if (!worldTabButton) {
            worldTabButton = this.ui.createElement('button', `worldTabButton-${world.id}`, 'world-tab-button', `${world.level}`); // world id minus 1000000 = the order of worlds

            const worldTabButtonClickHandler = () => {
                this.changeWorldMap(world);
            };
            this.eventManager.addDomListener(worldTabButton, 'click', worldTabButtonClickHandler);
            
            container.appendChild(worldTabButton);
            if (container.children.length > 0) {
                container.children[0].classList.add('active-tab');
            }
        }
    }

    updateWorldTabButtons(world) {
        let worldTabButton = document.getElementById(`worldTabButton-${world.id}`);
        if (world.active) {
            worldTabButton.style.display = "block";
            worldTabButton.textContent = world.level;
            worldTabButton.disabled = false;
        } else {
            worldTabButton.style.display = "none";
            worldTabButton.textContent = "U";
            worldTabButton.disabled = true;
        }
    }

    changeWorldMap(world) {
        const tabButtonsContainer = document.getElementById(`Odyssey-tab-buttons`);
        let tabButtons = Array.from(tabButtonsContainer.children);
        if (this.currentlyViewedWorld) {
            this.currentlyViewedWorld.currentlyViewing = false;
        }
        tabButtons.forEach(button => {
            button.classList.remove('active-tab');
        });
        let targetTab = document.getElementById(`worldTabButton-${world.id}`);
        targetTab.classList.add('active-tab');
        this.currentlyViewedWorld = world;
        world.currentlyViewing = true;
        const targetParent = document.getElementById('tab-col2-OdysseySubTab');
        let tabContents = Array.from(targetParent.children);
        tabContents.forEach(content => {
            if (content.id.includes('world-')) {
                content.style.display = 'none';
            }
        });
        document.getElementById(`world-${world.id}`).style.display = 'flex';
        this.eventManager.dispatchEvent('updateMapView', world);
    }

    populateWorld(world, index) {
        let mapViewWindow = document.getElementById('odyssey-current-view');
        let worldCell = this.ui.createElement('div', `world-${world.id}`, 'world-cell');
        worldCell.style.display = (index === 0 ? 'flex' : 'none');
        if (world.regions) {
            this.regionDisplayManager.populateRegions(world.regions, mapViewWindow);
        }
        mapViewWindow.appendChild(worldCell);
        world.DOMContent = worldCell;

        return worldCell;
    }








    // DEBUG EXPANDED MAP FUNCTIONALITY BELOW THIS POINT



    // In WorldDisplayManager class
    debugExpandAllRegions() {
        const mapViewWindow = document.getElementById('odyssey-current-view');
        const currentWorld = this.currentlyViewedWorld;

        // Clear the current map view
        while (mapViewWindow.firstChild) {
            mapViewWindow.removeChild(mapViewWindow.firstChild);
        }

        // Calculate the grid dimensions based on the regions' positions
        const gridDimensions = this.calculateGridDimensions(currentWorld.regions);

        // Create a container for the expanded regions
        const expandedRegionsContainer = this.ui.createElement('div', 'expanded-regions-container');
        expandedRegionsContainer.style.display = 'grid';
        expandedRegionsContainer.style.gridTemplateColumns = `repeat(${gridDimensions.width}, 1fr)`;
        expandedRegionsContainer.style.gridTemplateRows = `repeat(${gridDimensions.height}, 1fr)`;
        expandedRegionsContainer.style.gap = '10px'; // Adjust the gap between regions as needed

        // Populate the expanded regions
        currentWorld.regions.forEach(region => {
            const expandedRegionCell = this.createExpandedRegionCell(region);
            const row = region.topLeftCornerY / 100;
            const col = region.topLeftCornerX / 100;
            expandedRegionCell.style.gridRow = row + 1;
            expandedRegionCell.style.gridColumn = col + 1;
            expandedRegionsContainer.appendChild(expandedRegionCell);
        });

        // Append the expanded regions container to the map view
        mapViewWindow.appendChild(expandedRegionsContainer);

        const zoneContainers = document.querySelectorAll('.zones-container');
        zoneContainers.forEach(container => {
            container.style.top = '0';
            container.style.left = '0';
            // Add any other desired styles here
        });
    }

    // In WorldDisplayManager class
    createExpandedRegionCell(region) {
        const expandedRegionCell = this.ui.createElement('div', `expanded-region-${region.id}`, 'expanded-region-cell');

        if (region.image) {
            expandedRegionCell.style.backgroundImage = `url(${region.image})`;
            expandedRegionCell.style.backgroundSize = 'cover';
        }

        const zonesContainer = this.ui.createElement('div', null, 'zones-container');
        zonesContainer.style.backgroundImage = `url(${region.image})`;
        zonesContainer.style.backgroundSize = 'cover';
        expandedRegionCell.appendChild(zonesContainer);

        const regionTitle = this.ui.createElement('div', null, 'region-cell-title', region.name);
        const regionProgress = this.ui.createElement('div', null, 'region-cell-progress', `${Math.floor(region.progressPercentage)}%`);

        zonesContainer.appendChild(regionTitle);
        zonesContainer.appendChild(regionProgress);

        if (region.zones) {
            region.zones.forEach(zone => {
                const zoneElements = this.ui.tabManager.explorationTab.odysseySubTab.zoneDisplayManager.createZoneElements(zone, region);
                zonesContainer.appendChild(zoneElements.cell);

                // Create and populate the tooltip for the zone
                this.ui.populateTooltip(zone, zoneElements.button);
            });
        }

        return expandedRegionCell;
    }

    calculateGridDimensions(regions) {
        let maxRegionX = 0;
        let maxRegionY = 0;
        regions.forEach(region => {
            maxRegionX = Math.max(maxRegionX, region.topLeftCornerX);
            maxRegionY = Math.max(maxRegionY, region.topLeftCornerY);
        });

        return {
            width: maxRegionX / 100 + 1,
            height: maxRegionY / 100 + 1
        };
    }
}