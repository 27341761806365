import { DEC } from '../Utilities/decimal.js';

export default class ModTreeNode {
	constructor(ref, parentTree) {
		this.ref = ref;
		this.priority = ref.priority;
		this.result = DEC(0);
		this.runningResult = DEC(0);

		this.parentTree = parentTree;

		this.previousNode = null;
		this.nextNode = null;
	}
}