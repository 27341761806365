import { DEC } from '../../Utilities/decimal.js';
import GameFeature from '../../Core/GameFeature.js';

export default class AchievementSet extends GameFeature{
	constructor(eventManager,id, name, color, bonusType, bonusValue) {
		super(eventManager, id, name, "", DEC(0), DEC(1), null, null, null, null, null, null, false);
		this.eventManager = eventManager;
		this.id = id;
		this.name = name;
		this.color = color;
		this.achievements = [];
		this.bonusType = bonusType;
		this.bonusValue = bonusValue;

		this.completed = false;
		this.visible = false;
	}
  
	checkCompletion() {
		// check if all achievements are claimed
		if (this.achievements.every(a => a.isClaimed)) {
			this.completed = true;
			this.applyBonus();
		}
	}
  
	applyBonus() {
		if (this.completed) {
			if (this.bonusType !== "mod"){
				this.eventManager.dispatchEvent('updatePropertyValue', {
					property: this.bonusType,
					value: this.bonusValue,
					operation: 'add'
				});
			}
			else {
				this.levelUp("manual",DEC(1));
				this.updateObservers();
			}
		}
	}
}