import { DEC } from '../../Utilities/decimal.js';
import GameFeature from '../../Core/GameFeature.js';

export default class Fighter extends GameFeature {
	constructor(eventManager, id, name, note, description, tier, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active) {
		super(
			eventManager,
			id,
			name,
            note,
			description,
			level, maxLevel,
			costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate,
			active
		);
		this.featureType = "fighter";
		this.tier = tier;
		this.fighterTier = null;
		this.isDefeated = false;
		this.defeatCount = DEC(0);
		this.prevFighterDefeated = false;
		if (id === 80001){
			this.prevFighterDefeated = true;
		}
	}

	
	updateFeatureValues(isNewLvl, gameManager){
		
		this.costNextSingle = this.calcCostNextSingle();
		this.prodNextSingle = this.calcProdNextSingle();

		//dont update values if inactive || (level 0 & not being levelled up)
		if (!this.active || (this.level.eq(0) && !isNewLvl)) {
			return;
		}
		// handle if an active feature's multiplier is being updated but not its level
			// aka being upgraded or a mod is activated
		// also handles evolutions
		else if (this.active && !isNewLvl) {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
		//handle features that are being levelled manually or generator autopurchase
		else {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
	}

	setDefeated() {
		this.isDefeated = true;
		// this.active = false;
		
		this.defeatCount = this.defeatCount.plus(1);

		
		let resourceUpdates = [
			{ property: this.prodType, value: this.prodNextMultPurchase, operation: 'add' },
			{ property: 'lifetimeCrystalEarned', value: this.prodNextMultPurchase, operation: 'add' },
			{ property: 'lifetimeKills', value: DEC(1), operation: 'add' },
		];
		this.eventManager.dispatchEvent('batchUpdatePropertyValues', resourceUpdates);

		this.prodPrevious = this.prodCurrentGlobal = this.prodNextMultPurchase;
	}
}
