import { DEC } from '../Utilities/decimal.js';

export default class TournamentState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "tournament";
    }

    saveState(state, gameData){
        this.populateSaveDataBase(gameData);

        switch (state) {
			case 0:
				this.populateSaveDataFull(gameData);
				break;
			case 1:
				this.populateSaveDataRebirth1(gameData);
				break;
			case 2:
				this.populateSaveDataRebirth2(gameData);
				break;
			case 3:
				this.populateSaveDataRebirth3(gameData);
				break;
		}
    }
    
    loadState(state, gameData){
        this.applyLoadDataBase(gameData);

        switch (state) {
			case 0:
				this.applyLoadDataFull(gameData);
				break;
			case 1:
				this.applyLoadDataRebirth1(gameData);
				break;
			case 2:
				this.applyLoadDataRebirth2(gameData);
				break;
			case 3:
				this.applyLoadDataRebirth3(gameData);
				break;
		}
    }

    populateSaveDataBase(gameData){}

    populateSaveDataFull(gameData) {
        gameData.headbandPseudoObject = {}

        gameData.tournamentAuto = this.gameManager.gameContent.tournament.autoUnlocked;
		gameData.currentFighterTierID = this.gameManager.gameContent.tournament.currentFighterTier.id;
		gameData.currentFighterIndex = this.gameManager.gameContent.tournament.currentFighterIndex;
		gameData.tournamentRank = this.gameManager.gameContent.tournament.rank;

        // save headband pseudo object
		let headbandPseudoObject = this.gameManager.findObjectById(800);
		gameData.headbandPseudoObject = {
			active: headbandPseudoObject.active
		}
    }

    populateSaveDataRebirth1(gameData){}
    populateSaveDataRebirth2(gameData){}
    populateSaveDataRebirth3(gameData){}


	
    applyLoadDataBase(gameData){}

    applyLoadDataFull(gameData) {
        this.gameManager.gameContent.tournament.autoUnlocked = gameData.tournamentAuto;
		this.gameManager.gameContent.tournament.currentFighterTier = this.gameManager.findObjectById(gameData.currentFighterTierID);
		this.gameManager.gameContent.tournament.currentFighterIndex = gameData.currentFighterIndex;
		this.gameManager.gameContent.tournament.rank = gameData.tournamentRank;

        // load headband pseudo object
		let headbandPseudoObject = this.gameManager.findObjectById(800);
		if (gameData.headbandPseudoObject.active){
			headbandPseudoObject.setActive();
			headbandPseudoObject.updateObservers();
		}

        // recalculate accurate tournament rank
		this.gameManager.gameContent.tournament.calculateCurrentRank();
    }
    
    
    
    applyLoadDataRebirth1(gameData){}
    applyLoadDataRebirth2(gameData){}
    applyLoadDataRebirth3(gameData){}


}