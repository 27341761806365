
import { DEC } from '../Utilities/decimal.js';

export default class GameContentState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.gameContent = this.gameManager.gameContent;

        this.baseStateValues = [
			//lifetime stats
			'lifetimeForceEarned',
			'lifetimeWisdomEarned',
			'lifetimeEnergyEarned',
			'lifetimeDivineEarned',
			'lifetimeCrystalEarned',
			'maxPowerLevelAchieved',
			'lifetimeEssenceEarned',
			'rebirth1Count',
			

			'maxPowerLevelAchieved',
			'lifetimeZoneCompletions',
			'lifetimeRegionProgressions',
			'lifetimeWorldProgressions',
			'maxProgressionWorld',
			'maxProgressionRegion',
			'maxTournamentRank',
			'lifetimeFighterTiersDefeated',
			'lifetimeKills',
			'totalPlaytime',
	
			//base stats
			'baseForce',
			'baseWisdom',
			'baseEnergy',
			'baseDivine',
			'baseSkillpoint',
			'baseCrystal',
			'baseEssence',
			'baseForcePowerLevelMultiplier',
			'baseWisdomPowerLevelMultiplier',
			'baseEnergyPowerLevelMultiplier',
			'baseDivinePowerLevelMultiplier',
            'essenceGainOnNextRebirth1'
			//other
		];

        this.fullStateValues = ['powerLevel','force', 'wisdom', 'energy', 'divine', 'crystal', 'essence', 'skillpoints','forcePowerLevelMultiplier','powerLevelFromForce','wisdomPowerLevelMultiplier','powerLevelFromWisdom','energyPowerLevelMultiplier','powerLevelFromEnergy','divinePowerLevelMultiplier','powerLevelFromDivine',
        'radiance',
        'timeModifierUpgrade',
        'lastRebirth1','essenceGainOnNextRebirth1'
    ];

        this.rebirth1StateValues =[
            'essence','lifetimeEssenceEarned','rebirth1Count','essenceGainOnNextRebirth1','baseSkillpoint',
			'timeModifierUpgrade',
			'radiance'
        ];
    }

    saveState(state, gameData){

        switch (state) {
			case 0:
				this.populateSaveDataBase(gameData);
				this.populateSaveDataFull(gameData);
				break;
			case 1:
				this.populateSaveDataRebirth1(gameData);
				break;
			case 2:
				this.populateSaveDataRebirth2();
				break;
			case 3:
				this.populateSaveDataRebirth3();
				break;
		}
    }
    
    loadState(state, gameData){

        switch (state) {
			case 0:
				this.applyLoadDataBase(gameData);
				this.applyLoadDataFull(gameData);
				break;
			case 1:
				this.applyLoadDataRebirth1(gameData);
				break;
			case 2:
				this.applyLoadDataRebirth2(gameData);
				break;
			case 3:
				this.applyLoadDataRebirth3(gameData);
				break;
		}
    }

    populateSaveDataBase(gameData){
        this.saveGameContentData(gameData, this.baseStateValues);

		// save time variables
		gameData.originalStartDateTime = this.gameContent.originalStartDateTime.toString();

		gameData.playerName = this.gameContent.playerName;
    }

    populateSaveDataFull(gameData) {
        this.saveGameContentData(gameData, this.fullStateValues);
        // Save synergyUpgrades
		gameData.gameContentData.synergyUpgrades = this.stateManager.stringifyObjectArrays(this.gameContent.synergyUpgrades);
	
		// Save shards
		gameData.gameContentData.shards = this.stateManager.stringifyObjectArrays(this.gameContent.shards);

        // Save heap data
		gameData.forceHeap = this.stateManager.mapAndSaveIds(this.gameManager.automationManager.forceHeap.heap);
		gameData.wisdomHeap = this.stateManager.mapAndSaveIds(this.gameManager.automationManager.wisdomHeap.heap);
		gameData.energyHeap = this.stateManager.mapAndSaveIds(this.gameManager.automationManager.energyHeap.heap);
		gameData.divineHeap = this.stateManager.mapAndSaveIds(this.gameManager.automationManager.divineHeap.heap);

		//save autoUnlocks from essence upgrades
		gameData.gameContentData.autoConquestUnlocked = this.gameManager.gameContent.worldManager.autoUnlocked;
		gameData.gameContentData.autoTournamentUnlocked = this.gameManager.gameContent.tournament.autoUnlocked;
    }
    
    populateSaveDataRebirth1(gameData){
        this.saveGameContentData(gameData, this.rebirth1StateValues.concat(this.baseStateValues));

		
		//save autoUnlocks from essence upgrades
		gameData.gameContentData.autoConquestUnlocked = this.gameManager.gameContent.worldManager.autoUnlocked;
		gameData.gameContentData.autoTournamentUnlocked = this.gameManager.gameContent.tournament.autoUnlocked;
    }

    populateSaveDataRebirth2(gameData){}
    populateSaveDataRebirth3(gameData){}

    
    applyLoadDataBase(gameData){
        this.loadGameContentData(gameData, this.baseStateValues);

        
		// load time variables
		this.gameContent.originalStartDateTime = new Date(gameData.originalStartDateTime);

		
		this.gameContent.playerName = gameData.playerName;
    }

    applyLoadDataFull(gameData) {
        this.loadGameContentData(gameData, this.fullStateValues);
        // Load synergyUpgrades
		for (const [key, value] of Object.entries(gameData.gameContentData.synergyUpgrades || {})) {
			this.gameContent.synergyUpgrades[key] = DEC(value);
		}

        // Load shards
		for (const [key, value] of Object.entries(gameData.gameContentData.shards || {})) {
			this.gameContent.shards.set(key, DEC(value));
		}

        // Load heap data
		['forceHeap', 'wisdomHeap', 'energyHeap', 'divineHeap'].forEach(heapName => this.loadAutobuyHeapData(gameData, heapName));


		// load autoUnlocks from essence upgrades
		this.gameManager.gameContent.worldManager.autoUnlocked = gameData.gameContentData.autoConquestUnlocked;
		this.gameManager.gameContent.tournament.autoUnlocked = gameData.gameContentData.autoTournamentUnlocked;
	
    }
    
    applyLoadDataRebirth1(gameData){
        this.loadGameContentData(gameData, this.rebirth1StateValues.concat(this.baseStateValues));
        this.gameContent.essence = this.gameContent.essence.plus(this.gameContent.essenceGainOnNextRebirth1);
		this.gameContent.lifetimeEssenceEarned = this.gameContent.essenceGainOnNextRebirth1;
        this.gameContent.essenceGainOnNextRebirth1 = new DEC(0);


		// set skillpoints from skillpoints gained from rebirth upgarde
		this.gameContent.skillpoints = this.gameContent.baseSkillpoint;


		// load autoUnlocks from essence upgrades
		this.gameManager.gameContent.worldManager.autoUnlocked = gameData.gameContentData.autoConquestUnlocked;
		this.gameManager.gameContent.tournament.autoUnlocked = gameData.gameContentData.autoTournamentUnlocked;
    }
    applyLoadDataRebirth2(gameData){}
    applyLoadDataRebirth3(gameData){}


    

	saveGameContentData(gameData, properties){
		properties.forEach(stat => {
			gameData.gameContentData[stat] = this.gameContent[stat]?.toString();
		});
	}

	loadGameContentData(gameData, properties){
		properties.forEach(stat => {
			this.loadDecimalData(gameData, 'gameContentData', this.gameContent, stat)
			// this.gameData.gameContentData[stat] = this.gameContent[stat]?.toString();
		});
	}

    
	// Helper to load Decimal data into the game content
	loadDecimalData(gameData, dataPath, target, stat) {
		if (gameData[dataPath][stat]) {
			target[stat] = DEC(gameData[dataPath][stat]);
		} else {
			console.error("load error-data does not exist for:", stat);
		}
	}

    
	// Helper to load heap data
	loadAutobuyHeapData(gameData, heapName) {
		if (gameData[heapName]) {
			for (const item of gameData[heapName]) {
				const feature = this.gameManager.findObjectById(item.id);
				this.gameManager.automationManager[heapName].add(feature);
			}
		}
	}
    
	getOrUpdateFeatureData(feature, gameData, featureType) {
		// Access the correct array in gameData directly using featureType
		let featureDataArray = gameData[featureType];
	
		// If the array doesn't exist yet, create it
		if (!featureDataArray) {
			featureDataArray = [];
			gameData[featureType] = featureDataArray;
		}

		// Find the existing data in the gameData object, if it exists
		let featureData = featureDataArray.find(t => t.id === feature.id);
	
		// If the data doesn't exist yet, create it
		if (!featureData) {
			featureData = { id: feature.id };
			featureDataArray.push(featureData);
		}
	
		return featureData;
	}
}