export default class TournamentSubTab {
    constructor(eventManager, ui, gameContent, tabManager) {
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.tabManager = tabManager;

        this.tournament = this.gameContent.tournament;
        this.fighters = this.tournament.fighters;

        this.eventManager.addListener('startFight', (data) => {
            this.startFight(data.id, data.triggeredByEvent);
        });

        this.startIndex = this.tournament.currentFighterIndex;
        this.lastFighterStates = new Map();
		
        this.initializeEventListeners();
    }

    initializeEventListeners() {
        this.eventManager.addListener('startFight', (data) => this.startFight(data.id, data.triggeredByEvent));
    }

    populateDisplay() {
        this.tournamentCol = document.getElementById(`tab-col1-TournamentSubTab`);
        
        this.populateFighterList();
        this.populateCurrentFighterInfo();
        this.populatePlayerDataBar();
    }

    updateDisplay() {
        this.updateCurrentFighterInfo();
        this.updatePlayerDataBar();
		this.updateFighterList();
		this.centerOnPlayer();
    }

	populateFighterList() {
		let container = this.ui.findOrCreateElement(this.tournamentCol, 'div', 'left-column');
		container.innerHTML = ''; // Clear existing content
	
		// Create and append the header row
		let headerRow = document.createElement('div');
		headerRow.className = 'fighter-header';
		headerRow.innerHTML = `
			<div class="fighter-header-rank">Rank</div>
			<div class="fighter-header-name">${this.gameContent.playerName}</div>
			<div class="fighter-header-power">Power</div>
		`;
		container.appendChild(headerRow);
	
		// Create or find the existing container for the list of fighters
		let fighterListContainer = this.ui.findOrCreateElement(container, 'div', 'fighter-list-container');
		// fighterListContainer.style.overflowY = 'scroll';
		fighterListContainer.style.height = '500px'; // Adjust as needed for your layout
	
        
		this.updateFighterList();
	}
	
	
	updateFighterList() {
		let container = this.ui.findOrCreateElement(this.tournamentCol, 'div', 'left-column');
		let fighterListContainer = this.ui.findOrCreateElement(container, 'div', 'fighter-list-container');
		
		// Create a map of current elements for comparison
		let currentElements = {};
		Array.from(fighterListContainer.children).forEach(child => {
			currentElements[child.getAttribute('data-id')] = child;
		});
	
		// Calculate the position of the player in the reversed list
		const playerIndex = this.fighters.length - this.tournament.rank;
	
		// Loop through all fighters to create their elements in reverse order
		for (let i = 0; i < this.fighters.length; i++) {
			const fighter = this.fighters[i];
			const isPlayer = i === playerIndex;
			const fighterId = fighter.id;
	
			let fighterElement = currentElements[fighterId];
			const fighterNumber = this.fighters.length - i;
			const fighterName = isPlayer ? this.gameContent.playerName : (fighter.fighterTier.active ? fighter.name : '???');
			const fighterPower = isPlayer ? this.ui.formatNumber(this.gameContent.powerLevel) : this.ui.formatNumber(fighter.costNextMultPurchase);
			const fighterColor = `var(--color-${fighter.tier})`;
	
			if (!fighterElement) {
				// Create new element if it doesn't exist
				fighterElement = document.createElement('div');
				fighterElement.className = `fighter-entry ${isPlayer ? 'player-entry' : ''}`;
				fighterElement.setAttribute('data-id', fighterId);
				fighterListContainer.appendChild(fighterElement);
			} else {
				// Update the className if the fighter element already exists
				fighterElement.className = `fighter-entry ${isPlayer ? 'player-entry' : ''}`;
			}
	
			// Ensure the necessary elements exist before trying to access their properties
			let numberElement = fighterElement.querySelector('.fighter-number');
			let nameElement = fighterElement.querySelector('.fighter-name');
			let powerElement = fighterElement.querySelector('.fighter-power');
	
			if (!numberElement || !nameElement || !powerElement) {
				fighterElement.innerHTML = `
					<div class="fighter-number">${fighterNumber}</div>
					<div class="fighter-name ${isPlayer ? 'player-entry' : ''}">${fighterName}</div>
					<div class="fighter-power">${fighterPower}</div>
				`;
				numberElement = fighterElement.querySelector('.fighter-number');
				nameElement = fighterElement.querySelector('.fighter-name');
				powerElement = fighterElement.querySelector('.fighter-power');
			}
	
			// Update only if there are changes
			if (numberElement.textContent != fighterNumber ||
				nameElement.textContent != fighterName ||
				powerElement.textContent != fighterPower ||
				fighterElement.style.color != fighterColor) {
				numberElement.textContent = fighterNumber;
				nameElement.textContent = fighterName;
				powerElement.textContent = fighterPower;
				fighterElement.style.color = fighterColor;
			}
		}
	
		// Remove any leftover elements
		Object.keys(currentElements).forEach(id => {
			if (!this.fighters.find(fighter => fighter.id == id)) {
				currentElements[id].remove();
			}
		});
	}
	
	
	
    populateCurrentFighterInfo() {
		let container = this.ui.findOrCreateElement(this.tournamentCol, 'div', 'right-column');
		container.innerHTML = ''; // Clear existing content
	
		let currentFighter = this.fighters[this.startIndex];
		if (currentFighter) {
			container.innerHTML = `
				<div class="current-fighter-next" style="color:white;font-weight:bold;">Next Fighter:</div>
				<div class="current-fighter-name">${currentFighter.name}</div>
				<div class="current-fighter-cost">Power: ${currentFighter.costNextMultPurchase}</div>
				<div class="current-fighter-description">${currentFighter.description}</div>
				<div class="current-fighter-tier">Tier: ${currentFighter.tier}</div>
				<div class="current-fighter-reward">Crystal Reward: ${this.ui.formatNumber(currentFighter.prodNextMultPurchase)}</div>
				<button class="fight-button" ${currentFighter.tier !== 'active' ? 'disabled' : ''}>Fight (r)</button>
			`;
			this.attachFightButtonListener(container.querySelector('.fight-button'), currentFighter.id);
	
			// Apply color based on fighter tier
			container.style.color = `var(--color-${currentFighter.tier})`;
		}
	}
	
	updateCurrentFighterInfo() {
		this.startIndex = this.tournament.currentFighterIndex;
		let container = this.ui.findOrCreateElement(this.tournamentCol, 'div', 'right-column');
		let currentFighter = this.fighters[this.startIndex];
		
		if (currentFighter) {
			const currentFighterName = container.querySelector('.current-fighter-name');
			const currentFighterTier = container.querySelector('.current-fighter-tier');
			const currentFighterReward = container.querySelector('.current-fighter-reward');
			const currentFighterCost = container.querySelector('.current-fighter-cost');
			const currentFighterDescription = container.querySelector('.current-fighter-description');
			let fightButton = container.querySelector('.fight-button');
	
			if (!currentFighter.fighterTier.active) {
				if (currentFighterName) {
					currentFighterName.innerText = "Progress further in Odyssey to unlock the next tier of fighters";
				}
				if (currentFighterTier) {
					currentFighterTier.innerText = "";
				}
				if (currentFighterReward) {
					currentFighterReward.innerText = "";
				}
				if (currentFighterCost) {
					currentFighterCost.innerText = "";
				}
				if (currentFighterDescription) {
					currentFighterDescription.innerText = "";
				}
				if (fightButton) {
					fightButton.style.display = 'none';
				}
			} else {
				if (currentFighterName && currentFighterName.innerText !== `${currentFighter.name}`) {
					currentFighterName.innerText = `${currentFighter.name}`;
				}
				if (currentFighterTier && currentFighterTier.innerText !== `Tier: ${currentFighter.tier}`) {
					currentFighterTier.innerText = `Tier: ${currentFighter.tier}`;
				}
				if (currentFighterReward && currentFighterReward.innerText !== `Crystal Reward: ${this.ui.formatNumber(currentFighter.prodNextMultPurchase)}`) {
					currentFighterReward.innerText = `Crystal Reward: ${this.ui.formatNumber(currentFighter.prodNextMultPurchase)}`;
				}
				if (currentFighterCost && currentFighterCost.innerText !== `Power: ${this.ui.formatNumber(currentFighter.costNextMultPurchase)}`) {
					currentFighterCost.innerText = `Power: ${this.ui.formatNumber(currentFighter.costNextMultPurchase)}`;
				}
				if (currentFighterDescription && currentFighterDescription.innerText !== `${currentFighter.description}`) {
					currentFighterDescription.innerText = `${currentFighter.description}`;
				}
				if (fightButton) {
					fightButton.style.display = 'block';
					fightButton.disabled = !currentFighter.fighterTier.active || !this.ui.isAffordable(currentFighter);
					this.attachFightButtonListener(fightButton, currentFighter.id);
				}
				// Apply color based on fighter tier
				container.style.color = `var(--color-${currentFighter.tier})`;
			}
		}
	}
	

    populatePlayerDataBar() {
		let bar = this.ui.findOrCreateElement(this.tournamentCol, 'div', 'player-data-bar');
		bar.innerHTML = ''; // Clear existing content
	
		let playerRank = this.tournament.rank;
		let currentTier = this.tournament.currentFighterTier ? this.tournament.currentFighterTier.tier : 'N/A';
	
		bar.innerHTML = `
			<div class="player-name">Name: ${this.gameContent.playerName}</div>
			<div class="player-rank">Rank: ${playerRank}</div>
			<div class="player-tier">Tier: ${currentTier}</div>
			<div class="headband-data">
				${this.populateHeadbandData()}
			</div>
		`;
	}
	
	updatePlayerDataBar() {
		let bar = this.ui.findOrCreateElement(this.tournamentCol, 'div', 'player-data-bar');
		let playerRank = this.tournament.rank;
		let currentTier = this.tournament.currentFighterTier ? this.tournament.currentFighterTier.tier : 'N/A';
	
		const playerName = bar.querySelector('.player-name');
		const playerRankElement = bar.querySelector('.player-rank');
		const playerTierElement = bar.querySelector('.player-tier');
		const headbandDataElement = bar.querySelector('.headband-data');
	
		if (playerName.innerText !== `Name: ${this.gameContent.playerName}`) {
			playerName.innerText = `Name: ${this.gameContent.playerName}`;
		}
		if (playerRankElement.innerText !== `Rank: ${playerRank}`) {
			playerRankElement.innerText = `Rank: ${playerRank}`;
		}
		if (playerTierElement.innerText !== `Tier: ${currentTier}`) {
			playerTierElement.innerText = `Tier: ${currentTier}`;
		}
		const newHeadbandData = this.populateHeadbandData();
		if (headbandDataElement.innerHTML !== newHeadbandData) {
			headbandDataElement.innerHTML = newHeadbandData;
		}
	}
	
	populateHeadbandData() {
		let headbandPseudoObject = this.ui.gameManager.findObjectById(800);
		let headbandCount = headbandPseudoObject.level.toNumber(); // Assuming level is a BigNumber
	
		if (headbandCount > 0) {
			let headbandHTML = ['<div class="headband-title">Headbands Earned</div>'];
			let headbandTier = 1;
	
			for (let i = 0; i < headbandCount; i++) {
				let tooltipText = '';
				let tierStyle = '';
				if (headbandPseudoObject.observers[i] && headbandPseudoObject.observers[i].active) {
					let obs = headbandPseudoObject.observers[i];
					tooltipText = `Rank ${headbandTier}: ${obs.targetType} ${obs.type} ${obs.runningCalcType} (${obs.value} ${obs.sourceCalcType} ${obs.source.level})`;
					tierStyle = `color: var(--color-${headbandTier});`; // Using the tier for background color
					headbandTier++;
				}
	
				headbandHTML.push(`<div class="headband-box" style="${tierStyle}" title="${tooltipText}">${i + 1}</div>`);
			}
	
			return headbandHTML.join('');
		} else {
			return '';
		}
	}
	

	updateHeadbandData(headbandData) {
		let headbandPseudoObject = this.ui.gameManager.findObjectById(800);
		let headbandCount = headbandPseudoObject.level.toNumber(); // Assuming level is a BigNumber
	
		if (headbandCount > 0) {
			let headbandHTML = ['<div class="headband-title">Headbands Earned</div>'];
			let headbandTier = 1;
	
			for (let i = 0; i < headbandCount; i++) {
				let tooltipText = '';
				let tierStyle = '';
				if (headbandPseudoObject.observers[i] && headbandPseudoObject.observers[i].active) {
					let obs = headbandPseudoObject.observers[i];
					tooltipText = `Rank ${headbandTier}: ${obs.targetType} ${obs.type} ${obs.runningCalcType} (${obs.value} ${obs.sourceCalcType} ${obs.source.level})`;
					tierStyle = `color: var(--color-${headbandTier});`; // Using the tier for background color
					headbandTier++;
				}
	
				headbandHTML.push(`<div class="headband-box" style="${tierStyle}" title="${tooltipText}">${i + 1}</div>`);
			}
	
			this.ui.updateElementHTML(headbandData, headbandHTML.join(''));
		} else {
			this.ui.updateElementHTML(headbandData, '');
		}
	}
	

    attachFightButtonListener(button, fighterId) {
        // Remove existing handler if any
        if (button._handler) {
            button.removeEventListener('click', button._handler);
        }
        
        // Create a new handler
        const handler = () => {
            if (this.ui.isAffordable(this.ui.gameManager.findObjectById(fighterId))) {
                this.startFight(fighterId);
            } 
        };
        
        // Attach the new handler and store it
        button.addEventListener('click', handler);
        button._handler = handler;
    }

    startFight(id, triggeredByEvent = false) {
        let fighter = this.ui.gameManager.findObjectById(id);

        this.tournament.processFighterDefeat(fighter.id);

        if (!triggeredByEvent) {
            this.startIndex = this.tournament.currentFighterIndex;
            this.updateDisplay();
			this.updateFighterList();
        }

		this.centerOnPlayer();
    }

	centerOnPlayer(){
		let container = this.ui.findOrCreateElement(this.tournamentCol, 'div', 'left-column');
    	let fighterListContainer = this.ui.findOrCreateElement(container, 'div', 'fighter-list-container');
		const playerIndex = this.fighters.length - this.tournament.rank;
		const scrollTop = playerIndex * 40; // Adjusting for the reverse order

		if (!this.userScrolled) {
			fighterListContainer.scrollTop = scrollTop - (fighterListContainer.clientHeight / 2) + 25; // Center the player
		}
	}
}
