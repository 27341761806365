import { DEC } from '../../Utilities/decimal.js';

export default class Tournament {
	constructor(eventManager, id) {
		this.eventManager = eventManager;
		this.id = id;
		this.fighterTiers = [];
		this.fighters = [];
		this.autoUnlocked = false;
		this.rank = 201;
		this.currentFighterTier;
		this.currentFighterIndex = 0;
	}

	processFighterDefeat(fighterId) {
		let fighterIndex = this.fighters.findIndex(fighter => fighter.id === fighterId);
		if (fighterIndex === -1) {
			console.error("fighter not found. no fight processed");
			return;
		}
	
		let fighter = this.fighters[fighterIndex];
		if(!fighter.isDefeated){
			fighter.setDefeated();
			this.rank = this.fighters.length - (fighter.id - 80001);
			this.currentFighterIndex++;

			this.eventManager.dispatchEvent('updatePropertyValue', {
				property: 'maxTournamentRank',
				value: DEC(this.rank),
				operation: 'replaceIfLesser'
			});
		}
	
		// Check that the next fighter index is within the bounds of the fighters array
		if (fighterIndex + 1 < this.fighters.length) {
			
			//set up so next fighter will know that fighter was defeated, which updates button disable/enable based on prevFighterDefeated value
			let nextFighter = this.fighters.find(fighter => fighter.id === fighterId+1);
			nextFighter.prevFighterDefeated = true;

			// if next fighter is in next tier
			if (this.fighters[fighterIndex + 1].tier !== fighter.tier){
				let tierIndex = this.fighterTiers.findIndex(fighterTier => fighterTier.tier === fighter.tier);
				if (this.fighterTiers.length > tierIndex + 1){
					this.fighterTiers[tierIndex].setComplete();
					this.currentFighterTier = this.fighterTiers[tierIndex + 1];
				}
			}
			else {
				// If the next fighter isn't active yet, activate it
				if (!this.fighters[fighterIndex + 1].active) {
					this.fighters[fighterIndex + 1].active = true;
				}
			}
		}
	}

	calculateCurrentRank() {
		let defeatedFighters = this.fighters.filter(fighter => fighter.isDefeated).length;
		this.rank = this.fighters.length - defeatedFighters;
		return this.rank;
	}
}
