import { DEC } from '../../Utilities/decimal.js';
import GameFeature from '../../Core/GameFeature.js';

export default class Achievement extends GameFeature {
	constructor(eventManager, id, name, note, iconFilename, description, unlockCategory, conditionType, dependentID, radianceReward, triggerType, triggerValue, conditionValue, setID) {
		super(eventManager, id, name, note, description, DEC(0), DEC(1), null, null, null, null, null, null, false);
		this.featureType = "achievement";

		this.unlockCategory = unlockCategory;
		this.conditionType = conditionType;
		this.conditionValue = conditionValue;
		this.triggerType = triggerType;
		this.triggerValue = triggerValue;
		
		this.dependentID = dependentID;
		this.dependent = null;

		this.radianceReward = DEC(radianceReward);

		this.isClaimable = false;
		this.isClaimed = false;

		this.setID = setID;
		this.set = null;

		this.iconFilename = iconFilename;
		this.image = null;
	}

	
	setActive(){
		this.isClaimable = true;
		this.set.visible = true;
		super.setActive();
	}

	claim() {
		this.level = this.level.plus(1);
		this.isClaimed = true;
		this.isClaimable = false;
		this.active = true;

		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: 'radiance',
			value: this.radianceReward,
			operation: 'add'
		});

		this.set.checkCompletion();

		for (const observer of this.observers) {
			observer.active = true;
		}
		this.updateObservers();
	}
}
