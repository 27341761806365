import { DEC } from '../../Utilities/decimal.js';
import GameFeature from '../../Core/GameFeature.js';

export default class Artifact extends GameFeature {
	constructor(eventManager, id, name, note, evolutionTier, gearType, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active) {
		super(
			eventManager,
			id,
			name,
            note,
			description,
			level, maxLevel,
			costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate,
			active
		);
		this.featureType = "artifact";
		this.unlocked = false;
		this.gearType = gearType;
		this.evolutionTier = DEC(evolutionTier);
		this.evolutions = [];
	}


	// Triggered in GameFeature based on max level attainment
	// This assumes there is a next evolution tier.  the max evolution max level is 1e100 so it should never be reached
	evolve(){
		let nextEvolution;

		this.evolutionTier = this.evolutionTier.plus(1);
		
		nextEvolution = this.evolutions.find(evo => evo.evolutionTier === Number(this.evolutionTier));

		if (nextEvolution){
			this.name = nextEvolution.name;
			this.description = nextEvolution.description;
			this.note = nextEvolution.note;
			this.level = DEC(1);
			this.manualLevel = DEC(1);
			this.maxLevel = DEC(nextEvolution.maxLevel);
			this.observers[0].baseValue = nextEvolution.newModValue
			this.observers[0].value = nextEvolution.newModValue
			this.updateObservers();
		}
	}
	
	updateFeatureValues(isNewLvl, gameManager){
		
		this.costNextSingle = this.calcCostNextSingle();
		this.prodNextSingle = this.calcProdNextSingle();

		//dont update values if inactive || (level 0 & not being levelled up)
		if (!this.active || (this.level.eq(0) && !isNewLvl)) {
			return;
		}
		// handle if an active feature's multiplier is being updated but not its level
			// aka being upgraded or a mod is activated
		// also handles evolutions
		else if (this.active && !isNewLvl) {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
		//handle features that are being levelled manually or generator autopurchase
		else {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
	}
}