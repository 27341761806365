import iconImages from '../../../assets/icons/*.png';

import Tab from './Tab.js';
import SkillsTab from '../DisplayComponents/SkillsTab.js';
import ForgeTab from '../DisplayComponents/ForgeTab.js';
import ExplorationTab from '../DisplayComponents/ExplorationDisplay/ExplorationTab.js';
import TrainingTab from '../DisplayComponents/TrainingTab.js';
import EssenceTab from '../DisplayComponents/EssenceTab.js';
import RadianceTab from '../DisplayComponents/RadianceTab.js';
import AchievementsTab from '../DisplayComponents/AchievementsTab.js';
import SettingsTab from '../DisplayComponents/SettingsTab.js';

export default class TabManager{
    constructor(eventManager, gameContent, tabData, ui){
        this.eventManager = eventManager;
        this.gameContent = gameContent;
        this.tabData = tabData;
        this.ui = ui;

        this.trainingTab = new TrainingTab(this.eventManager, this.ui, this.gameContent, this);
        this.forgeTab = new ForgeTab(this.eventManager, this.ui, this.gameContent.forgeUpgrades);
        this.skillsTab = new SkillsTab(this.eventManager, this.ui, this.gameContent);
        this.explorationTab = new ExplorationTab(this.eventManager, this.ui,this.gameContent,this);
        this.essenceTab = new EssenceTab(this.eventManager, this.ui, this.gameContent, this);
        this.radianceTab = new RadianceTab(this.eventManager, this.ui, this.gameContent, this);
        this.achievementsTab = new AchievementsTab(this.eventManager, this.ui, this.gameContent, this);
        this.settingsTab = new SettingsTab(this.eventManager, this.ui, this.gameContent, this);
        
		this.tabIDs = ['training','forge','skills','exploration','essence','achievements','radiance','settings'];

        this.tabDisplays = [this.trainingTab, this.explorationTab, this.skillsTab, this.forgeTab, this.essenceTab, this.radianceTab, this.achievementsTab, this.settingsTab];

        this.tabs = [];
        this.initTabData();
        this.assignButtonsToTabObjects();
        this.currentTab = 'training'; //set default tab
		this.currentSubTab = 'force';
		this.currentTabHotkeys = null;
		this.currentTabButton = document.getElementById(this.currentTab + 'Tab');// Set default tab button
        this.eventManager.addListener('changeTab', this.changeTab.bind(this));


        this.createTabEventListeners();
    }

    populateTabs(){
        for (const tab of this.tabDisplays){
            tab.populateDisplay();
        }

        for (const tab of this.tabs) {
            this.populateTabInfoTooltips(tab);
        }
    }

    updateCurrentTabDisplay(){
		
        //perform follow-up UI updates only when tabs are the current active tab
        switch (this.currentTab) {
            case "training":
                this.trainingTab.updateDisplay();
                break;
            case "forge":
                this.forgeTab.updateDisplay();
                break;
            case "skills":
                this.skillsTab.updateDisplay();
                break;
            case "essence":
                this.essenceTab.updateDisplay();
                break;
            case "achievements":
                this.achievementsTab.updateDisplay();
                break;
            case "exploration":
                this.explorationTab.updateDisplay();
                break;
            case "settings":
                this.settingsTab.updateDisplay();
                break;
            case "radiance":
                this.radianceTab.updateDisplay();
                break;
            default:
                console.warn(`Unknown tab: ${this.ui.activeTab}`);
        }
    }

    initTabData() { 
		this.tabData.forEach(data => {
			const { id, name, active, initialUnlockedFeatureIDs, infoText, subTabs = [], hasBeenOpened = false } = data;
			const newTab = new Tab(this.eventManager, id, name, active, initialUnlockedFeatureIDs, infoText, null, null, hasBeenOpened);
	
			this.setTabInitialFeatureUnlockRefs(newTab);
	
			// Create sub tabs if they exist
			subTabs.forEach(subTabData => {
				const { id, name, active, initialUnlockedFeatureIDs, infoText } = subTabData;
				const newSubTab = new Tab(this.eventManager, id, name, active, initialUnlockedFeatureIDs, infoText, null, newTab);
	
				this.setTabInitialFeatureUnlockRefs(newSubTab);
	
				// Add sub tab to parent tab's subTab list
				newTab.subTabs.push(newSubTab);
				if (newTab.subTabs.length === 1){
					newTab.currentSubTab = newSubTab.name;
				}
	
				this.propagateTabReferences(newSubTab);
			});
			this.propagateTabReferences(newTab);
		});
	}
	

    // Add the tab to tabs list and id to object map
    propagateTabReferences(tab){
        this.tabs.push(tab);
        // this.gameContent.tabs.push(tab);
        this.gameContent.idToObjectMap.set(tab.id, tab);
    }

    //add refernces to objects that the tab unlocks when it is initially set active
    setTabInitialFeatureUnlockRefs(tab){
        if (tab.initialUnlockedFeatureIDs) {
            for (const id of tab.initialUnlockedFeatureIDs){
                if (this.ui.gameManager.findObjectById(id)){
                    tab.initialUnlockedFeatureRefs.push(this.ui.gameManager.findObjectById(id));
                }
            }
        }
    }

    toggleSubTabContentDisplay(parentId, idIncludes, displayStyle) {
		// Get the parent container for the tabs
		const parent = document.getElementById(parentId);
	
		// Toggle all content display
		let contents = Array.from(parent.children);
		contents.forEach(content => {
			if (content.id.includes(idIncludes)) {
				content.style.display = displayStyle;
			}
		});
	}

	toggleActiveSubTabClass(containerId, newActiveId) {
		// Get the parent container
		const container = document.getElementById(containerId);
		let buttons = Array.from(container.children);
	
		// Remove 'active-tab' class from all buttons
		buttons.forEach(button => {
			button.classList.remove('active-tab');
		});
	
		// Add 'active-tab' class to the new active element
		let newActiveElement = document.getElementById(newActiveId);
		newActiveElement.classList.add('active-tab');

	}

    returnSubTabButtons(){
		let currentTabElement = document.getElementById(this.currentTab);
		let allButtons = Array.from(currentTabElement.querySelectorAll('button'));
	
		let filteredButtons = allButtons.filter(button => {
			return (button.id.includes('SubTab')) 
				&& getComputedStyle(button).opacity !== '0';
		});
	
		if (filteredButtons.length === 0){
			this.currentSubTab = null;
		}
	
		return filteredButtons;
	}

    getActiveTabs() {
		let activeTabs = [];
	  
		// Iterate over the tab objects to check if the tab is active
		for (const tab of this.tabs) {
		  	if (tab.active && !tab.parentTab) {
				activeTabs.push(tab);
		  	}
		}
		return activeTabs;
	}

    updateTabButtons() {
		for (const tab of this.tabs) {
			// Update the visibility and interaction of tab buttons
			tab.button.style.pointerEvents = tab.active ? 'auto' : 'none';
			tab.button.classList.toggle('inactive-tab', !tab.active);
	
			// Flash newly unlocked tabs a soft blue until they are visited for the first time
			tab.button.classList.toggle('unvisited-tab', tab.active && tab.hasBeenOpened === false);
	
			// Update skills tab to reflect unused skill points
			if (tab.name === "skills" && tab.active) {
				tab.button.classList.toggle('tab-green-indicator', this.gameContent.skillpoints.gt(0));
			}
	
			// Achievements tab show indication when an achievement is claimable
			if (tab.name === "achievements" && tab.active) {
				let isClaimableAchievement = this.gameContent.achievements.some(achievement => achievement.isClaimable && !achievement.isClaimed);
				tab.button.classList.toggle('tab-green-indicator', isClaimableAchievement);
			}
	
			// Update Exploration and Odyssey tabs to reflect an indicator light
			if (tab.name === "exploration" || tab.name === "odyssey") {
				let isConquesting = false;
				let isAvailable = false;
	
				// Check if any zone is conquesting
				for (const zone of this.gameContent.zones) {
					if (zone.isConquesting) {
						isConquesting = true;
						break;
					}
				}
	
				// Only check for availability if no zones are conquesting
				if (!isConquesting) {
					for (const zone of this.gameContent.zones) {
						if (zone.active && !zone.isConquesting && !zone.isDefeated && zone.unlocked) {
							if (this.ui.isAffordable(zone)){

								// if zone is legendary boss, make sure its connection is active
								if (zone.zoneType === "legendaryBoss"){
									if (zone.parent && zone.parent.active && this.ui.isAffordable(zone)){
										isAvailable = true;
										break;
									}
								}
								// if not - just set it active
								else {
									isAvailable = true;
									break;
								}
							}
						}
					}
				}
	
				// Update the tab indicator
				tab.button.classList.toggle('tab-yellow-indicator', isConquesting);
				tab.button.classList.toggle('tab-green-indicator', isAvailable);
			}
		}
	}
	

    assignButtonsToTabObjects(){
		let primaryTabButtons = Array.from(document.getElementsByClassName('tabButton'));

		let explorationSubTabButtons = Array.from(document.getElementsByClassName('exploration-tab-button'));
		let realmSubTabButtons = Array.from(document.getElementsByClassName('realm-button'));
		let allTabButtons = primaryTabButtons.concat(explorationSubTabButtons).concat(realmSubTabButtons);

		for (const tabButton of allTabButtons) {
			let tabButtonIdWithoutTab = tabButton.id.replace('Tab', '').replace('realm-','').replace('Sub','').toLowerCase();

			for (const tab of this.tabs){
				if (tab.name === tabButtonIdWithoutTab) {
					tab.button = tabButton;
					break;  // Breaks the inner loop if the matching button is found
				}
			}
		}
	}

    createTabEventListeners() {
		const tabButtons = document.getElementById('tab-buttons').getElementsByTagName('button');
	
		// Loop through each button and add a click event listener
		for (let button of tabButtons) {
			const buttonClickHandler = () => {
				const tabId = button.id.slice(0, -3);
				this.changeTab(tabId);
			};
			this.eventManager.addDomListener(button, 'click', buttonClickHandler);
		}
	}

    changeTab(tabName) {
		// Hide the current tab
		this.ui.hideElement(this.currentTab);
	
		let currentTabObject = this.tabs.find(tab => tab.name === this.currentTab);
		if(currentTabObject) {
			this.ui.multiplierManager.toggleMultiplierContainer(currentTabObject, "none");
	
			// Hide the current subTab's multiplier container if exists
			if (this.currentSubTab){
				let currentSubTabObject = this.tabs.find(tab => tab.name === this.currentSubTab);
				this.ui.multiplierManager.toggleMultiplierContainer(currentSubTabObject, "none");
			}
		}
	
		//set new current tab
		if (tabName) {
			this.currentTab = tabName;
		}
	
		let newTabObject = this.tabs.find(tab => tab.name === tabName);
		newTabObject.hasBeenOpened = true;
			
		if (!newTabObject.subTabs.length > 0){
			this.currentSubTab = null;
		}
		else {
			this.currentSubTab = newTabObject.currentSubTab;
	
			// Show the current subTab's multiplier container if exists
			let newSubTabObject = this.tabs.find(tab => tab.name === this.currentSubTab);
			
			newSubTabObject.hasBeenOpened = true;
			this.ui.multiplierManager.toggleMultiplierContainer(newSubTabObject, "block");
				
			//also assign the current subtab to the current tab
			newTabObject.currentSubTabObject = newSubTabObject;
		}
	
		// Show the new tab
		this.ui.showElement(tabName);
	
		this.ui.multiplierManager.toggleMultiplierContainer(newTabObject, "block");
	
		if (this.currentTabButton) {
			this.currentTabButton.classList.remove('active-tab');
		}
	
		this.currentTabButton = document.getElementById(this.currentTab + 'Tab');
		if (this.currentTabButton) {
			this.currentTabButton.classList.add('active-tab');
		}
	
		this.eventManager.dispatchEvent('updateHotkeyButtons');
		// this.ui.updateVisibility(newTabObject.multiplierContainer,newTabObject.active);
		this.ui.gameManager.onTabChange(newTabObject);
	}

    populateTabInfoTooltips(tab) {
        if (!tab.infoText){
            return;
        }

        //find tab ID's, doing this manually for subtabs for now until Tab initialization is cleaned up
        let tabElementID = tab.name;
        if (tab.parentTab){
            if (tab.parentTab.name === "training"){
                tabElementID = 'realm-content-' + tab.name;
            }
            else if (tab.parentTab.name === "exploration"){
                tabElementID = 'tab-content-' + tab.name.charAt(0).toUpperCase() + tab.name.slice(1) + 'SubTab';
            }
        }
        let targetElement = document.getElementById(tabElementID);
        let tabInfoText = tab.infoText.join('\n\n');

        if (targetElement) {
            let tabInfoContainer = this.ui.createElement('div',`${tab.name}-tab-info`,`tab-info-container`);

            const infoIcon = this.ui.createElement('img',null,`info-icon`);
            infoIcon.src = iconImages.info_icon;
            infoIcon.alt = 'Info';

            const infoTextContainer = this.ui.createElement('div',`${tab.name}-info-text`,'info-text-container');
            this.ui.updateElementHTML(infoTextContainer,tabInfoText);
            infoTextContainer.style.display = "none";  // initially hidden

            const infoIconClickHandler = () => {
				if (infoTextContainer.style.display === "none") {
					infoTextContainer.style.display = "block";
				} else {
					infoTextContainer.style.display = "none";
				}
			};
			this.eventManager.addDomListener(infoIcon, 'click', infoIconClickHandler);


			// add window click listener to close the info text
			if (!this.windowClickListenerAdded) {
				window.addEventListener('click', (event) => {
					// Close all info text containers if clicked outside of any info icon
					if (!event.target.classList.contains('info-icon')) {
						const infoTextContainers = document.querySelectorAll('.info-text-container');
						infoTextContainers.forEach(container => {
							container.style.display = "none";
						});
					}
				});
				this.windowClickListenerAdded = true;
			}
			
            tabInfoContainer.appendChild(infoIcon);
            tabInfoContainer.appendChild(infoTextContainer);
            targetElement.prepend(tabInfoContainer);
            // infoIcon.click();
        }
    }	
}