export default class MiniMap {
    constructor(eventManager, ui, worldManager, worldDisplayManager) {
        this.eventManager = eventManager;
        this.ui = ui;
        this.worldManager = worldManager;
        this.worldDisplayManager = worldDisplayManager;

        this.eventManager.addListener('updateMiniMap', this.updateMiniMap.bind(this));
        this.regionNameElement = null;
    }

    // Creates the mini-map grid and appends it to the map view.
    createMiniMap(mapView) {
        let targetCol = document.getElementById('tab-col1-OdysseySubTab');
        this.miniMapGrid = this.ui.createElement('div', 'mini-map-grid');
        targetCol.appendChild(this.miniMapGrid);
        this.regionNameElement = this.ui.createElement('div', 'mini-map-region-name');
        targetCol.appendChild(this.regionNameElement);
    }

    // Updates the mini-map based on the current world and its regions.
    updateMiniMap() {
        if (this.worldDisplayManager.currentlyViewedRegion) {
            this.clearMiniMap();
            const currentWorld = this.worldDisplayManager.currentlyViewedRegion.world;
            const currentRegion = this.worldDisplayManager.currentlyViewedRegion;

            if (currentWorld) {
                const gridDimensions = this.calculateGridDimensions(currentWorld.regions);
                this.miniMapGrid.style.gridTemplateColumns = `repeat(${gridDimensions.width}, 1fr)`;
                this.miniMapGrid.style.gridTemplateRows = `repeat(${gridDimensions.height}, 1fr)`;

                currentWorld.regions.forEach(region => {
                    const regionSquare = this.createRegionSquare(region, gridDimensions, region.progressPercentage);
                    if (region === currentRegion) {
                        regionSquare.classList.add('current');
                    }
                    this.miniMapGrid.appendChild(regionSquare);
                });
            }
        }
    }

    // only update minimap elements if there are changes detected
    updateMiniMapProgress() {
        if (this.worldDisplayManager.currentlyViewedRegion && this.worldDisplayManager.currentlyViewedRegion.world && this.worldDisplayManager.currentViewType === 'region') {
            const currentWorld = this.worldDisplayManager.currentlyViewedRegion.world;
            currentWorld.regions.forEach(region => {
                region.updateZoneAvailability(); 
                
                const topLeftElement = document.getElementById(`mini-map-region-${region.id}-top-left`);
                if (topLeftElement) {
                    const newTopLeftContent = region.active ? `${Math.round(region.progressPercentage)}%` : "";
                    if (topLeftElement.textContent !== newTopLeftContent) {
                        topLeftElement.textContent = newTopLeftContent;
                    }
                }
                
                const bottomRightElement = document.getElementById(`mini-map-region-${region.id}-bottom-right`);
                if (bottomRightElement) {
                    if (region.active) {
                        if (bottomRightElement.textContent !== "•") {
                            bottomRightElement.textContent = "•"; // Set bullet point
                        }
                        
                        let newDisplay, newBackgroundColor;
                        if (region.zoneAvailable === 'available') {
                            newDisplay = 'block';
                            newBackgroundColor = '#6bff79';
                        } else if (region.zoneAvailable === 'conquesting') {
                            newDisplay = 'block';
                            newBackgroundColor = 'yellow';
                        } else {
                            newBackgroundColor = 'grey';
                            newDisplay = 'none';
                        }
    
                        if (bottomRightElement.style.display !== newDisplay) {
                            bottomRightElement.style.display = newDisplay;
                        }
                        if (bottomRightElement.style.backgroundColor !== newBackgroundColor) {
                            bottomRightElement.style.backgroundColor = newBackgroundColor;
                        }
                    } else {
                        if (bottomRightElement.textContent !== "") {
                            bottomRightElement.textContent = "";
                        }
                    }
                }
            });
        }
    }
    
    
    // updated to new function so only update elements if there are changes detected
    // updateMiniMapProgress() {
    //     if (this.worldDisplayManager.currentlyViewedRegion && this.worldDisplayManager.currentlyViewedRegion.world && this.worldDisplayManager.currentViewType === 'region') {
    //         const currentWorld = this.worldDisplayManager.currentlyViewedRegion.world;
    //         currentWorld.regions.forEach(region => {
    //             region.updateZoneAvailability(); 
                
    //             const topLeftElement = document.getElementById(`mini-map-region-${region.id}-top-left`);
    //             if (topLeftElement) {
    //                 if (region.active) {
    //                     topLeftElement.textContent = `${Math.round(region.progressPercentage)}%`;
    //                 } else {
    //                     topLeftElement.textContent = "";
    //                 }
    //             }
                
    //             const bottomRightElement = document.getElementById(`mini-map-region-${region.id}-bottom-right`);
    //             if (bottomRightElement) {
    //                 if (region.active) {
    //                     bottomRightElement.textContent = "•"; // Set bullet point
    //                     if (region.zoneAvailable === 'available') {
    //                         bottomRightElement.style.display = 'block';
    //                         bottomRightElement.style.backgroundColor = '#6bff79';
    //                     } else if (region.zoneAvailable === 'conquesting') {
    //                         bottomRightElement.style.display = 'block';
    //                         bottomRightElement.style.backgroundColor = 'yellow';
    //                     } else {
    //                         bottomRightElement.style.backgroundColor = 'grey';
    //                         bottomRightElement.style.display = 'none';
    //                     }
    //                 } else {
    //                     bottomRightElement.textContent = "";
    //                 }
    //             }
    //         });
    //     }
    // }

    
    
    

    // Calculates the grid dimensions based on the regions' positions.
    calculateGridDimensions(regions) {
        let maxRegionX = 0;
        let maxRegionY = 0;
        regions.forEach(region => {
            maxRegionX = Math.max(maxRegionX, region.topLeftCornerX);
            maxRegionY = Math.max(maxRegionY, region.topLeftCornerY);
        });

        return {
            width: maxRegionX / 100 + 1,
            height: maxRegionY / 100 + 1
        };
    }

    // Creates a square element representing a region in the mini-map.
    createRegionSquare(region, gridDimensions, progress) {
        const regionSquare = this.ui.createElement('div', `mini-map-region-${region.id}`, 'mini-map-region');
        const row = region.topLeftCornerY / 100;
        const col = region.topLeftCornerX / 100;

        // Create and append sub-elements
        const topLeftElement = this.ui.createElement('div', `mini-map-region-${region.id}-top-left`, ['mini-map-sub-element', 'top-left']);
        topLeftElement.textContent = `${progress}%`; // Display progress percentage
        topLeftElement.style.position = 'absolute';
        topLeftElement.style.top = '0';
        topLeftElement.style.left = '0';

        const bottomRightElement = this.ui.createElement('div', `mini-map-region-${region.id}-bottom-right`, ['mini-map-sub-element', 'bottom-right']);
        bottomRightElement.textContent = 'B'; // Placeholder letter
        bottomRightElement.style.position = 'absolute';
        bottomRightElement.style.bottom = '0';
        bottomRightElement.style.right = '0';

        regionSquare.appendChild(topLeftElement);
        regionSquare.appendChild(bottomRightElement);

        if (!region.active) {
            regionSquare.classList.add('inactive');
        } else {
            const regionSquareClickHandler = () => {
                this.handleRegionClick(region);
            };
            this.eventManager.addDomListener(regionSquare, 'click', regionSquareClickHandler);

            const regionSquareMouseEnterHandler = () => {
                this.showRegionName(region);
            };
            this.eventManager.addDomListener(regionSquare, 'mouseenter', regionSquareMouseEnterHandler);

            const regionSquareMouseLeaveHandler = () => {
                this.hideRegionName();
            };
            this.eventManager.addDomListener(regionSquare, 'mouseleave', regionSquareMouseLeaveHandler);
        }

        
        regionSquare.style.backgroundImage = `url(${region.image})`;
        regionSquare.style.backgroundSize = 'cover';

        return regionSquare;
    }

    showRegionName(region) {
        this.regionNameElement.textContent = region.name;
    }

    hideRegionName() {
        this.regionNameElement.textContent = '';
    }

    handleRegionClick(region) {
        this.eventManager.dispatchEvent('updateMapView', region);
    }

    // Clears the mini-map by removing all child elements.
    clearMiniMap() {
        while (this.miniMapGrid.firstChild) {
            this.miniMapGrid.removeChild(this.miniMapGrid.firstChild);
        }
    }

    // Shows or hides the mini-map based on the current view type.
    toggleMiniMapVisibility(viewType) {
        this.miniMapGrid.style.display = viewType === 'region' ? 'grid' : 'none';
    }
}
