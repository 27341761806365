import { DEC } from '../../Utilities/decimal.js';

import NumberFormatter from './NumberFormatter.js';
import HotkeyManager from './HotkeyManager.js';
import MultiplierManager from './MultiplierManager.js';
import NotificationManager from './NotificationManager.js';
import TabManager from './TabManager.js';
import StatsDisplay from '../DisplayComponents/StatsDisplay.js';

import gameData from '../../../assets/gameData/*.json';


export default class GameUI {
	constructor(eventManager, gameManager, stateManager, rewardManager,injectAdminSettings) {
		this.affordableBypass = false;
		this.isUiPopulated = false;

		// Update interval defined in Game

		this.eventManager = eventManager;
		this.gameManager = gameManager;
		this.injectAdminSettings = injectAdminSettings;
		this.gameContent = gameManager.gameContent;
		this.stateManager = stateManager;
		this.rewardManager = rewardManager;

		this.buildUiSkeleton();

		this.numberFormatter = new NumberFormatter(eventManager, this);

		this.tabManager = new TabManager(eventManager,this.gameContent,gameData.tabData,this);
		this.hotkeyManager = new HotkeyManager(eventManager,this);
		this.multiplierManager = new MultiplierManager(eventManager, this);
		this.notificationManager = new NotificationManager(eventManager,this);
		this.statsDisplay = new StatsDisplay(eventManager, this.gameContent, this);
		
		// this.elements = [];
		
		this.populateUI();
		
		this.multiplierManager.setupMultiplierButtons(this.tabManager.tabs);
		
		this.tabManager.changeTab('training'); // Set start tab
		this.hotkeyManager.initHotkeys();

		this.eventManager.addListener('triggerPopup', this.onTriggerPopup.bind(this));
        // Initialize a queue for popups
        this.popupQueue = [];
        this.isPopupActive = false;



		// TIME TRACKING
		if (this.injectAdminSettings){
			this.unlockTimes = []; // Array to store unlock times
			this.logInterval = 60000; // Log interval in milliseconds
			this.lastLogTime = 0; // Timestamp of the last log
		}
		// TIME TRACKING
	}

	populateUI(){
		this.statsDisplay.populateDisplay();
		this.tabManager.populateTabs();
		this.isUiPopulated = true;
	}

	updateUI() {

		//TIME TRACKING
		let startTime;
		if (this.injectAdminSettings){
			startTime = performance.now();
		}
		//TIME TRACKING


		this.statsDisplay.updateDisplay();
		this.tabManager.updateTabButtons();
		this.tabManager.updateCurrentTabDisplay();

		// TIME TRACKING
		
		if (this.injectAdminSettings){
			const endTime = performance.now();
			const elapsedTime = endTime - startTime;
			this.unlockTimes.push(elapsedTime);
			const currentTime = performance.now();
			if (currentTime - this.lastLogTime >= this.logInterval) {
				this.logUnlockTimes();
				this.lastLogTime = currentTime;
			}
		}
		// TIME TRACKING
	}

	//TIME TRACKING
	logUnlockTimes() {
		const count = this.unlockTimes.length;
		if (count === 0) {
			return;
		}

		const sum = this.unlockTimes.reduce((a, b) => a + b, 0);
		const avg = sum / count;
		const min = Math.min(...this.unlockTimes);
		const max = Math.max(...this.unlockTimes);

		console.log(`Update UI times (last ${count} runs) - Average: ${avg.toFixed(2)} ms, Minimum: ${min.toFixed(2)} ms, Maximum: ${max.toFixed(2)} ms`);

		this.unlockTimes = []; // Reset the unlock times array
	}
	//TIME TRACKING

	returnFirstTenButtonElements(tabID) {
		let currentTabElement, allButtons, filteredButtons;
		
		if (tabID === "tab-content-OdysseySubTab") {
			currentTabElement = document.getElementById("Odyssey-tab-buttons");
			allButtons = Array.from(currentTabElement.querySelectorAll('button'));
			filteredButtons = allButtons.filter(button => {
				return !(button.id.includes('realm') || button.id.includes('refund') || button.id.includes('toggle')) && getComputedStyle(button).opacity !== '0';
			});
		} else {
			currentTabElement = document.getElementById(tabID);
			allButtons = Array.from(currentTabElement.querySelectorAll('button'));
			filteredButtons = allButtons.filter(button => {
				if (tabID === 'essence' && button.id === 'rebirth1Button') {
					return false;
				}
				return !(button.id.includes('realm') || button.id.includes('tab') || button.id.includes('refund') || button.id.includes('Tab') || button.id.includes('toggle')) && getComputedStyle(button).opacity !== '0';
			}).slice(0, 10); // Grab the first 10 buttons
		}
		
		return filteredButtons;
	}
	
	
	buildUiSkeleton() {
		this.uiElementsMap = new Map(); 
		this.uiElementCounter = 0;

		this.rootElement = document.getElementById("root");

		gameData.interfaceElementData.forEach(element => {
			if (!this[element.variableName]) {
				let newElement = document.createElement(element.tag);
	
				if (element.tag === "select") {
					element.options.forEach(optionValue => {
						let option = document.createElement('option');
						option.value = optionValue;
						option.text = optionValue;
						newElement.appendChild(option);
					});
				}
	
				newElement.id = element.id;
	
				// Assigning button text as id
				if (element.tag === "button") {
					let name = element.id;
					if (name.includes("SubTab")){
						name = name.replace("SubTab", "");
					}
					else if (name.includes("Tab")){
						name = name.replace("Tab", "");
					}

					// Making the first letter uppercase
					name = name.charAt(0).toUpperCase() + name.slice(1);

					newElement.textContent = name;
				}

				if (element.className) {
					newElement.className = element.className;
				}
	
				let parentElement = document.getElementById(element.parent);
				parentElement.appendChild(newElement);
	
				this[element.variableName] = newElement; // store the reference
				this.uiElementsMap.set(element.variableName, newElement); // Add to the Map for later deletion
			}
		});
	}

	hideElement(id) {
		let element = document.getElementById(id);

		if (element) {
			element.style.display = "none";
		}
	}
	
	showElement(id, displayStyle="flex") {
		// console.error(id);
		let element = document.getElementById(id);
		// console.error(element);
		if (element) {
			element.style.display = displayStyle;
		}
	}

	isAffordable(feature) {
		if (this.affordableBypass){
			feature.isAffordable = true;
			return true;
		}
		let currentResource = this.gameManager.gameContent.queryPropertyValues(feature.costType);

		if (feature.featureType === "zone") {
			if (currentResource.gte(feature.costBase)) {
				feature.isAffordable = true;
				return true;
			}
			feature.isAffordable = false;
			return false;
		}
		if (currentResource.gte(feature.costNextMultPurchase)) {
			feature.isAffordable = true;
			return true;
		}
		feature.isAffordable = false;
		return false;
	}
	
	hideChildren(parentElement) {
		Array.from(parentElement.children).forEach(child => {
		child.style.display = 'none';
		});
	}

	populateMouseOverZIndexEvents(element) {
		let originalZIndex = element.style.zIndex;
	
		// Define named functions for the mouseover and mouseout events
		const mouseOverListener = () => {
			element.style.zIndex = 1000;
		};
		const mouseOutListener = () => {
			element.style.zIndex = originalZIndex;
		};
	
		// Use EventManager to add the event listeners
		this.eventManager.addDomListener(element, 'mouseover', mouseOverListener);
		this.eventManager.addDomListener(element, 'mouseout', mouseOutListener);
	
		// Optionally store the listeners if you need to remove them later
		element.mouseOverListener = mouseOverListener;
		element.mouseOutListener = mouseOutListener;
	}
	
	populateAutobuyCheckbox(feature, container, heap) {
		let checkbox = container.querySelector(`#checkbox-${feature.id}`);
		let label = container.querySelector(`#label-${feature.id}`);
	
		if (!checkbox) {
			checkbox = this.createElement('input', `checkbox-${feature.id}`);
			checkbox.type = 'checkbox';
			checkbox.style.display = 'none';
	
			const checkboxChangeListener = () => {
				feature.autoToggle = checkbox.checked;
				if (feature.featureType === "artifact") {
					if (checkbox.checked) {
						this.gameManager.automationManager.artifactAutobuys.push(feature);
					} else {
						this.gameManager.automationManager.artifactAutobuys = this.gameManager.automationManager.artifactAutobuys.filter(item => item !== feature);
					}
				} else {
					if (checkbox.checked) {
						heap.add(feature);
						feature.currentAutoHeap = heap;
					} else {
						heap.remove(feature);
						feature.currentAutoHeap = null;
					}
				}
			};
	
			this.eventManager.addDomListener(checkbox, 'change', checkboxChangeListener);
			checkbox.changeListener = checkboxChangeListener;
	
			label = this.createElement('label', `label-${feature.id}`, null, "Auto");
			label.htmlFor = checkbox.id;
			label.style.fontSize = 'small';
	
			container.appendChild(checkbox);
			container.appendChild(label);
		}
	}
	
	updateAutobuyCheckbox(feature, container) {
		let checkbox = container.querySelector(`#checkbox-${feature.id}`);
		let label = container.querySelector(`#label-${feature.id}`);
	
		if (feature.autoUnlocked) {
			label.style.opacity = 1;
			label.style.pointerEvents = 'auto';
		} else {
			label.style.opacity = 0;
			label.style.pointerEvents = 'none';
		}
	
		if (feature.maxLevel.eq(feature.level)){
			checkbox.checked = false;
			checkbox.disabled = true;
		} else {
			checkbox.checked = feature.autoToggle;
			checkbox.disabled = !feature.active;
		}
	}
	

	populateTooltip(feature, element){
		let tooltip;
		if (feature.id){
			tooltip = this.createElement('span',`tooltip-${feature.id}`,'tooltip-text',"Tooltip content here");
			
		}
		else {
			tooltip = this.createElement('span',`tooltip-${feature}`,['tooltip-text','stat-tooltip-text'],"Tooltip content here");
		}
		
		element.appendChild(tooltip);
	}

	updateTooltip(feature, stat = null){
		let tooltipText = [];

		//populate tooltips for stats in stats row
		if (stat){
			this.updateStatsRowTooltips(stat, tooltipText);
		}

		else {
			tooltipText = [];
			
			if (feature.featureType === "forgeUpgrade"){
				tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
				
			
				if (feature.note.length > 0) {
					tooltipText.push(`${feature.note}`);
				}
				
				if (feature.description.length > 0) {
					tooltipText.push(`${feature.description}`);
					tooltipText.push('------');
				}
				tooltipText.push(`<b>Cost: </b>${this.formatNumber(feature.costBase)} ${feature.costType}`);
			}
			else if (feature.featureType === "zone") {
				tooltipText = [];
				
				// Adding zone name to tooltip
				tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
			
				// Handling zone types with specific styles and descriptions
				const zoneTypeStyles = {
					"progressionBoss": `<span style = "color:yellow; font-weight:bold;">Unlocker Zone</span>`,
					"boss": `<span style="color:orange; font-weight:bold;">Regional Boss</span>`,
					"legendaryBoss": `<span style = "color:cyan; font-weight:bold;">Legendary Boss</span>`
				};
			
				if (feature.zoneType in zoneTypeStyles) {
					tooltipText.push(zoneTypeStyles[feature.zoneType]);
				}
			
				// Additional description for legendaryBoss
				if (feature.zoneType === "legendaryBoss" && feature.description.length > 0) {
					// tooltipText.push(feature.description);
				}
			
				// Adding note and description if present
				if (feature.note.length > 0) {
					tooltipText.push(`${feature.note}`);
					tooltipText.push('------');
				}
			
				if (feature.description.length > 0) {
					tooltipText.push(`${feature.description}`);
					tooltipText.push('------');
				}
			
				// Special case for region boss
				if (feature.zoneType === "boss") {
					tooltipText.push(`Region Boss: 1 skillpoints on first kill`);
				}
			
				// Handling unlocked zones
				if (feature.unlocked) {
					// Reset tooltip for specific case of unlocked, undefeated legendaryBoss
					if (feature.zoneType === "legendaryBoss" && !feature.isDefeated) {
						tooltipText = [];
						tooltipText.push(zoneTypeStyles["legendaryBoss"]);
						tooltipText.push(feature.unlockReqText);
					} else {
						if (feature.zoneType !== "progressionBoss") {
							tooltipText.push(`<b>Requires:</b> ${this.formatNumber(feature.costNextMultPurchase)} ${feature.costType}`);
						}
						if (feature.unlockReqText.length > 0) {
							tooltipText.push(feature.unlockReqText);
						}
						tooltipText.push(`<b>Duration:</b> ${Math.round(feature.conquestTime * 100) / 100} sec`);
						if (feature.timeRemaining > 0) {
							tooltipText.push(`<b>Remaining:</b> ${feature.timeRemaining.toFixed(2)} sec`);
						}
					}
				} else if (feature.zoneType === "legendaryBoss") {
					// Handle locked legendary boss case
					tooltipText = [];
					tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
					tooltipText.push(feature.unlockReqText);
					if (feature.description.length > 0) {
						tooltipText.push('------');
						tooltipText.push(`${feature.description}`);
					}
				} else {
					console.log("uh oh-update tooltip for zones triggered by id: ", feature.id, "name:", feature.name);
				}
			}
			
			else if (feature.featureType === "skill"){
				tooltipText = [];
				
				tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
				// tooltipText.push("");
				
				if (feature.note.length > 0) {
					tooltipText.push(`${feature.note}`);
				}
				
				if (feature.description.length > 0) {
						tooltipText.push(`${feature.description}`);
					}
				tooltipText.push('------');
				if (feature.level.lt(feature.maxLevel)){
					tooltipText.push(`<b>Cost:</b> ${feature.costNextMultPurchase}`);
				}
				else {
					tooltipText.push(`<b>Active</b>`);
				}
			}
			else if (feature.featureType === "artifact"){
				tooltipText = [];
				tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
				
				if (feature.note.length > 0) {
					tooltipText.push(`${feature.note}`);
				}
				
				if (feature.description.length > 0) {
					tooltipText.push(`${feature.description}`);
				}
				tooltipText.push('------');


				const modData = feature.observers[0];
				let contribution;
				if (feature.level.eq(0)) {
					contribution = DEC(0);
				}
				else {
					contribution = DEC(modData.value).plus(feature.level);
				}
				// Generate the artifact description dynamically
				const description = `${modData.targetType} ${modData.type} ${modData.runningCalcType} (${modData.value} ${modData.sourceCalcType} artifact level)`;
				tooltipText.push(`${description}`);
				
				


				tooltipText.push(`<b>Current Effect:</b> ${modData.targetType} ${modData.type} ${modData.runningCalcType} ${contribution}`);
				
				tooltipText.push(`<b>Item Tier:</b> ${feature.evolutionTier}`);
				
				const nextEvolution = feature.evolutions.find(evo => evo.evolutionTier === Number(feature.evolutionTier) + 1);
				
				if (nextEvolution) {
					tooltipText.push(`Artifact evolves at level ${feature.maxLevel}`);
				} else {
					tooltipText.push(`Artifact at max evolution`);
				}
				  
			}
			else if (feature.featureType === "essenceUpgrade"){
				tooltipText = [];
				tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
				tooltipText.push('');
				
				if (feature.note.length > 0) {
					tooltipText.push(`${feature.note}`);
				}
				
				if (feature.description.length > 0) {
					tooltipText.push(`${feature.description}`);
				}
				tooltipText.push('------');
				tooltipText.push(`<b>Level:</b> ${feature.level}/${feature.maxLevel}`);
				tooltipText.push(`<b>Cost:</b> ${feature.costNextMultPurchase}`);
			}
			else if (feature.featureType === "radianceUpgrade"){
				tooltipText = [];
				tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
				tooltipText.push('');
				
				if (feature.note.length > 0) {
					tooltipText.push(`${feature.note}`);
				}
				
				if (feature.description.length > 0) {
					tooltipText.push(`${feature.description}`);
				}
				tooltipText.push('------');
				tooltipText.push(`<b>Cost:</b> ${feature.costNextMultPurchase}`);
			}
			else if (feature.featureType === "achievement"){
				tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
				tooltipText.push("");
				let modData = feature.observers[0];
				
				if (feature.dependent){
					tooltipText.push(`<b>Condition:</b> ${feature.dependent.name} ${feature.conditionType} ${feature.conditionValue}`);
				}
				else{
					tooltipText.push(`<b>Condition:</b> ${DEC(feature.conditionValue).toFixed(0)} ${feature.conditionType}`);
				}
				tooltipText.push(`\n<b>Reward:</b> ${modData.targetType} ${modData.type} multiplier ${modData.runningCalcType} ${modData.value} `);

				tooltipText.push(`<b>Radiance Bonus:</b> ${feature.radianceReward}`);
				let achievementText = '<b>Set Bonus:</b> ' + feature.set.description;
				if (feature.set.completed){
				achievementText = `<span style="color:${feature.set.color}; font-weight:bold;">${achievementText} <br>(Active)</span>`;
				}
				tooltipText.push(achievementText);
			}
			else if (feature.featureType === "realmUpgrade") {
				tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
				tooltipText.push(feature.note);
				tooltipText.push('-----');
				for (let obs of feature.observers) {
					// Check if 'obs' has a 'targetType' property
					if (obs.targetType) {
						tooltipText.push('type targetting - not handled yet ');
						continue; // Skip to the next iteration of the loop
					}
			
					let tree = obs.target.modTreesMap.get("production");
					let targetNode = tree.nodes.find(node => node.ref.id === obs.id);
					let treeType = "prod";
				
					// Check if targetNode wasn't found in 'production'. If not, look for it in 'cost'
					if (!targetNode) {
						tree = obs.target.modTreesMap.get("cost");
						targetNode = tree.nodes.find(node => node.ref.id === obs.id);
						treeType = "cost";
					}
				
					if (targetNode) {
						tooltipText.push(`<b>Effect: </b>${obs.target.name} ${treeType} ${obs.runningCalcType} level`);

						
						let result = tree.calcNodeResult(targetNode, obs.source.level);
						tooltipText.push(`<b>Current Contribution:</b> ${treeType} ${obs.runningCalcType} ${result.toFixed(3)} `);
					}
				}
			}
			
			else if (feature.featureType === "training" || feature.featureType === "generator"){
				tooltipText = [];
				tooltipText.push(`<span style="font-size:120%; font-weight: bold;">${feature.name}</span>`);
				
				if (feature.note.length > 0) {
					tooltipText.push(`${feature.note}`);
				}
				
				if (feature.description.length > 0) {
					tooltipText.push(`${feature.description}`);
				}
				tooltipText.push('------');
				if (feature.featureType === "generator"){
					tooltipText.push(`Manual Levels: ${feature.manualLevel}`);
				}
				tooltipText.push(`<b>Production Multiplier:</b> ${feature.prodMult.toPrecision(3)}\n<b>Production Growthrate:</b> ${feature.prodGrowthRate.toPrecision(3)}`);
				tooltipText.push(`<b>Cost Multiplier:</b> ${feature.costMult.toPrecision(3)}\n<b>Cost Growthrate:</b> ${feature.costGrowthRate.toPrecision(3)}`);
				tooltipText.push(`<b>Evolution Tier:</b> ${feature.evolutionTier}`);

				//dont display prodNextMultPurchase value on upgrades or features who dont have prodNextMultPurchase
				if (feature.prodNextMultPurchase.gt(0)){
					
					//incorporate rank multipliers into next calculations where applicable
					let rankMult = DEC(1);
					if (feature.rankTiers && feature.featureType !== "artifact"){
						if (feature.nextLevelIncrement.plus(feature.manualLevel).gte(feature.nextRankLevel)){
							rankMult = feature.nextRankMult;
						}

						tooltipText.push(`<b>Current Rank Mult:</b>${feature.ranksAchieved * 2}\n<b>Next Rank Lvl:</b> ${feature.nextRankLevel}\n<b>Next Rank Mult:</b>${feature.nextRankMult}`);
					}
				}
			}
	
			let newContent = tooltipText.join('\n');
			newContent = newContent.replace(/ mult /g, " * ")
                       .replace(/ add /g, " + ")
                       .replace(/ div /g, " / ")
                       .replace(/ minus /g, " - ");
			let tooltipElement = document.getElementById(`tooltip-${feature.id}`);


			
			// if (feature.id === 6335){
			// 	console.log("tooltipElement.innerHTML:", JSON.stringify(tooltipElement.innerHTML));
			// console.log("newContent:", JSON.stringify(newContent));
			// }
			if (tooltipElement.innerHTML.replace(/\s/g, '') !== newContent.replace(/\s/g, '')) {
				this.updateElementHTML(tooltipElement, newContent);
			  }
		}
	}

	updateStatsRowTooltips(stat, tooltipText) {
		if (this.gameContent[stat + 'PowerLevelMultiplier']) {
			tooltipText.push(`<b>${stat.charAt(0).toUpperCase() + stat.slice(1)} Info:</b>`);
			// tooltipText.push('------');
			tooltipText.push(`Total Income: ${this.formatNumber(this.gameContent[stat + 'Income'])} /sec`);
			tooltipText.push(`Contribution to Power Level: x${this.formatNumber(this.gameContent[stat + 'PowerLevelMultiplier'])}`);
		}
		else {
			tooltipText.push(`${stat} Info:`);
			if (this.gameContent.force.gt(0)) {
				tooltipText.push(`Force Contribution: ${this.formatNumber(this.gameContent.forcePowerLevelMultiplier)}x`);
			}
			if (this.gameContent.wisdom.gt(0)) {
				tooltipText.push(`Wisdom Contribution: ${this.formatNumber(this.gameContent.wisdomPowerLevelMultiplier)}x`);
			}
			if (this.gameContent.energy.gt(0)) {
				tooltipText.push(`Energy Contribution: ${this.formatNumber(this.gameContent.energyPowerLevelMultiplier)}x`);
			}
			if (this.gameContent.divine.gt(0)) {
				tooltipText.push(`Divine Contribution: ${this.formatNumber(this.gameContent.divinePowerLevelMultiplier)}x`);
			}
		}

		let newContent = tooltipText.join('\n');
		let tooltipElement = document.getElementById(`tooltip-${stat}-stat`);

		this.updateElementHTML(tooltipElement, newContent);
	}

	createElement(type, id = null, classes = [], textContent) {
		let element = document.createElement(type);
	
		if (id){
			element.id = id;
		}
	
		if (typeof classes === 'string') {
			element.classList.add(classes);
		} else if (Array.isArray(classes)) {
			for (let cls of classes) {
				element.classList.add(cls);
			}
		}

		if (textContent){
			element.textContent = textContent;
		}

		const elementKey = 'uiElement_' + this.uiElementCounter++;
		this.uiElementsMap.set(elementKey, element); 
	
		return element;
	}
	
	findOrCreateElement(parent, type, id, classes = []) {
		let element = parent.querySelector(`#${id}`);
		if (!element) {
			element = this.createElement(type, id, classes);
			parent.appendChild(element);
		}
		return element;
	}

	updateVisibility(element, condition) {
		// element.style.display = condition ? 'block' : 'none';
		element.style.opacity = condition ? 1 : 0;
		element.style.pointerEvents = condition ? 'auto' : 'none';
	}

	updateElementTextContent(element, newContent) {
		if (element.textContent !== newContent) {
			element.textContent = newContent;
		}
	}

	updateElementHTML(element, newContent) {
		if (element.innerHTML !== newContent) {
			element.innerHTML = newContent;
		}
	}
	

	populateFeatureCell(feature, targetCol, featureId){
		let realmTypeForColor = targetCol.id.split("-")[0]; 
		let cell = this.createElement('div', featureId, [`feature-cell`, `${feature.featureType}-cell`, `${realmTypeForColor}-color`]);
	
		if (feature.featureType === "artifact"){
			let artifactSlot = this.createElement('span', null, 'gear-type', feature.gearType);
			cell.appendChild(artifactSlot);
		}
	
		const featureNameContainer = this.createElement('div', null, [`feature-name-container`, feature.featureType + '-name-container']);
		const featureName = this.createElement('div', null, [`feature-name`, feature.featureType + '-name'], `${feature.name}: ${feature.level}\n${feature.description}`);
		featureNameContainer.appendChild(featureName);
		cell.appendChild(featureNameContainer);
	
		const button = this.createElement('button', `button-${feature.id}`,'feature-button');
		button.setAttribute('data-ticks', '0'); // Start with 0 tick marks
	
		// Create a container to hold the button's text elements
		const buttonTextContainer = this.createElement('div', `buttonText-${feature.id}`, 'button-text-container');
	
		// Create separate spans for each part of the button text
		const levelSpan = this.createElement('span', `levelSpan-${feature.id}`, 'level-span');
		const productionSpan = this.createElement('span', `productionSpan-${feature.id}`, 'production-span');
		const costSpan = this.createElement('span', `costSpan-${feature.id}`, 'cost-span');
	
		buttonTextContainer.appendChild(levelSpan);
		buttonTextContainer.appendChild(productionSpan);
		buttonTextContainer.appendChild(costSpan);
		button.appendChild(buttonTextContainer);
	
		this.populateTooltip(feature, button);
	
		const buttonClickListener = () => {
			this.buyFeature(feature.id);
		};
	
		// Use EventManager to add the event listener
		this.eventManager.addDomListener(button, 'click', buttonClickListener);
	
		// Optional: Store the listener reference if you need to remove it later
		button.clickListener = buttonClickListener;
	
		cell.appendChild(button);
	
		targetCol.appendChild(cell);
	
		if (!feature.active){
			cell.style.display = 'none';
		} else {
			cell.style.display = 'flex';
		}
	
		return cell;
	}
	
	updateFeatureCell(feature, featureCell) {
		this.updateVisibility(featureCell, feature.active);
		
		let featureNameElement = featureCell.querySelector('.' + feature.featureType + '-name');
		let newFeatureNameText = `${feature.name}: ${this.formatNumber(feature.level.floor())}`;
		if (feature.featureType === "generator") {
			newFeatureNameText = `${feature.name}: ${this.formatNumber(feature.level.floor())} <span style="padding:0px;filter:hue-rotate(10deg);">(${feature.manualLevel})</span>`;
		}
		if (featureNameElement.innerHTML !== newFeatureNameText) {
			this.updateElementHTML(featureNameElement, newFeatureNameText);
		}
	
		const levelSpan = featureCell.querySelector(`#levelSpan-${feature.id}`);
		const productionSpan = featureCell.querySelector(`#productionSpan-${feature.id}`);
		const costSpan = featureCell.querySelector(`#costSpan-${feature.id}`);
		
		const newLevelSpanHTML = `<b><span style="font-size:12px;padding:0px;">+</span>${feature.nextLevelIncrement}</b>`;
		if (levelSpan.innerHTML !== newLevelSpanHTML) {
			levelSpan.innerHTML = newLevelSpanHTML;
		}
	
		let newProductionSpanHTML;
		if (feature.prodNextMultPurchase.gt(0)) {
			let rankMult = DEC(1);
			
			if (feature.rankTiers) {
				if (feature.nextLevelIncrement.plus(feature.manualLevel).gte(feature.nextRankLevel)) {
					rankMult = feature.nextRankMult;
					newProductionSpanHTML = `<span style="color:green;padding:0px;">+</span>${this.formatNumber((feature.prodNextMultPurchase.plus(feature.prodCurrentGlobal).times(rankMult)).minus(feature.prodCurrentGlobal))}/sec`;
				} else {
					newProductionSpanHTML = `<span style="color:green; padding:0px;">+</span>${this.formatNumber(feature.prodNextMultPurchase)}/sec`;
				}
			} else {
				if (feature.featureType === "radianceUpgrade") {
					newProductionSpanHTML = `<span style="color:green;padding:0px;">+</span>${this.formatNumber(feature.nextLevelIncrement.times(10))}% Speed`;
				} else {
					if (feature.prodType) {
						newProductionSpanHTML = `<span style="color:green;padding:0px;">+</span>${this.formatNumber(feature.prodNextMultPurchase)} ${feature.prodType.replace("Income", "")}/sec`;
					}
				}
			}
		}
		if (feature.featureType === "artifact"){
			newProductionSpanHTML = '';
			let observer = feature.observers[0];
			
			let formattedCalcType = observer.runningCalcType.replace('mult', '*').replace('div', '/').replace('add', '+').replace('sub', '-');
			
			newProductionSpanHTML = `${observer.targetType} ${observer.type} ${formattedCalcType} ${feature.level}`;
		}
		// empty production span html for features with no production
		if (feature.featureType === "realmUpgrade") {
			newProductionSpanHTML = '';
			for (let obs of feature.observers) {
				// Check if 'obs' has a 'targetType' property
				if (obs.targetType) {
					console.error('type targetting - not handled yet in updateFeatureCell');
					continue; // Skip to the next iteration of the loop
				}
		
				let tree = obs.target.modTreesMap.get("production");
				let targetNode = tree.nodes.find(node => node.ref.id === obs.id);
				let treeType = "prod";
				let treeDisplayType = "Multiplier";
			
				// Check if targetNode wasn't found in 'production'. If not, look for it in 'cost'
				if (!targetNode) {
					tree = obs.target.modTreesMap.get("cost");
					targetNode = tree.nodes.find(node => node.ref.id === obs.id);
					treeType = "cost";
					treeDisplayType = "Cost";
				}
			
				if (targetNode) {
					let result = tree.calcNodeResult(targetNode, obs.source.level);

					// newProductionSpanHTML = `<b>Effect: </b>${obs.target.name} ${treeType} ${obs.runningCalcType} level.  <b>Current Contribution:</b> ${treeType} ${obs.runningCalcType} ${result.toFixed(3)} `;

					let formattedCalcType;
					formattedCalcType = obs.runningCalcType.replace('mult', '*').replace('div', '/').replace('add', '+').replace('sub', '-');

					newProductionSpanHTML = `<b>Effect:</b>${treeDisplayType} ${formattedCalcType} ${result.toFixed(1)} `;
				}
			}
		}

		if (productionSpan.innerHTML !== newProductionSpanHTML) {
			productionSpan.innerHTML = newProductionSpanHTML;
		}
	
		const newCostSpanHTML = `<span style="color:red;padding:0px;">-</span>${this.formatNumber(feature.costNextMultPurchase)} ${feature.costType}`;
		if (costSpan.innerHTML !== newCostSpanHTML) {
			costSpan.innerHTML = newCostSpanHTML;
		}
	
		const button = featureCell.querySelector(`#button-${feature.id}`);
		const buttonShouldBeEnabled = feature.active && feature.level.neq(feature.maxLevel) && this.isAffordable(feature) && feature.nextLevelIncrement.gt(0);
		const buttonShouldBeDisabled = feature.level.eq(feature.maxLevel);
	
		if (buttonShouldBeEnabled) {
			if (button.disabled || !button.classList.contains('enabled')) {
				button.disabled = false;
				button.classList.add('enabled');
				button.classList.remove('disabled');
			}
			if (feature.nextLevelIncrement.gte(feature.nextRankLevel) && feature.nextRankLevel && feature.nextRankLevel.gt(0)) {
				if (!button.classList.contains('rank-affordable')) {
					button.classList.add('rank-affordable');
				}
			} else {
				if (button.classList.contains('rank-affordable')) {
					button.classList.remove('rank-affordable');
				}
			}
		} else if (buttonShouldBeDisabled) {
			if (!button.disabled || !button.classList.contains('complete')) {
				button.disabled = true;
				button.classList.remove('enabled');
				button.classList.add('disabled', 'complete');
				if (levelSpan.innerHTML !== "max") {
					levelSpan.innerHTML = "max";
				}
				if (productionSpan.innerHTML !== "") {
					productionSpan.innerHTML = "";
				}
				if (costSpan.innerHTML !== "") {
					costSpan.innerHTML = "";
				}
				button.classList.remove('rank-affordable');
			}
		} else {
			if (!button.disabled || !button.classList.contains('disabled')) {
				button.disabled = true;
				button.classList.remove('enabled');
				button.classList.add('disabled');
				button.classList.remove('rank-affordable');
			}
		}
	
		this.updateTooltip(feature);
	
		featureCell.style.display = feature.active ? 'flex' : 'none';
	}
	
	
	populateCurrentValueElement(feature,featureCell){
		let currentValueElement = this.createElement('div',null,[`feature-current-value`,`${feature.featureType}-current-value`]);
		featureCell.appendChild(currentValueElement);
		return currentValueElement;
	}

	onTriggerPopup(data) {
        this.popupQueue.push(data);
        this.showNextPopup();
    }

	// Function to show the next popup in the queue
    showNextPopup() {
        if (this.isPopupActive || this.popupQueue.length === 0) {
            return;
        }

        const data = this.popupQueue.shift();
        this.isPopupActive = true;

        const popup = this.createPopup(data.title, data.message, data.tab, data.tabButtonContent);
        popup.onClose = () => {
            this.isPopupActive = false;
            this.showNextPopup();
        };
    }

	createPopup(title, message, tab = false, tabButtonContent = 'Go') {
		// Create the backdrop for the modal
		const backdrop = document.createElement('div');
		backdrop.id = 'modal-backdrop';
	
		// Create the modal container
		const modal = document.createElement('div');
		modal.className = 'modal-container';
	
		// Create the title element
		const modalTitle = document.createElement('h1');
		modalTitle.className = 'modal-title';
		modalTitle.textContent = title;
	
		// Create the message element
		const modalMessage = document.createElement('p');
		modalMessage.className = 'modal-message';
		modalMessage.textContent = message;
	
		// Create a close button for the modal
		const closeButton = document.createElement('button');
		closeButton.className = 'modal-close-button';
		closeButton.textContent = 'Close (ESC)';
	
		const popup = {
			closeModal: () => closeButton.onclick(),
			onClose: null
		};
	
		closeButton.onclick = () => {
			document.body.removeChild(backdrop);
			this.isPopupActive = false;
			this.showNextPopup(); // Ensure the queue is checked again
			if (popup.onClose) popup.onClose(); // Execute onClose callback if defined
		};
	
		// Append elements to modal
		modal.appendChild(modalTitle);
		modal.appendChild(modalMessage);
	
		// if tab, populate a button that will close modal AND trigger event to change tab
		if (tab) {
			const tabButton = document.createElement('button');
			tabButton.className = 'modal-tab-nav-button';
			tabButton.textContent = tabButtonContent;
	
			tabButton.onclick = () => {
				document.body.removeChild(backdrop);
				this.isPopupActive = false;
				this.showNextPopup(); // Ensure the queue is checked again
				if (popup.onClose) popup.onClose(); // Execute onClose callback if defined
	
				// HANDLE SUBTABS
				if (tab === "tournament"){
					this.eventManager.dispatchEvent('changeTab', "exploration");
					this.tabManager.explorationTab.changeExplorationSubTab('TournamentSubTab','tournament');
				}
				else if (tab === "artifacts"){
					this.eventManager.dispatchEvent('changeTab', "exploration");
					this.tabManager.explorationTab.changeExplorationSubTab('ArtifactsSubTab','artifacts');
				}
				else if (tab === "odyssey"){
					this.eventManager.dispatchEvent('changeTab', "exploration");
					this.tabManager.explorationTab.changeExplorationSubTab('OdysseySubTab','odyssey');
				}
				else if (tab === "force"){
					this.eventManager.dispatchEvent('changeTab', "training");
					this.tabManager.trainingTab.changeRealmSubTab('forceSubTab','force');
				}
				else if (tab === "wisdom"){
					this.eventManager.dispatchEvent('changeTab', "training");
					this.tabManager.trainingTab.changeRealmSubTab('wisdomSubTab','wisdom');
				}
				else if (tab === "energy"){
					this.eventManager.dispatchEvent('changeTab', "training");
					this.tabManager.trainingTab.changeRealmSubTab('energySubTab','energy');
				}
				else if (tab === "divine"){
					this.eventManager.dispatchEvent('changeTab', "training");
					this.tabManager.trainingTab.changeRealmSubTab('divineSubTab','divine');
				}
				// Handle top level tabs
				else {
					this.eventManager.dispatchEvent('changeTab', tab);
				}
			};
			modal.appendChild(tabButton);
		}
	
		modal.appendChild(closeButton);
	
		// Append modal to backdrop
		backdrop.appendChild(modal);
	
		// Append backdrop to body
		document.body.appendChild(backdrop);
	
		return popup;
	}
	
	

	formatTime(milliseconds) {
		let totalSeconds = Math.floor(milliseconds / 1000);
		let hours = Math.floor(totalSeconds / 3600);
		totalSeconds %= 3600;
		let minutes = Math.floor(totalSeconds / 60);
		let seconds = totalSeconds % 60;
	
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	}

	//dispatch to gameManager
	buyFeature(id) {
		this.eventManager.dispatchEvent('handlePurchase', { id });
	}

	formatNumber(number){
		return this.numberFormatter.formatNumber(number);
	}
}
