export default class ForgeTab {
    constructor(eventManager, ui, forgeUpgrades) {
        this.eventManager = eventManager;
        this.ui = ui;
        this.forgeUpgrades = forgeUpgrades;
        this.forgeCol1 = document.getElementById('forge-upgrades-columns');
        this.upgradeSectionNames = ['force', 'wisdom', 'energy', 'divine', 'crystal'];
        this.allForgeUpgrades = this.groupUpgradesBySectionName();
    }

    groupUpgradesBySectionName() {
        return this.upgradeSectionNames.reduce((acc, section) => {
            acc[section] = this.forgeUpgrades.filter(upgrade => upgrade.costType === section);
            return acc;
        }, {});
    }

    createUpgradeButton(upgrade, colID) {
        const upgradeID = `${colID}-${upgrade.id}`;
        const upgradeCell = this.ui.createElement('div', upgradeID, 'forge-upgrade-cell');
        const button = this.ui.createElement('button', `button-${upgrade.id}`, 'forge-upgrade-button');
        const upgradeContent = this.ui.createElement('div', `upgrade-content-${upgrade.id}`);

        button.appendChild(upgradeContent);
        const buttonClickHandler = () => this.ui.buyFeature(upgrade.id);
        this.eventManager.addDomListener(button, 'click', buttonClickHandler);

        this.ui.populateMouseOverZIndexEvents(button);
        this.ui.populateTooltip(upgrade, button);

        upgradeCell.appendChild(button);
        return upgradeCell;
    }

    populateDisplay() {
        this.upgradeSectionNames.forEach(sectionName => {
            const upgrades = this.allForgeUpgrades[sectionName];
            const colID = `forgeUpgrades-${sectionName}`;
            const col = this.ui.createElement('div', colID, ['content-tab-col', `${sectionName}-color`]);
            const colContent = this.ui.createElement('div', null, 'forge-upgrade-col');
            const colTitle = this.ui.createElement('div', null, null, sectionName.charAt(0).toUpperCase() + sectionName.slice(1));

            col.appendChild(colTitle);

            upgrades.forEach(upgrade => {
                const upgradeCell = this.createUpgradeButton(upgrade, colID);
                colContent.appendChild(upgradeCell);
            });

            col.appendChild(colContent);
            this.forgeCol1.appendChild(col);
        });
    }

    updateDisplay() {
        this.upgradeSectionNames.forEach(sectionName => {
            const colID = `forgeUpgrades-${sectionName}`;
            const col = document.getElementById(colID);
            const upgrades = this.allForgeUpgrades[sectionName];

            upgrades.forEach(upgrade => {
                const button = col.querySelector(`#button-${upgrade.id}`);
                const upgradeContent = document.getElementById(`upgrade-content-${upgrade.id}`);

                this.ui.updateElementTextContent(upgradeContent, upgrade.name);
                this.ui.updateTooltip(upgrade);
                this.ui.updateVisibility(button, upgrade.active);

                if (upgrade.level.eq(upgrade.maxLevel)) {
                    button.disabled = true;
                    button.classList.remove('enabled', 'disabled');
                    button.classList.add('complete');
                    button.style.backgroundColor = `var(--${sectionName}-color)`;
                    button.children[0].style.color = 'white';
                } else if (upgrade.active && !upgrade.level.eq(upgrade.maxLevel) && this.ui.isAffordable(upgrade) && upgrade.nextLevelIncrement.gt(0)) {
                    button.disabled = false;
                    button.classList.add('enabled');
                    button.classList.remove('disabled');
                } else {
                    button.disabled = true;
                    button.classList.add('disabled');
                    button.classList.remove('enabled');
                }

                if (upgrade.id === upgrades[0].id) {
                    this.ui.updateVisibility(col, upgrade.active);
                }
            });
        });
    }
}