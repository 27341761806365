export default class WorldState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "worlds";

        

        this.regularPropertiesFull = ['isProgressed','isCompleted','active', 'totalZonesCompleted','progressPercentage','currentlyViewing'];

        this.propertiesFull = this.regularPropertiesFull;
    }

    saveState(state, gameData){
        this.populateSaveDataBase(gameData);

        switch (state) {
			case 0:
				this.populateSaveDataFull(gameData);
				break;
			case 1:
				this.populateSaveDataRebirth1(gameData);
				break;
			case 2:
				this.populateSaveDataRebirth2(gameData);
				break;
			case 3:
				this.populateSaveDataRebirth3(gameData);
				break;
		}
    }
    
    loadState(state, gameData){
        this.applyLoadDataBase(gameData);

        switch (state) {
			case 0:
				this.applyLoadDataFull(gameData);
				break;
			case 1:
				this.applyLoadDataRebirth1(gameData);
				break;
			case 2:
				this.applyLoadDataRebirth2(gameData);
				break;
			case 3:
				this.applyLoadDataRebirth3(gameData);
				break;
		}
    }

    populateSaveDataBase(gameData){}

    populateSaveDataFull(gameData) {
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);

        // save worldmanager data
		gameData.conquestAuto = this.gameManager.gameContent.worldManager.autoUnlocked;
		gameData.currentWorldID = this.gameManager.gameContent.worldManager.currentWorld.id;
		gameData.totalZonesCompleted = this.gameManager.gameContent.worldManager.totalZonesCompleted;
		gameData.progressPercentage = this.gameManager.gameContent.worldManager.progressPercentage;

    }

    populateSaveDataRebirth1(gameData){}
    populateSaveDataRebirth2(gameData){}
    populateSaveDataRebirth3(gameData){}

    

    applyLoadDataBase(gameData){}

    applyLoadDataFull(gameData) {
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);

        this.gameManager.gameContent.worldManager.autoUnlocked = gameData.conquestAuto;
		this.gameManager.gameContent.worldManager.currentWorld = this.gameManager.findObjectById(gameData.currentWorldID);
		this.gameManager.gameContent.worldManager.totalZonesCompleted = Number(gameData.totalZonesCompleted);
		this.gameManager.gameContent.worldManager.progressPercentage = Number(gameData.progressPercentage);
    }
    
    
    
    applyLoadDataRebirth1(gameData){}
    applyLoadDataRebirth2(gameData){}
    applyLoadDataRebirth3(gameData){}



}