export default class RadianceTab {
	constructor(eventManager, ui, gameContent, tabManager) {
	  this.eventManager = eventManager;
	  this.ui = ui;
	  this.gameContent = gameContent;
	  this.tabManager = tabManager;
	  this.radianceUpgrades = this.gameContent.radianceUpgrades;
	  this.targetCol = document.getElementById(`radiance-col1`);
  
	  this.crystalContainerWrapper = null;
	  this.baseCrystalContainer = null;
	  this.upgradeCrystalContainer = null;
	  this.baseCrystal = null;
	  this.upgradeCrystal = null;
	  this.baseCrystalFacets = [];
	  this.upgradeCrystalFacets = [];
	  this.baseSpeedIndicator = null;
	  this.upgradeSpeedIndicator = null;
	}
  
	populateDisplay() {
	  this.targetCol.classList.add('radiance-color');
	  this.radianceUpgrades.forEach(upgrade => {
		const upgradeId = `upgrade-${upgrade.id}`;
		let upgradeCell = this.ui.populateFeatureCell(upgrade, this.targetCol, upgradeId);
		this.ui.populateMouseOverZIndexEvents(upgradeCell);
		this.targetCol.appendChild(upgradeCell);
	  });
  
	  // Create the crystal container wrapper
	  this.crystalContainerWrapper = document.createElement('div');
	  this.crystalContainerWrapper.classList.add('crystal-container-wrapper');
	  this.targetCol.appendChild(this.crystalContainerWrapper);
  
	  // Create the base crystal container
	  this.baseCrystalContainer = document.createElement('div');
	  this.baseCrystalContainer.classList.add('crystal-container', 'base-crystal-container');
	  this.crystalContainerWrapper.appendChild(this.baseCrystalContainer);
  
	  // Create the base crystal
	  this.baseCrystal = document.createElement('div');
	  this.baseCrystal.classList.add('crystal', 'base-crystal');
	  this.baseCrystalContainer.appendChild(this.baseCrystal);
  
	  // Create the base crystal facets
	  for (let i = 0; i < 6; i++) {
		const facet = document.createElement('div');
		facet.classList.add('facet');
		this.baseCrystalFacets.push(facet);
		this.baseCrystal.appendChild(facet);
	  }
  
	  // Create the base speed indicator
	  this.baseSpeedIndicator = document.createElement('div');
	  this.baseSpeedIndicator.classList.add('speed-indicator');
	  this.baseSpeedIndicator.textContent = 'Base Speed: 100%';
	  this.baseCrystalContainer.appendChild(this.baseSpeedIndicator);
  
	  // Create the upgrade crystal container
	  this.upgradeCrystalContainer = document.createElement('div');
	  this.upgradeCrystalContainer.classList.add('crystal-container', 'upgrade-crystal-container');
	  this.crystalContainerWrapper.appendChild(this.upgradeCrystalContainer);
  
	  // Create the upgrade crystal
	  this.upgradeCrystal = document.createElement('div');
	  this.upgradeCrystal.classList.add('crystal', 'upgrade-crystal');
	  this.upgradeCrystalContainer.appendChild(this.upgradeCrystal);
  
	  // Create the upgrade crystal facets
	  for (let i = 0; i < 6; i++) {
		const facet = document.createElement('div');
		facet.classList.add('facet');
		this.upgradeCrystalFacets.push(facet);
		this.upgradeCrystal.appendChild(facet);
	  }
  
	  // Create the upgrade speed indicator
	  this.upgradeSpeedIndicator = document.createElement('div');
	  this.upgradeSpeedIndicator.classList.add('speed-indicator');
	  this.upgradeCrystalContainer.appendChild(this.upgradeSpeedIndicator);
	}
  
	updateDisplay() {
	  this.radianceUpgrades.forEach(upgrade => {
		const upgradeId = `upgrade-${upgrade.id}`;
		let upgradeCell = this.targetCol.querySelector(`#${upgradeId}`);
		this.ui.updateFeatureCell(upgrade, upgradeCell);
	  });
  
	  // Update the crystal color and rotation speed based on the upgrade level
	  const speedUpgrade = this.radianceUpgrades.find(upgrade => upgrade.id === 120001);
	  if (speedUpgrade) {
		const colorIndex = speedUpgrade.level % 10;
		const colors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff', '#800000', '#008000', '#000080', '#808000'];
		const selectedColor = colors[colorIndex];
  
		this.upgradeCrystalFacets.forEach(facet => {
		  facet.style.backgroundColor = selectedColor;
		  facet.style.borderColor = selectedColor;
		});
  
		const rotateSpeed = 5 / (1 + (0.1 * speedUpgrade.level));
		this.upgradeCrystal.style.animation = `rotate ${rotateSpeed}s infinite linear`;
  
		// Update the upgrade speed indicator text
		const speedIncrease = speedUpgrade.level * 10;
		this.upgradeSpeedIndicator.textContent = `Upgraded Speed: ${speedIncrease + 100}%`;
	  } else {
		// Default color and rotation speed when the upgrade is not available
		this.upgradeCrystalFacets.forEach(facet => {
		  facet.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
		  facet.style.borderColor = 'rgba(255, 255, 255, 0.8)';
		});
		this.upgradeCrystal.style.animation = 'rotate 5s infinite linear';
		this.upgradeSpeedIndicator.textContent = 'Upgraded Speed: +0%';
	  }
	}
  }