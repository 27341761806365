import { DEC } from '../../Utilities/decimal.js';

export default class WorldManager {
	constructor(eventManager, gameContent) {
		this.eventManager = eventManager;
		this.gameContent = gameContent;

		this.worlds = [];
		this.isCompleted = false;
		this.isProgressed = false;
		this.autoUnlocked = false;
		this.currentWorld;
		this.currentRegion;
		this.worldsProgressed = DEC(0);
		this.regionsProgressed = DEC(0);

		this.totalZoneCount = 0;
		this.totalZonesCompleted = 0;
		this.progressPercentage = 0;
	}

	worldProgressed(){
		//check if all worlds are progressed, or set next world active
		const allWorldsProgressed = this.worlds.every(world => world.isProgressed);

		if (allWorldsProgressed) {
			this.setProgressed();
		}
		else {
			const nextWorld = this.worlds.find(world => !world.active);
			if (nextWorld) {
				nextWorld.setActive();
				this.currentWorld = nextWorld;
			}
		}
		
		this.worldsProgressed = this.worldsProgressed.plus(1);

		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: 'maxProgressionWorld',
			value: this.worldsProgressed,
			operation: 'replaceIfGreater'
		});
	}

	worldCompleted() {
		const allWorldsCompleted = this.worlds.every(world => world.isCompleted);

		if (allWorldsCompleted) {
			this.setCompleted();
		}
	}
	setProgressed(){
		this.isProgressed = true;
	}

	setCompleted() {
		this.isCompleted = true;
	}

	zoneCompleted(){
		this.totalZonesCompleted++;
		this.progressPercentage = this.totalZonesCompleted / this.totalZoneCount * 100;
	}
}
