import { DEC } from '../../Utilities/decimal.js';

export default class GeneratorChain {
    constructor(eventManager, id, name, realmID, active) {
        this.eventManager = eventManager;
        this.id = id;
        this.name = name;
        this.generators = [];
        this.realmID = realmID;
        this.realm = null;
        this.active = active;
    }

    // Processes each generator in the chain, starting from the top and working down
    calculateChain(gameManager, elapsedTime) {
        // console.log(`Calculating chain for ${this.name} with elapsedTime: ${elapsedTime}`);
        
        // Log the initial state of each generator
        // this.generators.forEach((gen, idx) => {
            // console.log(`Initial state of Generator ${gen.name} at index ${idx}:
            //     Level: ${gen.level.toString()},
            //     Unspent Production: ${gen.unspentProduction.toString()},
            //     Production Base: ${gen.prodBase.toString()},
            //     ProdCurrent: ${gen.prodCurrentGlobal.toString()},
            //     Production Multiplier: ${gen.prodMult.toString()},
            //     Cost Base: ${gen.costBase.toString()},
            //     Cost Multiplier: ${gen.costMult.toString()}`);
        // });
    
        // Iterate over the generators, excluding the top one
        for (let index = this.generators.length - 2; index >= 0; index--) {
            const currentGenerator = this.generators[index];       // The generator that's being produced
            const producingGenerator = this.generators[index + 1];  // The generator that produces the current one
    
            // console.log(`Processing Generator ${currentGenerator.name} produced by ${producingGenerator.name}`);
    
            if (currentGenerator.active && producingGenerator.active && currentGenerator.level.gt(0)) {
                // Calculate how much of the current generator would be produced by the producing generator in the elapsed time
                const productionForElapsedTime = producingGenerator.prodCurrentGlobal.times(elapsedTime);
                // console.log(`Production for elapsed time for ${producingGenerator.name}: ${productionForElapsedTime.toString()}`);
    
                // Add this to the running total of produced but not yet spent amount of the current generator
                producingGenerator.unspentProduction = producingGenerator.unspentProduction.plus(productionForElapsedTime);
                // console.log(`Updated unspent production for ${producingGenerator.name}: ${producingGenerator.unspentProduction.toString()}`);
    
                // If there's enough unspent production to level up the current generator at least once, do so
                if (producingGenerator.unspentProduction.floor().gte(1)) {
                    // Level up the current generator by the number of whole levels we can afford
                    const wholeLevels = DEC(producingGenerator.unspentProduction.floor());
                    // console.log(`Leveling up ${currentGenerator.name} by ${wholeLevels.toString()} levels from ${producingGenerator.name}'s production`);
    
                    currentGenerator.levelUp("auto", wholeLevels);
    
                    // Update the game state to reflect the leveling up of the current generator
                    gameManager.updateFeatureValues(currentGenerator, true);
    
                    // Subtract the spent production from the running total of unspent production
                    producingGenerator.unspentProduction = producingGenerator.unspentProduction.minus(wholeLevels);
                    // console.log(`Post-level-up unspent production for ${producingGenerator.name}: ${producingGenerator.unspentProduction.toString()}`);
                }
            } else {
                // console.log(`Skipping generator ${currentGenerator.name} as it is inactive or at level 0.`);
            }
    
            // console.log(`Current state of Generator ${currentGenerator.name}:
            //     Level: ${currentGenerator.level.toString()},
            //     Production Base: ${currentGenerator.prodBase.toString()},
            //     Production Multiplier: ${currentGenerator.prodMult.toString()},
            //     Cost Base: ${currentGenerator.costBase.toString()},
            //     Cost Multiplier: ${currentGenerator.costMult.toString()}`);
        }
    
        // Log the final state of each generator
        // this.generators.forEach((gen, idx) => {
            // console.log(`Final state of Generator ${gen.name} at index ${idx}:
            //     Level: ${gen.level.toString()},
            //     Unspent Production: ${gen.unspentProduction.toString()},
            //     Production Base: ${gen.prodBase.toString()},
            //     ProdCurrent: ${gen.prodCurrentGlobal.toString()},
            //     Production Multiplier: ${gen.prodMult.toString()},
            //     Cost Base: ${gen.costBase.toString()},
            //     Cost Multiplier: ${gen.costMult.toString()}`);
        // });
    }
}