import Decimal from '../Utilities/break_eternity.min.js'; // large number library
import { DEC } from '../Utilities/decimal.js';

export default class Tester{
	constructor(game){
		this.game = game;
		this.gameManager = game.gameManager;
		this.gameContent = game.gameManager.gameContent;
		this.eventManager = this.game.eventManager;
		this.ui = game.ui;
		this.tabManager = game.ui.tabManager;
		this.passCount = 0;
		this.failCount = 0;
		this.testResults = {};


		this.featureMapping = {
			'fTrain1': 1001,
			'fTrain2': 1002,
			'fTrain3': 1003,
			'fTrain4': 1004,
			'fTrain5': 1005,
			'fUpgrade1': 1101,
			'fUpgrade2': 1102,
			'fUpgrade3': 1103,
			'fUpgrade4': 1104,
			'fUpgrade5': 1105,

			'achieve6240' : 6240,

			'forgeForce1': 10001,
			'forgeForce2': 10002,
			'forgeForce3': 10003,
			'forgeForce4': 10004,
			'forgeForce5': 10005,

			'forgeCrystalUnspentWisdomToForce': 10411,
			'forgeCrystalAllTrainProd25%': 10414,
			'forgeCrystalAllTrainCostMinus25%': 10415,
			'forgeForceTrainAutobuy': 10401,

			'zone1' :90001,
			'zone2' :90002,

			'essence1BoostForceMult': 100001,
			'essenceBoostZoneProd': 100002,
			'essenceBoostBaseSkillpoints': 100004,
			'essenceBoostTrain1BaseLevel': 100005,
			'essenceReduceArtifactCosts': 100009,
		}
		
		this.testSuite = {};
		this.currentTest = null;
		this.setupAndRunTestSuite();
	}

	// GETTERS
		// GET OBJECTS
			// getObjectByFeatureMapping(name)
			// getObjectById(id)

		// GET DOM ELEMENTS
			// FEATURE UN-SPECIFIC
				// getElementBySelector(selector)

			// FEATURE SPECIFIC
				// getTrainingButtonByFeatureMapping(name)
				// getAchievementButtonByFeatureMapping(name)
				// getZoneButtonByFeatureMapping(name)
				// getForgeButtonByFeatureMapping(name)
				// getEssenceButtonByFeatureMapping(name)

	// ACTIONS
		// FEATURE UN-SPECIFIC
			// clickElement(element)
			// clickElemenById(id,count=1,interval=0.1)
			// changeTab(tabName)
			// setCurrency(type,value)
			// wait(seconds)
			// fullReset

		// FEATURE SPECIFIC
			// purchaseTrainingByFeatureMapping(name,count=1,interval=0.1)
			// claimAchievementByFeatureMapping(name,count=1,interval=0.1)
			// startZoneByFeatureMapping(name,count=1,interval=0.1)
			// purchaseForgeByFeatureMapping(name,count = 1,interval=0.1)
			// purchaseEssenceUpgradeByFeatureMapping(name, count = 1, interval = 0.1)

	// ASSERTIONS
		// ELEMENTS
			// assertElementStyleEquals(element,property(string),expectation,tolerance=1e-5)
			// assertElementHasClass(element, className)

		// OBJECTS
			// assertObjectPropertyEquals(object,property(string),expectation)

		// RAW VALUES
			// assertValueEquals(value, expectation, tolerance = 1e-5)






	// ***************
	// Testing Suite
	setupTestSuite() {
		this.test('Game progress', async () => {
			let object, element;
			// Full Reset to ensure a clean start
			await this.fullReset();
			

			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			object = this.getObjectByFeatureMapping('fTrain1');
			this.assertObjectPropertyEquals(object, 'level', 1);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', 0.5);


			await this.addCurrency('force', 1000);


			await this.purchaseTrainingByFeatureMapping('fTrain1',10);
			object = this.getObjectByFeatureMapping('fTrain1');
			this.assertObjectPropertyEquals(object, 'level', 11);


			element = this.getTrainingButtonByFeatureMapping('fTrain2');
			this.assertElementHasClass(element, 'enabled');
			

			element = this.getTrainingButtonByFeatureMapping('fUpgrade1');
			this.assertElementHasClass(element, 'enabled');


			await this.addCurrency('force', 15000);
			

			await this.purchaseTrainingByFeatureMapping('fTrain2',10);
			object = this.getObjectByFeatureMapping('fTrain2');
			this.assertObjectPropertyEquals(object, 'level', 10);


			await this.addCurrency('force', 300000000);
			

			await this.purchaseTrainingByFeatureMapping('fTrain3',10);
			object = this.getObjectByFeatureMapping('fTrain3');
			this.assertObjectPropertyEquals(object, 'level', 10);


			await this.changeTab('achievements');


			object = this.getObjectByFeatureMapping('achieve6240');
			this.assertObjectPropertyEquals(object, 'isClaimed', false);
			await this.claimAchievementByFeatureMapping('achieve6240');
			this.assertObjectPropertyEquals(object, 'isClaimed', true);
			this.assertObjectPropertyEquals(object, 'isClaimable', false);
			

			object = this.getObjectByFeatureMapping('fTrain1');
			this.assertObjectPropertyEquals(object, 'prodMult', 4.5);
			this.assertObjectPropertyEquals(this.gameContent, 'radiance', 10);


			await this.changeTab('settings');
			await this.clickElementById('#save');
			await this.wait(0.5);
			await this.clickElementById('#load');
			await this.wait(1);
			this.removeOfflineModal();
			this.reAttachGameObjects();

			
			await this.changeTab('training');

			
			await this.clickElementById('#forceMultContainer [id="10Mult"]');

			
			await this.addCurrency('force', 312312300000000);
			

			await this.purchaseTrainingByFeatureMapping('fTrain1',1);
			object = this.getObjectByFeatureMapping('fTrain1');
			this.assertObjectPropertyEquals(object, 'level', 21);

			
			await this.clickElementById('#forceMultContainer [id="100Mult"]');

			
			await this.purchaseTrainingByFeatureMapping('fTrain1',1);
			object = this.getObjectByFeatureMapping('fTrain1');
			this.assertObjectPropertyEquals(object, 'level', 121);


			element = this.getTrainingButtonByFeatureMapping('fTrain1');
			this.assertElementHasClass(element, 'disabled');

			
			await this.changeTab('exploration');


			object = this.getObjectByFeatureMapping('zone1');
			element = this.getZoneButtonByFeatureMapping('zone1');
			await this.wait(1);
			this.assertElementHasClass(element, 'enabled');
			await this.wait(1);
			this.assertObjectPropertyEquals(object, 'isConquesting', false);
			await this.startZoneByFeatureMapping('zone1');
			this.assertObjectPropertyEquals(object, 'isConquesting', true);
			await this.wait(5);
			this.assertObjectPropertyEquals(object, 'isDefeated', true);
			this.assertElementHasClass(element, 'disabled');


			object = this.getObjectByFeatureMapping('zone2');
			element = this.getZoneButtonByFeatureMapping('zone2');
			this.assertElementHasClass(element, 'enabled');
			this.assertObjectPropertyEquals(object, 'isConquesting', false);
			await this.startZoneByFeatureMapping('zone2');
			await this.wait(3);
			this.assertObjectPropertyEquals(object, 'isDefeated', true);
			this.assertElementHasClass(element, 'disabled');





			
			function extractRelevantProperties(training) {
				return {
					maxLevel: training.maxLevel.toString(),
					autoLevel: training.autoLevel.toString(),
					manualLevel: training.manualLevel.toString(),
					baseLevel: training.baseLevel.toString(),
					level: training.level.toString(),
					maxAffLvl: training.maxAffLvl.toString(),
					nextAffordableRankLevel: training.nextAffordableRankLevel.toString(),
					nextRankLevel: training.nextRankLevel.toString(),
					nextRankMult: training.nextRankMult.toString(),
					nextLevelIncrement: training.nextLevelIncrement.toString(),
					costType: training.costType,
					costGrowthRate: training.costGrowthRate.toString(),
					costMultBase: training.costMultBase.toString(),
					costMult: training.costMult.toString(),
					costBase: training.costBase.toString(),
					costNextMultPurchase: training.costNextMultPurchase.toString(),
					prodType: training.prodType,
					prodGrowthRate: training.prodGrowthRate.toString(),
					prodMultBase: training.prodMultBase.toString(),
					prodMult: training.prodMult.toString(),
					// prodPrevious: training.prodPrevious.toString(),
					prodBase: training.prodBase.toString(),
					prodCurrentGlobal: training.prodCurrentGlobal.toString(),
					// prodNextMultPurchase: training.prodNextMultPurchase.toString(),
					prodNextSingle: training.prodNextSingle.toString(),  // Assuming calcProdNextSingle() updates this value correctly
				};
			}
			
			await this.changeTab('settings');

			let testValue1Properties = extractRelevantProperties(this.gameContent.trainings[0]);
			
			await this.clickElementById('#save');
			await this.wait(0.5);
			await this.clickElementById('#load');
			await this.wait(1);
			
			this.removeOfflineModal();
			this.reAttachGameObjects();
			await this.wait(1);
			
			await this.changeTab('training');

			let checkValue1Properties = extractRelevantProperties(this.gameContent.trainings[0]);

			// Compare the properties and log the differences
			for (let key in testValue1Properties) {
				if (testValue1Properties[key] !== checkValue1Properties[key]) {
					console.error(`Mismatch found at ${key}: before ${testValue1Properties[key]}, after ${checkValue1Properties[key]}`);
				}
			}
			

		});




		this.test('Test Training GrowthRate and costRates', async () => {
			let object, element, prodNextExpected, costNextExpected;
			// Full Reset to ensure a clean start
			await this.fullReset();

			
			await this.addCurrency('force', 1000);
			
			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			object = this.getObjectByFeatureMapping('fTrain1');
			this.assertObjectPropertyEquals(object, 'level', 1);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', 0.5);

			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', DEC(object.prodPrevious).plus((DEC(object.prodBase)).times(object.prodGrowthRate)));
			
			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			prodNextExpected = object.calculateProdN(DEC(3), DEC(0));
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);

			// just check if value has been set correctly
			costNextExpected = object.calculateCostN(1);
			let costNextExpectedTwo = object.calculateCostN(2).minus(costNextExpected);
			this.assertObjectPropertyEquals(object, 'costNextMultPurchase', costNextExpected);

			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			this.assertObjectPropertyEquals(object, 'costNextMultPurchase', costNextExpectedTwo);
		});

		this.test('Test Training prodNextSingle calculation', async () => {
			let prodNextExpected, object;
			// Full Reset to ensure a clean start
			await this.fullReset();

			
			await this.addCurrency('force', 1000);
			
			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			object = this.getObjectByFeatureMapping('fTrain1');

			prodNextExpected = object.calcProdNextSingle().plus(object.prodCurrentGlobal);

			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);

			prodNextExpected = object.calcProdNextSingle().plus(object.prodCurrentGlobal);
			
			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);
		});

		


		this.test('Test Rank Mults and calculateProdN', async () => {
			let object, prodNextExpected;
			await this.fullReset();
			
			await this.addCurrency('force', 1e20);

			await this.purchaseTrainingByFeatureMapping('fTrain1', 9);
			
			object = this.getObjectByFeatureMapping('fTrain1');

			prodNextExpected = object.calcProdNextSingle().plus(object.prodCurrentGlobal).times(2);

			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);

			prodNextExpected = object.calculateProdN(DEC(24), DEC(0));
			await this.purchaseTrainingByFeatureMapping('fTrain1', 14);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);

			prodNextExpected = (prodNextExpected.plus(object.calcProdNextSingle()).times(4));
			await this.purchaseTrainingByFeatureMapping('fTrain1', 1);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);

		});




		this.test('Force Upgrade Testing', async () => {
			let object, prodNextExpected, costNextExpected;
			await this.fullReset();
			
			await this.addCurrency('force', 1e40);
			
			object = this.getObjectByFeatureMapping('fTrain1');
			prodNextExpected = object.calculateProdN(DEC(10),DEC(0)).times(2);
			await this.purchaseTrainingByFeatureMapping('fTrain1', 10);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);
			await this.purchaseTrainingByFeatureMapping('fTrain2', 10);
			await this.purchaseTrainingByFeatureMapping('fTrain3', 10);
			await this.purchaseTrainingByFeatureMapping('fTrain4', 10);

			object = this.getObjectByFeatureMapping('fTrain5');
			prodNextExpected = object.calculateProdN(DEC(10),DEC(0)).times(2);
			await this.purchaseTrainingByFeatureMapping('fTrain5', 10);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);

			//prodmult mult upgrade
			object = this.getObjectByFeatureMapping('fTrain1');
			prodNextExpected = object.prodCurrentGlobal.times(11);
			await this.purchaseTrainingByFeatureMapping('fUpgrade1', 10);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);
			await this.purchaseTrainingByFeatureMapping('fUpgrade2', 10);

			//costmult div upgrade
			object = this.getObjectByFeatureMapping('fTrain3');
			costNextExpected = object.costNextMultPurchase.div(1.05+9);
			await this.purchaseTrainingByFeatureMapping('fUpgrade3', 10);
			this.assertObjectPropertyEquals(object, 'costNextMultPurchase', costNextExpected);

			await this.purchaseTrainingByFeatureMapping('fUpgrade4', 10);
			await this.purchaseTrainingByFeatureMapping('fUpgrade5', 10);

		});




		this.test('Test Forge Basic Mult Upgrades', async () => {
			let object, prodNextExpected, costNextExpected;
			await this.fullReset();
			
			await this.addCurrency('force', 1e40);
			
			await this.purchaseTrainingByFeatureMapping('fTrain1', 10);

			await this.purchaseTrainingByFeatureMapping('fUpgrade1', 10);

			
			await this.changeTab('forge');

			// prod * 5
			object = this.getObjectByFeatureMapping('fTrain1');
			prodNextExpected = object.prodCurrentGlobal.times(5);
			await this.purchaseForgeByFeatureMapping('forgeForce1', 1);
			this.assertObjectPropertyEquals(object, 'prodCurrentGlobal', prodNextExpected);
			
			//power level boost contribution by 10x
			object = this.gameContent;
			await this.purchaseForgeByFeatureMapping('forgeForce3', 1);
			this.assertObjectPropertyEquals(object, 'forcePowerLevelMultiplier', 10);


			
			// cost / 2
			object = this.getObjectByFeatureMapping('fTrain1');
			costNextExpected = object.costNextMultPurchase.div(2);
			await this.purchaseForgeByFeatureMapping('forgeForce4', 1);
			this.assertObjectPropertyEquals(object, 'costNextSingle', costNextExpected);
			

			// just checking this returns the same value
			costNextExpected = object.calculateCostN(1);
			this.assertObjectPropertyEquals(object, 'costNextSingle', costNextExpected);
		});






		this.test('Test Crystal Add Percentage Upgrades', async () => {
			let forgeObject;
			await this.fullReset();

			await this.addCurrency('force', 1e40);
			await this.purchaseTrainingByFeatureMapping('fTrain1', 10);
			await this.purchaseTrainingByFeatureMapping('fUpgrade1', 10);
			await this.addCurrency('crystal', 1e40);
			await this.addCurrency('wisdom', 1e10);

			// progress worlds and fighter tiers to unlock forge upgrades
			await this.changeTab('settings');
			await this.clickElementById('#adminButton8', 5, 0.2); //next world
			await this.clickElementById('#adminButton9', 5, 0.2); //next world
			
			await this.changeTab('forge');

			
			let trainObject = this.getObjectByFeatureMapping('fTrain1');
			let expectedNewForceIncome = trainObject.prodCurrentGlobal.times(1.25);
			forgeObject = this.getObjectByFeatureMapping('forgeCrystalAllTrainProd25%');
			await this.purchaseForgeByFeatureMapping('forgeCrystalAllTrainProd25%', 1);
			this.assertValueEquals(this.gameContent.forceIncome, expectedNewForceIncome);

		});





		this.test('Test Crystal Subtract Percentage Upgrades', async () => {
			let forgeObject;
			await this.fullReset();

			await this.addCurrency('force', 1e40);
			await this.purchaseTrainingByFeatureMapping('fTrain1', 10);
			await this.purchaseTrainingByFeatureMapping('fUpgrade1', 10);
			await this.addCurrency('crystal', 1e40);
			await this.addCurrency('wisdom', 1e10);

			// progress worlds and fighter tiers to unlock forge upgrades
			await this.changeTab('settings');
			await this.clickElementById('#adminButton8', 5, 0.2); //next world
			await this.clickElementById('#adminButton9', 5, 0.2); //next world
			
			await this.changeTab('forge');
			
			let trainObject = this.getObjectByFeatureMapping('fTrain1');
			let expectedCostNextSingle = trainObject.costNextSingle.times(0.75).div(2);
			forgeObject = this.getObjectByFeatureMapping('forgeCrystalAllTrainCostMinus25%');
			await this.purchaseForgeByFeatureMapping('forgeCrystalAllTrainCostMinus25%', 1);
			this.assertValueEquals(trainObject.costNextSingle, expectedCostNextSingle);

		});










		this.test('Test Essence Upgrades', async () => {
			let trainingObject, zoneObject, element;
			await this.fullReset();

			this.gameContent.powerLevel = DEC(1e80);

			
			await this.changeTab('essence');

			await this.clickElementById('#rebirth1Button', 1); //rebirth
			this.reAttachGameObjects();
			await this.changeTab('essence');


			
			
			
			zoneObject = this.getObjectByFeatureMapping('zone1');
			let zoneProdMultExpected = zoneObject.prodMult.times(2);
			
			await this.purchaseEssenceUpgradeByFeatureMapping('essence1BoostForceMult');
			await this.purchaseEssenceUpgradeByFeatureMapping('essenceBoostZoneProd');
			await this.purchaseEssenceUpgradeByFeatureMapping('essenceUnlockSkills');
			await this.purchaseEssenceUpgradeByFeatureMapping('essenceBoostBaseSkillpoints');
			await this.purchaseEssenceUpgradeByFeatureMapping('essenceBoostTrain1BaseLevel');
			await this.purchaseEssenceUpgradeByFeatureMapping('essenceReduceArtifactCosts');
			
			trainingObject = this.getObjectByFeatureMapping('fTrain1');
			this.assertObjectPropertyEquals(trainingObject,'level',10);
			this.assertObjectPropertyEquals(trainingObject,'baseLevel',10);
			this.assertObjectPropertyEquals(trainingObject,'autoLevel',0);
			this.assertObjectPropertyEquals(trainingObject,'manualLevel',10);

			await this.clickElementById('#adminButton1', 1); //ftrain/up x 40 - to unlock exploration

			this.assertObjectPropertyEquals(zoneObject,'prodMult',zoneProdMultExpected);

			await this.changeTab('settings');
			await this.clickElementById('#save'); // fix stupid rebirth save corruption
		});




		this.test('Test MinHeap node order', async () => {
			await this.fullReset();
			
			let automationManager = this.gameManager.automationManager;
		
			await this.addCurrency('crystal', 1e40);
			
			let forgeObject = this.getObjectByFeatureMapping('forgeForceTrainAutobuy');
			await this.purchaseForgeByFeatureMapping('forgeForceTrainAutobuy', 1);
		
			await this.wait(1);
		
			// Add force currency to trigger autobuy
			await this.addCurrency('force', 1e10);
		
			// Wait for autobuy to process
			await this.wait(1);
		
			// Get the current state of the forceHeap
			let forceHeap = automationManager.forceHeap;
		
			// Verify that the nodes in the forceHeap are in the correct order
			for (let i = 0; i < forceHeap.heap.length; i++) {
				let currentNode = forceHeap.heap[i];
				let leftChildIndex = forceHeap.getLeftChildIndex(i);
				let rightChildIndex = forceHeap.getRightChildIndex(i);
		
				// Check if left child exists and compare its costNextSingle with the current node
				if (leftChildIndex < forceHeap.heap.length) {
					let leftChild = forceHeap.heap[leftChildIndex];
					this.assert(currentNode.costNextSingle.lte(leftChild.costNextSingle),
						`Node at index ${i} should have a lower or equal costNextSingle than its left child`);
				}
		
				// Check if right child exists and compare its costNextSingle with the current node
				if (rightChildIndex < forceHeap.heap.length) {
					let rightChild = forceHeap.heap[rightChildIndex];
					this.assert(currentNode.costNextSingle.lte(rightChild.costNextSingle),
						`Node at index ${i} should have a lower or equal costNextSingle than its right child`);
				}
			}
		
			// Add more force currency to trigger further autobuy
			await this.addCurrency('force', 1e20);
		
			// Wait for autobuy to process
			await this.wait(1);
		});


		






		this.test('Test Crystal Synergy/Unspent Currency Mult Upgrades', async () => {
			let forgeObject, trainObject, element, prodCurrentGlobal;
			await this.fullReset();

			await this.addCurrency('force', 1e40);
			await this.purchaseTrainingByFeatureMapping('fTrain1', 10);
			await this.purchaseTrainingByFeatureMapping('fUpgrade1', 10);
			await this.addCurrency('crystal', 1e40);
			await this.addCurrency('wisdom', 1e10);

			// progress worlds and fighter tiers to unlock forge upgrades
			await this.changeTab('settings');
			await this.clickElementById('#adminButton8', 5, 0.2); //next world
			await this.clickElementById('#adminButton9', 5, 0.2); //next world
			
			await this.changeTab('forge');


			//unspent wisdom = force income - increase force prod by 1e-7 * current unspent wisdom
			trainObject = this.getObjectByFeatureMapping('fTrain1');
			prodCurrentGlobal = trainObject.prodCurrentGlobal;

			let currentWisdom = this.gameContent.wisdom;
			let expectedForceSynergyMult = currentWisdom.times(1e-7);
			let expectedNewForceIncome = prodCurrentGlobal;

			forgeObject = this.getObjectByFeatureMapping('forgeCrystalUnspentWisdomToForce');
			await this.purchaseForgeByFeatureMapping('forgeCrystalUnspentWisdomToForce', 1);
			
			// failing - forcesynergymult is 0
			await this.wait(1);
			this.assertValueEquals(expectedForceSynergyMult, this.gameContent.forceSynergyMult);
			
			await this.wait(1);
			this.assertValueEquals(this.gameContent.forceIncome, trainObject.prodCurrentGlobal);
			
		});




		this.test('Test Achievement Set Bonuses', async () => {
			let object, element;
			await this.fullReset();

			await this.addCurrency('force', 1e40);
			await this.claimAchievementByFeatureMapping('achieve6240', 1);
		});





		// this.test('Test asasdfdf', async () => {
		// 	let object, element;
		// 	await this.fullReset();

		// await this.addCurrency('force', 1e40);
		// });



		
	}
	// Testing Suite
	// ***************









	// ***************
	// Action Functions


	async purchaseTrainingByFeatureMapping(name, count = 1, interval = 0.1){
		let element = this.getTrainingButtonByFeatureMapping(name);
		await this.clickElement(element, count, interval)
	}

	async purchaseForgeByFeatureMapping(name, count = 1, interval = 0.1){
		let element = this.getForgeButtonByFeatureMapping(name);
		await this.clickElement(element, count, interval)
	}

	async claimAchievementByFeatureMapping(name, count = 1, interval = 0.1){
		let element = this.getAchievementButtonByFeatureMapping(name);
		await this.clickElement(element, count, interval)
	}

	async startZoneByFeatureMapping(name, count = 1, interval = 0.1){
		let element = this.getZoneButtonByFeatureMapping(name);
		await this.clickElement(element, count, interval)
	}

	async purchaseEssenceUpgradeByFeatureMapping(name, count = 1, interval = 0.1){
		let element = this.getEssenceButtonByFeatureMapping(name);
		await this.clickElement(element, count, interval)
	}

	async addCurrency(currencyType,value){
		this.gameContent[currencyType] = this.gameContent[currencyType].plus(DEC(value));
		await this.wait(0.1);
	}

	async changeTab(tabName){
		await this.wait(0.1);
		this.tabManager.changeTab(tabName);
	}

	async clickElementById(id, count = 1, interval = 0.1){
		let element = this.getElementBySelector(id);
		await this.clickElement(element, count, interval);
	}

	async clickElement(element, count = 1, interval = 0.1 ) {
		await this.wait(interval);
		for (let i = 0; i < count; i++) {
			element.click();
			await this.wait(interval);
		}
		await this.wait(interval);
	}

	async fullReset(){
		await this.changeTab('settings');
		this.removeOfflineModal();
		await this.clickElementById('#adminButton31');
		await this.wait(1);
		this.reAttachGameObjects();
	}

	removeOfflineModal(){

		let modal = document.getElementById("offline-modal");
		if (modal){
			modal.style.display = "none";
		}
	}

	reAttachGameObjects(){
		this.gameManager = this.game.gameManager;
		this.gameContent = this.game.gameManager.gameContent;
		this.eventManager = this.game.eventManager;
		this.ui = this.game.ui;
		this.tabManager = this.game.ui.tabManager;
	}

	wait(seconds) {
		return new Promise(resolve => {
		  	setTimeout(resolve, seconds * 1000);
		});
	}


	// Action Functions
	// ***************




	
	// ***************
	// Getter Functions


	getTrainingButtonByFeatureMapping(name){
		let id = this.featureMapping[name];
		let buttonId = `#button-${id}`;
		let element = this.getElementBySelector(buttonId);
		return element;
	}

	getForgeButtonByFeatureMapping(name){
		let id = this.featureMapping[name];
		let buttonId = `#button-${id}`;
		let element = this.getElementBySelector(buttonId);
		return element;
	}

	getAchievementButtonByFeatureMapping(name){
		let id = this.featureMapping[name];
		let buttonId = `#achievement-${id}`;
		let element = this.getElementBySelector(buttonId);
		return element;
	}

	getZoneButtonByFeatureMapping(name){
		let id = this.featureMapping[name];
		let buttonId = `#conquest-button-${id}`;
		let element = this.getElementBySelector(buttonId);
		return element;
	}

	getEssenceButtonByFeatureMapping(name){
		let id = this.featureMapping[name];
		let buttonId = `#eUpgrade-${id}`;
		let element = this.getElementBySelector(buttonId);
		return element;
	}

	getObjectByFeatureMapping(name){
		let id = this.featureMapping[name];
		let object = this.getObjectById(id);
		return object;
	}

	getObjectById(id) {
		return this.gameManager.findObjectById(id);
	}

	getElementBySelector(selector) {
		return document.querySelector(selector);
	}


	// Getter Functions
	// ***************



	// ***************
	// Assertion Functions
	
	

	assertElementStyleEquals(element, property, expectation) {
		try {
			let value;
			if (property in element.style) {
				value = element.style[property];
			} else {
				value = window.getComputedStyle(element)[property];
			}
	
			this.assert(value === expectation, `Expected ${property} to be ${expectation}, but got ${value}`);
			this.logTestResult(true, `${property} is ${expectation}`);
		} catch (error) {
			this.logTestResult(false, error.message);
		}
	}
	
	assertValueEquals(value, expectation, tolerance = 1e-5) {
		try {
			let isEqual;
	
			if (typeof value === 'object' && value !== null && 'eq' in value) {
				// If the value is a Decimal object, use the 'sub' method to check if the difference is within the tolerance
				const difference = value.sub(expectation).abs();
				isEqual = difference.lte(tolerance);
			  } else if (typeof value === 'number' && typeof expectation === 'number') {
				// If both value and expectation are numbers, use strict equality comparison
				isEqual = value === expectation;
			} else {
				// For all other cases (including strings), use strict equality comparison
				isEqual = value === expectation;
			}
	
			this.assert(isEqual, `Expected ${value} to be ${expectation}, but got ${value}`);
			this.logTestResult(true, `${value} is ${expectation}`);
		} catch (error) {
			this.logTestResult(false, error.message);
		}
	}
	
	assertObjectPropertyEquals(object, property, expectation, tolerance = 1e-5) {
		try {
			const value = object[property];
			let isEqual;
	
			if (typeof value === 'object' && value !== null && 'eq' in value) {
				// If the value is a Decimal object, use the 'sub' method to check if the difference is within the tolerance
				const difference = value.sub(expectation).abs();
				isEqual = difference.lte(tolerance);
			  } else if (typeof value === 'number' && typeof expectation === 'number') {
				// If both value and expectation are numbers, use strict equality comparison
				isEqual = value === expectation;
			} else {
				// For all other cases (including strings), use strict equality comparison
				isEqual = value === expectation;
			}
	
			this.assert(isEqual, `Expected ${property} to be ${expectation}, but got ${value}`);
			this.logTestResult(true, `${property} is ${expectation}`);
		} catch (error) {
			this.logTestResult(false, error.message);
		}
	}

	assertElementHasClass(element, expectedClassName) {
		try {
			const hasClass = element.classList.contains(expectedClassName);
			this.assert(hasClass, `Expected element to have class "${expectedClassName}"`);
			this.logTestResult(true, `Element has class "${expectedClassName}"`);
		} catch (error) {
			const actualClassList = element.classList.value;
			this.logTestResult(false, `${error.message}, found class(es): "${actualClassList}"`);
		}
	}

	// Assertion Functions
	// ******************





	// ******************
	// Testing Helper Functions

	setupAndRunTestSuite(){
		this.setupTestSuite();
		this.runTestSuite();
	}
	async runTestSuite() {
		for (const testName in this.testSuite) {
		  this.currentTest = testName;
		  try {
			await this.testSuite[testName]();
		  } catch (error) {
			this.logTestResult(false, error.message);
			this.logError(error);
		  }
		}
		this.printCondensedTestResults();
		this.printTestSummary();
	}

	test(description, testFunction) {
		this.testSuite[description] = testFunction;
	}

	logTestResult(result, message) {
		const status = result ? 'PASS' : 'FAIL';
		console.log(`[${status}] ${this.currentTest}: ${message}`);
		if (result) {
		  this.passCount++;
		} else {
		  this.failCount++;
		}
		
		// Update test result counters
		if (!this.testResults[this.currentTest]) {
		  this.testResults[this.currentTest] = {
			totalAssertions: 0,
			failedAssertions: 0,
		  };
		}
		this.testResults[this.currentTest].totalAssertions++;
		if (!result) {
		  this.testResults[this.currentTest].failedAssertions++;
		}
	}

	printCondensedTestResults() {
		console.log('');
		console.log('Condensed Test Results:');
		for (const testName in this.testResults) {
			const testResult = this.testResults[testName];
			const totalAssertions = testResult.totalAssertions;
			const failedAssertions = testResult.failedAssertions;
			const passedAssertions = totalAssertions - failedAssertions;
			const percentage = ((passedAssertions / totalAssertions) * 100).toFixed(2);
			console.log(`Test: ${testName}, Passed: ${passedAssertions}/${totalAssertions} - ${percentage}%`);
		}
	}

	printTestSummary() {
		const totalTests = this.passCount + this.failCount;
		const passPercentage = ((this.passCount / totalTests) * 100).toFixed(2);
		console.log('');
		console.log('Test Suite Summary:');
		console.log(`Total Tests: ${totalTests}`);
		console.log(`Passed: ${this.passCount}`);
		console.log(`Failed: ${this.failCount}`);
		console.log(`Pass Percentage: ${passPercentage}%`);
	}

	// assert(condition, message) {
	// 	if (!condition) {
	// 	  throw new Error(message);
	// 	}
	// }

	assert(condition, message) {
		if (condition) {
			console.log(`[PASS] ${message}`);
		} else {
			throw new Error(`[FAIL] ${message}`);
		}
	}

	logError(error) {
		console.error(`[ERROR] ${this.currentTest}: ${error.message}`);
		console.error(error.stack);
	}

	logCurrentTime() {
		const currentTime = new Date();
		console.log('Current time:', currentTime.toLocaleString());
	}

	startTimer() {
		this.startTime = new Date();
		console.log('Timer started at:', this.startTime.toLocaleString());
	}

	endTimer() {
		this.endTime = new Date();
		console.log('Timer ended at:', this.endTime.toLocaleString());
	}

	calculateTimeDifference() {
		if (this.startTime && this.endTime) {
			const timeDiff = this.endTime - this.startTime;
			const seconds = Math.floor(timeDiff / 1000);
			const milliseconds = timeDiff % 1000;
			console.log(`Time difference: ${seconds} seconds ${milliseconds} milliseconds`);
			return timeDiff;
		} else {
			console.log('Start time or end time is missing.');
			return null;
		}
	}

	
	// Testing Helper Functions
	// ******************
}

