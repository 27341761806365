export default class Tab {
	constructor(eventManager, id, name, active, initialUnlockedFeatureIDs, infoText = null, initialUnlockedSubTabs = null, parentTab = null, hasBeenOpened = false){
		this.eventManager = eventManager;
		this.id = id;
		this.name = name;
		this.active = active;
		this.initialUnlockedFeatureIDs = initialUnlockedFeatureIDs;
		this.initialUnlockedFeatureRefs = [];
		this.initialUnlockedSubTabs = initialUnlockedSubTabs;
		this.button = null;
		this.subTabs = [];
		this.parentTab = parentTab;

		this.currentSubTab = null;

		this.infoText = infoText;
		this.hasBeenOpened = hasBeenOpened;  // Set the hasBeenOpened property
	}

	setActive(){
		this.active = true;
		if (this.initialUnlockedFeatureRefs){
			for (const feature of this.initialUnlockedFeatureRefs){
				feature.setActive();
			}
		}

		// Slight delay so that CSS properties have time to update when checking for visible tabs
		setTimeout(() => {
			this.eventManager.dispatchEvent('updateHotkeyButtons');
		}, 100);
	}
}
