import Decimal from '../../Utilities/break_eternity.min.js'; // large number library
import { DEC } from '../../Utilities/decimal.js';

export default class NumberFormatter {
    constructor(eventManager, ui) {
        this.eventManager = eventManager;
        this.ui = ui;
    
        this.eventManager.addListener('updateNumberNotation', (data) => {
            if (data && data.value) {
                this.updateNumberNotation(data.value);
            }
        });
    }

	updateNumberNotation(value) {
		this.numberNotation = value;
	}
	
    formatNumber(num) {
		num = DEC(num);
		if (num.lte(9999)) {
			return Number(num.toFixed(2));
		}
		else{
			switch (this.numberNotation) {
				case "scientific":
					return num.toExponential(2).replace('+', '');
				case "engineering":
					// return `${num.mantissaWithDecimalPlaces(0)}e${num.magnitudeWithDecimalPlaces(1)}`;
					return this.toEngineeringNotation(num);
				case "log10":
					return num.log10().toFixed(0);
				case "toStringWithDecimalPlaces":
					return num.floor().toStringWithDecimalPlaces(3);
				case "toJSON":
					return num.floor().toJSON();
				case "toPrecision":
					return num.floor().toPrecision(3);
				case "toNumber":
					return num.floor().toNumber(2);
				case "string":
					return num.floor().toString(2);
				case "toFixed":
					return num.floor().toFixed(2);
				case "letter":
					return this.formatNumberLetter(num);
				case "cute":
					let [mantissa, exponent] = num.toExponential(3).split('e');
					return `${mantissa} * 10^${exponent}`;
				case "abbreviated":
					// need to generate an array with more than 3333 suffixes to handle numbers up to 10^9999
					const suffixes = this.generateSuffixes(); 
					const suffixNum = Math.max(0, Math.floor(num.log10() / 3));
					if (suffixNum >= suffixes.length) {
						return num.toExponential(3); // fallback to scientific notation if the number is too large
					}
					const shortValue = num.div(Decimal.pow(10, suffixNum * 3));
					return `${shortValue.toFixed(2)}${suffixes[suffixNum]}`;
					
				default:
					return num.toExponential(2).replace('+', '');
					// return this.toEngineeringNotation(num);
			}
		}
	}

	generateLetterNotationUnits() {
		const units = ['','K', 'M', 'B', 'T', 'Qa', 'Qi', 'Sx', 'Sp', 'Oc', 'No', 'Dc'];
		const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
		
		for (let i = 0; i < alphabet.length; i++) {
			for (let j = 0; j < alphabet.length; j++) {
				units.push(alphabet[i] + alphabet[j]);
			}
		}
		
		return units;
	}
	
	formatNumberLetter(num) {
		const units = this.generateLetterNotationUnits();
		let unitIndex = Math.floor(num.log10() / 3);
		let value = num.div(Decimal.pow(10, unitIndex * 3));
	
		let prefix = '';
		if (unitIndex >= 1000) {
			unitIndex -= 1000;
			prefix = 'Y';
		}
		if (unitIndex >= 1000) {
			unitIndex -= 1000;
			prefix = 'Z';
		}
		if (unitIndex >= 1000) {
			unitIndex -= 1000;
			prefix = 'E';
		}
	
		// Ensure the unit index does not exceed the length of the units array
		if (unitIndex >= units.length) {
			// Fallback to scientific notation if the number is too large
			return num.toExponential(3);
		}
	
		return `${value.toFixed(2)} ${prefix}${units[unitIndex]}`;
	}
	
	toEngineeringNotation(decimal) {
		let exponent = decimal.e;
		let mantissa = decimal.mantissa;
	
		if (exponent < 3) {
			return mantissa * Math.pow(10, exponent);
		}
	
		let engExponent = Math.floor(exponent / 3);
		let remainder = exponent % 3;
		let engMantissa = mantissa * Math.pow(10, remainder);
	
		return `${engMantissa.toFixed(2)}e${engExponent * 3}`;
	}

	toRoman(num) {
		if (isNaN(num))
			return NaN;
		if (num <= 0 || num >= 4000) 
			return "Invalid Input: Roman numerals are between 1 and 3999.";
		
		var values = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
		var numerals = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
		var roman = "";
		
		for (var i = 0; i < values.length; i++) {
			while (num >= values[i]) {
				num -= values[i];
				roman += numerals[i];
			}
		}
		
		return roman;
	}

	
}