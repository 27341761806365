export default class MinHeap {
    constructor() {
        this.heap = [];
    }

    getLeftChildIndex(parentIndex) { return 2 * parentIndex + 1; }
    getRightChildIndex(parentIndex) { return 2 * parentIndex + 2; }
    getParentIndex(childIndex) { return Math.floor((childIndex - 1) / 2); }

    hasLeftChild(index) { return this.getLeftChildIndex(index) < this.heap.length; }
    hasRightChild(index) { return this.getRightChildIndex(index) < this.heap.length; }
    hasParent(index) { return this.getParentIndex(index) >= 0; }

    leftChild(index) { return this.heap[this.getLeftChildIndex(index)]; }
    rightChild(index) { return this.heap[this.getRightChildIndex(index)]; }
    parent(index) { return this.heap[this.getParentIndex(index)]; }

    swap(indexOne, indexTwo) {
        let temp = this.heap[indexOne];
        this.heap[indexOne] = this.heap[indexTwo];
        this.heap[indexTwo] = temp;
    }

    peek() {
        if (this.heap.length === 0) throw "Heap is empty";
        return this.heap[0];
    }

    poll() {
        if (this.heap.length === 0) throw "Heap is empty";
        let item = this.heap[0];
        this.heap[0] = this.heap.pop();
        this.heapifyDown();
        return item;
    }

    add(item) {
		if (!this.contains(item)) {
			this.heap.push(item);
			this.heapifyUp();
		}
	}
	
    heapifyUp() {
        let index = this.heap.length - 1;
        while (this.hasParent(index) && this.parent(index).costNextSingle.gt(this.heap[index].costNextSingle)) {
            this.swap(this.getParentIndex(index), index);
            index = this.getParentIndex(index);
        }
    }

    heapifyDown() {
        let index = 0;
        while (this.hasLeftChild(index)) {
            let smallerChildIndex = this.getLeftChildIndex(index);
            if (this.hasRightChild(index) && this.rightChild(index).costNextSingle.lt(this.leftChild(index).costNextSingle)) {
                smallerChildIndex = this.getRightChildIndex(index);
            }

            if (this.heap[index].costNextSingle.lt(this.heap[smallerChildIndex].costNextSingle)) {
                break;
            } else {
                this.swap(index, smallerChildIndex);
            }

            index = smallerChildIndex;
        }
    }

	remove(item) {
        const index = this.heap.findIndex(element => element.id === item.id);
        if (index === -1) {
            throw new Error('Item not found in heap');
        }

        // Swap the found item with the last item in the heap
        this.swap(index, this.heap.length - 1);

        // Remove the last item in the heap (which is now the item we want to remove)
        this.heap.pop();

        // Re-heapify to maintain heap property
        this.heapifyDown(index);
        this.heapifyUp(index);
    }

	contains(item) {
		return this.heap.some(element => element.id === item.id);
	}

	// refresh() {
	// 	let startIdx = Math.floor((this.heap.length - 2) / 2);
	// 	for (let i = startIdx; i >= 0; i--) {
	// 		this.heapifyDown(i);
	// 	}
	// }


    // it's worth noting that the refresh method in the provided MinHeap class only re-heapifies the heap from the last non-leaf node to the root. If there are modifications to leaf nodes, they may not be properly heapified. You can modify the refresh method to iterate through all nodes to ensure a complete re-heapification:
    refresh() {
        for (let i = Math.floor(this.heap.length / 2); i >= 0; i--) {
            this.heapifyDown(i);
        }
    }
}