import { DEC } from '../../Utilities/decimal.js';

import GameFeature from '../../Core/GameFeature.js';

export default class EssenceUpgrade extends GameFeature {
	constructor(eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, specialTargetID, parentID, angleFromParent,distanceFromParent, isUnlockedByConnection) {
		super(
			eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active
		);
		this.specialTargetID = specialTargetID;
		this.target = null;
		this.featureType = "essenceUpgrade";

		this.isUnlockedByConnection = isUnlockedByConnection;
		//graphical and connection properties
		this.parentID = parentID;
		this.parent = null;
		this.angleFromParent = angleFromParent; // in degrees
		this.distanceFromParent = distanceFromParent; // distance from parent node in pixels
		this.children = [];
		this.x;
		this.y;
	}

	updateFeatureValues(isNewLvl, gameManager){
		let gameContent = gameManager.gameContent;
		this.costNextSingle = this.calcCostNextSingle();
		this.prodNextSingle = this.calcProdNextSingle();

		//dont update values if inactive || (level 0 & not being levelled up)
		if (!this.active || (this.level.eq(0) && !isNewLvl)) {
			return;
		}
		// handle if an active feature's multiplier is being updated but not its level
			// aka being upgraded or a mod is activated
		// also handles evolutions
		else if (this.active && !isNewLvl) {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
		//handle features that are being levelled manually or generator autopurchase
		else {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}


		switch (this.prodType) {
			case 'autoConquestProgression':
				gameContent.worldManager.autoUnlocked = true;
				break;
			case 'autoTournamentProgression':
				gameContent.tournament.autoUnlocked = true;
				break;
			case 'skillpoints':
				gameContent.skillpoints = gameContent.skillpoints.plus(this.prodBase);

				gameContent.baseSkillpoint = gameContent.baseSkillpoint.plus(this.prodBase);
				break;
			case 'baseFeatureLevel':
				this.target.baseLevel = this.target.baseLevel.plus(this.prodBase);
				this.target.updateValuesDigit(this.prodBase);
				this.target.levelUp("manual", this.prodBase);
				gameManager.updateFeatureValues(this.target, true);
				break;
			case 'zoneSkillpoint':
				//handle retroactive defeated zone skillpoints upgrades
				//also updated in stage.handleConquestComplete part of this solution
				for (const zone of gameContent.zones) {
					if (zone.active && zone.isDefeated) {
						zone.prodCurrentGlobal = zone.prodPrevious;
						zone.prodNextMultPurchase = zone.prodCurrentGlobal.times(zone.prodMult);
						let skillpointstoadd = zone.prodNextMultPurchase;
						gameContent.skillpoints = gameContent.skillpoints.plus(skillpointstoadd);
					}
				}
				break;
			case 'unlock':
				// feature.target.setActive();
				break;
			// case 'skillTreeUnlock':
			// 	feature.target.setActive();
			// 	break;
		}
	}

	activateChildren(){
		if (this.children.length > 0){
			for (const child of this.children){
				if (child.isUnlockedByConnection){
					child.setActive();
				}
			}
		}
	}
}