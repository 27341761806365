export default class ArtifactState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "artifacts";
        
        this.propertiesBase = ['baseLevel'];

        this.autoFeatureProperties = ['autoUnlocked','autoToggle'];

        this.regularPropertiesFull = ['level', 'manualLevel', 'autoLevel',
		'active', 'prodCurrentGlobal', 'prodPrevious','prodNextMultPurchase','prodNextSingle','prodMult', 'costNextMultPurchase','costNextSingle','costMult','maxAffLvl',  'evolutionTier', 'unlocked','evolved','evolutionTier'];

        this.propertiesFull = this.propertiesBase.concat(this.regularPropertiesFull).concat(this.autoFeatureProperties);
    }

    saveState(state, gameData){
        this.populateSaveDataBase(gameData);

        switch (state) {
			case 0:
				this.populateSaveDataFull(gameData);
				break;
			case 1:
				this.populateSaveDataRebirth1(gameData);
				break;
			case 2:
				this.populateSaveDataRebirth2(gameData);
				break;
			case 3:
				this.populateSaveDataRebirth3(gameData);
				break;
		}
    }
    
    loadState(state, gameData){
        this.applyLoadDataBase(gameData);

        switch (state) {
			case 0:
				this.applyLoadDataFull(gameData);
				break;
			case 1:
				this.applyLoadDataRebirth1(gameData);
				break;
			case 2:
				this.applyLoadDataRebirth2();
				break;
			case 3:
				this.applyLoadDataRebirth3();
				break;
		}
    }

    populateSaveDataBase(gameData){
        this.stateManager.populateSaveData(this.featureType, this.propertiesBase);}


    populateSaveDataFull(gameData) {
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);

        // save artifact autobuy array
		gameData.artifactAutobuys = this.stateManager.mapAndSaveIds(this.gameManager.automationManager.artifactAutobuys);
    }

    populateSaveDataRebirth1(gameData){
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
	}
    populateSaveDataRebirth2(gameData){}
    populateSaveDataRebirth3(gameData){}

    

    applyLoadDataBase(gameData){
        this.stateManager.applyLoadData(this.featureType, this.propertiesBase);}

    applyLoadDataFull(gameData) {
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);

		// load artifact autobuy array
		this.loadAutobuyArtifactData(gameData, 'artifactAutobuys');
    }
    
    applyLoadDataRebirth1(gameData){
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);
	}
    applyLoadDataRebirth2(){}
    applyLoadDataRebirth3(){}


    
	// Helper to load autoubuys
	loadAutobuyArtifactData(gameData, autoBuyName) {
		for (const item of gameData[autoBuyName]) {
			const artifact = this.gameManager.findObjectById(item.id);
			this.gameManager.automationManager[autoBuyName].push(artifact);
		}
	}
}