
export default class SkillsTab{
    constructor(eventManager, ui, gameContent){
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;

        this.skillTree = gameContent.skillTree;
        this.skills = this.skillTree.skills;

        this.skillsCol1 = this.ui.skillsCol1;
    }

    populateDisplay() {

        let skillData = this.skillsCol1.querySelector('#skill-data');
        
        this.skillGrid = this.ui.createElement('div',null,'skill-grid');

        
        //assign skillsCol1 color
        this.skillsCol1.classList.add('skills-color');

        skillData = this.ui.createElement('div','skill-data');
        this.skillsCol1.appendChild(skillData);

        let skillPointsTotal = this.ui.createElement('div','skillpoints',null,`Skill Points: ${this.ui.formatNumber(this.gameContent.skillpoints)}`);
        skillData.appendChild(skillPointsTotal);

        let refundSkillsButton = this.ui.createElement('button',`refund-skills-button`, null, "Refund All");

        const refundSkillsButtonClickHandler = () => {
            this.skillTree.refundAllSkills();
        };
        this.eventManager.addDomListener(refundSkillsButton, 'click', refundSkillsButtonClickHandler);
        
                                                                                                                
        skillData.appendChild(refundSkillsButton);

        this.skillsCol1.appendChild(this.skillGrid);

        this.skills.forEach(skill => {
            // Create a skill node element and position it based on its connections
            let skillNode = this.skillGrid.querySelector(`#skill-${skill.id}`);
            if (!skillNode) {
                skillNode = this.ui.createElement('button',`skill-${skill.id}`,'skill-node');
                skillNode.style.color = `${skill.color}`;
                skillNode.style.border = `2px solid ${skill.color}`;
                if (skill.skillType === "mastery"){
                    skillNode.classList.add("mastery-skill");
                }

                // Create the first letter text element
                const firstLetterText = this.ui.createElement('span', null, 'skill-first-letter');
                firstLetterText.textContent = skill.name.charAt(0).toUpperCase();
                // TROUBLESHOOTING SHOW ID'S AT A GLANCE
                // firstLetterText.textContent = skill.id - 40000;
                // firstLetterText.style.fontSize = "0.9em";
                // firstLetterText.style.color = "yellow";
                // TROUBLESHOOTING SHOW ID'S AT A GLANCE
                skillNode.appendChild(firstLetterText);

                // Append skill rune background image
                // skillNode.style.backgroundImage = `url(${skill.image})`;


                // Create a line element to connect the skill nodes
                const connections = skill.node.connections;
                for (const direction in connections) {
                    if (connections[direction]) { // Check if there is a connection in this direction
                        // console.error(connections[direction]);
                        const connectedSkill = connections[direction].skill;

                        if (connectedSkill && connectedSkill.unlocked) {
                            const line = this.ui.createElement('div',null,['skill-line', direction]);
                            line.style.backgroundColor = `${skill.color}`;
                            skillNode.appendChild(line);
                        }
                    }
                }

                //these parts of the function only fire initially, and not on updates. so write a function that i will move out of here, that can draw a line for things when they are unlocked, but doesnt draw it before. but also without running multiples times, it should check if there is a line appended here already, adn if there is, then do nothing, but if ther eisnt, then check unlcoks, or SOMETHING.  proabably mroe efficient than this


                // Add click event to upgrade the skill
                // Named function for the click event
                const skillNodeClickHandler = () => {
                    if (skill.level.eq(0)) {
                        this.ui.buyFeature(skill.id);
                    } else {
                        for (const connection of skill.unlockedConnections) {
                            if (connection.level.eq(1)) {
                                return;
                            }
                        }
                        skill.refundSkill();
                    }
                };

                // Use EventManager to add the event listener
                this.eventManager.addDomListener(skillNode, 'click', skillNodeClickHandler);

                // Optionally store the listener for future removal
                skillNode.clickListener = skillNodeClickHandler;

                this.ui.populateMouseOverZIndexEvents(skillNode);

                this.ui.populateTooltip(skill,skillNode);

                skillNode.style.left = `${skill.node.x}px`;
                skillNode.style.top = `${skill.node.y}px`;

                // Add the skill node to the skill grid
                this.skillGrid.appendChild(skillNode);
                this.updateConnectionLines(skill, skillNode);
            }
        });

        
            // Create a button to claim all affordable and active skills
            let claimAllSkillsButton = this.ui.createElement('button', 'claim-all-skills-button', null, "Claim Next Affordable (r)");

            const claimAllSkillsButtonClickHandler = () => {
                this.claimNextAffordableSkills();
            };
            this.eventManager.addDomListener(claimAllSkillsButton, 'click', claimAllSkillsButtonClickHandler);

            skillData.appendChild(claimAllSkillsButton);


        if (this.ui.injectAdminSettings){

            //and create a second button in the same manner as above, that will claim all skills that exist, and will set them to unlocked
            let claimAllUnlockedSkillsButton = this.ui.createElement('button', 'claim-all-skills-button', null, "Claim & Unlock All");
            const claimAndUnlockAllSkills = () => {
                this.skills.forEach(skill => {
                    if (skill.level.eq(0)) {
                        skill.unlocked = true;
                        skill.active = true;
                        skill.setUnlocked();
                        skill.setActive();
                        this.ui.buyFeature(skill.id);
                    }
                });
            };
            this.eventManager.addDomListener(claimAllUnlockedSkillsButton, 'click', claimAndUnlockAllSkills);
            skillData.appendChild(claimAllUnlockedSkillsButton);

        }
        
    }

    claimNextAffordableSkills(){
        this.skills.forEach(skill => {
            if (skill.active && this.ui.isAffordable(skill) && skill.level.eq(0) && skill.unlocked) {
                this.ui.buyFeature(skill.id);
            }
        });
    }

    updateDisplay(){
        // Update Elements
        let skillPointsTotal = document.querySelector('#skillpoints');
        this.ui.updateElementTextContent(skillPointsTotal,`Skill Points: ${this.ui.formatNumber(this.gameContent.skillpoints)}`);

        this.skillGrid = this.skillsCol1.querySelector('.skill-grid');

        this.skills.forEach(skill => {
            let skillNode = this.skillGrid.querySelector(`#skill-${skill.id}`);

            this.ui.updateTooltip(skill);

            this.checkButtonStatus(skill, skillNode);

            this.updateConnectionLines(skill, skillNode);
        });
    }

    updateConnectionLines(skill, skillNode) {
        const connections = skill.node.connections;
        for (const direction in connections) {
            if (connections[direction]) {
                const connectedSkill = connections[direction].skill;
                const line = skillNode.querySelector(`.skill-line.${direction}`);
    
                if (connectedSkill && connectedSkill.unlocked) {
                    if (!line) {
                        const newLine = this.ui.createElement('div', null, ['skill-line', direction]);
                        newLine.style.backgroundColor = `${skill.color}`;
                        skillNode.appendChild(newLine);
                    }
                } else {
                    if (line) {
                        line.remove();
                    }
                }
            }
        }
    }

    checkButtonStatus(skill, button) {
        let options;

        if (!skill.unlocked){
            options = {
                option: 1,
                disabled: true,
                addClasses: ['disabled'],
                removeClasses: [],
                styles: { opacity:`0.0`, cursor: 'default' }
            };
        }
        // Skill is purchased
        else if (skill.level.eq(skill.maxLevel)) {
            options = {
                option: 1,
                disabled: false,
                addClasses: ['enabled', 'skill-complete'],
                removeClasses: ['disabled'],
                styles: { backgroundColor: `${skill.color}` }
            };
        }
        // Skill is not active or not affordable
        else if (!skill.active || !this.ui.isAffordable(skill) || !skill.unlocked) {
            options = {
                option: 2,
                disabled: true,
                addClasses: ['disabled'],
                removeClasses: ['enabled', 'skill-complete'],
                styles: { backgroundColor: '', color: 'grey', opacity:'1.0', cursor:'pointer'}
            };
        }
        // Skill is affordable and active but not purchased
        else {
            options = {
                option: 3,
                disabled: false,
                addClasses: ['enabled'],
                removeClasses: ['disabled', 'skill-complete'],
                styles: { backgroundColor: '', color: `${skill.color}`,opacity:`1.0` }
            };
        }
        
    
        // Check if the option number is different from the last applied option
        if (skill.buttonStatusOption !== options.option) {
            this.updateButtonStatus(button, options);
            skill.buttonStatusOption = options.option; // Update the stored option number
        }

        // glow skills that are claimable
        if (skill.level.eq(0) && skill.active) {
            button.classList.add('retro-pulsate');
        } else {
            button.classList.remove('retro-pulsate');
        }
    }
  
    updateButtonStatus(button, options) {
        // Enable or Disable the button
        button.disabled = options.disabled;

        // Add or Remove Classes
        options.addClasses.forEach(cls => button.classList.add(cls));
        options.removeClasses.forEach(cls => button.classList.remove(cls));

        // Update Styles
        for (const [styleName, value] of Object.entries(options.styles || {})) {
            button.style[styleName] = value;
        }

        // Handle Child Elements (Optional)
        if (options.childStyles) {
            for (const [index, styles] of Object.entries(options.childStyles)) {
                const child = button.children[index];
                for (const [styleName, value] of Object.entries(styles)) {
                    child.style[styleName] = value;
                }
            }
        }
    }
}