export default class ZoneDisplayManager {
    constructor(eventManager, ui, gameContent) {
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.zoneUpdates = new Map();
        this.zoneElements = new Map();

        this.renderIntervalId = setInterval(() => this.renderZoneUpdates(), 10);

        this.eventManager.addListener('zoneConquestProgress', this.handleZoneConquestProgress.bind(this));
        this.eventManager.addListener('zoneConquestStopped', this.handleZoneConquestStopped.bind(this));

        this.regionNavBoxes = [];
        this.worldNavBoxes = [];
    }

    populateZones(zonesContainer, region) {
        region.zones.forEach(zone => {
            const zoneElements = this.createZoneElements(zone, region);
            zonesContainer.appendChild(zoneElements.cell);
            this.zoneElements.set(zone.id, zoneElements);
        });
    }

    createZoneElements(zone, region) {
        const zoneCell = this.ui.createElement('div', `zone-${zone.id}`, 'zone-cell');

        let topPosition = zone.y || 100;
        let leftPosition = zone.x || 200;

        if (zone.parent && zone.parent.region === zone.region) {
            let angleInRadians = zone.angleFromParent * (Math.PI / 180);
            topPosition = zone.parent.y + zone.distanceFromParent * Math.sin(angleInRadians);
            leftPosition = zone.parent.x + zone.distanceFromParent * Math.cos(angleInRadians);
        }

        zone.y = topPosition;
        zone.x = leftPosition;

        zoneCell.style = `top:${topPosition}px;left:${leftPosition}px;`;

        if (zone.parent) {
            const line = this.createZoneLine(zone, region);
            zoneCell.appendChild(line);
        }

        if (zone.hasConnectionToNewRegion) {
            this.createChildZoneLines(zone, zoneCell);
        }

        const button = this.createZoneButton(zone);
        zoneCell.appendChild(button);

        return { cell: zoneCell, button: button };
    }

    createZoneLine(zone, region) {
        const line = this.ui.createElement('div', null, 'zone-line');
        const radius = 15;
        let lineLength, angle, offsetX = 0, offsetY = 0;

        // handle connections within same region
        if (zone.parent.region === zone.region) {
            const dx = zone.x - zone.parent.x;
            const dy = zone.y - zone.parent.y;
            lineLength = Math.sqrt(dx * dx + dy * dy);
            angle = Math.atan2(dy, dx) * 180 / Math.PI - 180;

            const angleInRadians = (angle + 180) * Math.PI / 180;
            offsetX = radius * Math.cos(angleInRadians);
            offsetY = radius * Math.sin(angleInRadians);
        // handle connectinos to other worlds
        } else if (zone.parent.region.world !== zone.region.world) {
            lineLength = 100;
            angle = 180;
            const worldNavBox = this.createWorldNavBox(zone.parent.region.world, angle, zone.parent, zone);
            line.appendChild(worldNavBox);
        } else {
            lineLength = zone.distanceFromParent;
            angle = 90 - zone.angleFromParent;

            
            if (angle === 90){ // down
                lineLength = 80;
            }
            else if (angle === -90){ // up
                lineLength = 80;
            }
            else if (angle === 0 || angle === 180) { // right
                lineLength = 100;
            }
            else if (angle === -180){ // left
                lineLength = 100;
            }
            
            const regionNavBox = this.createRegionNavBox(zone.parent.region, angle, zone.parent, zone);
            line.appendChild(regionNavBox);
        }

        // const styleString = `width:${lineLength}px;transform:rotate(${angle}deg);left:${15 + offsetX}px;top:${15 + offsetY}px;`;
        const styleString = `width:${lineLength}px;transform:rotate(${angle}deg);`;
        line.style = styleString;

        return line;
    }

    createChildZoneLines(zone, zoneCell) {
        for (const child of zone.children) {
            const childLine = this.ui.createElement('div', null, 'zone-line');
            let lineLength, angle, offsetX = 0, offsetY = 0, navBox;

            // handle connections to other worlds
            if (child.region.world !== zone.region.world) {
                // lineLength = child.distanceFromParent;
                lineLength = 100;
                angle = 0;
                navBox = this.createWorldNavBox(child.region.world, angle, child, zone);
            // handle connections to other regions within a world
            } else if (child.region !== zone.region) {
                lineLength = child.distanceFromParent;
                const dx = child.region.topLeftCornerX - zone.region.topLeftCornerX;
                const dy = child.region.topLeftCornerY - zone.region.topLeftCornerY;
                angle = Math.atan2(dy, dx) * 180 / Math.PI;

                const angleInRadians = (angle + 180) * Math.PI / 180;
                offsetX = 15 * Math.cos(angleInRadians);
                offsetY = 15 * Math.sin(angleInRadians);

                if (angle === 90){ // down
                    lineLength = 80;
                }
                else if (angle === -90){ // up
                    lineLength = 80;
                }
                else if (angle === 0 || angle === 180) { // right
                    lineLength = 100;
                }
                else if (angle === -180){ // left
                    lineLength = 100;
                }
                navBox = this.createRegionNavBox(child.region, angle, child, zone);
            } else {
                continue;
            }

            // const styleString = `width:${lineLength}px;transform:rotate(${angle}deg);left:${15 + offsetX}px;top:${15 + offsetY}px;`;
            const styleString = `width:${lineLength}px;transform:rotate(${angle}deg)`;
            childLine.style = styleString;
            childLine.appendChild(navBox);
            zoneCell.appendChild(childLine);
        }
    }

    createWorldNavBox(world, angle, zone, zonePartner = null) {
        const worldNavBox = this.ui.createElement('div', null, ['worldNav-box', 'nav-box']);
        worldNavBox.style = `transform: rotate(${-angle}deg);`;
        // worldNavBox.textContent = "World: " + world.name;
        
        worldNavBox.textContent = "W";
    
        const clickListener = () => {
            if (zone.isDefeated || zonePartner.isDefeated) {
                this.eventManager.dispatchEvent('changeWorldMap', world);
            }
        };
    
        this.eventManager.addDomListener(worldNavBox, 'click', clickListener);
        worldNavBox.clickListener = clickListener;

        this.worldNavBoxes.push({ navBox: worldNavBox, zone, zonePartner });
        return worldNavBox;
    }

    createRegionNavBox(region, angle, zone, zonePartner = null) {
        const regionNavBox = this.ui.createElement('div', null, ['regionNav-box', 'nav-box']);
        regionNavBox.style = `transform: rotate(${-angle}deg);`;
        // regionNavBox.textContent = region.name;
        regionNavBox.textContent = "R";
    
        const regionClickHandler = () => {
            if (zone.isDefeated || zonePartner.isDefeated) {
                this.eventManager.dispatchEvent('updateMapView', region);
            }
        };
    
        this.eventManager.addDomListener(regionNavBox, 'click', regionClickHandler);
        regionNavBox.clickListener = regionClickHandler;
    
    
        this.regionNavBoxes.push({ navBox: regionNavBox, zone, zonePartner });
        return regionNavBox;
    }

    createZoneButton(zone) {
        const button = this.ui.createElement('button', `conquest-button-${zone.id}`, 'zone-button');
        
        const zoneClickHandler = () => {
            if (!button.disabled && !zone.isConquesting) {
                zone.startConquest();
            }
        };
    
        this.eventManager.addDomListener(button, 'click', zoneClickHandler);
        button.clickListener = zoneClickHandler;
    
        this.ui.populateMouseOverZIndexEvents(button);
        this.ui.populateTooltip(zone, button);
    
        // Add pseudo-element for costif cost is > 0
        const cost = zone.cost || 0; // Replace this with the actual property for the zone's cost
        const formattedCost = this.ui.formatNumber(cost);
        const costTypeClass = `${zone.costType}-color`; // Class based on costType
    
        button.style.position = 'relative';
        if (cost > 0 ){
            button.insertAdjacentHTML('beforeend', `<span class="zone-cost ${costTypeClass}">${formattedCost}</span>`);
        }
        else {
            button.insertAdjacentHTML('beforeend', `<span class="zone-cost ${costTypeClass}"></span>`);
        }
    
        return button;

    }
    

    
    // createZoneButton(zone) {
    //     const button = this.ui.createElement('button', `conquest-button-${zone.id}`, 'zone-button');
    
    //     const zoneClickHandler = () => {
    //         if (!button.disabled && !zone.isConquesting) {
    //             zone.startConquest();
    //         }
    //     };
    
    //     this.eventManager.addDomListener(button, 'click', zoneClickHandler);
    //     button.clickListener = zoneClickHandler;
    
    //     this.ui.populateMouseOverZIndexEvents(button);
    //     this.ui.populateTooltip(zone, button);
    
    //     return button;
    // }

    updateZones(zones) {
        zones.forEach(zone => {
            const zoneElements = this.zoneElements.get(zone.id);
            if (!zoneElements) return;
        
            const button = zoneElements.button;
            this.ui.updateTooltip(zone);
            this.checkZoneButtonStatus(zone, button);
            
            let zoneCostElement = button.querySelector('.zone-cost');
            if (zone.cost > 0){

                this.ui.updateElementTextContent(zoneCostElement,this.ui.formatNumber(zone.cost));
            }
        });

        this.updateNavBoxes();
    }

    updateNavBoxes() {
        // Update region nav boxes
        this.regionNavBoxes.forEach(({ navBox, zone, zonePartner }) => {
            if (zone && zonePartner && (zone.isDefeated || zonePartner.isDefeated)) {
                navBox.classList.remove('disabled');
            } else {
                navBox.classList.add('disabled');
            }
        });
    
        // Update world nav boxes
        this.worldNavBoxes.forEach(({ navBox, zone, zonePartner }) => {
            if (zone && zonePartner && (zone.isDefeated || zonePartner.isDefeated)) {
                navBox.classList.remove('disabled');
            } else {
                navBox.classList.add('disabled');
            }
        });
    }

    checkZoneButtonStatus(zone, button) {
        let options;
    
        if (zone.isDefeated) {
            options = {
                option: 1,
                disabled: true,
                addClasses: ['disabled-complete'],
                removeClasses: ['enabled','glow'],
                styles: {
                    border: zone.zoneType === "boss" ? '3px solid orange' :
                            zone.zoneType === "progressionBoss" ? '3px solid yellow' :
                            zone.zoneType === "legendaryBoss" ? '3px solid cyan' : '3px solid green' 
                        }
            };
            //hide element's zone-cost subelement
            if (button.querySelector('.zone-cost')) {
                button.querySelector('.zone-cost').style.display = 'none';
            }
        } else if (!zone.active || !this.ui.isAffordable(zone) || zone.isConquesting || !zone.unlocked) {
            options = {
                option: 2,
                disabled: true,
                addClasses: ['disabled'],
                removeClasses: ['enabled','glow'],
                styles: {
                    border: zone.zoneType === "boss" ? '3px solid orange' :
                            zone.zoneType === "progressionBoss" ? '3px solid yellow' :
                            zone.zoneType === "legendaryBoss" ? '3px solid cyan' : ''
                }
            };
        } 
        // if a zone's parent zone OR any of a zone's child zones exist- and if any of those zones are active, unlocked, and defeated
        else if ((zone.active && zone.unlocked && !zone.isDefeated) && zone.parent && zone.parent.active && zone.parent.isDefeated || zone.children.some(child => child.active && child.unlocked && child.isDefeated)) {
            options = {
                option: 4,
                disabled: false,
                addClasses: ['enabled','glow'],
                removeClasses: ['disabled'],
                styles: {
                    border: zone.zoneType === "boss" ? '3px solid orange' :
                    zone.zoneType === "progressionBoss" ? '3px solid yellow' :
                    zone.zoneType === "legendaryBoss" ? '3px solid cyan' : ''
                }
            };
        }
        
        else if (zone.parent && !zone.parent.active) {
            // if any of zone children are not the same region, and that child is active, AND this zone is unlocked
            // for handling mid-tree nodes with connections to other regions, so they are activated properly outside the (stupid and needing repalcement) child/parent paradigm 
            if (zone.active && zone.unlocked && zone.children.some(child => child.region !== zone.region && child.active)) {
                
                options = {
                    option: 4,
                    disabled: false,
                    addClasses: ['enabled','glow'],
                    removeClasses: ['disabled'],
                    styles: {
                        border: zone.zoneType === "boss" ? '3px solid orange' :
                        zone.zoneType === "progressionBoss" ? '3px solid yellow' :
                        zone.zoneType === "legendaryBoss" ? '3px solid cyan' : ''
                    }
                };

            }
            else {
                options = {
                    option: 3,
                    disabled: true,
                    addClasses: ['disabled'],
                    removeClasses: ['enabled','glow'],
                    styles: {
                        border: zone.zoneType === "boss" ? '3px solid orange' :
                        zone.zoneType === "progressionBoss" ? '3px solid yellow' :
                        zone.zoneType === "legendaryBoss" ? '3px solid cyan' : ''
                    }
                };
            }   
           
        } else {
            options = {
                option: 4,
                disabled: false,
                addClasses: ['enabled','glow'],
                removeClasses: ['disabled'],
                styles: {
                    border: zone.zoneType === "boss" ? '3px solid orange' :
                    zone.zoneType === "progressionBoss" ? '3px solid yellow' :
                    zone.zoneType === "legendaryBoss" ? '3px solid cyan' : ''
                }
            };
        }
    
        if (zone.buttonStatusOption !== options.option) {
            this.updateZoneButtonStatus(button, options);
            zone.buttonStatusOption = options.option;
        }
    }
    
    updateZoneButtonStatus(button, options) {
        button.disabled = options.disabled;
    
        options.addClasses.forEach(cls => button.classList.add(cls));
        options.removeClasses.forEach(cls => button.classList.remove(cls));
    
        for (const [styleName, value] of Object.entries(options.styles || {})) {
            button.style[styleName] = value;
        }
    }

    handleZoneConquestProgress(data) {
        this.zoneUpdates.set(data.zoneID, data.progress);
    }

    handleZoneConquestStopped(data) {
        const zoneElements = this.zoneElements.get(data.zoneID);
        if (!zoneElements) return;

        const button = zoneElements.button;
        button.style.background = '';
        button.disabled = false;
    }

    renderZoneUpdates() {
        for (const [zoneID, progress] of this.zoneUpdates) {
            const zoneElements = this.zoneElements.get(zoneID);
            if (!zoneElements) continue;

            const button = zoneElements.button;

            if (progress === 1) {
                button.style.backgroundColor = "rgb(21, 97, 122)";
                button.style.backgroundImage = "";
            } else {
                button.style.backgroundColor = "rgb(21, 97, 122)";
                button.style.backgroundImage = `linear-gradient(to right, #90ee90 0%, #90ee90 ${progress * 100}%, transparent ${progress * 100}%, transparent 100%)`;
            }
        }
        this.zoneUpdates.clear();
    }
}