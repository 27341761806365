
import Unlock from '../Core/Unlock.js';

export default class UnlockState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.gameContent = gameManager.gameContent;
        this.unlockManager = gameManager.unlockManager;
        this.stateManager = stateManager;
        this.featureType = "unlocks";

    }

    saveState(state, gameData){
        this.populateSaveDataBase(gameData);

        switch (state) {
			case 0:
				this.populateSaveDataFull(gameData);
				break;
			case 1:
				this.populateSaveDataRebirth1(gameData);
				break;
			case 2:
				this.populateSaveDataRebirth2(gameData);
				break;
			case 3:
				this.populateSaveDataRebirth3(gameData);
				break;
		}
    }
    
    loadState(state, gameData){
        this.applyLoadDataBase(gameData);

        switch (state) {
			case 0:
				this.applyLoadDataFull(gameData);
				break;
			case 1:
				this.applyLoadDataRebirth1(gameData);
				break;
			case 2:
				this.applyLoadDataRebirth2(gameData);
				break;
			case 3:
				this.applyLoadDataRebirth3(gameData);
				break;
		}
    }

    populateSaveDataBase(gameData){}

    populateSaveDataFull(gameData) {
        gameData.incompleteUnlocks = this.stateManager.mapAndSaveIds(Array.from(this.gameManager.unlockManager.unlocks.values()));
		gameData.completedUnlocks = this.stateManager.mapAndSaveIds(Array.from(this.gameManager.unlockManager.completedUnlocks.values()));
    }

    populateSaveDataRebirth1(gameData){
        gameData.incompleteUnlocks = this.stateManager.mapAndSaveIds(Array.from(this.gameManager.unlockManager.unlocks.values()));
		gameData.completedUnlocks = this.stateManager.mapAndSaveIds(Array.from(this.gameManager.unlockManager.completedUnlocks.values()));
	}
    populateSaveDataRebirth2(gameData){}
    populateSaveDataRebirth3(gameData){}

    

    applyLoadDataBase(gameData){}

	applyLoadDataFull(gameData) {
		// Process completed unlocks
		gameData.completedUnlocks.forEach(data => {
		  if (this.unlockManager.unlocks.has(data.id)) {
			const completedUnlock = this.unlockManager.unlocks.get(data.id);
			this.unlockManager.unlocks.delete(data.id);
			this.unlockManager.completedUnlocks.set(data.id, completedUnlock);
		  }
		});
	  
		// Process incomplete unlocks
		gameData.incompleteUnlocks.forEach(data => {
		  if (!this.unlockManager.unlocks.has(data.id) && !this.unlockManager.completedUnlocks.has(data.id)) {
			this.unlockManager.unlocks.set(data.id, data);
		  }
		});
	  
		// Filter out evolution unlocks from completed unlocks
		let filteredCompletedUnlocks = new Map(
		  Array.from(this.unlockManager.completedUnlocks.entries()).filter(([_, unlock]) => unlock.triggerType !== 'evolve')
		);

		//filter out any unlocks with tutorial type so they do not re-process
		filteredCompletedUnlocks = new Map(
		  Array.from(filteredCompletedUnlocks).filter(([_, unlock]) => unlock.type !== 'tutorial')
		);
	  
		// Process triggers for the new filtered completed unlocks
		this.unlockManager.processTriggers(filteredCompletedUnlocks, false);
	  }
    
	  applyLoadDataRebirth1(gameData){
		// Process completed tutorial unlocks
		gameData.completedUnlocks.forEach(data => {
			// Check if the id exists in the current unlocks
			if (this.unlockManager.unlocks.has(data.id)) {
				const unlock = this.unlockManager.unlocks.get(data.id);
				if (unlock.type === 'tutorial') {
					// Remove from unlocks and add to completedUnlocks
					this.unlockManager.unlocks.delete(data.id);
					this.unlockManager.completedUnlocks.set(data.id, unlock);
				}
			} else if (this.unlockManager.completedUnlocks.has(data.id)) {
				const completedUnlock = this.unlockManager.completedUnlocks.get(data.id);
				if (completedUnlock.type === 'tutorial') {
					// Ensure it's in completedUnlocks if it should be
					this.unlockManager.completedUnlocks.set(data.id, completedUnlock);
				}
			}
		});
	
		// Process incomplete tutorial unlocks
		gameData.incompleteUnlocks.forEach(data => {
			// Check if the id exists in the current unlocks
			if (this.unlockManager.unlocks.has(data.id)) {
				const unlock = this.unlockManager.unlocks.get(data.id);
				if (unlock.type === 'tutorial' && !this.unlockManager.completedUnlocks.has(data.id)) {
					this.unlockManager.unlocks.set(data.id, unlock);
				}
			} else {
				// Add to unlocks if not present in either unlocks or completedUnlocks
				this.unlockManager.unlocks.set(data.id, data);
			}
		});
	
		// No trigger processing
	}
	
	

	
    applyLoadDataRebirth2(gameData){}
    applyLoadDataRebirth3(gameData){}

    reApplyUnlocks(){

    }
}