import { DEC } from '../../../Utilities/decimal.js';

export default class ArtifactsSubTab{
    constructor(eventManager, ui, gameContent, tabManager){
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.tabManager = tabManager;

        
		this.shardsMap = this.gameContent.shards;
		this.artifacts = this.gameContent.artifacts;
        
    }

    populateDisplay(){
	
        this.targetCol1 = document.getElementById(`tab-col1-ArtifactsSubTab`);
		this.targetCol2 = document.getElementById(`tab-col2-ArtifactsSubTab`);
		this.targetCol1.classList.add('artifacts-color');
		this.targetCol2.classList.add('artifacts-color');
	
		
		// Create overall shard multiplier cell
		const overallMultiplierId = 'overall-shard-multiplier';
		let overallMultiplierCell = this.targetCol1.querySelector(`#${overallMultiplierId}`);
		if (!overallMultiplierCell) {
			overallMultiplierCell = this.ui.createElement('div', overallMultiplierId, 'overall-multiplier-cell');
			this.targetCol1.appendChild(overallMultiplierCell);
		}

		// Create header row for shard cells
		const shardHeaderId = 'shard-header';
		let shardHeaderCell = this.targetCol1.querySelector(`#${shardHeaderId}`);
		if (!shardHeaderCell) {
			shardHeaderCell = this.ui.createElement('div', shardHeaderId, ['shard-cell','shard-cells-header']);
			const shardNameHeader = this.ui.createElement('div', null, 'shard-name');
			const shardCountHeader = this.ui.createElement('div', null, 'shard-count');
			const shardMultBaseHeader = this.ui.createElement('div', null, 'shard-mult-base');
			const shardMultAllHeader = this.ui.createElement('div', null, 'shard-mult-all');
			shardNameHeader.textContent = 'Shard';
			shardCountHeader.textContent = 'Count';
			shardMultBaseHeader.textContent = 'Multiplier';
			shardMultAllHeader.textContent = 'Total';
			shardHeaderCell.appendChild(shardNameHeader);
			shardHeaderCell.appendChild(shardCountHeader);
			shardHeaderCell.appendChild(shardMultBaseHeader);
			shardHeaderCell.appendChild(shardMultAllHeader);
			this.targetCol1.appendChild(shardHeaderCell);
		}
		
		// Populate shards currency display
		this.shardsMap.forEach((amount, shardType) => {
			const shardId = `shard-${shardType}`;
			let shardCell = this.targetCol1.querySelector(`#${shardId}`);
			if (!shardCell) {
				shardCell = this.ui.createElement('div', shardId, 'shard-cell');
				const shardName = this.ui.createElement('div', null, 'shard-name');
				const shardCount = this.ui.createElement('div', null, 'shard-count');
				const shardMultBase = this.ui.createElement('div', null, 'shard-mult-base');
				const shardMultAll = this.ui.createElement('div', null, 'shard-mult-all');
				shardCell.appendChild(shardName);
				shardCell.appendChild(shardCount);
				shardCell.appendChild(shardMultBase);
				shardCell.appendChild(shardMultAll);
				this.targetCol1.appendChild(shardCell);
			}
		});
	

		// populate initial base item artifact cells and hide inactive ones
		for (const artifact of this.artifacts) {
			let artifactId = `artifact-${artifact.id}`;
			let artifactCell = this.targetCol2.querySelector(`#${artifactId}`);


			artifactCell = this.ui.populateFeatureCell(artifact, this.targetCol2, artifactId);
			this.ui.populateAutobuyCheckbox(artifact, artifactCell, null);
			this.ui.updateVisibility(artifactCell,artifact.active);
			this.ui.populateMouseOverZIndexEvents(artifactCell);

		}
		
    }

    updateDisplay() {
		if (this.artifactEvolving) {
			return;
		}
	
		// Update shard column
this.shardsMap.forEach((amount, shardType) => {
	const shardId = `shard-${shardType}`;
	let shardCell = this.targetCol1.querySelector(`#${shardId}`);
	let shardName = shardCell.querySelector('.shard-name');
	let shardCount = shardCell.querySelector('.shard-count');
	let shardMultBase = shardCell.querySelector('.shard-mult-base');
	let shardMultAll = shardCell.querySelector('.shard-mult-all');
  
	// Check if amount is zero
	if (amount.eq(0)) {
	  // Set display to none if amount is zero
	  if (shardCell.style.display !== 'none') {
		shardCell.style.display = 'none';
	  }
	  return;
	} else {
	  // Set display to flex if amount is non-zero
	  if (shardCell.style.display !== 'flex') {
		shardCell.style.display = 'flex';
	  }
	}
  
	// Update shard name if changed
	const newShardName = shardType.slice(0, -5);
	const formattedShardName = newShardName.charAt(0).toUpperCase() + newShardName.slice(1);
	if (shardName.textContent !== formattedShardName) {
	  shardName.textContent = formattedShardName;
	}
  
	// Update shard count if changed
	const formattedCount = `${this.ui.formatNumber(DEC(amount))}`;
	if (shardCount.textContent !== formattedCount) {
	  shardCount.textContent = formattedCount;
	}
  
	// Update shard base multiplier if changed
	const baseMultiplier = this.gameContent.shardBonusMultipliers[shardType];
	if (shardMultBase.textContent !== baseMultiplier) {
	  shardMultBase.textContent = baseMultiplier;
	}
  
	// Update shard total multiplier if changed
	const computedMultiplier = this.gameContent.computedShardMultipliers[shardType] || DEC(0);
	const formattedMultiplier = `${this.ui.formatNumber(computedMultiplier)}`;
	if (shardMultAll.textContent !== formattedMultiplier) {
	  shardMultAll.textContent = formattedMultiplier;
	}
  });
  
  // Update overall shard multiplier
  const overallMultiplierCell = this.targetCol1.querySelector('#overall-shard-multiplier');
  overallMultiplierCell.textContent = `Shard Income Multiplier: ${this.ui.formatNumber(this.gameContent.overallShardBonusMultiplier)}`;
	
		
		// Update Artifact Column
		for (const artifact of this.artifacts) {
			let artifactId = `artifact-${artifact.id}`;
			let artifactCell = this.targetCol2.querySelector(`#${artifactId}`);

			if (artifact.active) {
				this.ui.updateFeatureCell(artifact, artifactCell);
				this.ui.updateAutobuyCheckbox(artifact, artifactCell);
			}
		}
    }
}
