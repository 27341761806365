export default class StatsDisplay {
	constructor(eventManager, gameContent, ui){
        this.eventManager = eventManager;
        this.gameContent = gameContent;
        this.ui = ui;

        
		this.statsList = ['force', 'wisdom', 'energy', 'divine', 'crystal', 'essence', 'radiance', 'powerLevel'];
    }

    async populateDisplay() {
        let column = null;

        for(let i = 0; i < this.statsList.length; i++){
            // Check for 'powerLevel' or new column every 2 items
            if(this.statsList[i] === 'powerLevel' || i % 2 === 0){
                column = this.ui.createElement('div',null,'stats-column');

                this.ui.statsRow.appendChild(column);
            }

            const statDiv = document.createElement('div');
            statDiv.className = this.statsList[i] === 'powerLevel' ? `${this.statsList[i]}-stat` : `${this.statsList[i]}-stat ${this.statsList[i]}-color`;
            statDiv.classList.add(`stats-container`);

            const statNameDiv = this.ui.createElement('div',null,'stat-name',`${this.statsList[i].charAt(0).toUpperCase() + this.statsList[i].slice(1)}`);

            const statValueDiv = this.ui.createElement('div',null,'stat-value');
            statDiv.appendChild(statNameDiv);
            statDiv.appendChild(statValueDiv);

            if (this.statsList[i] !== "radiance" && this.statsList[i] !== "essence" && this.statsList[i] !== "crystal"){
                this.ui.populateTooltip(`${this.statsList[i]}-stat`, statDiv);
            }
            
            column.appendChild(statDiv);
        }
	}

    async updateDisplay() {

        this.statsList.forEach(stat => {
            let statDiv = this.ui.statsRow.querySelector(`.${stat}-stat`);
            if (statDiv) {
            let statValueDiv = this.ui.statsRow.querySelector(`.${stat}-stat .stat-value`) || this.ui.statsRow.querySelector(`.${stat} .stat-value`);
            this.ui.updateElementTextContent(statValueDiv, `${this.ui.formatNumber(this.gameContent[stat])}`);
            if (stat !== "radiance" && stat !== "essence" && stat !== "crystal") {
                this.ui.updateTooltip(null, stat);
            }

            // Update the opacity and width of the statDiv based on the stat value
            if (this.gameContent[stat] == 0) {
                statDiv.style.opacity = 0;
                statDiv.style.width = '0';
                statDiv.style.marginLeft = '0';
                statDiv.style.marginRight = '0';
                statDiv.style.padding = '0';
            } else {
                statDiv.style.opacity = 1;
                statDiv.style.width = '';
                statDiv.style.marginLeft = '';
                statDiv.style.marginRight = '';
                statDiv.style.padding = '';
            }
            }
        });
    }
}