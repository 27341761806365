export default class EventManager {
	constructor() {
		this.listeners = {};
        this.domListeners = new Map(); 
	}

	// Register an event listener
	addListener(eventType, callback) {
		if (!this.listeners[eventType]) {
			this.listeners[eventType] = [];
		}
		this.listeners[eventType].push(callback);
	}

	addDomListener(element, eventType, callback) {
        // Add the event listener to the DOM element
        element.addEventListener(eventType, callback);

        // Store the reference to the listener for later removal
        if (!this.domListeners.has(element)) {
            this.domListeners.set(element, []);
        }
        this.domListeners.get(element).push({ eventType, callback });
    }

    removeDomListener(element, eventType, callback) {
        // Remove the listener from the DOM element
        element.removeEventListener(eventType, callback);

        // Remove the listener reference from the map
        const listeners = this.domListeners.get(element);
        if (listeners) {
            const index = listeners.findIndex(listener => listener.eventType === eventType && listener.callback === callback);
            if (index > -1) {
                listeners.splice(index, 1);
            }
            // If no listeners remain for the element, remove the element from the map
            if (listeners.length === 0) {
                this.domListeners.delete(element);
            }
        }
		element.remove();
    }

	// Remove an event listener
	removeListener(eventType, callback) {
		if (this.listeners[eventType]) {
			const index = this.listeners[eventType].indexOf(callback);
			if (index > -1) {
				this.listeners[eventType].splice(index, 1);
			}
		}
	}

	// Dispatch an event to all registered listeners
	dispatchEvent(eventType, eventData, handleResult) {
		if (this.listeners[eventType]) {
			this.listeners[eventType].forEach(callback => {
				if (typeof handleResult === 'function') {
					callback(eventData, handleResult);
				} else {
					callback(eventData);
				}
			});
		}
	}

	dispatchQuery(eventType, eventData) {
		return new Promise((resolve) => {
			this.dispatchEvent(eventType, eventData, resolve);
		});
	}

	clearAllListeners() {
        // Iterate through all DOM elements and their listeners for removal
        this.domListeners.forEach((listeners, element) => {
			// Clone the array to avoid modification issues while iterating
			const clonedListeners = [...listeners];
			clonedListeners.forEach(({ eventType, callback }) => {
				// Use removeDomListener to ensure all cleanup logic is executed
				this.removeDomListener(element, eventType, callback);
			});
		});
        // Clear the map
        this.domListeners.clear();

        // Clear application-level listeners
        this.listeners = {};
    }
}
