import { DEC } from '../../Utilities/decimal.js';

export default class World {
	constructor(eventManager, worldManager, id, name, note, zoneShardType, active) {
		this.eventManager = eventManager;
		this.id = id;
		this.name = name;
		this.note = note;
		this.worldManager = worldManager;
		
		this.featureType = "world";

		this.regions = [];

		this.zoneShardType = zoneShardType;

		this.active = active;
		this.isCompleted = false;
		this.isProgressed = false;

		this.regionsProgressedCount = DEC(0);
		this.zonesProgressedCount = DEC(0);
		this.worldsProgressedCount = DEC(0);

		this.totalZoneCount = 0;
		this.totalZonesCompleted = 0;
		this.progressPercentage = 0;

		this.currentlyViewing = false;

		this.level = this.id - 1000000;

		this.shardScalingFactor = DEC(this.level * 0.01);
		this.augmentedShardRarities = {};
		this.normalizedShardRarities = {};
		this.shardDistribution = {};
		this.calculateShardDistribution();
	}
	
	// Calculate augmented rarities based on world level
	calculateShardDistribution(){
		let baseShardRarities = this.worldManager.gameContent.baseShardRarities;
		this.augmentedShardRarities = baseShardRarities.map(shard => {
			return { 
				type: shard.type, 
				rarity: shard.rarity.times(DEC(1).plus(this.shardScalingFactor)) // Adjust the scaling factor if needed
			};
		});

		let totalRarity = this.augmentedShardRarities.reduce((sum, shard) => sum.plus(shard.rarity), DEC(0));
		this.normalizedShardRarities = this.augmentedShardRarities.map(shard => {
			return {
				type: shard.type,
				rarity: shard.rarity.div(totalRarity)
			};
		});

		this.normalizedShardRarities.forEach(shard => {
			this.shardDistribution[shard.type] = DEC(0);
		});
	}

	setCompleted() {
		this.isCompleted = true;
		this.worldManager.worldCompleted();
	}

	setProgressed(){
		this.isProgressed = true;

		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: "lifetimeWorldProgressions",
			value: DEC(1),
			operation: 'add'
		});

		this.worldManager.worldProgressed();
	}

	setActive() {
		this.active = true;
		this.regions[0].setActive();
		// if (this.fighterTier){
		// 	this.fighterTier.worldSetActive();
		// }
	}

	regionCompleted() {
		const allRegionsCompleted = this.regions.every(region => region.isCompleted);

		if (allRegionsCompleted) {
			this.setCompleted();
		}
	}

	regionProgressed(){
		//check if world is progressed or set next region active
		const allRegionsProgressed = this.regions.every(region => region.isProgressed);
		if (allRegionsProgressed) {
			this.setProgressed();
		}
		// else {
			// const nextRegion = this.regions.find(region => !region.active);
			// if (nextRegion) {
			// 	nextRegion.setActive();
			// }
		// }

		// this.worldManager.regionProgressed();
		
		this.worldManager.regionsProgressed = this.worldManager.regionsProgressed.plus(1);

		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: 'maxProgressionRegion',
			value: this.worldManager.regionsProgressed,
			operation: 'replaceIfGreater'
		});
	}

	zoneCompleted(){
		this.worldManager.zoneCompleted();
		this.totalZonesCompleted++;
		this.progressPercentage = this.totalZonesCompleted / this.totalZoneCount * 100;
	}
}
