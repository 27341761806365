export default class Observable {
	constructor() {
		this.observers = [];
	}

	registerObserver(observer) {
		this.observers.push(observer);
	}

	unregisterObserver(observer) {
		const index = this.observers.indexOf(observer);
		if (index > -1) {
			this.observers.splice(index, 1);
		}
	}

	notifyObservers(data) {
		this.observers.forEach(observer => {
			if (observer.active) {
				observer.update(data);
			}
		});
	}

	unlockObserver(observerID) {
		this.observers.find(observer => observer.id === observerID).active = true;
	}
}