import Builder from '../Builder/Builder.js';
import EventManager from './_EventManager.js';
import GameSettings from './GameSettings.js';
import GameManager from './_GameManager.js';
import GameUI from '../UI/Core/_GameUI.js';
import StateManager from '../StateManagement/_StateManager.js';
import RewardManager from './RewardManager.js';
import OfflineManager from './OfflineManager.js';


export default class Game {
	constructor() {
		this.eventManager = new EventManager();
		// this.settings = new GameSettings();
		
		
		this.incomeUpdateInterval = 100; // ms
		this.uiUpdateInterval = 100; // ms
		this.unlockCheckInterval = 200;
		
		this.gameStartTime = new Date().getTime();
		this.totalSessionTime = 0;
		
		this.lastSaveTime = null;

		this.autosaveFrequency = 10000;
		this.lastAutosaveTime = Date.now();
		this.lastAutosaveTimeConverted = null;
		
		
        this.lastProcessTime = Date.now();
        this.offlineProcessingThresholdMs = 10000; // ms

		this.running = false;

		this.autosaveEnabled = true;
		this.offlineGainsEnabled = true;


		this.injectAdminSettings = false;

		

		// TIME TRACKING
		if (this.injectAdminSettings){
			this.unlockTimes = []; // Array to store unlock times
			this.logInterval = 60000; // Log interval in milliseconds (e.g., 10 seconds)
			this.lastLogTime = 0; // Timestamp of the last log
		}
		// TIME TRACKING



		this.init();
		
		
		// process game when tab loses and regains focus
		// Define a named function for the visibility change event
		const visibilityChangeHandler = () => {
			let currentTime = Date.now();
			let timeSinceLastProcess = currentTime - this.lastProcessTime;
	
			if (this.offlineGainsEnabled && (timeSinceLastProcess >= this.offlineProcessingThresholdMs)) {
				this.offlineManager.processOffline(timeSinceLastProcess);
			}
		};

		document.addEventListener('visibilitychange', visibilityChangeHandler);
	}

	init() {
		this.running = false;
	
		this.gameManager = new GameManager(this.eventManager, this.injectAdminSettings);
		this.builder = new Builder(this.eventManager, this.gameManager);
		this.rewardManager = new RewardManager(this.eventManager, this.gameManager);
	
		//state must be declared before UI
		this.stateManager = new StateManager(this.eventManager, this.gameManager);
		this.ui = new GameUI(this.eventManager, this.gameManager, this.stateManager, this.rewardManager, this.injectAdminSettings);
	
		//because ui must be declared second, i apply these references afterwards for use in state management
		this.stateManager.ui = this.ui;
		this.stateManager.displayState.tabs = this.stateManager.ui.tabManager.tabs;
	
		this.offlineManager = new OfflineManager(this.eventManager, this.gameManager);
	
		let state = 0;
		this.eventManager.addListener('restart', (state) => this.restart(state));
		
		state = this.stateManager.saveStateType;

		// load game data based on state value
		this.stateManager.loadState(state);
	
		this.lastSaveTime = this.stateManager.lastSaveTime;
	
		this.builder = null;
	
		this.lastIncomeUpdate = performance.now();
		this.lastUnlockCheck = performance.now();
	
		this.running = true;
	
		// Restart intervals if regular game load
		if (state === 0) {
			this.restartRunningIntervals();
		}
	
		this.gameLoop();
	
		// Defer the offline gains processing to ensure the UI has loaded
		setTimeout(() => {
			this.processOfflineGainsIfNeeded(state);
		}, 0);
	}
	
	processOfflineGainsIfNeeded(state) {
		if (!this.stateManager.versionMismatch && this.offlineGainsEnabled) {
			// if game loaded naturally, and a save file exists, process offline income
			if (state === 0 && this.stateManager.localStorageRetrieved) {
				let currentTime = Date.now();


				// DEBUG TESTING FAKE LAST SAVE TIME INJECTION
				// let testingSaveTime = 'Mon Jun 17 2024 10:05:39 GMT-0500 (Central Daylight Time)';
				// let fakeLastSaveTimestamp = Date.parse(testingSaveTime);
				// let elapsedTimeMs = currentTime - fakeLastSaveTimestamp;
				// END DEBUG TESTING FAKE LAST SAVE TIME INJECTION

				let elapsedTimeMs = currentTime - this.stateManager.lastSaveTime;
	
				if (elapsedTimeMs >= this.offlineProcessingThresholdMs) {
					this.offlineManager.processOffline(elapsedTimeMs);
				}
			}
		}
	}
	
	restart(state) {
		// this.running = false;
		// this.clearRunningIntervals();
		
		// this.eventManager.clearAllListeners();
		// this.ui.hotkeyManager.detachKeyDownListener();
		
		// this.clearGameObjects();

		if (state === -1){
			this.stateManager.saveState(-1);
		}
		window.location.reload();

		// this.init(state);
	}

	clearGameObjects() {
		const visitedObjects = new WeakSet();

		const nullifyObject = (obj) => {
			if (visitedObjects.has(obj)) {
				return;
			}
			visitedObjects.add(obj);

			for (let prop in obj) {
				if (obj.hasOwnProperty(prop)) {
					if (typeof obj[prop] === 'object' && obj[prop] !== null) {
						nullifyObject(obj[prop]);
					}
					obj[prop] = null;
				}
			}
		};

		// Clear event listeners
		this.eventManager.clearAllListeners();
		this.clearUI();
	
		this.gameManager.gameContent.idToObjectMap.clear();
		// Clear game manager
		this.gameManager.gameContent = null;
		this.gameManager.unlockManager = null;
		this.gameManager.automationManager = null;
		this.gameManager.eventManager = null;
		this.gameManager = null;
	
		// Clear state manager
		this.stateManager = null;
	
		// Clear reward manager
		this.rewardManager = null;

	
		// Clear offline manager
		this.offlineManager.eventManager = null;
		this.offlineManager.gameManager = null;
		this.offlineManager = null;

		// console.error({...this});
	}

	clearRunningIntervals(){
		for (const zone of this.gameManager.gameContent.zones){
			if (zone.isConquesting){
				zone.stopConquest();
			}
		}

		if (this.ui.tabManager.explorationTab.odysseySubTab.renderIntervalId) {
			clearInterval(this.ui.tabManager.explorationTab.odysseySubTab.renderIntervalId);
			this.ui.tabManager.explorationTab.odysseySubTab.renderIntervalId = null;
			clearInterval(this.ui.tabManager.explorationTab.odysseySubTab.zoneDisplayManager.renderIntervalId);
			this.ui.tabManager.explorationTab.odysseySubTab.zoneDisplayManager.renderIntervalId = null;
		}
	}

	restartRunningIntervals(){
		for (const zone of this.gameManager.gameContent.zones){
			// if (zone.active && zone.isDefeated && zone.autoUnlocked){
			if (zone.isConquesting && !zone.isDefeated){
				zone.startConquest(zone.progress);
			}
		}
	}

	clearUI() {
		const rootElement = document.getElementById('root');
		while (rootElement.firstChild) {
			rootElement.removeChild(rootElement.firstChild);
		}
	
		// Clear the UI references
		this.ui.uiElementsMap.forEach((element, key) => {
			this.ui[key] = null; // Clear the reference in GameUI
			this.ui.uiElementsMap.delete(key); // Remove from the Map
		});
		this.ui.uiElementsMap.clear(); // Optional, but ensures the Map is truly empty
		
	}

	gameLoop() {
		if (!this.running) {
		  return;
		}
	
		//TIME TRACKING
		let startTime;
		if (this.injectAdminSettings){
			startTime = performance.now();
		}
		//TIME TRACKING
	
		this.lastProcessTime = Date.now();
		this.checkUnlocks();
		this.updateUI();
		this.updateIncome();
		if (this.autosaveEnabled) {
		 	 this.manageAutosave();
		}
		this.updatePlayTimeProperties();
	
		this.rewardManager.checkRewards();
	
		
		//TIME TRACKING
		if (this.injectAdminSettings){
			const endTime = performance.now();
			const elapsedTime = endTime - startTime;
			this.unlockTimes.push(elapsedTime);
			const currentTime = performance.now();
			if (currentTime - this.lastLogTime >= this.logInterval) {
				this.logUnlockTimes();
				this.lastLogTime = currentTime;
			}
		}
		//TIME TRACKING
	
		requestAnimationFrame(() => this.gameLoop());
	}
	
	//TIME TRACKING
	logUnlockTimes() {
		const count = this.unlockTimes.length;
		if (count === 0) {
			return;
		}

		const sum = this.unlockTimes.reduce((a, b) => a + b, 0);
		const avg = sum / count;
		const min = Math.min(...this.unlockTimes);
		const max = Math.max(...this.unlockTimes);

		console.log(`Game loop times (last ${count} runs) - Average: ${avg.toFixed(2)} ms, Minimum: ${min.toFixed(2)} ms, Maximum: ${max.toFixed(2)} ms`);

		this.unlockTimes = []; // Reset the unlock times array
	}
	//TIME TRACKING


	manageAutosave(){
		const now = Date.now();
		if (!this.lastAutosaveTime || now - this.lastAutosaveTime >= this.autosaveFrequency) {  // milliseconds
			this.stateManager.autosave();
			this.lastAutosaveTime = now;
		}
	}

	updatePlayTimeProperties(){
		// If lastTickTime doesn't exist yet, set it to the current time.
		// This should only happen on the first tick.
		if (!this.lastTickTime) {
			this.lastTickTime = Date.now();
		}
		
		let currentTime = Date.now();
		let deltaPlaytime = currentTime - this.lastTickTime;
		this.gameManager.gameContent.totalPlaytime = this.gameManager.gameContent.totalPlaytime.plus(deltaPlaytime);
		
		// Update lastTickTime to the current time for the next tick.
		this.lastTickTime = currentTime;
	}

	updateIncome() {
		const currentTime = performance.now();

		if (currentTime - this.lastIncomeUpdate >= this.incomeUpdateInterval) {
			// Calculate deltaTimeSeconds in seconds
			let deltaTimeSeconds = (currentTime - this.lastIncomeUpdate) / 1000;

			//increase deltatime by timeModifierUpgrade
			deltaTimeSeconds *= this.gameManager.gameContent.timeModifierUpgrade;

			this.gameManager.processGamePropertyUpdates(deltaTimeSeconds);
			this.lastIncomeUpdate = currentTime;
		}
	}

	checkUnlocks() {
		const currentTime = performance.now();
		// Check unlocks every 100 ms
		if (currentTime - this.lastUnlockCheck >= this.unlockCheckInterval) {
			this.gameManager.unlockManager.checkUnlocks();
			this.lastUnlockCheck = currentTime;
		}
	}

	updateUI() {
		// Throttle the UI updates to every 100ms
		const throttledUpdateUI = this.throttle(() => this.ui.updateUI(), this.uiUpdateInterval);

		throttledUpdateUI();
	}

	throttle(func, wait) {
		let context, args, prevArgs, argsChanged, result;
		let previous = 0;

		return function () {
			let now, remaining;
			if (wait) {
				now = Date.now();
				remaining = wait - (now - previous);
			}
			context = this;
			args = arguments;
			argsChanged = JSON.stringify(args) !== JSON.stringify(prevArgs);
			prevArgs = Object.assign({}, args);

			if (argsChanged || (wait && (remaining <= 0 || remaining > wait))) {
				if (wait) {
					previous = now;
				}
				result = func.apply(context, args);
				context = args = null;
			}

			return result;
		};
	}
}
