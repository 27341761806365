export default class HotkeyManager {
    constructor(eventManager, ui) {
		this.eventManager = eventManager;
        this.ui = ui;
        this.hotkeyButtons = [];
        this.letterHotkeyButtons = [];

        this.hotkeysEnabled = true;
        
        this.keyDownListener = this.handleKeyDown.bind(this);

        this.eventManager.addListener('updateHotkeyButtons', this.updateHotkeyButtons.bind(this));
        this.eventManager.addListener('hotkeyEnabled', this.enableHotkeys.bind(this));
        this.eventManager.addListener('hotkeyDisabled', this.disableHotkeys.bind(this));
    }
    
    initHotkeys() {
        //     setTimeout(() => {
        this.attachKeyDownListener();
        this.updateHotkeyButtons();
        //     }, 100);
    }

    enableHotkeys() {
        this.hotkeysEnabled = true;
        // this.attachKeyDownListener();
    }

    disableHotkeys() {
        this.hotkeysEnabled = false;
        // this.detachKeyDownListener();
    }

    attachKeyDownListener() {
        document.removeEventListener('keydown', this.keyDownListener);
        document.addEventListener('keydown', this.keyDownListener);
    }

    detachKeyDownListener() {
        document.removeEventListener('keydown', this.keyDownListener);
    }

    populateHotkeyListDisplay() {
        return `
            <b>Hotkeys:</b>
            <div><span style="color:yellow;">Tab/Shift+Tab </span><span>   Main Tab Navigation</span></div>
            <div><span style="color:yellow;">Q, E</span><span>   SubTab Navigation</span></div>
            <div><span style="color:yellow;">1-0</span><span>   First 10 Buttons of any tab (or world tabs in odyssey)</span></div>
            <div><span style="color:yellow;">W,A,S,D & Arrows</span><span>   Odyssey Miniap Navigation</span></div>
            <div><span style="color:yellow;">R</span><span>   Action Key: Start Zone, Claim Skill, Claim Achievements, Fight Fighter</span></div>
        `;
    }
    
    handleKeyDown(event) {
        if (this.hotkeysEnabled){
            if (event.key === "Enter" && event.target.tagName === "BUTTON") {
                event.preventDefault();
            } else if (event.key === 'Tab') {
                event.preventDefault();
                this.handleTabNavigation(event.shiftKey);
            } else if (event.key === 'Escape') {
                this.handleEscapeKey();
            } else if (event.key >= '0' && event.key <= '9') {
                let num = parseInt(event.key);
                num = num === 0 ? 9 : num - 1;
                if (num >= 0 && num < 10 && this.hotkeyButtons[num] && !this.hotkeyButtons[num].disabled && !this.hotkeyButtons[num].classList.contains('disabled')) {
                    this.hotkeyButtons[num].click();
                }
            } else if (event.key.toLowerCase() === 'q' || event.key.toLowerCase() === 'e') {
                if (this.letterHotkeyButtons && this.letterHotkeyButtons.length > 1) {
                    let currentSubTabIndex = this.letterHotkeyButtons.findIndex(btn => btn.classList.contains('active-tab'));
                    let nextSubTabIndex = currentSubTabIndex;
                    
                    if (event.key.toLowerCase() === 'q') {
                        do {
                            nextSubTabIndex = (nextSubTabIndex === 0) ? this.letterHotkeyButtons.length - 1 : nextSubTabIndex - 1;
                        } while (this.letterHotkeyButtons[nextSubTabIndex].classList.contains('inactive-tab'));
                    } else if (event.key.toLowerCase() === 'e') {
                        do {
                            nextSubTabIndex = (nextSubTabIndex + 1) % this.letterHotkeyButtons.length;
                        } while (this.letterHotkeyButtons[nextSubTabIndex].classList.contains('inactive-tab'));
                    }
                    
                    if (this.letterHotkeyButtons[nextSubTabIndex] && !this.letterHotkeyButtons[nextSubTabIndex].disabled) {
                        this.letterHotkeyButtons[nextSubTabIndex].click();
                    }
                }
            }
            
            else if (event.key.toLowerCase() === 'r') {
                if (this.ui.tabManager.currentTab === "exploration" && this.ui.tabManager.currentSubTab === "odyssey"){
                    this.handleZoneHotkeyProgression();
                }
                else if (this.ui.tabManager.currentTab === "achievements"){
                    this.ui.tabManager.achievementsTab.claimAllUnclaimed();
                }
                else if (this.ui.tabManager.currentSubTab === "tournament"){
                    let fightButton = document.querySelector('.fight-button');
                    fightButton.click();
                }
                else if (this.ui.tabManager.currentTab === "skills"){
                    this.ui.tabManager.skillsTab.claimNextAffordableSkills();
                }
            }
            else if (this.ui.tabManager.currentTab === "exploration" && this.ui.tabManager.currentSubTab === "odyssey") {
                if (this.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentViewType === "region"){
                    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                        event.preventDefault();  // This prevents the default arrow key actions (like scrolling)
                        this.handleMiniMapNavigation(event.key);
                    } else if (['w', 'a', 's', 'd'].includes(event.key.toLowerCase())) {
                        // This handles the WASD keys
                        this.handleMiniMapNavigation(event.key.toLowerCase());
                    } 
                    // else if (['r'].includes(event.key.toLowerCase())) {
                    //     // This handles zone manual hotkey progression
                    //     this.handleZoneHotkeyProgression();
                    // }
                }
                else if (this.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentViewType === "world") {
                    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                        event.preventDefault();  // This prevents the default arrow key actions (like scrolling)
                        this.hotkeyEnterRegionFromWorld(event.key);
                    } else if (['w', 'a', 's', 'd'].includes(event.key.toLowerCase())) {
                        // This handles the WASD keys
                        this.hotkeyEnterRegionFromWorld(event.key.toLowerCase());
                    }
                }
            }
        }
    }

	handleEscapeKey() {
		if (this.ui.isPopupActive) {
			const backdrop = document.getElementById('modal-backdrop');
			if (backdrop) {
				document.body.removeChild(backdrop);
				this.ui.isPopupActive = false;
				this.ui.showNextPopup();
			}
		}
    
		const offlineModal = document.getElementById('offline-modal');
		if (offlineModal && offlineModal.style.display === 'block') {
			offlineModal.style.display = 'none';
		}
	}

    hotkeyEnterRegionFromWorld(key){
        const currentWorld = this.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedWorld;
        const firstRegion = currentWorld.regions[0];
        this.ui.tabManager.explorationTab.odysseySubTab.mapViewManager.updateMapView(firstRegion);
    }

    handleZoneHotkeyProgression(){
        if (this.ui.tabManager.currentTab === "exploration" && this.ui.tabManager.currentSubTab === "odyssey" && this.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentViewType === "region") {
            let success = false;
            const currentRegion = this.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedRegion;

            const zones = currentRegion.zones;
            const activeZones = zones.filter(zone => zone.active);
            for (const zone of activeZones) {
                if (zone.isConquesting || zone.isDefeated || !zone.unlocked){
                    continue;
                }
                let currentResource = this.ui.gameManager.gameContent.queryPropertyValues(zone.costType);
                if (currentResource.gt(zone.costNextMultPurchase)) {
                    zone.startConquest();
                    success = true;
                    break;
                }
            }
            if (!success){
                // console.error("No zones available to conquest");
            }
        }
    }

    handleMiniMapNavigation(key) {
        const currentRegion = this.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedRegion;
        const currentWorld = currentRegion.world;
        const regions = currentWorld.regions;
        const currentIndex = regions.findIndex(region => region === currentRegion);
        const gridDimensions = this.ui.tabManager.explorationTab.odysseySubTab.mapViewManager.miniMap.calculateGridDimensions(currentWorld.regions);

        let targetRegion = null;
        if ((key === 'w' || key === 'ArrowUp') && currentIndex >= gridDimensions.width) {
            targetRegion = regions[currentIndex - gridDimensions.width];
        } else if ((key === 's' || key === 'ArrowDown') && currentIndex < regions.length - gridDimensions.width) {
            targetRegion = regions[currentIndex + gridDimensions.width];
        } else if ((key === 'a' || key === 'ArrowLeft') && currentIndex % gridDimensions.width !== 0) {
            targetRegion = regions[currentIndex - 1];
        } else if ((key === 'd' || key === 'ArrowRight') && (currentIndex + 1) % gridDimensions.width !== 0) {
            targetRegion = regions[currentIndex + 1];
        }
        
        if (targetRegion && targetRegion.active) {
            this.eventManager.dispatchEvent('updateMapView', targetRegion);
        }
    }

    handleTabNavigation(isShiftKey) {
        let activeTabs = this.ui.tabManager.getActiveTabs();
        if (activeTabs.length > 1) {
          let currentTabName = this.ui.tabManager.currentTab;
          let currentIndex = activeTabs.findIndex(tab => tab.name === currentTabName);
          let nextIndex = isShiftKey ? (currentIndex === 0 ? activeTabs.length - 1 : currentIndex - 1) : (currentIndex + 1) % activeTabs.length;
          let newTab = activeTabs[nextIndex];
          
          // Hide the current tab and subTab's UI elements
          this.ui.hideElement(currentTabName);
          let currentTabObject = this.ui.tabManager.tabs.find(tab => tab.name === currentTabName);
          if(currentTabObject) {
            this.ui.multiplierManager.toggleMultiplierContainer(currentTabObject, "none");
            if (this.ui.tabManager.currentSubTab){
              let currentSubTabObject = this.ui.tabManager.tabs.find(tab => tab.name === this.ui.tabManager.currentSubTab);
              this.ui.multiplierManager.toggleMultiplierContainer(currentSubTabObject, "none");
            }
          }
          
          if (newTab.subTabs.length > 0) {
            this.ui.tabManager.currentSubTab = newTab.currentSubTab || newTab.subTabs[0].name;
          } else {
            this.ui.tabManager.currentSubTab = null;
          }
          this.ui.tabManager.changeTab(activeTabs[nextIndex].name);
        }
      }

    updateHotkeyButtons() {
        this.hotkeyButtons = [];
        let numberButtons;
        let tabContentID;
        
        if (this.ui.tabManager.currentTab === "settings") {
            return;
        }
        if (this.ui.tabManager.currentSubTab) {
            tabContentID = this.ui.tabManager.currentSubTab;
            
            if (this.ui.tabManager.currentTab.includes('training')) {
                tabContentID = "realm-content-" + tabContentID;
            } else if (this.ui.tabManager.currentTab.includes('exploration')) {
                this.ui.tabManager.currentSubTab = tabContentID;
                tabContentID = tabContentID.charAt(0).toUpperCase() + tabContentID.slice(1);
                tabContentID = "tab-content-" + tabContentID + "SubTab";
            }
            numberButtons = this.ui.returnFirstTenButtonElements(tabContentID);
        } 
        else {
            numberButtons = this.ui.returnFirstTenButtonElements(this.ui.tabManager.currentTab);
        }
        
        for (let i = 0; i < 10 && i < numberButtons.length; i++) {
            this.hotkeyButtons.push(numberButtons[i]);
        }
        
        this.letterHotkeyButtons = [];
        if (this.ui.tabManager.getActiveTabs().some(tab => tab.name === this.ui.tabManager.currentTab && tab.subTabs.length > 0)) {
            let subTabButtons = this.ui.tabManager.returnSubTabButtons();
            for (let i = 0; i < subTabButtons.length; i++) {
                this.letterHotkeyButtons.push(subTabButtons[i]);
            }
        }
    }
}