import { DEC } from '../../Utilities/decimal.js';
import GameFeature from '../../Core/GameFeature.js';

export default class Training extends GameFeature {
	constructor(eventManager, id, realmID, evolutionTier, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active) {
		super(eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active);
		this.featureType = "training";
		this.realmID = realmID;
		this.realm = null;

		this.rankTiers = [];
		this.evolutionTier = evolutionTier;
		this.evolutions = [];
	}

	
	updateFeatureValues(isNewLvl, gameManager){
		
		this.costNextSingle = this.calcCostNextSingle();
		this.prodNextSingle = this.calcProdNextSingle();

		//dont update values if inactive || (level 0 & not being levelled up)
		if (!this.active || (this.level.eq(0) && !isNewLvl)) {
			return;
		}
		// handle if an active feature's multiplier is being updated but not its level
			// aka being upgraded or a mod is activated
		// also handles evolutions
		else if (this.active && !isNewLvl) {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
		//handle features that are being levelled manually or generator autopurchase
		else {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
	}

	evolve(evoTier = null) {
		// Find the evolution object with the next evolution tier
		let nextEvolution;
		if (!evoTier){
			nextEvolution = this.evolutions.find(evo => evo.evolutionTier === this.evolutionTier + 1);
		}
		else if (evoTier > 1){
			nextEvolution = this.evolutions.find(evo => evo.evolutionTier === evoTier);
		}
		else {
			return;
		}
		
		if (nextEvolution) {
			if (evoTier){
				this.name = nextEvolution.name;
				this.description = nextEvolution.description;
				return;
			}

			this.evolutionTier = nextEvolution.evolutionTier;
			this.name = nextEvolution.name;
			this.description = nextEvolution.description;
			this.level = DEC(this.baseLevel);
			this.manualLevel = DEC(this.baseLevel);
			this.autoLevel = DEC(0);
			this.costBase = DEC(nextEvolution.costBase);
			this.costGrowthRate = DEC(nextEvolution.costGrowthRate);
			this.prodBase = DEC(nextEvolution.prodBase);
			this.prodGrowthRate = DEC(nextEvolution.prodGrowthRate);
			
			this.nextRankLevel = DEC(0);
			
			this.resetRankUnlocks();
			
			// this.resetRealmFeatureUnlocks();

			this.setNextRankLevel();

			this.costNextSingle = this.calcCostNextSingle();
			
			this.eventManager.dispatchEvent('updateFeatureValues', { target: this, isNewLvl: false });

			this.eventManager.dispatchEvent('updateNewMultiplierValues', { feature: this });

			this.prodCurrentGlobal = DEC(0);
		}
		else {
			console.error("Next evolution tier not found!");
		}
	}

	resetRankUnlocks(){
		this.eventManager.dispatchEvent('reEngage-unlock', { detail: { id: this.id, type: "rank" } });
	}
	
	resetRealmFeatureUnlocks(){
		this.eventManager.dispatchEvent('reEngage-unlock', { detail: { id: this.id, type: "realm-feature-unlock" } });
	}
}