import Decimal from '../../Utilities/break_eternity.min.js'; // large number library
export default class TrainingTab {
    constructor(eventManager, ui, gameContent, tabManager){
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.tabManager = tabManager;

        this.realms = this.gameContent.realms;
		
		this.eventManager.addListener('rankAchieved', this.onRankAchieved.bind(this));
    
	}

    populateDisplay() {
        let targetParent = document.getElementById('training');
		// let realmButtonsContainer = document.getElementById('training-realm-buttons');

		this.realms.forEach((realm, index) => {
			const realmButtonId = `${realm.name}SubTab`;
			let realmButton = document.getElementById(realmButtonId);
			let realmContent = document.getElementById(`realm-content-${realm.name}`);
			let col1, col2;


            const realmButtonClickHandler = () => this.changeRealmSubTab(realmButtonId, realm.name);
			this.eventManager.addDomListener(realmButton, 'click', realmButtonClickHandler);

            // If it's the first realm, add 'active-tab' class
            if (index === 0) {
                realmButton.classList.add('active-tab');
            }

            realmContent = this.ui.createElement('div',`realm-content-${realm.name}`,`realm-content`);
            realmContent.style.display = (index === 0 ? 'flex' : 'none'); // Only display the first realm by default

            let header = this.ui.createElement('div',`${realm.name}-realm-header`,['realm-header',`${realm.name}-color`]);
            let totalIncome = this.ui.createElement('div',`${realm.name}-realm-income`,'realm-income');
            let powerLevelContrib = this.ui.createElement('div',`${realm.name}-realm-power-contrib`, 'realm-power-contrib');

            col1 = this.ui.createElement('div',`${realm.type}-col1-${realm.name}`,'content-tab-col');
            col2 = this.ui.createElement('div',`${realm.type}-col2-${realm.name}`,'content-tab-col');

			let col1Title = this.ui.createElement('div',null,['realm-col-title',`${realm.name}-color`],"Training");
			col1.appendChild(col1Title);

			let col2Title = this.ui.createElement('div',null,['realm-col-title',`${realm.name}-color`],"Upgrades");
			col2.appendChild(col2Title);
			
            let realmNameContainer = this.ui.createElement('div',`${realm.name}-realm-name`, ['realm-name',`${realm.name}-color`]);
            // realmContent.appendChild(realmNameContainer);

			header.appendChild(realmNameContainer);
            header.appendChild(totalIncome);
            header.appendChild(powerLevelContrib);
            realmContent.appendChild(header);
            realmContent.appendChild(col1);
            realmContent.appendChild(col2);
			
            targetParent.appendChild(realmContent);


			this.populateRealm(col1,col2,realm);
		});
        

		// set defautl tooltips off for training tab

		document.querySelector('#training').classList.add('tooltips-off');
    }

    updateDisplay(){
		this.realms.forEach((realm) => {
			let col1 = document.getElementById(`${realm.type}-col1-${realm.name}`);
			let col2 = document.getElementById(`${realm.type}-col2-${realm.name}`);
			if (this.tabManager.currentSubTab === realm.type){
				this.populateRealm(col1,col2,realm);
			}
        });
    }

    populateRealm(targetCol1,targetCol2,realm){
		let realmFeatures;
		if (realm.type === "force" || realm.type === "energy"){ 
			realmFeatures = realm.trainings;
		}
		else if (realm.type === "wisdom" || realm.type === "divine"){
			realmFeatures = realm.generatorChains[0].generators;
		}

		const header = document.getElementById(`${realm.name}-realm-header`);
		let realmContent = document.getElementById(`${realm.name}-col1-${realm.name}`);
		let bulkToggleBtn = document.getElementById(`${realm.name}-bulk-toggle-btn`);
		if (!bulkToggleBtn) {
			let bulkToggleBtn = this.ui.createElement('button', `${realm.name}-bulk-toggle-btn`, 'bulk-toggle-btn', 'Bulk Toggle Auto');
			bulkToggleBtn.style.color = `var(--${realm.name}-color)`;
			realmContent.appendChild(bulkToggleBtn);
			const bulkToggleClickListener = () => this.toggleAllAutobuy(realm);
			this.eventManager.addDomListener(bulkToggleBtn, 'click', bulkToggleClickListener);
		}



		realmFeatures.forEach(feature => {
			let featureId = `feature-${feature.id}`;
			let featureCell = targetCol1.querySelector(`#${featureId}`);
			let currentValueElement;
			if (!featureCell) {
				featureCell = this.ui.populateFeatureCell(feature,targetCol1,featureId);
				let featureCellNameContainer = featureCell.getElementsByClassName('feature-name-container')[0];
		
				
				const progressBar = this.ui.createElement('div', `progress-bar-${feature.id}`, 'progress-bar');
				const progressFill = this.ui.createElement('div', `progress-fill-${feature.id}`, 'progress-fill');
				progressBar.appendChild(progressFill);
				// Create a text element for flashing text
				const flashText = this.ui.createElement('div', `flash-text-${feature.id}`, 'flash-text');
				flashText.textContent = "Rank Achieved!";  // Filler text
				flashText.style.display = 'none';  // Hide it by default
				progressBar.appendChild(flashText);

				featureCellNameContainer.appendChild(progressBar);

				const rankTierLevel = this.ui.createElement('div', `rank-level-${feature.id}`, 'rank-level');
				featureCellNameContainer.appendChild(rankTierLevel);

				this.updateRankProgress(feature, featureCell);
				
				this.ui.populateMouseOverZIndexEvents(featureCell);
				this.ui.populateAutobuyCheckbox(feature, featureCell, this.ui.gameManager.automationManager[realm.type + 'Heap']);
				currentValueElement = this.ui.populateCurrentValueElement(feature,featureCell);
			} 
			else {
				currentValueElement = featureCell.querySelector(`.${feature.featureType}-current-value`);
			}
	
			
			this.ui.updateAutobuyCheckbox(feature, featureCell);

			let newCurrentValueText;
			if (feature.prodCurrentGlobal.gt(0)){
				newCurrentValueText = `${this.ui.formatNumber(feature.prodCurrentGlobal)} ${feature.prodType.replace("Income", "")}/sec\r\n`;
				this.ui.updateElementTextContent(currentValueElement,newCurrentValueText);
			}
			else {
				this.ui.updateElementTextContent(currentValueElement,'');
			}
		
			this.ui.updateFeatureCell(feature, featureCell);
			this.updateRankProgress(feature, featureCell);
		});

		realm.realmUpgrades.forEach(upgrade => {
			let upgradeId = `upgrade-${upgrade.id}`;
			let upgradeCell = targetCol2.querySelector(`#${upgradeId}`);
			if (!upgradeCell) {
				upgradeCell = this.ui.populateFeatureCell(upgrade,targetCol2,upgradeId);
				

				this.ui.populateAutobuyCheckbox(upgrade, upgradeCell, this.ui.gameManager.automationManager[realm.type + 'Heap']);
				
				this.ui.populateMouseOverZIndexEvents(upgradeCell);
				targetCol2.appendChild(upgradeCell);
			}

			this.ui.updateAutobuyCheckbox(upgrade, upgradeCell);
			this.ui.updateFeatureCell(upgrade, upgradeCell);
		});

		
		// update realm stats

		let totalIncome = document.getElementById(`${realm.name}-realm-income`);
		let newIncomeText = '';
		if (this.gameContent[realm.name + 'Income'].gt(0)){
			newIncomeText = `Total Income: ${this.ui.formatNumber(this.gameContent[realm.name + 'Income'])} /sec`;
		}
		this.ui.updateElementTextContent(totalIncome,newIncomeText);
		
		let powerLevelContrib = document.getElementById(`${realm.name}-realm-power-contrib`);
		let newPowerContribText = '';
		if (this.gameContent[realm.name + 'PowerLevelMultiplier'].gt(1)){

			newPowerContribText = `Contribution to Power Level: x${this.ui.formatNumber(this.gameContent[realm.name + 'PowerLevelMultiplier'])}`;
		}
		this.ui.updateElementTextContent(powerLevelContrib,newPowerContribText);
		
		// update realm name
		let realmNameContainer = document.getElementById(`${realm.name}-realm-name`);
		let newRealmNameText = '';
		if (realm.evolutionTier.gt(1)) {
			newRealmNameText = `${(realm.evolutionTierNames[Number(realm.evolutionTier) - 1])} - `;
			newRealmNameText += `Tier ${this.ui.numberFormatter.toRoman(realm.evolutionTier)}`;
		} else if (realm.evolutionTier.eq(1)) {
			newRealmNameText = `${(realm.evolutionTierNames[Number(realm.evolutionTier) - 1])}`;
		}

		this.ui.updateElementTextContent(realmNameContainer,newRealmNameText);

	}

	toggleAllAutobuy(realm) {
		let minHeap;
		switch (realm.type) {
			case 'force':
				minHeap = this.ui.gameManager.automationManager.forceHeap;
				break;
			case 'wisdom':
				minHeap = this.ui.gameManager.automationManager.wisdomHeap;
				break;
			case 'energy':
				minHeap = this.ui.gameManager.automationManager.energyHeap;
				break;
			case 'divine':
				minHeap = this.ui.gameManager.automationManager.divineHeap;
				break;
			default:
				console.error(`Unknown realm type: ${realm.type}`);
				return;
		}
	
		if (!minHeap || !minHeap.heap) {
			console.error(`MinHeap or minHeap.heap is undefined for realm type: ${realm.type}`);
			return;
		}
	
		// Determine the new toggle status
		const toggleStatus = !minHeap.heap.some(feature => feature.autoToggle);
	
		// Clear the heap if toggling off
		if (!toggleStatus) {
			minHeap.heap.length = 0; // Clear the heap
		}
	
		const realmFeatures = realm.type === "force" || realm.type === "energy"
			? [...realm.trainings, ...realm.realmUpgrades]
			: [...realm.generatorChains[0].generators, ...realm.realmUpgrades];
	
		// Update each feature's autoToggle and heap
		realmFeatures.forEach(feature => {
			if (feature.active && feature.autoUnlocked) {
				feature.autoToggle = toggleStatus;
		
				if (toggleStatus) {
					if (!minHeap.heap.includes(feature)) {
						minHeap.add(feature); // Add feature to heap if not already present
					}
				} else {
					if (minHeap.heap.includes(feature)) {
						minHeap.remove(feature); // Remove feature from heap if present
					}
				}
		
				const checkbox = document.getElementById(`checkbox-${feature.id}`);
				if (checkbox) {
					checkbox.checked = toggleStatus;
				}
			}
		});
	
		this.updateDisplay();
	}
	
	
	onRankAchieved(event) {
		// console.log("Rank achieved:", event);
		const featureId = event.id;
		const progressBar = document.querySelector(`#progress-bar-${featureId}`);
		const flashText = document.querySelector(`#flash-text-${featureId}`);  // Get the text element
		flashText.textContent = `x ${(event.ranksAchieved) * 2}`;
		
		if (progressBar && flashText) {
			progressBar.classList.add('flash-effect');
			flashText.classList.add('flash-effect-text');  // Apply the text flashing effect
	
			progressBar.addEventListener('animationend', () => {
				progressBar.classList.remove('flash-effect');
			}, { once: true });
	
			flashText.addEventListener('animationend', () => {
				flashText.classList.remove('flash-effect-text');  // Remove class after animation to reset state
				flashText.style.display = 'none';  // Hide the text after animation
			}, { once: true });
		}
	}
	
	updateRankProgress(feature, featureCell) {
		const progressFill = featureCell.querySelector(`#progress-fill-${feature.id}`);
		const rankTierLevel = featureCell.querySelector(`#rank-level-${feature.id}`);
	
		if (feature.rankTiers.length > 0) {
			const currentRank = feature.ranksAchieved;
			let previousRankLevel = new Decimal(0); // Starting point for the first level
			let nextRankLevel;
	
			if (currentRank > 0) {
				previousRankLevel = new Decimal(feature.rankTiers[currentRank - 1]);
			}
			nextRankLevel = new Decimal(feature.rankTiers[currentRank]);
	
			// Calculate progress
			let progress;
			if (feature.manualLevel.gte(nextRankLevel)) {
				progress = 1; // Full progress if manualLevel has reached or exceeded nextRankLevel
			} else if (nextRankLevel.gt(previousRankLevel)) {
				progress = feature.manualLevel.minus(previousRankLevel).div(nextRankLevel.minus(previousRankLevel)).toNumber();
			} else {
				progress = 0; // No progress if there's no difference between levels
			}
	
			progressFill.style.width = `${Math.max(0, Math.min(progress, 1)) * 100}%`;
	
			// Update rank display
			rankTierLevel.textContent = `Rank: ${feature.manualLevel.gte(nextRankLevel) ? currentRank + 1 : currentRank}`;
		} else {
			progressFill.style.width = '0%';
			rankTierLevel.textContent = '';
		}
	}
	
	

    changeRealmSubTab(realmButtonId, realmType) {
		this.tabManager.toggleActiveSubTabClass(`training-realm-buttons`, realmButtonId);

		this.tabManager.toggleSubTabContentDisplay('training', 'realm-content', 'none');

		// Hide the current subTab's multiplier container if exists
		if (this.tabManager.currentSubTab){
			let currentSubTabObject = this.tabManager.tabs.find(tab => tab.name === this.tabManager.currentSubTab);
			this.ui.multiplierManager.toggleMultiplierContainer(currentSubTabObject, "none");
		}

		let trainingTab = this.tabManager.tabs.find(tab => tab.name === this.tabManager.currentTab);
		trainingTab.currentSubTab = realmType;

		this.tabManager.currentSubTab = realmType;

		// Show the new subTab's multiplier container if exists
		if (this.tabManager.currentSubTab){
			let currentSubTabObject = this.tabManager.tabs.find(tab => tab.name === this.tabManager.currentSubTab);
			this.ui.multiplierManager.toggleMultiplierContainer(currentSubTabObject, "block");
			
			currentSubTabObject.hasBeenOpened = true;

			//also assign new subtab to currentTab object
			trainingTab.currentSubTabObject = currentSubTabObject;
		}

		this.eventManager.dispatchEvent('updateHotkeyButtons');
		this.ui.gameManager.onTabChange(trainingTab);

		this.ui.showElement(`realm-content-${realmType}`);
	}

}