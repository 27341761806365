import { DEC } from '../../Utilities/decimal.js';

import GameFeature from '../../Core/GameFeature.js';

export default class RadianceUpgrade extends GameFeature {
	constructor(eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, specialTargetID) {
		super(eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active);
		this.specialTargetID = specialTargetID;
		this.target = null;
		this.featureType = "radianceUpgrade";
	}

	updateFeatureValues(isNewLvl, gameManager){
		let gameContent = gameManager.gameContent;
		this.costNextSingle = this.calcCostNextSingle();
		this.prodNextSingle = this.calcProdNextSingle();

		//dont update values if inactive || (level 0 & not being levelled up)
		if (!this.active || (this.level.eq(0) && !isNewLvl)) {
			return;
		}
		// handle if an active feature's multiplier is being updated but not its level
			// aka being upgraded or a mod is activated
		// also handles evolutions
		else if (this.active && !isNewLvl) {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
		//handle features that are being levelled manually or generator autopurchase
		else {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}

		switch (this.prodType) {
			case 'timeMult':
				gameContent.timeModifierUpgrade = this.prodBase.plus(this.level.times(this.prodGrowthRate)).toNumber();
				break;
		}
	}
}