import Decimal from '../Utilities/break_eternity.min.js'; // large number library
import { DEC } from '../Utilities/decimal.js';

import MinHeap from '../Utilities/MinHeap.js';

export default class AutomationManager {
    constructor(eventManager, gameManager) {
      this.eventManager = eventManager;
      this.gameManager = gameManager;
      this.gameContent = gameManager.gameContent;

      this.forceHeap = new MinHeap();
      this.wisdomHeap = new MinHeap();
      this.energyHeap = new MinHeap();
      this.divineHeap = new MinHeap();
      
      this.artifactAutobuys = [];
    }
  
    processAutobuying() {
        this.autoTournamentProgression();
        this.autoConquestProgression();
        this.autobuyArtifacts();
        this.autoTraining(this.gameContent.force, "force");
        this.autoTraining(this.gameContent.wisdom, "wisdom");
        this.autoTraining(this.gameContent.energy, "energy");
        this.autoTraining(this.gameContent.divine, "divine");
    }

    
    autoTraining(resource, resourceType) {
        // Validate resourceType
        const validTypes = ['force', 'wisdom', 'energy', 'divine'];
        if (!validTypes.includes(resourceType)) {
            throw new Error('Invalid resourceType ' + resourceType);
        }

        let minHeap;
        switch(resourceType){
            case 'force':
                minHeap = this.forceHeap;
                break;
            case 'wisdom':
                minHeap = this.wisdomHeap;
                break;
            case 'energy':
                minHeap = this.energyHeap;
                break;
            case 'divine':
                minHeap = this.divineHeap;
                break;
        }

        if (!minHeap || minHeap.heap.length === 0) {
            return; 
        }

        //accomodate constantly fluctuating costs
        minHeap.refresh();

        let tempResource = DEC(resource);
        let canPurchase = true;

        
        while (canPurchase) {
            let feature = minHeap.peek();
            let tempFeatureCostNext = feature.costNextSingle;
            let tempFeatureProdNext = feature.prodNextSingle;
            
            //continue if can afford one
            if (tempFeatureCostNext.lte(tempResource)){
                let ratio = tempResource.div(tempFeatureCostNext);
                let purchaseCount = Decimal.max(1, ratio.plus(1).log(2)).floor();
                if (ratio.gte(10)){
                    while (purchaseCount.gt(1) && feature.calculateCostN(purchaseCount).gt(tempResource)) {
                        purchaseCount = purchaseCount.minus(1);
                    }
                }
                
                //reduce purchase level if will push feature above max
                if (purchaseCount.gt(feature.maxLevel.minus(feature.level))){
                    purchaseCount = feature.maxLevel.minus(feature.level);
                    tempFeatureCostNext = feature.calculateCostN(purchaseCount);
                    tempFeatureProdNext= feature.calculateProdN(purchaseCount);

                    tempResource = tempResource.minus(tempFeatureCostNext);
                    this.gameManager.handlePurchase(feature.id, purchaseCount, tempFeatureCostNext);

                    //removed maxed feature from heap
                    minHeap.poll();
                    break;
                }

                // set prod/cost values before purchasing
                tempFeatureCostNext= feature.calculateCostN(purchaseCount);
                tempFeatureProdNext = feature.calculateProdN(purchaseCount);

                // floating point calculations sometimes trigger this, at which point it should subtract 1 from purchase count
                if (tempFeatureCostNext.gt(tempResource)){
                    // console.error("error, autobuy will subtract more resource than available", feature.name,tempFeatureCostNext,tempResource);
                    purchaseCount = purchaseCount.minus(1);
                    if (purchaseCount.lt(1)){
                        break;
                    }
                }

                // feature.nextLevelIncrement = purchaseCount;
                tempResource = tempResource.minus(tempFeatureCostNext);
                this.gameManager.handlePurchase(feature.id, purchaseCount);

                minHeap.poll();
                if (feature.level.neq(feature.maxLevel)){
                    minHeap.add(feature);
                }
            }
            else {
                canPurchase = false;
            }
        }
    }

    autoConquestProgression(){
        if (!this.gameManager.gameContent.worldManager.autoUnlocked){
            return;
        }

        for (const zone of this.gameManager.gameContent.zones){
            if (zone.defeatCount.lt(1)){
                continue;
            }

            if (zone.isConquesting || !zone.active || zone.isDefeated || !zone.unlocked){
                continue;
            }
            if (zone.zoneType === "legendaryBoss"){
                if (!zone.active){
                    continue;
                }
                if (zone.parent){
                    if (!zone.parent.active){
                        continue;
                    }
                }
                if (zone.children){
                    if (zone.children.length > 0){
                        let hasActiveChild = false;
                        for (const childZone of zone.children){
                            if (childZone.active){
                                hasActiveChild = true;
                                break;
                            }
                        }
                        if (!hasActiveChild){
                            continue;
                        }
                    }
                }
                zone.startConquest();
            }
            else {
                let currentResource = this.gameManager.gameContent.queryPropertyValues(zone.costType);

                if (currentResource.gte(zone.costNextMultPurchase)) {
                    zone.startConquest();
                }
            }
        }
    }

    autoTournamentProgression() {
        if (!this.gameManager.gameContent.tournament.autoUnlocked) {
            return;
        }
    
        for (const fighter of this.gameManager.gameContent.tournament.fighters) {
            if (fighter.defeatCount.lt(1)){
                continue;
            }
            if (fighter.isDefeated || fighter.isFighting || !fighter.active) {
                continue;
            } else {
                let currentResource = this.gameManager.gameContent.queryPropertyValues(fighter.costType);
                if (currentResource.gte(fighter.costNextMultPurchase)) {
                    this.gameManager.eventManager.dispatchEvent('startFight', { id: fighter.id, triggeredByEvent: true });
                }
            }
        }
    }

    
    autobuyArtifacts() {
        for (const artifact of this.artifactAutobuys){
            if (!artifact.autoUnlocked){
                break;
            }
            
            let tempFeatureCostNext = artifact.costNextSingle;

            const allResources = this.gameManager.gameContent.queryPropertyValues("all");
            let tempResource = DEC(allResources.find(resource => resource.type === artifact.costType).value);
            let purchased = true;

            while (purchased) {
                if (tempFeatureCostNext.lte(tempResource) && artifact.level.lt(artifact.maxLevel)) {
                  let ratio = tempResource.dividedBy(tempFeatureCostNext);
                  let purchaseCount = Decimal.max(1, ratio.plus(1).log(2)).floor();
          
                  if (ratio.gte(10)) {
                    while (purchaseCount.greaterThan(1) && artifact.calculateCostN(purchaseCount).gt(tempResource)) {
                      purchaseCount = purchaseCount.minus(1);
                    }
                  }
          
                  // Check if the purchase is affordable
                  let totalCost = artifact.calculateCostN(purchaseCount);
                  if (totalCost.gt(tempResource)) {
                    // If the purchase is not affordable, reduce the purchaseCount until it becomes affordable
                    while (purchaseCount.greaterThan(1) && artifact.calculateCostN(purchaseCount).gt(tempResource)) {
                      purchaseCount = purchaseCount.minus(1);
                    }
                    totalCost = artifact.calculateCostN(purchaseCount);
                  }
          
                  // If the purchase count would increase level beyond maxLevel, adjust purchaseCount
                  if (artifact.level.plus(purchaseCount).gt(artifact.maxLevel)) {
                    purchaseCount = artifact.maxLevel.minus(artifact.level);
                    totalCost = artifact.calculateCostN(purchaseCount);
                  }
          
                  // Proceed with the purchase only if it is affordable
                  if (totalCost.lte(tempResource)) {
                    tempFeatureCostNext = totalCost;
                    tempResource = tempResource.minus(tempFeatureCostNext);
                    this.gameManager.handlePurchase(artifact.id, purchaseCount, tempFeatureCostNext);

                  } else {
                    purchased = false;
                  }
                } else {
                  purchased = false;
                }
          
                // Break the loop if maxLevel has been reached
                if (artifact.level.gte(artifact.maxLevel)) {
                  break;
                }
              }
            }
          }

}