import { DEC } from '../Utilities/decimal.js';

export default class Admin{
	constructor(eventManager, gameManager, gameUI){
		this.eventManager = eventManager;
		this.gameManager = gameManager;
		this.gameContent = gameManager.gameContent;
		this.ui = gameUI;
	}

	progressState(option){
		let forceTrainings = this.gameManager.gameContent.trainings.filter(training => training.realmID === 10);
		let wisdomGenerators = this.gameManager.gameContent.generators.filter(generator => generator.parentGenChain.realmID === 20);
		let energyTrainings = this.gameManager.gameContent.trainings.filter(training => training.realmID === 30);
		let divineGenerators = this.gameManager.gameContent.generators.filter(generator => generator.parentGenChain.realmID === 40);

		let forceUpgrades = this.gameManager.gameContent.realmUpgrades.filter(upgrades => upgrades.realmID === 10);
		let wisdomUpgrades = this.gameManager.gameContent.realmUpgrades.filter(upgrades => upgrades.realmID === 20);
		let energyUpgrades = this.gameManager.gameContent.realmUpgrades.filter(upgrades => upgrades.realmID === 30);
		let divineUpgrades = this.gameManager.gameContent.realmUpgrades.filter(upgrades => upgrades.realmID === 40);

		let forgeUpgrades = this.gameManager.gameContent.forgeUpgrades;
		let radianceUpgrades = this.gameManager.gameContent.radianceUpgrades;
		let essenceUpgrades = this.gameManager.gameContent.essenceUpgrades;

		let tournament = this.gameManager.gameContent.tournament;
		let fighters = this.gameManager.gameContent.fighters;
		let fighterTiers = this.gameManager.gameContent.fighterTiers;
		
		let worldManager = this.gameManager.gameContent.worldManager;
		let zones = this.gameManager.gameContent.zones;

		let mods = this.gameManager.gameContent.mods;

		let achievements = this.gameManager.gameContent.achievements;

		let unlocks = this.gameManager.gameContent.unlocks;

		let skills = this.gameManager.gameContent.skills;


		let filtered;
		switch(option){
			case 0:
				let amounToMultiply = 1e10;
				this.gameContent.force = this.gameContent.force.times(amounToMultiply);
				this.gameContent.wisdom =	this.gameContent.wisdom.times(amounToMultiply);
				this.gameContent.energy =	this.gameContent.energy.times(amounToMultiply);
				this.gameContent.divine =	this.gameContent.divine.times(amounToMultiply);
				this.gameContent.crystal = this.gameContent.crystal.times(amounToMultiply);
				this.gameContent.radiance = this.gameContent.radiance.times(amounToMultiply);
				this.gameContent.essence = this.gameContent.essence.times(amounToMultiply);
				this.gameContent.powerLevel = this.gameContent.powerLevel.times(amounToMultiply);
				break;
			case 1:
				this.addResource('force', 1e20);
				for (const training of forceTrainings){
					this.gameManager.handlePurchase(training.id,40);
				}
				for (const upgrade of forceUpgrades){
					this.gameManager.handlePurchase(upgrade.id,40);
				}
				break;
			case 2:
				this.addResource('force', 1e20);
				this.addResource('wisdom', 1e20);
				
				for (const training of forceTrainings){
					this.gameManager.handlePurchase(training.id,40);
				}
				for (const generator of wisdomGenerators){
					this.gameManager.handlePurchase(generator.id,40);
				}
				for (const upgrade of forceUpgrades.concat(wisdomUpgrades)){
					this.gameManager.handlePurchase(upgrade.id,10);
				}
				break;
			case 3:
				filtered = zones.filter(zone => zone.id <= 9009);
				for (const zone of filtered){
					zone.handleConquestComplete();
				}
				break;
			case 4:
				let tempFiltered = [];
				for (const zone of zones){
					if (zone.region){
						if (zone.region.world.id){
							tempFiltered.push(zone);
						}
					}
				}
				filtered = tempFiltered.filter(zone => zone.region.world.id && zone.region.world.id === 1000001 && zone.zoneType !== 'legendaryBoss');
				for (const zone of filtered){
					zone.handleConquestComplete();
				}
				break;
			case 5:
				filtered = fighters.filter(fighter => fighter.tier === 1);
				for (const fighter of filtered){
					tournament.processFighterDefeat(fighter.id);
				}
				break;
			case 6:
				let targetIDs1 = [10001, 10002, 10003, 10004, 10101, 10102, 10103, 10104];
				filtered = forgeUpgrades.filter(upgrade => targetIDs1.includes(upgrade.id));
				for (const upgrade of filtered){
					if (upgrade.level.eq(0)){
						this.gameManager.handlePurchase(upgrade.id);
					}
				}
				break;
			case 7:
				let targetIDs2 = [10418, 10401, 10402, 10403, 10404, 10406, 10407];
				filtered = forgeUpgrades.filter(upgrade => targetIDs2.includes(upgrade.id));
				for (const upgrade of filtered){
					if (upgrade.level.eq(0)){
						this.gameManager.handlePurchase(upgrade.id);
					}
				}
				break;
			case 8:
				let tempFiltered2 = [];
				for (const zone of zones){
					tempFiltered2.push(zone);
				}
				filtered = tempFiltered2.filter(zone => zone.region.world.id && zone.region.world.id === worldManager.currentWorld.id && !zone.isDefeated);
				for (const zone of filtered){
					zone.stopConquest();
					zone.handleConquestComplete();
					zone.progress = 0;
				}
				break;
			case 9:
				filtered = fighters.filter(fighter => fighter.tier === tournament.currentFighterTier.tier);
				for (const fighter of filtered){
					tournament.processFighterDefeat(fighter.id);
				}
				break;
			case 10:
				this.addResource('essence', 1e4);
				break;

		}
	}

	completeAllUnlocks(){
		this.gameManager.unlockManager.completeAllUnlocks();
	}

	resetGame(){
		this.eventManager.dispatchEvent('restart', -1);
	}

	addResource(resource, value){
		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: resource,
			value: DEC(value),
			operation: 'add'
		});
	}
}