export default class RealmUpgradeState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "realmUpgrades";


		// took these out cuz realm evolution writes over cost/prodbase
        // this.baseFeatureProperties = ['baseLevel', 'costBase', 'prodBase'];
        this.propertiesBase = ['baseLevel'];


        this.autoFeatureProperties = ['autoUnlocked','autoToggle'];

        this.regularPropertiesFull = ['level', 'manualLevel', 'autoLevel',
		'active', 'prodCurrentGlobal', 'prodPrevious','prodNextMultPurchase','prodNextSingle','prodMult', 'costNextMultPurchase','costNextSingle','costMult','maxAffLvl',  'evolutionTier'];

        this.propertiesFull = this.propertiesBase.concat(this.regularPropertiesFull).concat(this.rankPropertiesFull).concat(this.autoFeatureProperties);
    }

    saveState(state){
        this.populateSaveDataBase();

        switch (state) {
			case 0:
				this.populateSaveDataFull();
				break;
			case 1:
				this.populateSaveDataRebirth1();
				break;
			case 2:
				this.populateSaveDataRebirth2();
				break;
			case 3:
				this.populateSaveDataRebirth3();
				break;
		}
    }
    
    loadState(state){
        this.applyLoadDataBase();

        switch (state) {
			case 0:
				this.applyLoadDataFull();
				break;
			case 1:
				this.applyLoadDataRebirth1();
				break;
			case 2:
				this.applyLoadDataRebirth2();
				break;
			case 3:
				this.applyLoadDataRebirth3();
				break;
		}
    }

    populateSaveDataBase(){}

    populateSaveDataFull() {
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
    }

    populateSaveDataRebirth1(){}

    populateSaveDataRebirth2(){}

    populateSaveDataRebirth3(){}

    applyLoadDataBase(){}

    applyLoadDataFull() {
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);

        // apply realm evolutions
		for(const feature of this.gameManager.gameContent.realmUpgrades){
			feature.evolve(feature.evolutionTier);
		}

        // this.initializeUpgradeEffects();
    }

    // Don't need this after implementing ModState
    // as they propogate the ModTrees and update multipliers automatically
    initializeUpgradeEffects(){
        this.gameManager.gameContent.realmUpgrades.forEach((upgrade) => {
            if (upgrade.active) {
                upgrade.updateObservers();
				this.gameManager.updateFeatureValues(upgrade, true);
            }
        });
    }
    
    applyLoadDataRebirth1(){}

    applyLoadDataRebirth2(){}

    applyLoadDataRebirth3(){}

}