import { DEC } from '../Utilities/decimal.js';

export default class Rebirth1StateHandler {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
    }

    saveState(state, gameData){
        this.populateSaveDataBase(gameData);

        switch (state) {
			case 0:
				this.populateSaveDataFull(gameData);
				break;
			case 1:
				this.populateSaveDataRebirth1(gameData);
				break;
			case 2:
				this.populateSaveDataRebirth2(gameData);
				break;
			case 3:
				this.populateSaveDataRebirth3(gameData);
				break;
		}
    }
    
    loadState(state, gameData){
        this.applyLoadDataBase(gameData);

        switch (state) {
			case 0:
				this.applyLoadDataFull(gameData);
				break;
			case 1:
				this.applyLoadDataRebirth1(gameData);
				break;
			case 2:
				this.applyLoadDataRebirth2(gameData);
				break;
			case 3:
				this.applyLoadDataRebirth3(gameData);
				break;
		}
    }

    populateSaveDataBase(gameData){}
    populateSaveDataFull(gameData) {
        gameData.rebirth1PseudoObject = {}

		let rebirth1PseudoObject = this.gameManager.findObjectById(60000);

		gameData.rebirth1PseudoObject = {
			id: rebirth1PseudoObject.id,
			level: rebirth1PseudoObject.level.toString(),
			active: rebirth1PseudoObject.active
		}
    }
    populateSaveDataRebirth1(gameData){
		this.isRebirthing = true;
        gameData.rebirth1PseudoObject = {}

		// save rebirth pseudo object for essence multiplier
		let rebirth1PseudoObject = this.gameManager.findObjectById(60000);

        // apply the new essence gain multiplier to rebirth 1 pseudoobject, so that it's mod will see the higher level source and multiply based upon that
        rebirth1PseudoObject.level = rebirth1PseudoObject.level.plus(this.gameManager.gameContent.essenceGainOnNextRebirth1);

		gameData.rebirth1PseudoObject = {
			id: rebirth1PseudoObject.id,
			level: rebirth1PseudoObject.level.toString(),
			active: rebirth1PseudoObject.active
		}
	}

    populateSaveDataRebirth2gameData(){}

    populateSaveDataRebirth3(gameData){}

    
    applyLoadDataBase(gameData){}

    applyLoadDataFull(gameData) {
		let rebirth1PseudoObject = this.gameManager.findObjectById(60000);
		const { id, level, active } = gameData.rebirth1PseudoObject;
		rebirth1PseudoObject.level = DEC(level);
		rebirth1PseudoObject.setActive();
		rebirth1PseudoObject.updateObservers();
    }

    applyLoadDataRebirth1(gameData){
        // load rebirth pseudo object for essence multiplier
		let rebirth1PseudoObject = this.gameManager.findObjectById(60000);
		const { id, level, active } = gameData.rebirth1PseudoObject;
		rebirth1PseudoObject.level = DEC(level);
		rebirth1PseudoObject.setActive();
		rebirth1PseudoObject.updateObservers();

		//apply upgrades that increase base level of features
		for (const feature of this.gameManager.gameContent.trainings.concat(this.gameManager.gameContent.generators)) {
			if (feature.baseLevel.gt(0)){
				feature.levelUp("manual", feature.baseLevel);
				this.gameManager.updateFeatureValues(feature, false);
			}
		}

		// enable first zone by default without requiring the initial unlock
		let firstZone = this.gameManager.findObjectById(90001);
		firstZone.region.setActive();
		firstZone.setActive();
    }
    applyLoadDataRebirth2(gameData){}
    applyLoadDataRebirth3(gameData){}
}