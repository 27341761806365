import { DEC } from '../../Utilities/decimal.js';
import GameFeature from '../../Core/GameFeature.js';

export default class Zone extends GameFeature {
	constructor(eventManager, id, regionID, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, baseConquestTime, active, zoneType, parentID, angleFromParent,distanceFromParent, x = null, y = null, outsideRegionConnectionIDs) {
		super(
			eventManager,
			id,
			name,
            note,
			description,
			level, maxLevel,
			costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate,
			active
		);
		this.featureType = "zone";
		this.regionID = regionID;
		this.region = null;
		this.zoneType = zoneType;

		this.isDefeated = false;
		this.defeatCount = DEC(0);

		this.baseConquestTime = DEC(baseConquestTime);
		this.conquestTime = DEC(baseConquestTime);
		this.isConquesting = false;
		this.conquestTimeRemaining = 0;

		this.unlockReqText = "";
		this.progress = 0;
		this.outsideRegionConnectionIDs = outsideRegionConnectionIDs;
		this.unlocked;


		
		
		//graphical and connection properties
		this.parentID = parentID;
		this.parent = null;
		this.angleFromParent = angleFromParent; // in degrees
		this.distanceFromParent = distanceFromParent; // distance from parent node in pixels
		this.children = [];
		this.x = x;
		this.y = y;
		this.hasConnectionToNewRegion = null;

		
        this.elements = {
            cell: null,
            button: null,
            data: null
        };
	}
	
	updateFeatureValues(isNewLvl, gameManager){
		this.costNextSingle = this.calcCostNextSingle();
		this.prodNextSingle = this.calcProdNextSingle();

		//dont update values if inactive || (level 0 & not being levelled up)
		if (!this.active || (this.level.eq(0) && !isNewLvl)) {
			return;
		}
		// handle if an active feature's multiplier is being updated but not its level
			// aka being upgraded or a mod is activated
		// also handles evolutions
		else if (this.active && !isNewLvl) {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
		//handle features that are being levelled manually or generator autopurchase
		else {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
	}

	setActive() {
		this.active = true;
		if (this.active && this.unlocked){
			this.region.zoneAvailable = true;
		}
	}

	setUnlocked(){
		this.unlocked = true;
		if (this.active && this.unlocked){
			this.region.zoneAvailable = true;
		}
	}
 
	startConquest(startTime = 0) {
		if (this.defeatCount.eq(0)) {
			this.eventManager.dispatchEvent('updatePropertyValue', {
				property: this.costType,
				value: this.costNextMultPurchase,
				operation: 'subtract'
			});
		}
		this.isConquesting = true;
	
		this.progress = startTime;
		this.timeRemaining = this.conquestTime * (1 - startTime);
	
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	
		const startTimestamp = Date.now() - (startTime * this.conquestTime * 1000);
		const initialConquestTime = this.conquestTime;
	
		this.intervalId = setInterval(() => {
			const elapsedTime = (Date.now() - startTimestamp) / 1000; // Elapsed time in seconds
			const currentConquestTime = this.conquestTime;
	
			if (currentConquestTime !== initialConquestTime) {
				// Conquest time has been updated
				this.progress = elapsedTime / currentConquestTime;
				this.timeRemaining = currentConquestTime - elapsedTime;
			} else {
				this.progress = startTime + (elapsedTime / initialConquestTime);
				this.timeRemaining = initialConquestTime - elapsedTime;
			}
	
			if (this.progress >= 1) {
				this.progress = 0;
				this.timeRemaining = 0;
				clearInterval(this.intervalId);
				this.isConquesting = false;
				this.handleConquestComplete();
			}
			this.eventManager.dispatchEvent('zoneConquestProgress', { zoneID: this.id, progress: this.progress, timeRemaining: this.timeRemaining });
		}, 10); // Update every 10 milliseconds
	}

	stopConquest() {
        this.eventManager.dispatchEvent('zoneConquestStopped', { zoneID: this.id });
        this.progress = 0;
        this.conquestTimeRemaining = 0;
        this.isConquesting = false;
        clearInterval(this.intervalId);
    }

	handleConquestComplete(count = 1) {
		if (!this.isDefeated){
			this.isDefeated = true;
      		this.progress = 0;
        	this.conquestTimeRemaining = 0;
			this.activateConnections();
		}
		this.region.zoneCompleted(this);

		this.processRewards(count);

		this.defeatCount = this.defeatCount.plus(count);

		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: "lifetimeZoneCompletions",
			value: DEC(count),
			operation: 'add'
		});
	}


	// 	Explanation:
	// Scaling Factor: worldLevel.times(0.01): The scaling factor is based on the world level. This means at world level 4, the scaling factor is 0.04.
	// Augmented Rarities: Each shard's rarity is increased by multiplying it with DEC(1).plus(scalingFactor).
	// For alphaShard (base rarity 0.25), at world level 4, augmented rarity = 0.25 * (1 + 0.04) = 0.25 * 1.04 = 0.26.
	// For omicronShard (base rarity 0.001), at world level 4, augmented rarity = 0.001 * (1 + 0.04) = 0.001 * 1.04 = 0.00104.
	// This approach ensures that the rarities of rarer shards increase more significantly than those of common shards as the world level increases, making rare shards more likely to drop at higher levels.
	processShardGain() {
		// Calculate amount of shards to be gained based on world level
		let worldLevel = DEC(this.region.world.level);
		// Factor in the zone's prodNext (which factors in the multiplier from its modTree)
		let shardGain = DEC(0).plus(this.prodNextMultPurchase.times(worldLevel));
	
		// Multiply shard count based on zone type (boss = 3x, progressionBoss = 2x, legendaryBoss = 5x)
		if (this.zoneType === "boss") {
			shardGain = shardGain.times(3);
		} else if (this.zoneType === "legendaryBoss") {
			shardGain = shardGain.times(5);
		} else if (this.zoneType === "progressionBoss") {
			shardGain = shardGain.times(2);
		}

		// Aggregate Gains: The expected count for each shard type is calculated directly using a probabilistic distribution approach, avoiding the need for iterative random sampling.
		// Floor Function: Using floor to calculate the expected count ensures that the number of iterations is reduced to a minimum, which is crucial when handling extremely high values.

		// Grab pre-calculated normalized shard distribution from world
		let normalizedShardRarities = this.region.world.normalizedShardRarities;
		let shardDistribution = this.region.world.shardDistribution;
	
		// Calculate total weight of the shard rarities
		let totalWeight = normalizedShardRarities.reduce((sum, shard) => sum.plus(shard.rarity), DEC(0));
	
		// Distribute shards probabilistically using a multinomial-like approach
		normalizedShardRarities.forEach(shard => {
			let shardProbability = shard.rarity.div(totalWeight);
			let shardCount = shardGain.times(shardProbability).floor(); // Calculate the expected count
			shardDistribution[shard.type] = shardDistribution[shard.type].plus(shardCount);
		});
	
		// Create the resource updates array for shards that are >0 in the distribution
		let resourceUpdates = [];
		for (let shard in shardDistribution) {
			if (shardDistribution[shard].greaterThan(0)) {
				resourceUpdates.push({ property: shard, value: shardDistribution[shard], operation: 'add' });
			}
		}
	
		// Dispatch the event for batch updating property values
		this.eventManager.dispatchEvent('batchUpdatePropertyValues', resourceUpdates);
	}


	
	processRewards(count = null) {
		// if zonetype is boss and any of its children's world id's are different from its own
		if (this.zoneType === "boss" && this.children.some(child => child.region.world.id !== this.region.world.id)){
			this.eventManager.dispatchEvent('updatePropertyValue', {
				property: 'skillpoints',
				value: DEC(2),
				operation: 'add'
			});
		}
		else if (this.zoneType === "boss"){
			this.eventManager.dispatchEvent('updatePropertyValue', {
				property: 'skillpoints',
				value: DEC(1),
				operation: 'add'
			});
		}
		else if (this.zoneType === "legendaryBoss"){
			this.eventManager.dispatchEvent('updatePropertyValue', {
				property: 'skillpoints',
				value: DEC(1),
				operation: 'add'
			});
		}

		this.processShardGain();
	}

	activateConnections(){
		if (this.children.length > 0){
			for (const child of this.children){
				// if next zone is in a new world, set current world as progressed
				if (child.region.world !== this.region.world){
					this.region.world.setProgressed();
				}
				child.setActive();
				if (child.region !== this.region){
					child.region.setActive();
				}
			}
		}
		if (this.parent){
			this.parent.setActive();
			if (this.parent.region !== this.region){
				this.parent.region.setActive();
			}
		}
	}
}