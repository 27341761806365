export default class NotificationManager {
    constructor(eventManager,ui){

		this.eventManager = eventManager;
        this.ui = ui;

        this.eventManager.addListener('triggerNotification', this.triggerNotification.bind(this));
        
		this.setupNotificationWindow();
    }

	
	setupNotificationWindow(){
		let notifyWindow = this.ui.createElement('div', 'notifyWindow', 'notify-window');
		// notifyWindow.style.display = "none";
		let mainWindow = document.getElementById("main-window");
		mainWindow.appendChild(notifyWindow);
	}

	triggerNotification(data){
		let targetType = data.targetType;
		let targetName = data.targetName;
		// let triggerType = data.triggerType;
		let notifyWindow = document.getElementById("notifyWindow");

		let notifyStr = [];
		if (targetType === 'Tab'){
			notifyStr = [`Mechanic Unlocked!`,`${targetName.charAt(0).toUpperCase() + targetName.slice(1)}` ];
		}
		// else if (targetType === 'Achievement'){
		// 	notifyStr = [`Achievement Unlocked!`];
		// }
		// let notifyStr = [
			// `${parsedType} Unlocked!`,
			// `${targetName.charAt(0).toUpperCase() + targetName.slice(1)}`
		// ];
		if (notifyStr.length > 0){

			this.ui.updateElementHTML(notifyWindow, notifyStr.join('\n'));
			notifyWindow.style.animation = 'none';
			notifyWindow.offsetHeight;  //a trick to make the browser immediately render changes, allowing us to reset CSS animations.   ALSO ensures new notifications replace older
			notifyWindow.style.animation = 'fadein 6s';
		}

	}
}