import { DEC } from '../../Utilities/decimal.js';

export default class FighterTier {
	constructor(eventManager, id, tier, name){
		this.eventManager = eventManager;
		this.id = id;
		this.name = name;

		this.fighters = [];

		this.world = null;

		this.tier = tier;

		this.isCompleted = false;

		this.active = false;
	}

	setComplete(){
		this.isCompleted = true;
		//award / improve headband
		
		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: "lifetimeFighterTiersDefeated",
			value: DEC(1),
			operation: 'add'
		});
	}


	setActive(){
		this.active = true;
		// this.fighters[0].setActive();
		// this.fighters[0].active = true;
		for (const fighter of this.fighters){
			fighter.setActive();
		}
	}
}