import { DEC } from '../../Utilities/decimal.js';

export default class Realm {
	constructor(eventManager, id, type, name, evolutionTierNames, description, active, startingResource) {
		this.eventManager = eventManager;
		this.id = id;
		this.type = type;
		this.name = name;
		this.displayName;
		this.description = description;
		this.active = active;
		this.trainings = [];
		this.realmUpgrades = [];
		this.generatorChains = [];
		this.evolutionTier = DEC(1);
		this.evolutionTierNames = evolutionTierNames;

		this.startingResource = DEC(startingResource);
	}

	setActive() {
		this.active = true;

		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: this.type,
			value: this.startingResource,
			operation: 'add'
		});
	}

	evolve(){
		this.evolutionTier = this.evolutionTier.plus(1);


		if (this.type === "force" || this.type === "energy"){
			for (const feature of this.trainings.concat(this.realmUpgrades)){
				feature.evolve();
			}
		}
		else if (this.type === "wisdom" || this.type === "divine"){

			for (const feature of this.generatorChains[0].generators.concat(this.realmUpgrades)){
				feature.evolve();
			}
		}
	}
}


