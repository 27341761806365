export default class RegionDisplayManager {
    constructor(eventManager, ui, gameContent) {
      this.eventManager = eventManager;
      this.ui = ui;
      this.gameContent = gameContent;
      this.worldManager = this.gameContent.worldManager;
    }
  
    populateRegions(regions, mapViewWindow) {
      regions.forEach((region) => {
        let regionCell = document.querySelector(`#region-${region.id}`);
        if (!regionCell) {
          regionCell = this.createRegionCell(region, mapViewWindow);
        }
        region.DOMContent = regionCell;
      });
    }
  
    createRegionCell(region, mapViewWindow) {
      const regionCell = this.ui.createElement('div', `region-${region.id}`, 'region-cell');
      const topLeftCornerX = region.topLeftCornerX + 100;
      const topLeftCornerY = region.topLeftCornerY + 60;
  
      regionCell.style.top = `${topLeftCornerY}px`;
      regionCell.style.left = `${topLeftCornerX}px`;
      regionCell.style.display = 'none';
  
      if (region.image) {
          regionCell.style.backgroundImage = `url(${region.image})`;
          regionCell.style.backgroundSize = 'cover';
      }
  
      const regionTitle = this.ui.createElement('div', null, 'region-cell-title', region.name);
      const regionProgress = this.ui.createElement('div', null, 'region-cell-progress', `${Math.floor(region.progressPercentage)}%`);
      
      // Add the status indicator element
      const regionStatus = this.ui.createElement('div', null, 'region-cell-status');
  
      regionCell.appendChild(regionTitle);
      regionCell.appendChild(regionProgress);
      regionCell.appendChild(regionStatus); // Append status indicator to region cell
  
      const zonesContainer = this.ui.createElement('div', null, 'zones-container');
      zonesContainer.style.backgroundImage = `url(${region.image})`;
      zonesContainer.style.backgroundSize = 'cover';
      zonesContainer.style.display = 'none';
      mapViewWindow.appendChild(zonesContainer);
      region.zoneDOMContent = zonesContainer;
  
      const regionBackButton = this.ui.createElement('button', null, 'region-back-button', `^`);
  
      const backButtonClickHandler = () => {
          this.mapViewManager.updateMapView(region.world);
      };
      this.eventManager.addDomListener(regionBackButton, 'click', backButtonClickHandler);
  
      const regionCellClickHandler = () => {
          if (region.active) {
              this.mapViewManager.updateMapView(region);
          }
      };
      this.eventManager.addDomListener(regionCell, 'click', regionCellClickHandler);
  
      const regionCellMouseoverHandler = () => {
          regionCell.style.filter = 'brightness(150%)';
      };
      this.eventManager.addDomListener(regionCell, 'mouseover', regionCellMouseoverHandler);
  
      const regionCellMouseoutHandler = () => {
          regionCell.style.filter = 'brightness(100%)';
      };
      this.eventManager.addDomListener(regionCell, 'mouseout', regionCellMouseoutHandler);
  
      zonesContainer.prepend(regionBackButton);
      mapViewWindow.appendChild(regionCell);
  
      if (region.zones) {
          this.zoneDisplayManager.populateZones(zonesContainer, region);
      }

      let conquestNextButton = this.ui.createElement('button', null, 'conquest-next-button', 'Attempt Next Zone (r)');
      //add dom listener to conquest next zone
      this.eventManager.addDomListener(conquestNextButton, 'click', () => {
          this.ui.hotkeyManager.handleZoneHotkeyProgression(region);
      })
      zonesContainer.appendChild(conquestNextButton);
  
      return regionCell;
  }
  
  
  updateRegions(regions) {
    regions.forEach(region => {
        let regionCell = document.querySelector(`#region-${region.id}`);
        let regionProgress = regionCell.querySelector('.region-cell-progress');
        this.ui.updateElementTextContent(regionProgress, Math.floor(region.progressPercentage) + "%");
        this.updateWorldRegionFog(region);
        
        region.updateZoneAvailability(); 
        
        let regionStatus = regionCell.querySelector('.region-cell-status');

        if (region.active) {
            const newDisplay = (region.zoneAvailable === 'available' || region.zoneAvailable === 'conquesting') ? 'block' : 'none';
            let newBackgroundColor;

            if (region.zoneAvailable === 'available') {
                newBackgroundColor = '#6bff79';
            } else if (region.zoneAvailable === 'conquesting') {
                newBackgroundColor = 'yellow';
            } else {
                newBackgroundColor = 'grey';
            }

            if (regionStatus.style.display !== newDisplay) {
                regionStatus.style.display = newDisplay;
            }
            if (regionStatus.style.backgroundColor !== newBackgroundColor) {
                regionStatus.style.backgroundColor = newBackgroundColor;
            }
        } else {
            if (regionStatus.textContent !== "") {
                regionStatus.textContent = "";
            }
            if (regionStatus.style.display !== 'none') {
                regionStatus.style.display = 'none';
            }
        }

        if (region.zones) {
            this.zoneDisplayManager.updateZones(region.zones);
        }
    });
}

  
    updateWorldRegionFog(region) {
      let newOpacity;
      let currentOpacity = region.DOMContent.style.opacity;
  
      if (region.active) {
        newOpacity = (region.progressPercentage / 100) + 0.6;
      } else {
        newOpacity = (region.progressPercentage / 100) + 0.1;
      }
  
      if (currentOpacity !== newOpacity) {
        region.DOMContent.style.opacity = newOpacity;
      }
    }
  }