import Decimal from '../Utilities/break_eternity.min.js'; // large number library

export default class OfflineManager {
    constructor(eventManager, gameManager) {
        this.eventManager = eventManager;
        this.gameManager = gameManager;
        this.gameContent = gameManager.gameContent;

        this.beforeOfflineData = null;

        this.offlineProcessingStepIntervalMs = 10000; // ms

        // Create and insert the modal HTML right after the root element
        this.createOfflineModal();
    }

    createOfflineGainsComparisonObject() {
        let comparisonObject = {
            powerLevel: this.gameContent.powerLevel,
            forceIncome: this.gameContent.forceIncome,
            wisdomIncome: this.gameContent.wisdomIncome,
            trainingLevels: {},
            generatorLevels: {}
        };
    
        this.gameManager.gameContent.trainings.forEach(training => {
            comparisonObject.trainingLevels[training.id] = {
                level: training.level,
                name: training.name
            };
        });
    
        this.gameManager.gameContent.generators.forEach(generator => {
            comparisonObject.generatorLevels[generator.id] = {
                level: generator.level,
                name: generator.name
            };
        });
    
        return comparisonObject;
    }

    createOfflineModal() {
        const rootElement = document.getElementById('root');
    
        // Create the overlay to block interactions
        const overlay = document.createElement('div');
        overlay.setAttribute('id', 'offline-overlay');
        overlay.style.position = 'fixed';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        overlay.style.zIndex = '9998'; // Ensure it is above most elements but below the modal
        overlay.style.display = 'none'; // Hidden by default
        rootElement.appendChild(overlay);
    
        const modal = document.createElement('div');
        modal.setAttribute('id', 'offline-modal');
        modal.setAttribute('class', 'modal');
        modal.style.zIndex = '9999'; // Ensure it is above the overlay
        
        const modalContent = document.createElement('div');
        modalContent.setAttribute('class', 'modal-content');
        
        const spanClose = document.createElement('span');
        spanClose.setAttribute('class', 'close');
        spanClose.innerHTML = '&times;';
        modalContent.appendChild(spanClose);
        
        const spanMessage = document.createElement('span');
        spanMessage.setAttribute('id', 'modal-message');
        modalContent.appendChild(spanMessage);
        
        modal.appendChild(modalContent);
        
        // Inserting the modal right after the root element
        rootElement.appendChild(modal);
        
        // Disable closing the modal when clicking on the close button or outside the modal while processing
        const spanCloseClickHandler = () => {
            if (!this.processing) { // Allow closing only if not processing
                modal.style.display = "none"; // Hide the modal
                overlay.style.display = "none"; // Hide the overlay
            }
        };
        this.eventManager.addDomListener(spanClose, 'click', spanCloseClickHandler);
        
        // Prevent interaction with the modal when clicking outside if processing
        this.modalClickHandler = (event) => {
            const modalContent = document.querySelector('.modal-content');
            if (modalContent && !modalContent.contains(event.target) && !this.processing) {
                modal.style.display = "none"; // Hide the modal
                overlay.style.display = "none"; // Hide the overlay
            }
        };
    
        // Add event listener for closing the modal when clicking outside of it if not processing
        window.addEventListener('click', this.modalClickHandler);
    }
    

    processOffline(elapsedTimeMs) {
        const timeDifferenceSec = elapsedTimeMs / 1000; // in seconds
        let stepIntervalSec = this.offlineProcessingStepIntervalMs / 1000; // in seconds
    
        if (timeDifferenceSec > stepIntervalSec) {
            // Show the modal with the loading message
            this.displayOfflineGainsModal(true);
    
            this.beforeOfflineData = this.createOfflineGainsComparisonObject();
    
            let totalTimeSec = timeDifferenceSec * this.gameManager.gameContent.timeModifierUpgrade;
            let remainderSec = totalTimeSec % stepIntervalSec;
            let processCount = 0;
    
            // Use setTimeout to process chunks asynchronously
            const processStep = () => {
                if (totalTimeSec >= stepIntervalSec) {
                    this.gameManager.processGamePropertyUpdates(stepIntervalSec);
                    totalTimeSec -= stepIntervalSec;
                    processCount++;
                    setTimeout(processStep, 0); // Schedule the next chunk
                } else if (remainderSec > 0) {
                    this.gameManager.processGamePropertyUpdates(remainderSec);
                    processCount++;
                    remainderSec = 0;
                }
    
                if (totalTimeSec < stepIntervalSec && remainderSec === 0) {
                    let gains = this.calculateOfflineGains();
                    if (this.hasOfflineGains(gains)) {
                        // Update the modal with the detailed gains
                        this.displayOfflineGainsModal(false, timeDifferenceSec, processCount);
                    } else {
                        // Hide the modal and overlay if no gains
                        this.hideOfflineModal();
                    }
                }
            };
    
            // Start processing the first step
            processStep();
        }
    }
    

    hasOfflineGains(gains) {
        if (!gains) return false;

        for (let key in gains) {
            if (key !== 'trainingLevels' && key !== 'generatorLevels') {
                if (gains[key].gt(0)) return true;
            } else if (key === 'trainingLevels' || key === 'generatorLevels') {
                for (let subKey in gains[key]) {
                    if (gains[key][subKey].level.gt(0)) return true;
                }
            }
        }

        return false;
    }

    displayOfflineGainsModal(isLoading = false, timeDifferenceSec = 0, processCount = 0) {
        var modal = document.getElementById("offline-modal");
        var modalContent = document.getElementById("modal-message");
        var overlay = document.getElementById("offline-overlay");
    
        if (isLoading) {
            // Show overlay and modal with the loading message
            this.processing = true;
            overlay.style.display = "block";
            modal.style.display = "block";
            modalContent.textContent = "Processing offline gains...";
        } else {
            this.processing = false;
            // Update the modal content with the detailed gains
            let gains = this.calculateOfflineGains();
            let textContent = '';
    
            textContent += `Offline Gains\n`;
            const formattedTime = this.formatElapsedTime(timeDifferenceSec);
            textContent += `${formattedTime} since the last game update.\n`;
            textContent += `${processCount} income and autobuy intervals processed.\n\n`;
    
            for (let key in gains) {
                if (key !== 'trainingLevels' && key !== 'generatorLevels') {
                    textContent += `${key} gained: ${gains[key].toFixed(2)}\n`;
                } else if (key === 'trainingLevels') {
                    textContent += '\nTraining Levels Gained:\n';
                    for (let trainingId in gains.trainingLevels) {
                        let trainingName = gains.trainingLevels[trainingId].name;
                        let trainingGain = gains.trainingLevels[trainingId].level;
                        textContent += `${trainingName} gained: ${this.formatNumber(trainingGain)}\n`;
                    }
                } else if (key === 'generatorLevels') {
                    for (let generatorId in gains.generatorLevels) {
                        let generatorName = gains.generatorLevels[generatorId].name;
                        let generatorGain = gains.generatorLevels[generatorId].level;
                        textContent += `${generatorName} gained: ${this.formatNumber(generatorGain)}\n`;
                    }
                }
            }
    
            modalContent.textContent = textContent;
    
            // Hide the overlay after displaying the results
            overlay.style.display = "none";
        }
    }
    

    hideOfflineModal() {
        const modal = document.getElementById("offline-modal");
        const overlay = document.getElementById("offline-overlay");
        if (modal) {
            modal.style.display = "none";
        }
        if (overlay) {
            overlay.style.display = "none";
        }
    }
    

    calculateOfflineGains() {
        if (!this.beforeOfflineData) return null;

        let beforeOfflineData = this.beforeOfflineData;
        let afterOfflineData = this.createOfflineGainsComparisonObject();

        let gains = {
            powerLevel: Decimal.sub(afterOfflineData.powerLevel, beforeOfflineData.powerLevel),
            forceIncome: Decimal.sub(afterOfflineData.forceIncome, beforeOfflineData.forceIncome),
            wisdomIncome: Decimal.sub(afterOfflineData.wisdomIncome, beforeOfflineData.wisdomIncome),
            trainingLevels: {},
            generatorLevels: {}
        };

        for (let trainingId in beforeOfflineData.trainingLevels) {
            let beforeTrainingLevel = beforeOfflineData.trainingLevels[trainingId].level;
            let afterTrainingLevel = afterOfflineData.trainingLevels[trainingId].level;

            let dif = Decimal.sub(afterTrainingLevel, beforeTrainingLevel);
            if (dif.gt(0)) {
                gains.trainingLevels[trainingId] = {
                    level: dif,
                    name: afterOfflineData.trainingLevels[trainingId].name
                };
            }
        }

        for (let generatorId in beforeOfflineData.generatorLevels) {
            let beforeGeneratorLevel = beforeOfflineData.generatorLevels[generatorId].level;
            let afterGeneratorLevel = afterOfflineData.generatorLevels[generatorId].level;

            let dif = Decimal.sub(afterGeneratorLevel, beforeGeneratorLevel);
            if (dif.gt(0)) {
                gains.generatorLevels[generatorId] = {
                    level: dif,
                    name: afterOfflineData.generatorLevels[generatorId].name
                };
            }
        }

        return gains;
    }

    formatNumber(number) {
        if (number.lessThan(1000)) {
            return number.toFixed(2);
        } else {
            return number.toExponential(2);
        }
    }

    formatElapsedTime(seconds) {
        const days = Math.floor(seconds / (24 * 60 * 60));
        seconds %= (24 * 60 * 60);
        const hours = Math.floor(seconds / (60 * 60));
        seconds %= (60 * 60);
        const minutes = Math.floor(seconds / 60);
        seconds = Math.floor(seconds % 60); // Remaining seconds after minutes
    
        let formattedTime = '';
    
        if (days > 0) {
            formattedTime += `${days} day${days > 1 ? 's' : ''}, `;
        }
        if (hours > 0) {
            formattedTime += `${hours} hour${hours > 1 ? 's' : ''}, `;
        }
        if (minutes > 0) {
            formattedTime += `${minutes} minute${minutes > 1 ? 's' : ''}, `;
        }
        if (seconds > 0 || formattedTime === '') { // Show seconds even if it's 0 and nothing else has been shown
            formattedTime += `${seconds} second${seconds > 1 ? 's' : ''}`;
        } else {
            // Remove trailing comma and space if seconds are not shown
            formattedTime = formattedTime.slice(0, -2);
        }
    
        return formattedTime;
    }
    
}
