
import { DEC } from '../Utilities/decimal.js';

import GameContent from './GameContent.js';
import UnlockManager from './UnlockManager.js';
import AutomationManager from './AutomationManager.js';


export default class GameManager {
	constructor(eventManager, injectAdminSettings = false) {
		this.eventManager = eventManager;
		this.multiplierString = "1";
		this.notificationsToggled = true;
		this.injectAdminSettings = injectAdminSettings;
		this.gameContent = new GameContent(eventManager);
		this.unlockManager = new UnlockManager(eventManager,this, this.injectAdminSettings);

		this.automationManager = new AutomationManager(eventManager,this);

		this.eventManager.addListener('handlePurchase', (data) => {
			this.handlePurchase(data.id);
		});

		this.eventManager.addListener('updateFeatureValues', (data) => {
			this.updateFeatureValues(data.target, data.isNewLvl);
		});

		this.eventManager.addListener('updateNewMultiplierValues', (data) => {
			this.updateNewMultiplierValues(data.multiplierString, data.feature);
		});

		// TIME TRACKING
		this.unlockTimes = []; // Array to store unlock times
		this.logInterval = 60000; // Log interval in milliseconds (e.g., 10 seconds)
		this.lastLogTime = 0; // Timestamp of the last log
		// TIME TRACKING
	}

	onTabChange(newTab){
		this.currentTab = newTab;
		this.currentSubTab = newTab.currentSubTabObject;
		let newMultiplierString;
		if (this.currentSubTab && this.currentSubTab.multiplierString){
			newMultiplierString = this.currentSubTab.multiplierString;
		}
		else if (this.currentTab.multiplierString){
			newMultiplierString = this.currentTab.multiplierString;
		}
		else {
			return;
		}
		this.multiplierString = newMultiplierString;
		this.updateNewMultiplierValues(newMultiplierString);
	}
	
	updateNewMultiplierValues(newMultiplierString = this.multiplierString, feature = null) {
		this.multiplierString = newMultiplierString;
		let multipliableFeatures;
	
		if (feature){
			multipliableFeatures = [feature];
		}
		else {
			let multipliableTabName = this.getMultipliableTabName();
	
			if (multipliableTabName){
				multipliableFeatures = this.getFeaturesByTabName(multipliableTabName);
				multipliableFeatures = multipliableFeatures.filter(feature => !feature.level.equals(feature.maxLevel));
			}
			else {
				multipliableFeatures = 
					this.gameContent.skills
					.concat(this.gameContent.zones)
					.concat(this.gameContent.fighters)
					.concat(this.gameContent.artifacts)
					.concat(this.gameContent.forgeUpgrades)
						.filter(feature => !feature.level.equals(feature.maxLevel));
			}
		}
	
		this.processMultiplierValue(newMultiplierString, multipliableFeatures);
	}
	
	processMultiplierValue(multiplier, features) {
		if (multiplier === "Rank") {
			for (const feature of features) {
				// if (["training", "generator", "artifact"].includes(feature.featureType)) {
				if (["training", "generator"].includes(feature.featureType)) {
					feature.updateValuesRank();
				} else {
					feature.updateValuesDigit(DEC(1));
				}
			}
		} else if (multiplier === "Max") {
			for (const feature of features) {
				const allResources = this.gameContent.queryPropertyValues("all");
				let matchingResource = allResources.find(resource => resource.type === feature.costType);
				feature.updateValuesMax(matchingResource.value);
			}
		} else {
			let n = DEC(multiplier);
			for (const feature of features) {
				feature.updateValuesDigit(n);
			}
		}
	}
	
	getMultipliableTabName(){
		if (this.currentTab && this.currentTab.multiplierContainer){
			return this.currentTab.name;
		}
		else if (this.currentSubTab && this.currentSubTab.multiplierContainer) {
			return this.currentSubTab.name;
		}
		return null;
	}

	getFeaturesByTabName(tabName){
		const realmMapping = {
			'force': [this.gameContent.trainings, this.gameContent.realmUpgrades, 10],
			'wisdom': [this.gameContent.generators, this.gameContent.realmUpgrades, 20],
			'energy': [this.gameContent.trainings, this.gameContent.realmUpgrades, 30],
			'divine': [this.gameContent.generators, this.gameContent.realmUpgrades, 40]
		};
	
		if(realmMapping[tabName]){
			// console.error(tabName);
			const [feature1, feature2, realmID] = realmMapping[tabName];
			return feature1.filter(training => training.realmID === realmID).concat(feature2.filter(upgrade => upgrade.realmID === realmID));
		}
		
		// console.error(tabName);
		switch(tabName){
			case 'artifacts':
				return this.gameContent.artifacts;
			case 'radiance':
				return this.gameContent.radianceUpgrades;
			case 'essence':
				return this.gameContent.essenceUpgrades;
		}
	}

	handlePurchase(featureID, purchaseCount = null, bypassMult = null) {
		const feature = this.findObjectById(featureID);
		this.deductFeatureCost(feature, bypassMult);
		this.updateFeatureLevel(feature, purchaseCount);
		feature.updateFeatureValues(true,this);
		this.updateNewMultiplierValues(this.multiplierString, feature);
	}

	deductFeatureCost(feature, bypassMult = null) {
		let costToSubtract;
		if (bypassMult){
			costToSubtract = bypassMult;
		}
		else {
			costToSubtract = feature.costNextMultPurchase;
		}
		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: feature.costType,
			value: costToSubtract,
			operation: 'subtract'
		});
	}

	updateFeatureLevel(feature, purchaseCount) {
		let count = null;
		if (purchaseCount) {
			count = DEC(purchaseCount);
		}
		feature.levelUp("manual", count);
	}

	updateFeatureValues(feature, isNewLvl) {
		feature.updateFeatureValues(isNewLvl, this);
	}

	processGamePropertyUpdates(deltaTimeSeconds) {
		
		// TIME TRACKING
		let startTime;
		if (this.injectAdminSettings){
			startTime = performance.now(); // Start tracking time
		}
		// TIME TRACKING

		for (const chain of this.gameContent.generatorChains) {
			if (chain.active) {
				chain.calculateChain(this, deltaTimeSeconds);
			}
		}
		this.gameContent.updateCurrencySynergyMultipliers();
		this.gameContent.updateShardBonusMultipliers();
		this.gameContent.processTrainingIncome(deltaTimeSeconds);
		this.gameContent.updatePowerLevel(deltaTimeSeconds);
		this.gameContent.updateLifetimeValues(deltaTimeSeconds);
		this.gameContent.updateRebirthGainCalculation(deltaTimeSeconds);
		this.automationManager.processAutobuying();
		this.updateZoneCompletionTimes();

		this.updateNewMultiplierValues(this.multiplierString);

		
		// TIME TRACKING
		if (this.injectAdminSettings){
			const endTime = performance.now();
			const elapsedTime = endTime - startTime;
			this.unlockTimes.push(elapsedTime);
			const currentTime = performance.now();
			if (currentTime - this.lastLogTime >= this.logInterval) {
				this.logUnlockTimes();
				this.lastLogTime = currentTime;
			}
		}
		// TIME TRACKING
	}

	//TIME TRACKING
	logUnlockTimes() {
		const count = this.unlockTimes.length;
		if (count === 0) {
			return;
		}

		const sum = this.unlockTimes.reduce((a, b) => a + b, 0);
		const avg = sum / count;
		const min = Math.min(...this.unlockTimes);
		const max = Math.max(...this.unlockTimes);

		console.log(`Property Updates times (last ${count} runs) - Average: ${avg.toFixed(2)} ms, Minimum: ${min.toFixed(2)} ms, Maximum: ${max.toFixed(2)} ms`);

		this.unlockTimes = []; // Reset the unlock times array
	}
	//TIME TRACKING

	updateZoneCompletionTimes() {
		//reduce zone conquest time based on power level and timeModifier upgrades
		const powerFactor = DEC(1).plus(this.gameContent.powerLevel.plus(1).ln().times(0.3));
		const timeModifier = DEC(this.gameContent.timeModifierUpgrade);
		this.gameContent.conquestTimeModifier = timeModifier.times(powerFactor);

		//reduce zone conquest time based on power level
		for (const zone of this.gameContent.zones) {
			zone.conquestTime = zone.baseConquestTime.div(this.gameContent.conquestTimeModifier);
			// zone.conquestTime = zone.baseConquestTime.div(powerFactor);
		}
	}

	findObjectById(id) {
		let object = this.gameContent.idToObjectMap.get(id);
		if (object){
			return object;
		}
		else {
			console.error(`find object by id not found: ${id}`);
			return null;
		}
	}
}
