import { DEC } from '../../../Utilities/decimal.js';


import OdysseySubTab from './OdysseySubTab.js';
import TournamentSubTab from './TournamentSubTab.js';
import ArtifactsSubTab from './ArtifactsSubTab.js';

export default class ExplorationTab{
    constructor(eventManager, ui, gameContent, tabManager){
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.tabManager = tabManager;

        this.odysseySubTab = new OdysseySubTab(this.eventManager,this.ui,this.gameContent);
        this.tournamentSubTab = new TournamentSubTab(this.eventManager,this.ui,this.gameContent);
        this.artifactsSubTab = new ArtifactsSubTab(this.eventManager,this.ui,this.gameContent);

    }

    populateDisplay(){
		this.populateExplorationTabSubTabButtons();

		this.odysseySubTab.populateDisplay();
		this.tournamentSubTab.populateDisplay();
		this.artifactsSubTab.populateDisplay();
    }

    
    updateDisplay(){
        switch(this.tabManager.currentSubTab){
            case 'odyssey':
                this.odysseySubTab.updateDisplay();
                break;
            case 'tournament':
                this.tournamentSubTab.updateDisplay();
                break;
            case 'artifacts':
                this.artifactsSubTab.updateDisplay();
                break;
        }
    }

	populateExplorationTabSubTabButtons(){
        let targetParent = document.getElementById('exploration');
        const tabButtonsContainer = document.getElementById(`exploration-tab-buttons`);
        let subTabButtons = Array.from(tabButtonsContainer.children);
        let subTabNames = subTabButtons.map(button => button.id.replace('SubTab', '').toLowerCase());


        subTabButtons.forEach((button, index) => {
            const buttonClickHandler = () => {
                this.changeExplorationSubTab(button.id, subTabNames[index]);
            };
            this.eventManager.addDomListener(button, 'click', buttonClickHandler);
            
        

            let tabContent = document.getElementById(`tab-content-${button.id}`);
            let col1, col2;

            if (!tabContent) {
                tabContent = this.ui.createElement('div',`tab-content-${button.id}`);
                tabContent.style.display = (index === 0 ? 'flex' : 'none'); // Only display the first tab by default

                col1 = this.ui.createElement('div',`tab-col1-${button.id}`,'content-tab-col');

                col2 = this.ui.createElement('div',`tab-col2-${button.id}`,'content-tab-col');

                tabContent.appendChild(col1);
                tabContent.appendChild(col2);

                targetParent.appendChild(tabContent);
            }
        });

        // If it's the first tab, add 'active-tab' class
        if (subTabButtons.length > 0) {
            subTabButtons[0].classList.add('active-tab');
        }
	}

	changeExplorationSubTab(tabId, subTabName) {
		this.tabManager.toggleActiveSubTabClass(`exploration-tab-buttons`, tabId);

		this.tabManager.toggleSubTabContentDisplay('exploration', 'tab-content', 'none');

		// Hide the current subTab's multiplier container if exists
		if (this.tabManager.currentSubTab){
			let currentSubTabObject = this.tabManager.tabs.find(tab => tab.name === this.tabManager.currentSubTab);
			this.ui.multiplierManager.toggleMultiplierContainer(currentSubTabObject, "none");
		}

		let explorationTab = this.tabManager.tabs.find(tab => tab.name === this.tabManager.currentTab);
		explorationTab.currentSubTab = subTabName;
		
		this.tabManager.currentSubTab = subTabName;

		// Show the new subTab's multiplier container if exists
		if (this.tabManager.currentSubTab){
			let currentSubTabObject = this.tabManager.tabs.find(tab => tab.name === this.tabManager.currentSubTab);
			this.ui.multiplierManager.toggleMultiplierContainer(currentSubTabObject, "block");

			currentSubTabObject.hasBeenOpened = true;

			//also assign the current subtab to the current tab
			explorationTab.currentSubTabObject = currentSubTabObject;
		}

		this.eventManager.dispatchEvent('updateHotkeyButtons');
		this.ui.gameManager.onTabChange(explorationTab);
		
		this.ui.showElement(`tab-content-${tabId}`);
	}
}