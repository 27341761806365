export default class EssenceTab{
    constructor(eventManager, ui, gameContent, tabManager){
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.tabManager = tabManager;
    
        this.essenceUpgrades = this.gameContent.essenceUpgrades;
    
        this.targetCol1 = this.ui.essenceCol1;
        this.targetCol2 = this.ui.essenceCol2;
    
        window.addEventListener('resize', () => {
            this.repositionElements();
        });
    }
    
    repositionElements() {
        let centerX = window.innerWidth / 2;
        let centerY = window.innerHeight / 3;
    
        // Only reposition essence cells, not the rebirth button
        this.essenceUpgrades.forEach(upgrade => {
            const upgradeID = `eUpgrade-${upgrade.id}`;
            let essenceCell = document.getElementById(upgradeID);
    
            if (essenceCell) {
                let angleInRadians = upgrade.angleFromParent * (Math.PI / 180);
                let topPosition = centerY + upgrade.distanceFromParent * Math.sin(angleInRadians);
                let leftPosition = centerX + upgrade.distanceFromParent * Math.cos(angleInRadians);
    
                // Check if parent exists and adjust accordingly
                if (upgrade.parent) {
                    let parentCell = document.getElementById(`eUpgrade-${upgrade.parent.id}`);
                    if (parentCell) {
                        topPosition = parseInt(parentCell.style.top, 10) + upgrade.distanceFromParent * Math.sin(angleInRadians);
                        leftPosition = parseInt(parentCell.style.left, 10) + upgrade.distanceFromParent * Math.cos(angleInRadians);
                    }
                }
    
                essenceCell.style.position = 'absolute';
                essenceCell.style.top = `${topPosition}px`;
                essenceCell.style.left = `${leftPosition}px`;
            }
        });
    }
    
    

    populateDisplay(){
        let initialX = window.innerWidth / 2;
        let initialY = window.innerHeight / 3;

		//set overall essence stats/multiplier
        let rebirth1Button = this.ui.createElement('button','rebirth1Button','rebirth-button','Rebirth');
        rebirth1Button.disabled = true;
        
        const rebirth1ButtonClickHandler = () => {
            if (this.gameContent.rebirth1Count.eq(0)){
                const confirmation = window.confirm("Are you sure you want to proceed with Rebirth? This action cannot be undone.  Read the info tooltip if you want to see what you retain and what you will lose.");
                if (confirmation) {
                    this.ui.stateManager.saveState(1);
                }
            }
            else {
                this.ui.stateManager.saveState(1);
            }
        };
        this.eventManager.addDomListener(rebirth1Button, 'click', rebirth1ButtonClickHandler);
        
        this.rebirth1Button = rebirth1Button;
        this.targetCol1.appendChild(this.rebirth1Button);

        let essenceStats = this.ui.createElement('div',null,'essence-stats');
        this.targetCol2.appendChild(essenceStats);

		//init essence cells container
        //assign tab color
        this.targetCol1.classList.add('essence-color');

        let essenceGrid = this.ui.createElement('div',`essence-grid`);
        this.targetCol1.appendChild(essenceGrid);
		

		this.essenceUpgrades.forEach(upgrade => {
			const upgradeID = `eUpgrade-${upgrade.id}`;
			let essenceCell = this.targetCol1.querySelector(`#${upgradeID}`);

			//set essence cells
            essenceCell = this.ui.createElement('button',upgradeID,'essence-cell');
            const essenceCellClickHandler = () => {
                this.ui.buyFeature(upgrade.id);
                upgrade.activateChildren();
            };
            this.eventManager.addDomListener(essenceCell, 'click', essenceCellClickHandler);
            
            
            this.ui.populateMouseOverZIndexEvents(essenceCell);

            let topPosition = initialY;
            let leftPosition = initialX;
            // calculate the position of the upgradeCell
            if (upgrade.parent){
                let angleInRadians = upgrade.angleFromParent * (Math.PI / 180);
                topPosition = upgrade.parent.y + upgrade.distanceFromParent * Math.sin(angleInRadians);
                leftPosition = upgrade.parent.x + upgrade.distanceFromParent * Math.cos(angleInRadians);
            }

            essenceCell.style.position = 'absolute';
            essenceCell.style.top = `${topPosition}px`;
            essenceCell.style.left = `${leftPosition}px`;

            upgrade.y = topPosition;
            upgrade.x = leftPosition;

            //Add connecting lines
            if (upgrade.parent) {
                // Calculate the line's width (the distance between the cells) and angle
                let dx = upgrade.x - upgrade.parent.x;
                let dy = upgrade.y - upgrade.parent.y;
                let lineLength = Math.sqrt(dx*dx + dy*dy);
                let angle = Math.atan2(dy, dx) * 180 / Math.PI - 180;
                
                // Create line div and apply the calculated width and angle
                let line = this.ui.createElement('div',null,'essence-line');
                line.style.width = lineLength + 'px';
                line.style.transform = `rotate(${angle}deg)`;
        
                // Append the line to the upgrade cell
                essenceCell.appendChild(line);
            }
            
            let essenceData = this.ui.createElement('div',`essence-data-${upgradeID}`);
            essenceCell.appendChild(essenceData);

            this.ui.populateTooltip(upgrade,essenceCell);

            essenceGrid.appendChild(essenceCell);
		});
    }

    updateDisplay(){
        let essenceStats = document.querySelector('.essence-stats');
        let essenceGrid = this.targetCol1.querySelector(`#essence-grid`);
    
        // Update overall essence stats/multiplier
        let essenceGain = this.gameContent.essenceGainOnNextRebirth1;
        let rebirthMultGain = essenceGain;
    
        let essencePseudoObject = this.ui.gameManager.findObjectById(60000);
    
        // Update essence stats
        let newEssenceStatsText =  `
        Total Essence Earned: ${this.ui.formatNumber(this.gameContent.lifetimeEssenceEarned)}<br>
        Essence Multiplier: ${this.ui.formatNumber(essencePseudoObject.level)}<br>
        Essence Gain on next Rebirth: ${this.ui.formatNumber(essenceGain)}<br>
        Multiplier after rebirth: ${this.ui.formatNumber(rebirthMultGain.plus(essencePseudoObject.level))}`;
    
        this.ui.updateElementHTML(essenceStats, newEssenceStatsText);
    
        this.essenceUpgrades.forEach(upgrade => {
            const upgradeID = `eUpgrade-${upgrade.id}`;
            let essenceCell = this.targetCol1.querySelector(`#${upgradeID}`);
            let essenceData = essenceCell.querySelector(`#essence-data-${upgradeID}`);
    
            this.ui.updateTooltip(upgrade);
    
            let newEssenceDataText;
            if (upgrade.level.eq(upgrade.maxLevel)){
                newEssenceDataText = `${upgrade.name}\nlvl ${upgrade.level}/${upgrade.maxLevel}`;
            } else {
                newEssenceDataText = `${upgrade.name}\nlvl ${upgrade.level}/${upgrade.maxLevel}\nCost: ${this.ui.formatNumber(upgrade.costNextMultPurchase)}`;
            }
            this.ui.updateElementTextContent(essenceData, newEssenceDataText);
    
            // Update button state and classes only if necessary
            let currentClasses = essenceCell.className.split(' ');
            let hasEnabled = currentClasses.includes('enabled');
            let hasDisabled = currentClasses.includes('disabled');
            let hasComplete = currentClasses.includes('complete');
            let hasIncompleteDisabled = currentClasses.includes('incomplete-disabled');
    
            if (upgrade.level.lt(upgrade.maxLevel) && upgrade.level.gt(0) && !this.ui.isAffordable(upgrade)) {
                if (!hasIncompleteDisabled) {
                    essenceCell.disabled = true;
                    essenceCell.classList.add('incomplete-disabled');
                    essenceCell.classList.remove('enabled');
                }
            } else if (upgrade.active && upgrade.level.neq(upgrade.maxLevel) && this.ui.isAffordable(upgrade) && upgrade.nextLevelIncrement.gt(0)) {
                if (!hasEnabled) {
                    essenceCell.disabled = false;
                    essenceCell.classList.add('enabled');
                    essenceCell.classList.remove('disabled', 'incomplete-disabled');
                }
            } else if (upgrade.level.eq(upgrade.maxLevel)) {
                if (!hasComplete) {
                    essenceCell.disabled = true;
                    essenceCell.classList.add('complete');
                    essenceCell.classList.remove('enabled', 'incomplete-disabled');
                }
            } else {
                if (!hasDisabled) {
                    essenceCell.disabled = true;
                    essenceCell.classList.add('disabled');
                    essenceCell.classList.remove('enabled', 'incomplete-disabled');
                }
            }
        });
    }
    
}