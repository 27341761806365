export default class SkillTree {
	constructor(eventManager) {
		this.eventManager = eventManager;
		this.skills = [];
	}

	refundAllSkills(){
		for(const skill of this.skills){
			if (skill.level.gt(0)){
			skill.refundSkill();
			}
		}
	}
}