export default class EssenceUpgradesState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "essenceUpgrades";
        
        this.propertiesBase = ['baseLevel'];

        this.regularPropertiesFull = ['level', 'manualLevel', 'autoLevel',
		'active', 'prodCurrentGlobal', 'prodPrevious','prodNextMultPurchase','prodNextSingle','prodMult', 'costNextMultPurchase','costNextSingle','costMult','maxAffLvl',  'evolutionTier'];

        this.propertiesFull = this.propertiesBase.concat(this.regularPropertiesFull);
    }

    saveState(state, gameData){
        this.populateSaveDataBase();

        switch (state) {
			case 0:
				this.populateSaveDataFull(gameData);
				break;
			case 1:
				this.populateSaveDataRebirth1(gameData);
				break;
			case 2:
				this.populateSaveDataRebirth2();
				break;
			case 3:
				this.populateSaveDataRebirth3();
				break;
		}
    }
    
    loadState(state, gameData){
        this.applyLoadDataBase(gameData);

        switch (state) {
			case 0:
				this.applyLoadDataFull(gameData);
				break;
			case 1:
				this.applyLoadDataRebirth1(gameData);
				break;
			case 2:
				this.applyLoadDataRebirth2();
				break;
			case 3:
				this.applyLoadDataRebirth3();
				break;
		}
    }

    populateSaveDataBase(){}


    populateSaveDataFull() {
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
    }

    populateSaveDataRebirth1(){
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
	}
    populateSaveDataRebirth2(){}
    populateSaveDataRebirth3(){}

    

    applyLoadDataBase(gameData){}

    applyLoadDataFull(gameData) {
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);
        // this.reApplyEssenceeUpgrades(gameData);
    }
    
    applyLoadDataRebirth1(gameData){
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);
        // this.reApplyEssenceeUpgrades(gameData);
	}
    applyLoadDataRebirth2(){}
    applyLoadDataRebirth3(){}

	

    // Don't need this after implementing ModState?
    // as they propogate the ModTrees and update multipliers automaticall
    reApplyEssenceeUpgrades(gameData){
        gameData.essenceUpgrades.forEach(data => {
			for (const essenceUpgrade of this.gameManager.gameContent.essenceUpgrades )
			if (data.id === essenceUpgrade.id) {
				if (data.active){
					essenceUpgrade.setActive();
					essenceUpgrade.updateObservers();
				}
			}
		});
    }
}