import { GAME_VERSION } from '../../Utilities/config.js';

import Admin from '../../Core/Admin.js';

export default class SettingsTab{
    constructor(eventManager, ui, gameContent, tabManager){
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.tabManager = tabManager;

		this.admin = new Admin(eventManager,this.ui.gameManager,this);
    }

	populateDisplay(){
		let settingsTabContent = document.getElementById('settings');
		let settingsTitle = this.ui.createElement('h3', 'settings-title',null, 'Settings');
		let settingsTabContainer = document.getElementById('settings-tab-container');

		let settingsStateButtons = document.getElementById('settings-state-buttons');
		// let rewardContainer = document.getElementById('reward-container');

		// Populate initial elements
        let col1 = document.getElementById('settings-col1');
        let col2 = document.getElementById('settings-col2');

        settingsStateButtons = this.populateSettingsStateButtons();
        let settingsHotkeyList = this.populateSettingsHotkeyToggle();
        let settingsGameContentStats = this.ui.createElement('div',"settings-stats");

        // rewardContainer = this.populateAndUpdateRewardsContainer();

        let gameVersionText = this.ui.createElement('div',null,'game-version-text',`Version ${GAME_VERSION} (beta)`);

        //SETTINGS
        let tooltipSettingsToggle = this.populateSettingsTooltipToggle();
        let notificationSettingsToggle = this.populateSettingsNotificationsToggle();
        let hotkeySettingsToggle = this.populateSettingsHotkeysToggle();
        let numberNotationSettings = this.populateSettingsNumberNotation();
		let playerNameContainer = this.populatePlayerNameContainer();
		let discordLink = this.ui.createElement('button', 'discord-link', null, 'Discord');

		// Set the link URL
		let linkHtml = 'https://discord.gg/vQJ4BgkZwd';

		// Add event listener to open the link in a new tab
		discordLink.addEventListener('click', function() {
			window.open(linkHtml, '_blank');
		});


        // col1.appendChild(rewardContainer);


		let adminContainer = this.ui.createElement('div', 'admin-container', 'admin-container');
       	// Append Admin Settings Buttons
		if (this.ui.injectAdminSettings) {
			adminContainer = this.populateAdminSettings(adminContainer);
		}
		// Create Full Reset button
		let resetButton = this.ui.createElement('button', 'resetButton', ['admin-uber-button', 'admin-button'], 'Full Reset');
		// Define the handler for the Full Reset button
		let resetButtonHandler = () => {
			let confirmReset = confirm("This will wipe all game data and delete the save file. Are you sure you want to proceed?");
			if (confirmReset) {
				this.admin.resetGame(); // Proceed with the game reset if the user confirms
			}
		};
		// Add the click event listener with the confirmation
		this.eventManager.addDomListener(resetButton, 'click', resetButtonHandler);
		adminContainer.appendChild(resetButton);

		
		col1.appendChild(settingsTitle);
		col1.appendChild(settingsStateButtons);
		col1.appendChild(playerNameContainer);
		col1.appendChild(discordLink);
		col1.appendChild(settingsHotkeyList);
		col1.appendChild(numberNotationSettings);
		col1.appendChild(tooltipSettingsToggle);
		col1.appendChild(notificationSettingsToggle);
		col1.appendChild(hotkeySettingsToggle);
		col1.appendChild(adminContainer);



        col2.appendChild(settingsGameContentStats);
        
        settingsTabContainer.appendChild(col1);
        settingsTabContainer.appendChild(col2);
        col2.appendChild(gameVersionText);
		
		
		// Update Elements
		// rewardContainer = this.populateAndUpdateRewardsContainer();
    }

    updateDisplay(){
		const options = {
			timeZone: 'America/Chicago',
			timeZoneName: 'short'
		};
		
		const formattedDate = new Date(this.ui.stateManager.lastSaveTime).toLocaleString('en-US', options);
		const outputString = `${formattedDate}`;
		
		
		let settingsGameContentStats = document.getElementById('settings-stats');
        settingsGameContentStats.innerHTML = `
		<b>Lifetime Stats:</b>
		\r\nMax PowerLevel Achieved: ${this.ui.formatNumber(this.gameContent.maxPowerLevelAchieved)}
		\r\nLifetime Force Earned: ${this.ui.formatNumber(this.gameContent.lifetimeForceEarned)}
		\rLifetime Wisdom Earned: ${this.ui.formatNumber(this.gameContent.lifetimeWisdomEarned)}
		\nLifetime Energy Earned: ${this.ui.formatNumber(this.gameContent.lifetimeEnergyEarned)}
		\nLifetime Divine Earned: ${this.ui.formatNumber(this.gameContent.lifetimeDivineEarned)}
		\nLifetime Essence Earned: ${this.ui.formatNumber(this.gameContent.lifetimeEssenceEarned)}
		\nLifetime Crystal Earned: ${this.ui.formatNumber(this.gameContent.lifetimeCrystalEarned)}
		\nMax World Achieved: ${this.ui.formatNumber(this.gameContent.maxProgressionWorld)}
		\nMax Region Achieved: ${this.ui.formatNumber(this.gameContent.maxProgressionRegion)}
		\nLifetime Zone Completions: ${this.ui.formatNumber(this.gameContent.lifetimeZoneCompletions)}
		\nLifetime Region Progressions: ${this.ui.formatNumber(this.gameContent.lifetimeRegionProgressions)}
		\nLifetime World Progressions: ${this.ui.formatNumber(this.gameContent.lifetimeWorldProgressions)}
		\nMax Tournament Rank Achieved:${this.ui.formatNumber(this.gameContent.maxTournamentRank)}
		\nLifetime Kills: ${this.ui.formatNumber(this.gameContent.lifetimeKills)}
		\nLifetime FighterTiers Defeated: ${this.ui.formatNumber(this.gameContent.lifetimeFighterTiersDefeated)}
		\nTotal Rebirth 1's: ${this.ui.formatNumber(this.gameContent.rebirth1Count)}
		\nTotal Playtime: ${this.ui.formatTime(this.gameContent.totalPlaytime.mag)}
		\nStarted Playing: ${this.gameContent.originalStartDateTime.toLocaleString()}
		
		\nLast Save: ${outputString}`;

		let playerNameDisplay = document.getElementById('player-name-display');
		this.ui.updateElementHTML(playerNameDisplay,this.gameContent.playerName);
		
    }

	populatePlayerNameContainer() {
		let playerNameContainer = this.ui.createElement('div', 'player-name-container');
	
		let label = this.ui.createElement('span', 'player-name-label', null, 'Player Name:');
		label.style.marginRight = '10px';
	
		let playerNameDisplay = this.ui.createElement('span', 'player-name-display', null, this.gameContent.playerName);
		let editIcon = this.ui.createElement('span', 'edit-icon', null, '✎'); // Simple edit icon
		editIcon.style.cursor = 'pointer';
		editIcon.style.marginLeft = '10px';
	
		let playerNameInput = this.ui.createElement('input', 'player-name-input');
		playerNameInput.type = 'text';
		playerNameInput.value = this.gameContent.playerName;
		playerNameInput.style.display = 'none';
		playerNameInput.style.marginLeft = '10px';
	
		let saveButton = this.ui.createElement('button', 'save-name-button', null, 'Save');
		saveButton.style.display = 'none';
		saveButton.style.marginLeft = '10px';
	
		// Add event listener for edit icon
		this.eventManager.addDomListener(editIcon, 'click', () => {
			playerNameInput.value = this.gameContent.playerName; // Update the input value with the current player name
			playerNameDisplay.style.display = 'none';
			editIcon.style.display = 'none';
			playerNameInput.style.display = 'inline-block';
			saveButton.style.display = 'inline-block';
		});
	
		// Add event listener for save button
		this.eventManager.addDomListener(saveButton, 'click', () => {
			this.gameContent.playerName = playerNameInput.value;
			playerNameDisplay.textContent = this.gameContent.playerName;
			playerNameDisplay.style.display = 'inline-block';
			editIcon.style.display = 'inline-block';
			playerNameInput.style.display = 'none';
			saveButton.style.display = 'none';
		});
	
		playerNameContainer.appendChild(label);
		playerNameContainer.appendChild(playerNameDisplay);
		playerNameContainer.appendChild(editIcon);
		playerNameContainer.appendChild(playerNameInput);
		playerNameContainer.appendChild(saveButton);
	
		return playerNameContainer;
	}
	

	
    populateSettingsStateButtons(){
		let settingsStateButtons = this.ui.createElement('div', 'settings-state-buttons');
		let saveButton = this.ui.createElement('button','save','state-button','Save');
		let loadButton = this.ui.createElement('button','load','state-button','Load');
		let exportButton = this.ui.createElement('button','export','state-button','Export');

		let importButton = this.ui.createElement('button','import','state-button','Import');
		// Create the textarea element
		var importArea = document.createElement("textarea");
		// Set the attributes for the textarea
		importArea.id = "importInput";
		importArea.rows = "5";
		importArea.cols = "30";
		importArea.placeholder = "Paste Import String Here";


		

		this.saveButtonHandler = () => {
			this.ui.stateManager.saveState(0);
			// saveButton.innerHTML = "Game Saved!";
			// saveButton.classList.add('fade');
			// setTimeout(function () {
			// 	saveButton.classList.remove('fade');
			// 	saveButton.innerHTML = "Save";
			// }, 1000);
		};

		this.loadButtonHandler = () => {
			this.eventManager.dispatchEvent('restart', 0);
			// loadButton.innerHTML = "Game Loaded!";
			// loadButton.classList.add('fade');
			// setTimeout(function () {
			// 	loadButton.classList.remove('fade');
			// 	loadButton.innerHTML = "Load";
			// }, 1000);
		};

		// Assuming this.eventManager is accessible

		// For saveButton
		this.eventManager.addDomListener(saveButton, 'click', this.saveButtonHandler.bind(this));

		// For loadButton
		this.eventManager.addDomListener(loadButton, 'click', this.loadButtonHandler.bind(this));

		const exportButtonHandler = () => {
			this.ui.stateManager.exportGameState();
		};
	
		const importButtonHandler = () => {
			const importedData = document.getElementById('importInput').value;
			this.ui.stateManager.importGameState(importedData);
		};
	
		// Use EventManager to add event listeners
		this.eventManager.addDomListener(exportButton, 'click', exportButtonHandler);
		this.eventManager.addDomListener(importButton, 'click', importButtonHandler);


		settingsStateButtons.appendChild(saveButton);
		settingsStateButtons.appendChild(loadButton);
		settingsStateButtons.appendChild(exportButton);
		settingsStateButtons.appendChild(importButton);
		settingsStateButtons.appendChild(importArea);

		return settingsStateButtons;
	}

	populateSettingsNumberNotation(){
		let numberSettingsContainer = this.ui.createElement('div','number-settings-container');

		let numberSettingsTitle = this.ui.createElement("div",'number-settings-title');
		numberSettingsTitle.textContent = 'Notation:';

		let numberSettings = this.ui.createElement("select","numberSettings");
		
		let options =  [ "scientific","engineering","log10", "string","letter","cute"];
		options.forEach(option => {
			let opt = document.createElement('option');
			opt.value = option;
			opt.innerText = option;
			numberSettings.appendChild(opt);
		});

		this.numberSettings = numberSettings;

		// Creating a bound function for the change event
		const numberSettingsChangeHandler = this.updateNumberNotation.bind(this);

		// Use EventManager to add the event listener
		this.eventManager.addDomListener(numberSettings, 'change', numberSettingsChangeHandler);

		// Optionally store the listener for future removal
		numberSettings.changeListener = numberSettingsChangeHandler;

		numberSettingsContainer.appendChild(numberSettingsTitle);
		numberSettingsContainer.appendChild(numberSettings);
	
		return numberSettingsContainer;
	}

	updateNumberNotation(event) {
		let value = event.target.value;
		this.eventManager.dispatchEvent('updateNumberNotation', { value: value });
	}

	populateSettingsHotkeyToggle() {
		let settingsHotkeyToggle = this.ui.createElement('div', "settings-hotkeys");
		settingsHotkeyToggle.innerHTML = this.ui.hotkeyManager.populateHotkeyListDisplay();
		return settingsHotkeyToggle;
	}

	populateSettingsHotkeysToggle() {
		let hotkeysContainer = this.ui.createElement('div', 'hotkeys-settings-container');
		let hotkeysTitle = this.ui.createElement('div', null, null, "Toggle Hotkeys");
		let hotkeysCheckBox = document.createElement('div');
		let checkbox = this.ui.createElement('input', `checkbox-hotkeys`);
		checkbox.type = 'checkbox';
		checkbox.style.display = 'none';
		checkbox.checked = true;
	
		const hotkeysCheckboxHandler = () => {
			if (checkbox.checked) {
				this.eventManager.dispatchEvent('hotkeyEnabled');
			} else {
				this.eventManager.dispatchEvent('hotkeyDisabled');
			}
		};
	
		// Use EventManager to add the event listener
		this.eventManager.addDomListener(checkbox, 'change', hotkeysCheckboxHandler);
	
		// Optionally store the listener for future removal
		checkbox.changeListener = hotkeysCheckboxHandler;
	
		let label = this.ui.createElement('label', `label-hotkeys`);
		label.htmlFor = checkbox.id;
	
		hotkeysCheckBox.appendChild(checkbox);
		hotkeysCheckBox.appendChild(label);
	
		hotkeysContainer.appendChild(hotkeysTitle);
		hotkeysContainer.appendChild(hotkeysCheckBox);
	
		return hotkeysContainer;
	}

    populateSettingsTooltipToggle(){
		let tooltipContainer = this.ui.createElement('div','tooltips-settings-container');

		let tooltipTitle = this.ui.createElement('div',null,null,"Toggle Advanced Training Tooltips");

		let tooltipCheckBox = document.createElement('div');
		let checkbox = this.ui.createElement('input',`checkbox-tooltips`);
		checkbox.type = 'checkbox';
		checkbox.style.display = 'none';
		checkbox.checked = false; 
		
		// Named function for the change event
		const tooltipCheckboxHandler = () => {
			if (checkbox.checked) {
				document.querySelector('#training').classList.remove('tooltips-off');
			} else {
				document.querySelector('#training').classList.add('tooltips-off');
			}
		};

		// Use EventManager to add the event listener
		this.eventManager.addDomListener(checkbox, 'change', tooltipCheckboxHandler);

		// Optionally store the listener for future removal
		checkbox.changeListener = tooltipCheckboxHandler;

		let label = this.ui.createElement('label',`label-tooltips`);
		label.htmlFor = checkbox.id;

		tooltipCheckBox.appendChild(checkbox);
		tooltipCheckBox.appendChild(label);
		tooltipContainer.appendChild(tooltipTitle);
		tooltipContainer.appendChild(tooltipCheckBox);
		
		return tooltipContainer;
	}

    populateSettingsNotificationsToggle(){
		let notificationToggleContainer = this.ui.createElement('div','notifications-settings-container');

		let notificationToggleTitle = this.ui.createElement('div',null,null,"Toggle Notifications");

		let notificationToggleCheckBox = document.createElement('div');
		let checkbox = this.ui.createElement('input',`checkbox-notifications`);
		checkbox.type = 'checkbox';
		checkbox.style.display = 'none';
		checkbox.checked = true; 

		const notificationsCheckboxHandler = () => {
			if (checkbox.checked) {
				this.ui.gameManager.notificationsToggled = true;
			} else {
				this.ui.gameManager.notificationsToggled = false;
			}
		};

		// Use EventManager to add the event listener
		this.eventManager.addDomListener(checkbox, 'change', notificationsCheckboxHandler);

		// Optionally store the listener for future removal
		checkbox.changeListener = notificationsCheckboxHandler;

		
		
		let label = this.ui.createElement('label',`label-notifications`);
		label.htmlFor = checkbox.id;

		notificationToggleCheckBox.appendChild(checkbox);
		notificationToggleCheckBox.appendChild(label);
		notificationToggleContainer.appendChild(notificationToggleTitle);
		notificationToggleContainer.appendChild(notificationToggleCheckBox);
		
		return notificationToggleContainer;
	}
	
	populateAdminSettings(adminContainer) {
		// let adminContainer = this.ui.createElement('div', 'admin-container', 'admin-container');
		
		let adminTitle = this.ui.createElement('div', "admin-title", null, "Admin Settings");
		adminContainer.appendChild(adminTitle);
	
		// Create a loop to handle similar buttons
		for (let i = 0; i <= 10; i++) {
			let adminButton = this.ui.createElement('button', `adminButton${i}`, 'admin-button', this.getAdminButtonLabel(i));
			let adminButtonHandler = this.admin.progressState.bind(this.admin, i);
			this.eventManager.addDomListener(adminButton, 'click', adminButtonHandler);
			adminContainer.appendChild(adminButton);
		}
	
		// Create Uber Buttons
		let adminButton30 = this.ui.createElement('button', 'adminButton30', ['admin-uber-button', 'admin-button'], 'Complete All Unlocks');
		let adminButton30Handler = this.admin.completeAllUnlocks.bind(this.admin);
		this.eventManager.addDomListener(adminButton30, 'click', adminButton30Handler);
		adminContainer.appendChild(adminButton30);
	
	
		return adminContainer;
	}
	
	// Helper function to get labels based on the button index
	getAdminButtonLabel(index) {
		const labels = [
			'Currencies * 1e10',
			'Force Train/Up x40',
			'Force/Wisdom Train/Up x100',
			'Region 1 Complete',
			'World 1 Complete',
			'Fighter Tier 1 Complete',
			'Initial Forge Upgrades',
			'Initial Forge Automations',
			'Complete Next World',
			'Complete Next Fighter Tier',
			'Give 10k essence'
		];
		return labels[index] || 'Button';
	}
	

    populateAndUpdateRewardsContainer(){
		// Add a new container for the rewards
		let rewardContainer = document.getElementById('reward-cantainer');
		if (!rewardContainer){

			
			// rewardContainer = this.ui.createElement('div', 'reward-container');

			// Create elements for daily reward
			// let dailyRewardContainer = this.ui.createElement('div', 'dailyRewardContainer');
			// let dailyRewardTitle = this.ui.createElement('div', null, null, 'Daily Reward');
			// let dailyRewardTimer = this.ui.createElement('div', 'dailyRewardTimer');
			// let dailyRewardButton = this.ui.createElement('button', 'dailyRewardButton', null, 'Claim Daily Reward');
			// dailyRewardButton.onclick = () => this.rewardManager.giveDailyReward();
			
			// // Create elements for hourly reward
			// let hourlyRewardContainer = this.ui.createElement('div', 'hourlyRewardContainer');
			// let hourlyRewardTitle = this.ui.createElement('div', 'hourlyRewardTitle', null, 'Hourly Reward');
			// let hourlyRewardTimer = this.ui.createElement('div', 'hourlyRewardTimer');
			// let hourlyRewardButton = this.ui.createElement('button', 'hourlyRewardButton', null, 'Claim Hourly Reward');
			// hourlyRewardButton.onclick = () => this.rewardManager.giveHourlyReward();


			// dailyRewardContainer.appendChild(dailyRewardTitle);
			// dailyRewardContainer.appendChild(dailyRewardTimer);
			// dailyRewardContainer.appendChild(dailyRewardButton);
			// hourlyRewardContainer.appendChild(hourlyRewardTitle);
			// hourlyRewardContainer.appendChild(hourlyRewardTimer);
			// hourlyRewardContainer.appendChild(hourlyRewardButton);
			// rewardContainer.appendChild(dailyRewardContainer);
			// rewardContainer.appendChild(hourlyRewardContainer);
		}

		


		// // Check and display the time until the next daily reward
		// let dailyRewardTimeLeft = this.rewardManager.checkDailyReward();
		// let dailyRewardTimer = document.getElementById('dailyRewardTimer');
		// dailyRewardTimer.innerText = this.formatTime(dailyRewardTimeLeft);
		// let dailyRewardButton = document.getElementById('dailyRewardButton');
		// //disable button if not claimable
		// dailyRewardButton.disabled = !this.rewardManager.dailyRewardClaimable;
	
		// // hourly reward info
		// let hourlyRewardTitle = document.getElementById(`hourlyRewardTitle`);
		// hourlyRewardTitle.innerText = `Hourly Reward (${this.rewardManager.currentHourlyRewardsClaimable}/${this.rewardManager.hourlyRewardCap})`;
		// hourlyRewardTitle.id = "hourlyRewardTitle";

		// // Check and display the time until the next hourly reward
		// let hourlyRewardTimeLeft = this.rewardManager.checkHourlyReward();
		// let hourlyRewardTimer = document.getElementById('hourlyRewardTimer');
		// hourlyRewardTimer.innerText = this.formatTime(hourlyRewardTimeLeft);
		// let hourlyRewardButton = document.getElementById('hourlyRewardButton');
		// //disable button if not claimable
		// hourlyRewardButton.disabled = !this.rewardManager.hourlyRewardClaimable;
	
		// // Display the current amount of hourly rewards that are claimable on the claim button
		// let currentHourlyRewards = this.rewardManager.currentHourlyRewardsClaimable;
		// if (currentHourlyRewards.gt(0)) {
		// 	hourlyRewardButton.innerText = `Claim Hourly Reward (${currentHourlyRewards.toString()})`;
		// } else {
		// 	hourlyRewardButton.innerText = 'Claim Hourly Reward';
		// }
	}
}