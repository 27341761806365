export default class Unlock {
	constructor(id, category, type = null, dependentID, targetID, conditionType, conditionValue, triggerType, triggerValue, triggerValue2 = null, triggerValue3 = null, triggerValues = null) {
		this.id = id;
		
		this.dependentID = dependentID;
		this.dependent = null;
		this.targetID = targetID;
		this.target = null;

		this.category = category;
		this.type = type;

		this.conditionType = conditionType;
		this.conditionValue = conditionValue;

		this.triggerType = triggerType;
		this.triggerValue = triggerValue;

		this.triggerValue2 = triggerValue2;
		this.triggerValue3 = triggerValue3;

		this.triggerValues = triggerValues;
	}
}