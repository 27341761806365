import WorldDisplayManager from './WorldDisplayManager';
import RegionDisplayManager from './RegionDisplayManager';
import ProgressBarManager from './ProgressBarManager';
import MapViewManager from './MapViewManager';
import ZoneDisplayManager from './ZoneDisplayManager';

export default class OdysseySubTab {
    constructor(eventManager, ui, gameContent) {
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.worldManager = this.gameContent.worldManager;

        this.regionDisplayManager = new RegionDisplayManager(eventManager, ui, gameContent);
        this.worldDisplayManager = new WorldDisplayManager(eventManager, ui, gameContent, this.regionDisplayManager);
        this.zoneDisplayManager = new ZoneDisplayManager(eventManager, ui, gameContent);
        this.mapViewManager = new MapViewManager(eventManager, ui, gameContent, this.worldDisplayManager);
        this.progressBarManager = new ProgressBarManager(ui, gameContent, this.worldDisplayManager);

        this.regionDisplayManager.mapViewManager = this.mapViewManager;
        this.regionDisplayManager.zoneDisplayManager = this.zoneDisplayManager;

        this.debugExpandedOdysseyView = this.ui.injectAdminSettings;

        this.legendContainer = null;
    }

    populateDisplay() {
        let odysseySidebarCol = document.getElementById(`tab-col1-OdysseySubTab`);
        let odysseyMainWindowCol = document.getElementById(`tab-col2-OdysseySubTab`);
        let odysseyContainer = document.getElementById(`tab-content-OdysseySubTab`);

        this.worldDisplayManager.populateWorldTabButtons(odysseySidebarCol);
        this.progressBarManager.createProgressBars(odysseyContainer);
        this.mapViewManager.initializeMapView(odysseyMainWindowCol);

        this.worldManager.worlds.forEach((world, index) => {
            this.worldDisplayManager.updateWorldTabButtons(world);
            this.worldDisplayManager.populateWorld(world, index);
            if (world.currentlyViewing) {
                this.updateWorld(world);
            }
        });

        let conquestTimeModifier = this.gameContent.conquestTimeModifier;
        let conquestTimeDisplay = this.ui.createElement('div', 'conquest-time-display', 'conquest-time-display', `Conquest Time: ${conquestTimeModifier}`);
        odysseySidebarCol.appendChild(conquestTimeDisplay);

        let odysseyCurrView = document.querySelector('#odyssey-current-view');
        this.legendContainer = this.createLegend();
        odysseySidebarCol.appendChild(this.legendContainer);

        if (this.debugExpandedOdysseyView){
            const debugButton = this.ui.createElement('button', 'odyssey-map-expander-button', 'odyssey-map-expander-button', 'Toggle Expand Map View (Works but breaks)');
            const debugButtonClickHandler = this.toggleDebugExpandedView.bind(this);
            this.eventManager.addDomListener(debugButton, 'click', debugButtonClickHandler);
            
            odysseySidebarCol.appendChild(debugButton);
        }

        this.mapViewManager.setInitialView();
    }

    updateDisplay() {
        if (!this.debugExpanded){
            this.progressBarManager.updateProgressBars();
            this.worldManager.worlds.forEach((world) => {
                this.worldDisplayManager.updateWorldTabButtons(world);
                if (world.currentlyViewing) {
                    this.updateWorld(world);
                }
            });
            this.mapViewManager.miniMap.updateMiniMapProgress(); // Add this line to update the mini-map progress

            

            let conquestTimeDisplay = document.querySelector('#conquest-time-display');
            conquestTimeDisplay.textContent = `Speed: ${Number(this.gameContent.conquestTimeModifier).toFixed(2)}x`;

        }
    }
    
    
    createLegend() {
        const legendContainer = this.ui.createElement('div', 'odyssey-legend-container', 'odyssey-legend-collapsed');
        const legendToggle = this.ui.createElement('div', 'odyssey-legend-toggle', null, 'Legend ▼');
        const legendContent = this.ui.createElement('div', 'odyssey-legend-content');
    
        const legendItems = [
            { label: 'Boss - Awards skillpoints', className: 'boss-zone', color: 'orange' },
            { label: 'Unlock - Awards mechanic unlocks', className: 'progression-boss-zone', color: 'yellow' },
            { label: 'Legendary Boss - Special Requirements - Special Rewards', className: 'legendary-boss-zone', color: 'cyan' },
            { label: 'Completed Zone', className: 'completed-zone', color: 'green' },
            { label: 'Unlocked Zone', className: 'unlocked-zone', color: 'rgb(21, 97, 122)' },
            { label: 'Locked Zone', className: 'locked-zone', color: 'gray' },
            { label: 'Region Navigation', shortLabel: 'R', className: 'region-navigation', color: 'white', background: '#2c7d72', isSquare: true },
            { label: 'World Navigation', shortLabel: 'W', className: 'world-navigation', color: 'white', background: '#57cf63', isSquare: true }
        ];
    
        legendItems.forEach(item => {
            const legendItem = this.ui.createElement('div', null, 'odyssey-legend-item');
            const legendColor = this.ui.createElement('div', null, ['odyssey-legend-color', item.isSquare ? 'square' : 'circle']);
    
            legendColor.style.backgroundColor = item.background || item.color;
            if (item.isSquare) {
                legendColor.textContent = item.shortLabel;
            }
    
            const legendLabel = this.ui.createElement('div', null, 'odyssey-legend-label', item.label);
    
            legendItem.appendChild(legendColor);
            legendItem.appendChild(legendLabel);
            legendContent.appendChild(legendItem);
        });
    
        legendToggle.addEventListener('click', () => {
            if (legendContent.classList.contains('expanded')) {
                legendContent.classList.remove('expanded');
                legendToggle.textContent = "Legend ▼";
                legendContainer.classList.remove('odyssey-legend-expanded');
                legendContainer.classList.add('odyssey-legend-collapsed');
            } else {
                legendContent.classList.add('expanded');
                legendToggle.textContent = "Legend ▲";
                legendContainer.classList.remove('odyssey-legend-collapsed');
                legendContainer.classList.add('odyssey-legend-expanded');
            }
        });
    
        legendContent.classList.remove('expanded');
    
        legendContainer.appendChild(legendToggle);
        legendContainer.appendChild(legendContent);
        return legendContainer;
    }

    changeWorldTab(world) {
        this.eventManager.dispatchEvent('changeWorldMap', world);
        this.mapViewManager.updateMapView(world);
    }

    updateWorld(world) {
        if (world.regions) {
            this.regionDisplayManager.updateRegions(world.regions);
        }
    }

    toggleDebugExpandedView() {
        let mainMap = document.querySelector('#tab-col2-OdysseySubTab');

        if (this.debugExpanded) {
            mainMap.style.marginLeft = '10px';
            this.debugExpanded = false;
            this.populateDisplay();
        } else {
            mainMap.style.marginLeft = '-300px';
            this.worldDisplayManager.debugExpandAllRegions();
            this.debugExpanded = true;
            for (const region of this.worldDisplayManager.currentlyViewedWorld.regions) {
                for (const zone of region.zones) {
                    let dumbTooltip = document.getElementById(`tooltip-${zone.id}`);
                    if (dumbTooltip) {
                        dumbTooltip.remove();
                    }
                    this.ui.updateTooltip(zone);
                }
            }
        }
    }
}
