import { DEC } from '../Utilities/decimal.js';

import ModTree from '../Core/ModTree.js';

import Mod from '../Core/Mod.js';
import Unlock from '../Core/Unlock.js';
import PseudoFeature from '../Core/PseudoFeature.js';

import Realm from '../Features/Training/Realm.js';
import Training from '../Features/Training/Training.js';
import Generator from '../Features/Training/Generator.js';
import GeneratorChain from '../Features/Training/GeneratorChain.js';

import RealmUpgrade from '../Features/Upgrades/RealmUpgrade.js';
import ForgeUpgrade from '../Features/Upgrades/ForgeUpgrade.js';
import RadianceUpgrade from '../Features/Upgrades/RadianceUpgrade.js';
import EssenceUpgrade from '../Features/Upgrades/EssenceUpgrade.js';


// import Skill from '../Features/Skills/Skill.js';
// import SkillNode from '../Features/Skills/SkillNode.js';

import Artifact from '../Features/Odyssey/Artifact.js';
import Zone from '../Features/Odyssey/Zone.js';
import Region from '../Features/Odyssey/Region.js';
import World from '../Features/Odyssey/World.js';

import Fighter from '../Features/Tournament/Fighter.js';
import FighterTier from '../Features/Tournament/FighterTier.js';
import Tournament from '../Features/Tournament/Tournament.js';

import mapImages from '../../assets/maps/*.jpg';
import gameData from '../../assets/gameData/*.json';



import AchievementBuilder from './AchievementBuilder.js';
import SkillBuilder from './SkillBuilder.js';

export default class Builder {
	constructor(eventManager, gameManager) {
		this.eventManager = eventManager;
		this.gameManager = gameManager;

		//storage
		this.trainings = [];
		this.realmUpgrades = [];
		this.essenceUpgrades = [];
		this.forgeUpgrades = [];
		this.radianceUpgrades = [];
		this.realms = [];
		// this.skills = [];
		// this.achievements = [];
		// this.achievementSets = [];
		this.generatorChains = [];
		this.generators = [];
		this.worlds = [];
		this.regions = [];
		this.regions = [];
		this.zones = [];
		this.artifacts = [];

		this.tournament;
		this.fighterTiers = [];
		this.fighters = [];

		this.modsWaiting = [];
		this.mods = [];
		this.unlocks = new Map();
		this.modTrees = [];

		this.skillBuilder = new SkillBuilder(this);
		this.achievementBuilder = new AchievementBuilder(this);

		this.build();
		
		//testing
		//this.printTrainingInfo();
		//this.printUpgradeInfo();
		//this.printZoneInfo();
		//this.printUnlockInfo();
		// this.printWorldRegionZoneHeirarchy();
	}
	

	findObjectById(id) {
		let object = this.gameManager.gameContent.idToObjectMap.get(id);
	   
		return object;
	}
	
	addObjectToIdMap(id, object){
		if (this.gameManager.gameContent.idToObjectMap.has(id)) {
			console.error(`Duplicate ID found in idToObjectMap: ${id}`);
		}
		else{
			this.gameManager.gameContent.idToObjectMap.set(id, object);
		}
	}

	build(){
		this.initializeGameFeatures();
		this.populateFeatures();
		this.distributeModsAndReferences();
	}



	initializeGameFeatures() {
		this.initTrainings();
		this.initGenerators();
		this.initAllUpgradeTypes();
		this.initArtifacts();
		this.initRealms();
		this.initWorlds();
		this.initRegions();
		this.initZones();
		this.initTournament();
		this.initFighters();
		this.initFighterTiers();
		// this.initSkills();
		this.skillBuilder.initSkills();
		this.achievementBuilder.initAchievements();
	}

	populateFeatures(){
		this.pushZonesToRegions();
		this.pushRegionsToWorlds();
		this.dynamicallySetZoneCosts();
		this.pushTrainingsToRealms();
		this.pushUpgradesToRealms();
		this.pushGeneratorsToRealms();
		this.populateRanks();
		this.initRebirthModAndPseudoFeature();
		this.initHeadbandModsAndPseudoFeature();
		this.initUnlocks();
	}

	distributeModsAndReferences(){
		this.createModObjects();
		this.assignModPriorities();
		this.assignModReferences();
		this.assignUnlockReferences();
		this.assignEssenceUpgradeReferences();
		this.registerModsToSources();
		this.registerModObserversAndTrees();
		this.initModTrees();
	}

	initUnlocks() {
		this.initForceRealmUnlocks();
		this.initWisdomRealmUnlocks();
		this.initEnergyRealmUnlocks();
		this.initDivineRealmUnlocks();

		this.initForgeUpgradeUnlocks();

		this.initLegendaryBossUnlocks();

		this.initSkillTreeUnlocks();
		
		this.initHeadbandModUnlocks();
		this.initFighterTierUnlocks();

		this.initArtifactUnlocks();

		this.initTabUnlocks();
		this.initRebirthUnlocks();
		this.initTutorialUnlocks();

		//skillconnections last so that locked paths are not auto-added (this checks for existing unlocks)
		this.skillBuilder.initSkillConnectionUnlocks();
	}

	initAllUpgradeTypes() {
		this.initRealmUpgrades();
		this.initforgeUpgrades();
		this.initEssenceUpgrades();
		this.initRadianceUpgrades();
	}

	createGenerators(generatorData) {
		generatorData.forEach(data => {
			const { id, genChainID, evolutionTier, name, note, description, level, maxLevel = DEC(Infinity), costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, evolutions } = data;
			const generator = new Generator(this.eventManager, id, genChainID, evolutionTier, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, evolutions);
			this.generators.push(generator);
			this.gameManager.gameContent.generators.push(generator);
			this.addObjectToIdMap(id, generator);

			if (evolutions){
				evolutions.forEach(evolveData => {
					generator.evolutions.push(evolveData);
				});
			}

			//push generators to generatorchains
			const genChain = this.generatorChains.find(genChain => genChain.id === generator.genChainID);
			if (genChain) {
				genChain.generators.push(generator);
				generator.parentGenChain = genChain;
				generator.realmID = genChain.realmID;
			}
		});
	}

	pushGeneratorsToRealms() {
		this.generatorChains.forEach(chain => {
			const realm = this.realms.find(realm => realm.id === chain.realmID);
			if (realm) {
				realm.generatorChains.push(chain);
				chain.realm = realm; // Assign the realm reference to the generator's realm value
			} else {
				console.error(`No realm found with id ${chain.realmID} for generatorChain ${chain.name}`);
			}
		});
	}


	initLegendaryBossUnlocks(){
		// Direct unlock assignment to legendary bosses.  if boss doesnt exist, prints an error

		// each legendary boss unlocks an artifact from 5010-5120
		let legendaryBossUnlocks = [
			// artifact 1 lvl 10
			{ category: "id", dependentID: 5010, conditionType: "level", conditionValue: DEC(10), triggerType: "setUnlocked", triggerValue: null, targetID: 90118 },
			// wisdom 1e20
			{ category: "stat", dependentID: null, conditionType: "wisdom", conditionValue: DEC(1e20), triggerType: "setUnlocked", triggerValue: null, targetID: 90301 },
			// powerlevel 1e100
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e100), triggerType: "setUnlocked", triggerValue: null, targetID: 90507  },
			// energy 1e5
			{ category: "stat", type: null, dependentID: null, conditionType: "energy", conditionValue: DEC(1e5), triggerType: "setUnlocked", triggerValue: null, targetID: 90609  },
			// powerlevel 1e300
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e300), triggerType: "setUnlocked", triggerValue: null, targetID: 91307  },
			// headband lvl 3
			{ category: "id", type: null, dependentID: 800, conditionType: "level", conditionValue: DEC(3), triggerType: "setUnlocked", triggerValue: null, targetID: 91411  },
			// artifact 3 lvl 1
			{ category: "id", type: null, dependentID: 5030, conditionType: "level", conditionValue: DEC(1), triggerType: "setUnlocked", triggerValue: null, targetID: 91609  },
			// artifact 4 lvl 10
			{ category: "id", type: null, dependentID: 5040, conditionType: "level", conditionValue: DEC(10), triggerType: "setUnlocked", triggerValue: null, targetID: 91820  },
			// force 1e500
			{ category: "stat", type: null, dependentID: null, conditionType: "force", conditionValue: DEC(1e500), triggerType: "setUnlocked", triggerValue: null, targetID: 92007 },
			// powerlevel 1e500
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e500), triggerType: "setUnlocked", triggerValue: null, targetID: 92101 },
			// wisdom 1e300
			{ category: "stat", type: null, dependentID: null, conditionType: "wisdom", conditionValue: DEC(1e300), triggerType: "setUnlocked", triggerValue: null, targetID: 92212 },
			// energy 1e200
			{ category: "stat", type: null, dependentID: null, conditionType: "energy", conditionValue: DEC(1e200), triggerType: "setUnlocked", triggerValue: null, targetID: 92707 },
			// ^ End of version 0.5 phase artifact unlocks up to artifact 5120


			// stat
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e80), triggerType: "setUnlocked", triggerValue: null, targetID: 93002 },
			// stat
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e90), triggerType: "setUnlocked", triggerValue: null, targetID: 93407 },
			// stat
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e100), triggerType: "setUnlocked", triggerValue: null, targetID: 93409 },
			// stat
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e110), triggerType: "setUnlocked", triggerValue: null, targetID: 93513 },
			// stat
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e120), triggerType: "setUnlocked", triggerValue: null, targetID: 94207 },
			// stat
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e130), triggerType: "setUnlocked", triggerValue: null, targetID: 94907 },
			// stat
			{ category: "stat", type: null, dependentID: null, conditionType: "powerLevel", conditionValue: DEC(1e140), triggerType: "setUnlocked", triggerValue: null, targetID: 95607 },
		];

		let legendaryBossIDs = [];

		for (const zone of this	.zones.filter(zone => zone.zoneType === "legendaryBoss")) {
			legendaryBossIDs.push(zone.id);

			// Check if the zone.id exists as a targetID in legendaryBossUnlocks
			const isTargetIdPresent = legendaryBossUnlocks.some(unlock => unlock.targetID === zone.id);

			if (!isTargetIdPresent) {
			console.error(`Error: Legendary boss with ID ${zone.id} does not have a corresponding unlock in legendaryBossUnlocks.`);
			}
			
		}

		// Check if there are unlocks targeting a zone.id that is not in legendaryBossIDs
		for (const unlock of legendaryBossUnlocks) {
			const targetBoss = this.zones.find(zone => zone.id === unlock.targetID);

			// Only check for unlocks targeting  bosses
			if (targetBoss && !legendaryBossIDs.includes(unlock.targetID)) {
				console.error(`Error: Unlock with targetID ${unlock.targetID} does not correspond to any legendary boss zone.`);
			}
		}

		let unlockID = 9901;
		// populate legendary boss zone requirement text property
		for (const unlock of legendaryBossUnlocks) {
			let boss = this.gameManager.findObjectById(unlock.targetID);
			
			if (boss){ //check if boss exists. so game doesnt crash if it doesnt
				if (unlock.dependentID){
					let dependent = this.gameManager.findObjectById(unlock.dependentID);
					boss.unlockReqText = `<b>Access via:</b> ${dependent.name} ${unlock.conditionType} ${unlock.conditionValue}`;
				}
				else {
					boss.unlockReqText = `<b>Access via:</b> ${unlock.conditionType} ${unlock.conditionValue}`;
				}
				
				unlock.id = unlockID++;
			}
			else {
				console.error("legendaryBoss not found in build legendary boss unlocks.", unlock.targetID, boss);
			}
		}

		this.createUnlocks(legendaryBossUnlocks);
	}

	initTabUnlocks(){
		this.createUnlocks([
			//training tab and force realm - default enabled 
			//settings tab - default enabled

			// wisdom realm & tab  -  unlocked by world 2 legendary boss Font of Wisdom
			{ id: 251, category: "id", type: null, dependentID: 90912, targetID: 112, conditionType: "isDefeated", conditionValue: true, triggerType: "tabEnable", triggerValue: 20 },

			//energy realm & tab
			{ id: 252, category: "id", type: null, dependentID: 92515,  targetID: 113, conditionType: "isDefeated", conditionValue: true, triggerType: "tabEnable", triggerValue: 30 },

			//divine realm & tab
			{ id: 253, category: "id", type: null, dependentID: 97825, targetID: 114, conditionType: "isDefeated", conditionValue: true, triggerType: "tabEnable", triggerValue: 40 },

			//exploration tab - if change this, change tutorial unlocks related
			{ id: 256, category: "id", type: null, dependentID: 1002, targetID: 130, conditionType: "level", conditionValue: 10, triggerType: "tabEnable", triggerValue: null },

			//tournament subtab
			{ id: 282, category: "id", type: null, dependentID: 98001, targetID: 132, conditionType: "isDefeated", conditionValue: true, triggerType: "tabEnable", triggerValue: null },

			//artifact subtab - shows up at certain shard count
			{ id: 283, category: "stat", type: null, dependentID: null, targetID: 133, conditionType: "alphaShard", conditionValue: 1, triggerType: "tabEnable", triggerValue: null },


			//forge
			{ id: 254, category: "id", type: null, dependentID: 90212, targetID: 120, conditionType: "isDefeated", conditionValue: true, triggerType: "tabEnable", triggerValue: null },
			//skills
			{ id: 255, category: "stat", type: null, dependentID: null, targetID: 140, conditionType: "skillpoints", conditionValue: 1, triggerType: "tabEnable", triggerValue: null },
			//essence
			{ id: 257, category: "stat", type: null, dependentID: null, targetID: 150, conditionType: "powerLevel", conditionValue: 1e60, triggerType: "tabEnable", triggerValue: null },
			//achievements
			{ id: 258, category: "id", type: null, dependentID: 1001, targetID: 160, conditionType: "level", conditionValue: 5, triggerType: "tabEnable", triggerValue: null },
			//radiance
			{ id: 259, category: "stat", type: null, dependentID: 6001, targetID: 180, conditionType: "radiance", conditionValue: 1, triggerType: "tabEnable", triggerValue: null },

		]);
	}

	initRebirthUnlocks(){
		this.createUnlocks([
			//rebirth 1 unlock at power level 
			{ id: 265, category: "stat", type: null, dependentID: null, targetID: null, conditionType: "powerLevel", conditionValue: 1e60, triggerType: "buttonEnable", triggerValue: "rebirth1Button"},
		]);
	}

	

	initTutorialUnlocks(){
		// Find all fighter tiers and assign unlocks in ID order

		// triggervalue 1 = target element ID
		let tutorialUnlocks = [
			
			// Trigger Exploration Popup Tutorial - same value as exploration tab enable
				// must declare that, rather than the tab's ID, because tabs dont exist yet at this point in builder.
			{ category: "id", type: "tutorial", dependentID: 1002, conditionType: "level", conditionValue: 10, triggerType: "triggerTutorialPopup", triggerValues: [
				"odyssey.",
				"As you get stronger, you feel more sure of yourself, and are ready to venture into the great unknown. The training you've done has prepared you for this moment. Embrace the challenge, and let your newfound power guide you to new heights.",
				"odyssey",
				"Go to Odyssey"] 
			},

			// First Realm Evolution Popup Tutorial
			{ category: "id", type: "tutorial", dependentID: 10, conditionType: "evolutionTier", conditionValue: 2, triggerType: "triggerTutorialPopup", triggerValues: [
				"evolution.",
					"Your powers grow, you have outgrown your old training, etc.  Anyways, your training levels reset and are replaced with more powerful trainings.  Cuz you gotta become a big strong boi!  Your upgrades also cost less.  Get over it.  This is the only time you'll see this."] 
			},

			// First Headband Popup Tutorial
			{ category: "id", type: "tutorial", dependentID: 851, conditionType: "isCompleted", conditionValue: true, triggerType: "triggerTutorialPopup", triggerValues: [
				"you first fighter headband.",
				"You have earned your first tournament headband. Each tier of fighters defeated will award a new headband with a new bonus. Each headband increases in strength with the number of headband ranks you have. The more you get, the stronger they all get."] 
			},
			
			// First Skillpoint Popup Tutorial
			{ category: "stat", type: "tutorial", dependentID: null, conditionType: "skillpoints", conditionValue: 1, triggerType: "triggerTutorialPopup", triggerValues: [
				"skills.",
				"You have earned your first skill.  Gee i wonder what this does.",
				"skills",
				"Go to SkillTree"] 
			},
			
			// Wisdom Realm Popup Tutorial
			{ category: "id", type: "tutorial", dependentID: 90912, conditionType: "isDefeated", conditionValue: true, triggerType: "triggerTutorialPopup", triggerValues: [
				"wisdom realm unlocked.",
				"You have unlocked a new realm.  Your mind expands.  Your spirit awakens.  Welcome to Wisdom Realm you un-enlightened soul.",
				"wisdom",
				"Go to Training"] 
			},
			
			// Energy Realm Popup Tutorial
			{ category: "id", type: "tutorial", dependentID: 92515, conditionType: "isDefeated", conditionValue: true, triggerType: "triggerTutorialPopup", triggerValues: [
				"energy realm unlocked.",
				"You have unlocked a new realm.  You feel the power between your fingers. Energy crackles.  Bunch of other cool stuff. etc.",
				"energy",
				"Go to Training"] 
			},
			
			// Tournament Popup Tutorial
			{ category: "id", type: "tutorial", dependentID: 98001, conditionType: "isDefeated", conditionValue: true, triggerType: "triggerTutorialPopup", triggerValues: [
				"tournament unlocked.",
				"You have unlocked the tournament.  Go battle.  Defeat fighters to earn crystal for powerful forge upgrades. Perhaps a crumb of automation if you behave.",
				"tournament",
				"Go to Tournament"] 
			},
			
			// Forge Popup Tutorial
			{ category: "id", type: "tutorial", dependentID: 90212, conditionType: "isDefeated", conditionValue: true, triggerType: "triggerTutorialPopup", triggerValues: [
				"forge unlocked.",
				"You have unlocked the forge.  Guess what happens here?",
				"forge",
				"Go to Forge"] 
			},
			
			// Essence Popup Tutorial
			{ category: "stat", type: "tutorial", dependentID: null, conditionType: "powerLevel", conditionValue: 1e60, triggerType: "triggerTutorialPopup", triggerValues: [
				"rebirth unlocked.",
				"You have unlocked the power of rebirth.  Reincarnation.  Whatever you want to call it.  You can reset your power level to gain a multiplier to your training.",
				"essence",
				"Go to Rebirth"] 
			},
			
			// First Progression Zone Popup Tutorial
			{ category: "id", type: "tutorial", dependentID: 90021, conditionType: "isDefeated", conditionValue: true, triggerType: "triggerTutorialPopup", triggerValues: [
				"training upgrades.",
				"You have discovered how to augment your training.",
				"training",
				"Go to Training"] 
				},
				
			// First Artifact Popup Tutorial
			{ category: "id", type: "tutorial", dependentID: 5010, conditionType: "active", conditionValue: true, triggerType: "triggerTutorialPopup", triggerValues: [
				"artifacts.",
				"You have unlocked your first artifact.  Go look at it.",
				"artifacts",
				"Go to Artifacts"] 
			},




			// FIRST TRAINING BEGIN INDICATOR
			{ category: "id", type: "tutorialHide", dependentID: 1001, conditionType: "level", conditionValue: 0, triggerType: "addPointerIndicator", triggerValue: "#button-1001", triggerValue2: "tutorial-begin-training" },
			{ category: "id", type: "tutorialHide", dependentID: 1001, conditionType: "level", conditionValue: 1, triggerType: "removePointerIndicator", triggerValue: "#button-1001", triggerValue2: "tutorial-begin-training" },

			
			// FIRST TRAINING SHOW MULT CONTAINER
			{ category: "id", type: "tutorialHide", dependentID: 1001, conditionType: "level", conditionValue: 0, triggerType: "elementOpacity", triggerValue: "#forceMultContainer", triggerValue2: 0 },
			{ category: "id", type: "tutorialHide", dependentID: 1001, conditionType: "level", conditionValue: 10, triggerType: "elementOpacity", triggerValue: "#forceMultContainer", triggerValue2: 1 },


			// SECOND TRAINING SHOW INFO ICON
			{ category: "id", type: "tutorialHide", dependentID: 1002, conditionType: "level", conditionValue: 0, triggerType: "elementOpacity", triggerValue: "#force-tab-info", triggerValue2: 0 },
			{ category: "id", type: "tutorialHide", dependentID: 1002, conditionType: "level", conditionValue: 10, triggerType: "elementOpacity", triggerValue: "#force-tab-info", triggerValue2: 1 },

			// SHOW TRAINING REALM BUTTONS ONCE WISDOM REALM UNLOCKED
			{ category: "id", type: "tutorialHide", dependentID: 20, conditionType: "active", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#training-realm-buttons", triggerValue2: "none" },
			{ category: "id", type: "tutorialHide", dependentID: 20, conditionType: "active", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#training-realm-buttons", triggerValue2: "flex" },


			// FIRST UPGRADE SHOW COLUMN FOR ALL REALMS
			// Force
			{ category: "id", type: "tutorialHide", dependentID: 1101, conditionType: "active", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#force-col2-force", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 1101, conditionType: "active", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#force-col2-force", triggerValue2: "flex" },
			// Wisdom
			{ category: "id", type: "tutorialHide", dependentID: 631, conditionType: "active", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#wisdom-col2-wisdom", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 631, conditionType: "active", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#wisdom-col2-wisdom", triggerValue2: "flex" },
			// Energy
			{ category: "id", type: "tutorialHide", dependentID: 2101, conditionType: "active", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#energy-col2-energy", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 2101, conditionType: "active", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#energy-col2-energy", triggerValue2: "flex" },
			// Divine
			{ category: "id", type: "tutorialHide", dependentID: 731, conditionType: "active", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#divine-col2-divine", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 731, conditionType: "active", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#divine-col2-divine", triggerValue2: "flex" },

			// Dont show artifacts column until an artifact is earned
			{ category: "id", type: "tutorialHide", dependentID: 5010, conditionType: "active", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#tab-col2-ArtifactsSubTab", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 5010, conditionType: "active", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#tab-col2-ArtifactsSubTab", triggerValue2: "flex" },

			// Dont show artifacts mult container until an artifact is level 1
			{ category: "id", type: "tutorialHide", dependentID: 5010, conditionType: "level", conditionValue: 0, triggerType: "elementOpacity", triggerValue: "#artifactsMultContainer", triggerValue2: 0},
			{ category: "id", type: "tutorialHide", dependentID: 5010, conditionType: "level", conditionValue: 1, triggerType: "elementOpacity", triggerValue: "#artifactsMultContainer", triggerValue2: 1},



			// ODYSSEY TAB INITIAL UNLOOCKS TO REDUCE CLUTTER / DISTRACTION
			// hide minimap until region 2
			{ category: "id", type: "tutorialHide", dependentID: 90101, conditionType: "isDefeated", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#mini-map-grid", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 90101, conditionType: "isDefeated", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#mini-map-grid", triggerValue2: "grid" },
			// hide region back button until region 2
			{ category: "id", type: "tutorialHide", dependentID: 90101, conditionType: "isDefeated", conditionValue: false, triggerType: "elementDisplay", triggerValue: ".region-back-button", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 90101, conditionType: "isDefeated", conditionValue: true, triggerType: "elementDisplay", triggerValue: ".region-back-button", triggerValue2: "flex" },
			// hide world progress bar until region 2
			{ category: "id", type: "tutorialHide", dependentID: 90101, conditionType: "isDefeated", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#universe-progress-bar-container", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 90101, conditionType: "isDefeated", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#universe-progress-bar-container", triggerValue2: "flex" },
			// hide region progress bar until zone 1 defeated
			{ category: "id", type: "tutorialHide", dependentID: 90001, conditionType: "isDefeated", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#odyssey-progress-bar-container", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 90001, conditionType: "isDefeated", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#odyssey-progress-bar-container", triggerValue2: "flex" },

			// hide world nav buttons until world 2
			{ category: "id", type: "tutorialHide", dependentID: 1000002, conditionType: "active", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#Odyssey-tab-buttons", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 1000002, conditionType: "active", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#Odyssey-tab-buttons", triggerValue2: "flex" },

			// hide conquest speed display until region 3
			{ category: "id", type: "tutorialHide", dependentID: 90107, conditionType: "isDefeated", conditionValue: false, triggerType: "elementDisplay", triggerValue: "#conquest-time-display", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 90107, conditionType: "isDefeated", conditionValue: true, triggerType: "elementDisplay", triggerValue: "#conquest-time-display", triggerValue2: "flex" },


			// hide force bulk auto toggle until auto is unlocked
			{ category: "id", type: "tutorialHide", dependentID: 10009, conditionType: "level", conditionValue: 0, triggerType: "elementDisplay", triggerValue: "#force-bulk-toggle-btn", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 10009, conditionType: "level", conditionValue: 1, triggerType: "elementDisplay", triggerValue: "#force-bulk-toggle-btn", triggerValue2: "block" },

			
			// hide wisdom bulk auto toggle until auto is unlocked
			{ category: "id", type: "tutorialHide", dependentID: 10109, conditionType: "level", conditionValue: 0, triggerType: "elementDisplay", triggerValue: "#wisdom-bulk-toggle-btn", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 10109, conditionType: "level", conditionValue: 1, triggerType: "elementDisplay", triggerValue: "#wisdom-bulk-toggle-btn", triggerValue2: "block" },
			
			// hide energy bulk auto toggle until auto is unlocked
			{ category: "id", type: "tutorialHide", dependentID: 10209, conditionType: "level", conditionValue: 0, triggerType: "elementDisplay", triggerValue: "#energy-bulk-toggle-btn", triggerValue2: "none"},
			{ category: "id", type: "tutorialHide", dependentID: 10209, conditionType: "level", conditionValue: 1, triggerType: "elementDisplay", triggerValue: "#energy-bulk-toggle-btn", triggerValue2: "block" },
		];

		let unlockID = 70000;

		for (const unlock of tutorialUnlocks){
			unlock.id = unlockID++;
		}

		this.createUnlocks(tutorialUnlocks);
	}

	initForgeUpgradeUnlocks(){
		this.createUnlocks([

			//row 1 force upgrades unlocked by default when forge tab unlocks

			//row 2 force upgrades 
			{ id: 11000, category: "id", type: null, dependentID: 98501, targetID: [10006,10007, 10008, 10009, 10010], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			//row 3+ force upgrades
			{ id: 11001, category: "id", type: null, dependentID: 98502, targetID: [10011,10012,10013,10014,10015], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},

			//row 1 wisdom upgrades
			{ id: 11002, category: "id", type: null, dependentID: 98503, targetID: [10101,10102,10103,10104,10105], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			//row 2 wisdom upgrades
			{ id: 11003, category: "id", type: null, dependentID: 98504, targetID: [10106,10107,10108,10109, 10110], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			//row 3+ wisdom upgrades
			{ id: 11004, category: "id", type: null, dependentID: 98505, targetID: [10111,10112,10113,10114,10115], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},

			
			//row 1 energy upgrades
			{ id: 11005, category: "id", type: null, dependentID: 98506, targetID: [10201,10202,10203,10204,10205], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			//row 2 energy upgrades
			{ id: 11006, category: "id", type: null, dependentID: 98507, targetID: [10206,10207,10208,10209,10210], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			//row 3+ energy upgrades
			{ id: 11007, category: "id", type: null, dependentID: 98508, targetID: [10211,10212,10213,10214,10215], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},


			//row 1 divine upgrades
			{ id: 11008, category: "id", type: null, dependentID: 98509, targetID: [10301,10302,10303,10304], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			//row 2 divine upgrades
			{ id: 11009, category: "id", type: null, dependentID: 98510, targetID: [10306,10307,10308,10309,10310], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			//row 3+ divine upgrades
			{ id: 11010, category: "id", type: null, dependentID: 98511, targetID: [10311,10312,10315], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},



			// all crystal upgrades unlocked by tournament progression zones
			//group 1 crystal upgrades
			{ id: 11011, category: "id", type: null, dependentID: 98001, targetID: [10401,10402,10403,10404,10405], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			//group 2 crystal upgrade
			{ id: 11012, category: "id", type: null, dependentID: 98002, targetID: [10406,10407,10408,10409,10410], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			// //group 3 crystal upgrades
			{ id: 11013, category: "id", type: null, dependentID: 98003, targetID: [10411,10412,10413,10414,10415], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			// //group 4 crystal upgrades
			{ id: 11014, category: "id", type: null, dependentID: 98004, targetID: [10416,10417], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			// //group 5 crystal upgrades
			// { id: 11015, category: "id", type: null, dependentID: 98007, targetID: [10413], conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},

		]);
	}

	initForceRealmUnlocks() {
		//these unlocks manually added to reEngageUnlock for evolution resetting via ID

		this.createUnlocks([
			//trainings unlocking trainings
			{ id: 1201, category: "id", type: null, dependentID: 1001, targetID: 1002, conditionType: "level", conditionValue: 15, triggerType: "setActive", triggerValue: null},
			{ id: 1202, category: "id", type: null, dependentID: 1002, targetID: 1003, conditionType: "level", conditionValue: 20, triggerType: "setActive", triggerValue: null},
			{ id: 1203, category: "id", type: null, dependentID: 1003, targetID: 1004, conditionType: "level", conditionValue: 30, triggerType: "setActive", triggerValue: null},
			{ id: 1204, category: "id", type: null, dependentID: 1004, targetID: 1005, conditionType: "level", conditionValue: 40, triggerType: "setActive", triggerValue: null},

			// unlocked by progressionBoss zones
			{ id: 1205, category: "id", type: null, dependentID: 90021, targetID: 1101, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			{ id: 1206, category: "id", type: null, dependentID: 90125, targetID: 1102, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			{ id: 1207, category: "id", type: null, dependentID: 90321, targetID: 1103, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			{ id: 1208, category: "id", type: null, dependentID: 90423, targetID: 1104, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			{ id: 1209, category: "id", type: null, dependentID: 90623, targetID: 1105, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},


			// realm evolutions - unlocked by progressionBoss zones
			{ id: 1210, category: "id", type: null, dependentID: 92620, targetID: 10, conditionType: "isDefeated", conditionValue: true, triggerType: "evolve", triggerValue: null},
			{ id: 1211, category: "id", type: null, dependentID: 96368, targetID: 10, conditionType: "isDefeated", conditionValue: true, triggerType: "evolve", triggerValue: null}
		]);
	}

	initWisdomRealmUnlocks() {
		//these unlocks manually added to reEngageUnlock for evolution resetting via ID

		this.createUnlocks([
			//gens unlock gens
			{ id: 621, category: "id", type: null, dependentID: 611, targetID: 612, conditionType: "manualLevel", conditionValue: 10, triggerType: "setActive", triggerValue: null},
			{ id: 622, category: "id", type: null, dependentID: 612, targetID: 613, conditionType: "manualLevel", conditionValue: 20, triggerType: "setActive", triggerValue: null},
			{ id: 623, category: "id", type: null, dependentID: 613, targetID: 614, conditionType: "manualLevel", conditionValue: 30, triggerType: "setActive", triggerValue: null},
			{ id: 624, category: "id", type: null, dependentID: 614, targetID: 615, conditionType: "manualLevel", conditionValue: 40, triggerType: "setActive", triggerValue: null},

			// unlocked by progressionBoss zones
			{ id: 625, category: "id", type: null, dependentID: 91123, targetID: 631, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			{ id: 626, category: "id", type: null, dependentID: 91211, targetID: 632, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			{ id: 627, category: "id", type: null, dependentID: 91511, targetID: 633, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			{ id: 628, category: "id", type: null, dependentID: 91611, targetID: 634, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},
			{ id: 629, category: "id", type: null, dependentID: 92211, targetID: 635, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null},


			// realm evolutions - unlocked by progressionBoss zones
			{ id: 660, category: "id", type: null, dependentID: 93011, targetID: 20, conditionType: "isDefeated", conditionValue: true, triggerType: "evolve", triggerValue: null},
			{ id: 661, category: "id", type: null, dependentID: 96674, targetID: 20, conditionType: "isDefeated", conditionValue: true, triggerType: "evolve", triggerValue: null}
		]);
	}

	initEnergyRealmUnlocks() {
		//these unlocks manually added to reEngageUnlock for evolution resetting via ID

		this.createUnlocks([
			//trainings unlocking trainings
			{ id: 2201, category: "id", type: null,dependentID: 2001, targetID: 2002,conditionType: "level", conditionValue: 15, triggerType: "setActive", triggerValue: null },
			{ id: 2202, category: "id", type: null,dependentID: 2002, targetID: 2003,conditionType: "level", conditionValue: 20, triggerType: "setActive", triggerValue: null },
			{ id: 2203, category: "id", type: null,dependentID: 2003, targetID: 2004,conditionType: "level", conditionValue: 30, triggerType: "setActive", triggerValue: null },
			{ id: 2204, category: "id", type: null,dependentID: 2004, targetID: 2005,conditionType: "level", conditionValue: 40, triggerType: "setActive", triggerValue: null },

			// unlocked by progressionBoss zones
			{ id: 2205, category: "id", type: null,dependentID: 92712, targetID: 2101,conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 2206, category: "id", type: null,dependentID: 93212, targetID: 2102,conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 2207, category: "id", type: null,dependentID: 93512, targetID: 2103,conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 2208, category: "id", type: null,dependentID: 94112, targetID: 2104,conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 2209, category: "id", type: null,dependentID: 94712, targetID: 2105,conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },

			// realm evolutions - unlocked by progressionBoss zones
			{ id: 2210, category: "id", type: null, dependentID: 97034, targetID: 30, conditionType: "isDefeated", conditionValue: true, triggerType: "evolve", triggerValue: null},
			{ id: 2211, category: "id", type: null, dependentID: 97408, targetID: 30, conditionType: "isDefeated", conditionValue: true, triggerType: "evolve", triggerValue: null}
		]);
	}

	initDivineRealmUnlocks() {
		//these unlocks manually added to reEngageUnlock for evolution resetting via ID

		this.createUnlocks([
			//gens unlock gens
			{ id: 721, category: "id", type: null, dependentID: 711, targetID: 712 ,conditionType: "level", conditionValue: 5, triggerType: "setActive", triggerValue: null},
			{ id: 722, category: "id", type: null, dependentID: 712, targetID: 713 ,conditionType: "level", conditionValue: 5, triggerType: "setActive", triggerValue: null},
			{ id: 723, category: "id", type: null, dependentID: 713, targetID: 714 ,conditionType: "level", conditionValue: 5, triggerType: "setActive", triggerValue: null},
			{ id: 724, category: "id", type: null, dependentID: 714, targetID: 715 ,conditionType: "level", conditionValue: 5, triggerType: "setActive", triggerValue: null},
			
			// unlocked by progressionBoss zones
			{ id: 725, category: "id", type: null, dependentID: 711, targetID: 731 , conditionType: "level", conditionValue: 10, triggerType: "setActive", triggerValue: null},
			{ id: 726, category: "id", type: null, dependentID: 712, targetID: 732 , conditionType: "level", conditionValue: 10, triggerType: "setActive", triggerValue: null},
			{ id: 727, category: "id", type: null, dependentID: 713, targetID: 733 , conditionType: "level", conditionValue: 10, triggerType: "setActive", triggerValue: null},
			{ id: 728, category: "id", type: null, dependentID: 714, targetID: 734 , conditionType: "level", conditionValue: 10, triggerType: "setActive", triggerValue: null},

			// realm evolutions - unlocked by progressionBoss zones
			{ id: 729, category: "id", type: null, dependentID: 97826, targetID: 40, conditionType: "isDefeated", conditionValue: true, triggerType: "evolve", triggerValue: null},
			{ id: 730, category: "id", type: null, dependentID: 97827, targetID: 40, conditionType: "isDefeated", conditionValue: true, triggerType: "evolve", triggerValue: null}
		]);
	}

	initArtifactUnlocks(){
		this.createUnlocks([
			// artifacts unlocked by legendary bosses
			{ id: 5902, category: "id", type: null, dependentID: 90301, targetID: 5010, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5901, category: "id", type: null, dependentID: 90118, targetID: 5020, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5903, category: "id", type: null, dependentID: 90507, targetID: 5030, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5904, category: "id", type: null, dependentID: 90609, targetID: 5040, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5905, category: "id", type: null, dependentID: 91307, targetID: 5050, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5906, category: "id", type: null, dependentID: 91411, targetID: 5060, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5907, category: "id", type: null, dependentID: 91609, targetID: 5070, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5908, category: "id", type: null, dependentID: 91820, targetID: 5080, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5909, category: "id", type: null, dependentID: 92007, targetID: 5090, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5910, category: "id", type: null, dependentID: 92101, targetID: 5100, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5911, category: "id", type: null, dependentID: 92212, targetID: 5110, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ id: 5912, category: "id", type: null, dependentID: 92707, targetID: 5120, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
		]);
	}


	initSkillTreeUnlocks(){
		this.createUnlocks([
			
			// progression zone - font of wisdom -  unlock alltrain and wisdom tree
			{ id: 3073, category: "id", type: null, dependentID: 90912, targetID: [40014,40015,40023,40024,40025,40035,40036,40037,40038,40051,40121,40122,40123,40124,40125,40011,40002,40012,40039,40040,40041,40042,40043,40034], conditionType: "isDefeated", conditionValue: true, triggerType: "setUnlocked", triggerValue: null },

			// progression zone - font of energy - unlock energy tree and leading up alltrain nodes
			{ id: 3074, category: "id", type: null, dependentID: 92515, targetID: [40100,40101,40102,40103,40104,40105,40106,40107,40108,40109, 40019,40008,40006], conditionType: "isDefeated", conditionValue: true, triggerType: "setUnlocked", triggerValue: null },

			
		
			// LEFT SIDE SKILLS
			// region 4 - unlock exploration related skills
			{ id: 3071, category: "id", type: null, dependentID: 1010004, targetID: [40004, 40016, 40017, 40018, 40013, 40029, 40030, 40061, 40062,40063,40064,40065,40066,40071,40072,40073,40074], conditionType: "active",conditionValue: true,triggerType: "setUnlocked", triggerValue: null },

			// first tournament unlock - unlock tournament related skills
			{ id: 3072, category: "id", type: null, dependentID: 98001, targetID: [40004, 40016,40010,40009,40031,40081,40082,40083,40084,40085,40086,40026,40027,40028,40087,40088,40089,40090], conditionType: "isDefeated", conditionValue: true, triggerType: "setUnlocked", triggerValue: null },

			// artifacts found - unlock artifacts realted skills
			{ id: 3075, category: "id", type: null, dependentID: 5010, targetID: [40004, 40016,40032,40033,40091,40092,40093,40094,40095,40096,40097,40098,40099], conditionType: "active", conditionValue: true, triggerType: "setUnlocked", triggerValue: null }
		]);
	}
 
	createUnlocks(unlockData) {
		unlockData.forEach(data => {
			const { id, category, type, dependentID, targetID, conditionType, conditionValue, triggerType, triggerValue, triggerValue2, triggerValue3, triggerValues } = data;
	
			// check if conditionvalue is a digit, and if so, convert to Decimal
			// json cannot store a decimal value, but conditionvalue can be a digit or bool
			const decimalConditionValue = typeof conditionValue === 'number' ? DEC(conditionValue) : conditionValue;
			const decimalTriggerValue = typeof triggerValue === 'number' ? DEC(triggerValue) : triggerValue;
	
			const unlock = new Unlock( id, category, type, dependentID, targetID, conditionType, decimalConditionValue, triggerType, decimalTriggerValue, triggerValue2, triggerValue3, triggerValues);
			
			this.gameManager.unlockManager.unlocks.set(id, unlock);
			this.unlocks.set(id, unlock);
			this.gameManager.gameContent.unlocks.set(id, unlock);
			this.addObjectToIdMap(id, unlock);

			
		});
	}

	initTrainings() {
		gameData.trainingData.forEach(data => {
			const { id, realmID, evolutionTier, name, note, description, level, maxLevel = DEC(Infinity), costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, evolutions } = data;
			const training = new Training(this.eventManager, id, realmID, evolutionTier, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, evolutions);

			if (evolutions){
				evolutions.forEach(evolveData => {
					training.evolutions.push(evolveData);
				});
			}

			this.trainings.push(training);
			this.gameManager.gameContent.trainings.push(training);
			this.addObjectToIdMap(id, training);
		});
	}

	initRealmUpgrades(){
		gameData.realmUpgradeData.forEach(data => {
			const { id, realmID, evolutionTier, name, note, description, level, maxLevel = DEC(Infinity), costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, mods, evolutions } = data;
			const upgrade = new RealmUpgrade(this.eventManager, id, realmID, evolutionTier, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, evolutions);

			//grab mods declared in JSON and add them to the mod waiting array to be processed after all objects are initialized
			if (mods){
				mods.forEach(modData => {
					this.modsWaiting.push(modData);
				});
			}

			if (evolutions){
				evolutions.forEach(evolveData => {
					upgrade.evolutions.push(evolveData);
				});
			}

			
			this.realmUpgrades.push(upgrade);
			this.gameManager.gameContent.realmUpgrades.push(upgrade);
			this.addObjectToIdMap(id, upgrade);
		});
	}

	initGeneratorChains(){
		const generatorChain1 = new GeneratorChain(this.eventManager, 601, "wGenChain1", 20, true);
		this.generatorChains.push(generatorChain1);
		this.gameManager.gameContent.generatorChains.push(generatorChain1);
		this.addObjectToIdMap(generatorChain1.id, generatorChain1);

		const generatorChain2 = new GeneratorChain(this.eventManager, 701, "dGenChain1", 40, true);
		this.generatorChains.push(generatorChain2);
		this.gameManager.gameContent.generatorChains.push(generatorChain2);
		this.addObjectToIdMap(generatorChain2.id, generatorChain2);
	}

	initGenerators() {
		this.initGeneratorChains();

		this.createGenerators(gameData.generatorData);
	}

	initEssenceUpgrades() {
		gameData.essenceUpgradeData.forEach(data => {
			const { id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active = false, specialTargetID, parentID, angleFromParent,distanceFromParent, isUnlockedByConnection = true, mods } = data;
			const essenceUpgrade = new EssenceUpgrade(this.eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, specialTargetID, parentID, angleFromParent, distanceFromParent,isUnlockedByConnection);

			//grab mods declared in JSON and add them to the mod waiting array to be processed after all objects are initialized
			if (mods){
				mods.forEach(modData => {
					modData.id = essenceUpgrade.id + 500;
					modData.name = "eUpgradeMod" + essenceUpgrade.id;
					if (!modData.sourceID){
						modData.sourceID = essenceUpgrade.id;
					}
					this.modsWaiting.push(modData);

					//create essenceUpgrade description based off of mod values
					essenceUpgrade.description = `${modData.targetType} ${modData.type} ${modData.runningCalcType} (${modData.value} ${modData.sourceCalcType} this level)`;
				});
			}

			this.essenceUpgrades.push(essenceUpgrade);
			this.gameManager.gameContent.essenceUpgrades.push(essenceUpgrade);
			this.addObjectToIdMap(id, essenceUpgrade);
		});

		this.buildEssenceUpgradeConnections();
	}

	buildEssenceUpgradeConnections(){
		for(const upgrade of this.essenceUpgrades){
			if (upgrade.parentID){
				upgrade.parent = this.findObjectById(upgrade.parentID);
				upgrade.parent.children.push(upgrade);
			}
		}
	}

	initforgeUpgrades(){
		gameData.forgeUpgradeData.forEach(data => {
			const { id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, specialVar1 = null, specialVar2 = null, specialVar3 = null, mods } = data;
			const forgeUpgrade = new ForgeUpgrade(this.eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, specialVar1, specialVar2, specialVar3);

			//grab mods declared in JSON and add them to the mod waiting array to be processed after all objects are initialized
			if (mods){
				mods.forEach(modData => {
					modData.id = forgeUpgrade.id + 50;
					modData.name = "forgeUpgradeMod" + forgeUpgrade.id;
					if (!modData.sourceID){
						modData.sourceID = forgeUpgrade.id;
					}

					this.modsWaiting.push(modData);
				});
			}

			this.forgeUpgrades.push(forgeUpgrade);
			this.gameManager.gameContent.forgeUpgrades.push(forgeUpgrade);
			this.addObjectToIdMap(id, forgeUpgrade);
		});
	}

	initRadianceUpgrades() {
		gameData.radianceUpgradeData.forEach(data => {
			const { id, name, note, description, level, maxLevel = DEC(Infinity), costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, specialTargetID, mods } = data;

			const upgrade = new RadianceUpgrade(this.eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, specialTargetID);

			//grab mods declared in JSON and add them to the mod waiting array to be processed after all objects are initialized
			if (mods){
				mods.forEach(modData => {
					this.modsWaiting.push(modData);
				});
			}

			this.radianceUpgrades.push(upgrade);
			this.gameManager.gameContent.radianceUpgrades.push(upgrade);
			this.addObjectToIdMap(id, upgrade);
		});
	}

	initArtifacts() {
		gameData.artifactData.forEach(data => {
			let { id, name, note, evolutionTier, gearType, description, level = 0, maxLevel, costType, costBase, costGrowthRate, prodType = null, prodBase = null, prodGrowthRate = null, active = false, mods, evolutions } = data;
			
			const artifact = new Artifact(this.eventManager, id, name, note, DEC(evolutionTier), gearType, description, level, DEC(maxLevel), costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, evolutions);

			if (evolutions){
				evolutions.forEach(evolveData => {
					artifact.evolutions.push(evolveData);
				});
			}

			//grab mods declared in JSON and add them to the mod waiting array to be processed after all objects are initialized
			if (mods){
				mods.forEach(modData => {
					modData.id = artifact.id + 500;
					modData.name = "artifactMod" + artifact.id;
					if (!modData.sourceID){
						modData.sourceID = artifact.id;
					}
					this.modsWaiting.push(modData);

					//create artifact description based off of mod values
					// artifact.description = `${modData.targetType} ${modData.type} ${modData.runningCalcType} (${modData.value} ${modData.sourceCalcType} artifact level)`;
				});
			}
	
			this.artifacts.push(artifact);
			this.gameManager.gameContent.artifacts.push(artifact);
			this.addObjectToIdMap(id, artifact);
		});
	}

	assignEssenceUpgradeReferences() {
		for (const upgrade of this.essenceUpgrades) {
			if (upgrade.specialTargetID) {
				upgrade.target = this.findObjectById(upgrade.specialTargetID);
			}
		}
	}

	initRealms() {
		const realmData = [
			//force realms
			{ id: 10, type: "force", name: "force", description: "", active: true, evolutionTierNames: ["Neighborhood Training","Rocky Training","Gravity Chamber"] },
			{ id: 20, type: "wisdom", name: "wisdom", description: "", active: false, startingResource: 1, evolutionTierNames: ["Temple","Waterfall","Tree of Life"] },
			{ id: 30, type: "energy", name: "energy", description: "", active: false, startingResource: 1, evolutionTierNames: ["Basic Energy Training","Neutron Star Training","Black Hole Training"] },
			{ id: 40, type: "divine", name: "divine", description: "", active: false, startingResource: 1, evolutionTierNames: ["Divine Training Tier 1","Divine Training Tier 2","Divine Training Tier 3"] },
		];

		realmData.forEach(data => {
			const { id, type, name, description, active , startingResource, evolutionTierNames} = data;
			const realm = new Realm(this.eventManager, id, type, name, evolutionTierNames, description, active, startingResource);
			this.realms.push(realm);
			this.gameManager.gameContent.realms.push(realm);
			this.addObjectToIdMap(id, realm);
		});
	}

	initWorlds() {
		gameData.worldData.forEach(data => {
			const { id, name, note,zoneShardType, active = false } = data;
	
	
			const world = new World(this.eventManager, this.gameManager.gameContent.worldManager, id, name, note, zoneShardType, active);
			
			this.worlds.push(world);
			this.gameManager.gameContent.worldManager.worlds.push(world);
			this.addObjectToIdMap(id, world);
			this.gameManager.gameContent.worlds.push(world);
			if (world.id === 1000001) {
				this.gameManager.gameContent.worldManager.currentWorld = world;
			}
		});
	}
	
	
	initRegions() {
		gameData.regionData.forEach(data => {
			const { id, worldID, name, mapFilename, note, shardType, costRanges, topLeftCornerX, topLeftCornerY, active = false} = data;
			const region = new Region(this.eventManager, id, worldID, name, mapFilename, note, shardType, costRanges, topLeftCornerX, topLeftCornerY, active);
			this.regions.push(region);
			this.addObjectToIdMap(id, region);
			this.gameManager.gameContent.regions.push(region);

			// assign region images
			if (region.mapFilename) {
				region.image = mapImages[mapFilename];
			}
			else {
				// console.error("region map has no corresponding image for " + region.id);
			}

		});
	}

	pushRegionsToWorlds() {
		this.regions.forEach(region => {
			let world = this.worlds.find(world => world.id === region.worldID);
			if (world) {
				world.regions.push(region);
				region.world = world;
				world.totalZoneCount += region.zones.length;
			}
		});
	}

	dynamicallySetZoneCosts() {
		// Iterate through each region
		for (let region of this.regions) {
			if (region.zones) {
				// Filter out special zones ("legendaryBoss" and "progressionBoss")
				const nonSpecialZones = region.zones.filter(zone => zone.zoneType !== "legendaryBoss" && zone.zoneType !== "progressionBoss");
	
				// Object to group zones by their costType
				const costTypeBuckets = {};
	
				// Group zones by costType
				for (let zone of nonSpecialZones) {
					const costType = zone.costType;
					if (!costTypeBuckets[costType]) {
						costTypeBuckets[costType] = [];
					}
					costTypeBuckets[costType].push(zone);
				}
	
				// Iterate through each costType bucket
				for (let costType in costTypeBuckets) {
					const zones = costTypeBuckets[costType];
					const regionZoneCount = zones.length;
	
					// Check if cost ranges are defined for the current costType
					if (region.costRanges && region.costRanges.hasOwnProperty(costType)) {
						const { min: costMin, max: costMax } = region.costRanges[costType];
						const costMinDec = DEC(costMin); // Convert min cost to a decimal
						const costMaxDec = DEC(costMax); // Convert max cost to a decimal
						const factor = costMaxDec.div(costMinDec).pow(1 / (regionZoneCount - 1)); // Calculate factor for cost progression
						let currCost = costMinDec;
	
						// Apply calculated costs to each zone in the current costType bucket
						for (let zone of zones) {
							if (zone.costBase.eq(100)) {
								// Set initial costs if the cost base is the default value (100)
								zone.costBase = zone.cost = zone.costNextMultPurchase = zone.costNextSingle = currCost;
							}
							currCost = currCost.times(factor); // Update current cost for the next zone
						}
					} else {
						// Log an error if the cost range for the current costType is not found
						console.error(`Cost range not found for costType: ${costType} in region: ${region.name}`);
					}
				}
			}
		}
	}
	

	initZones() {
		gameData.zoneData.forEach(data => {
			const { id, regionID, name, note, description, level = DEC(0), maxLevel = DEC(1), costType, costBase, costGrowthRate = DEC(2), prodType, prodBase, prodGrowthRate = DEC(1.01), baseConquestTime, active = false, zoneType = "standard", parentID, angleFromParent,distanceFromParent,x = false, y = false, outsideRegionConnectionIDs } = data;
			const zone = new Zone(
				this.eventManager, id, regionID, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate,baseConquestTime, active, zoneType, parentID, angleFromParent,distanceFromParent, x, y, outsideRegionConnectionIDs
			);

			if (zone.zoneType === "legendaryBoss"){
				zone.unlocked = false;
			}
			else {
				zone.unlocked = true;
			}

			this.zones.push(zone);
			this.gameManager.gameContent.zones.push(zone);
			this.addObjectToIdMap(id, zone);
		});

		this.buildZoneConnections();
	}

	buildZoneConnections(){
		for(const zone of this.zones){
			if (zone.parentID){
				zone.parent = this.findObjectById(zone.parentID);
				zone.parent.children.push(zone);
				if (zone.regionID !== zone.parent.regionID){
					zone.parent.hasConnectionToNewRegion = true;
				}
			}
			if (zone.outsideRegionConnectionIDs){
				for (const child of zone.outsideRegionConnectionIDs){
					let newChild = this.findObjectById(child);
					if (zone.regionID !== newChild.regionID){
						zone.hasConnectionToNewRegion = true;
					}
					zone.children.push(newChild);
					newChild.children.push(zone);
					newChild.hasConnectionToNewRegion = true;
				}
			}
		}
	}

	pushZonesToRegions() {
		this.zones.forEach(zone => {
			let region = this.regions.find(region => region.id === zone.regionID);
			if (region) {
				region.zones.push(zone);
				zone.region = region;
				region.totalZoneCount++;
			}
			this.gameManager.gameContent.worldManager.totalZoneCount++;
		});
	}

	initFighters() {
		let fighterID = 80001;
		let fighterCost = DEC(1e10);
		let fighterProd = DEC(10);

		gameData.fighterData.forEach(data => {
			const { id = fighterID, name, note, description, tier, level = DEC(0), maxLevel = DEC(1), costType = "powerLevel", costBase, costGrowthRate = DEC(2), prodType = "crystal", prodBase, prodGrowthRate = DEC(1.01), active = false} = data;
			const fighter = new Fighter(
				this.eventManager, id, name, note, description, tier,level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active
			);
			fighter.costBase = fighter.costNextMultPurchase = fighter.costNextSingle = fighterCost;
			fighter.prodBase = fighter.prodNextMultPurchase = fighter.prodNextSingle = fighterProd;
			fighterCost = fighterCost.times(20);
			fighterProd = fighterProd.times(2);
			this.fighters.push(fighter);
			this.gameManager.gameContent.fighters.push(fighter);
			this.gameManager.gameContent.tournament.fighters.push(fighter);
			this.addObjectToIdMap(id, fighter);
			fighterID++;
		});
	}

	initFighterTiers(){
		// creates fighter tiers based on fighters' fighterTier property, and assigns corresponding fighters
		let fighterTierID = 851;
		let fighterTierName = "Tier 1";
		for (const fighter of this.fighters){
			let added = false;
			for (const fighterTier of this.fighterTiers){
				if (fighterTier.tier === fighter.tier){
					fighterTier.fighters.push(fighter);
					fighter.fighterTier = fighterTier;
					added = true;
				}
			}
			if (!added){
				let newTier = new FighterTier(this.eventManager, fighterTierID, fighter.tier, fighterTierName);
				newTier.fighters.push(fighter);
				fighterTierID++;
				// increment the fighter tier name by 1 as well
				fighterTierName = `Tier ${fighterTierID - 850}`;


				this.fighterTiers.push(newTier);
				this.gameManager.gameContent.fighterTiers.push(newTier);
				this.gameManager.gameContent.tournament.fighterTiers.push(newTier);
				this.addObjectToIdMap(newTier.id, newTier);
				fighter.fighterTier = newTier;

				if (newTier.id === 851){
					newTier.setActive();
					this.gameManager.gameContent.tournament.currentFighterTier = newTier;
				}
			}
		}
	}

	initFighterTierUnlocks(){
		// Find all fighter tiers and assign unlocks in ID order

		let fighterTierUnlocks = [
			// progression boss zone unlocks for each
			{ category: "id", dependentID: 98001, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ category: "id", dependentID: 98002, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ category: "id", dependentID: 98003, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ category: "id", dependentID: 98004, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ category: "id", dependentID: 98005, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ category: "id", dependentID: 98006, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ category: "id", dependentID: 98007, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ category: "id", dependentID: 98008, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ category: "id", dependentID: 98009, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
			{ category: "id", dependentID: 98010, conditionType: "isDefeated", conditionValue: true, triggerType: "setActive", triggerValue: null },
		];


		let fighterTierIDs = [];
		for (const fighterTier of this.fighterTiers) {
			fighterTierIDs.push(fighterTier.id);
		}

		let unlockID = 871;
		let tierIDIndex = 0;

		for (const unlock of fighterTierUnlocks){
			unlock.id = unlockID++;

			if (tierIDIndex < fighterTierIDs.length) {
				unlock.targetID = fighterTierIDs[tierIDIndex++];
			} else {
				// If you run out of IDs in fighterTierIDs but still have more unlocks, 
				// you need to decide what to do. This is just a placeholder.
				console.warn("Ran out of fighterTierIDs. Not all unlocks have targetIDs.");
				break;
			}
		}
		if (tierIDIndex < fighterTierIDs.length){
			console.error(fighterTierIDs.length - tierIDIndex, "fighter tier unlocks not assigned");
		}

		this.createUnlocks(fighterTierUnlocks);
	}
	

	initTournament(){
		let tournament = new Tournament(this.eventManager, 799);
		this.tournament = tournament;
		
		this.gameManager.gameContent.tournament = tournament;
		this.addObjectToIdMap(tournament.id, tournament);
	}


	initRebirthModAndPseudoFeature() {
		//hidden upgrade object to act as source of rebirth mod
		const rebirthPseudoObject =
			{ id: 60000, name: "hidden rebirth1 upgrade source", description: "hidden rebirth1 pseudoFeature source", level: DEC(1), maxLevel: Infinity,   active: false };
		const { id, name, note, description, level, maxLevel,  active } = rebirthPseudoObject;
		const rebirthObject = new PseudoFeature(this.eventManager, id, name, note, description, level, maxLevel, active);
		// this.realmUpgrades.push(rebirthObject);
		this.addObjectToIdMap(id, rebirthObject);


		let modID = 60001;
		const mod = new Mod(this.eventManager, modID, "rebirth1EssenceMod", "production", null, 60000, "add", "allTrain", null, "mult", DEC(1), DEC(1), false);
		this.mods.push(mod);
		this.addObjectToIdMap(modID, mod);
	}

	initHeadbandModsAndPseudoFeature(){
		//hidden upgrade object to act as source of rebirth mod
		const headbandPseudoObject =
			{ id: 800,  name: "Tournament Headband", description: "hidden tournament headband pseudoFeature source", level: DEC(0), maxLevel: Infinity, active: false };

			const { id, name, note, description, level, maxLevel,  active } = headbandPseudoObject;
			
			const headbandObject = new PseudoFeature(this.eventManager, id, name, note, description, level, maxLevel, active);

		// this.realmUpgrades.push(headbandObject);
		this.addObjectToIdMap(id, headbandObject);

		let modData = [
			{ id: 801, name: "headbandMod1", type: "production", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "forceTrain", targetID: null, runningCalcType: "mult", baseValue: 2, value: 2, active: false },
			{ id: 802, name: "headbandMod2", type: "cost", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "forceTrain", targetID: null, runningCalcType: "div", baseValue: 2, value: 2, active: false },
			{ id: 803, name: "headbandMod3", type: "production", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "wisdomTrain", targetID: null, runningCalcType: "mult", baseValue: 2, value: 2, active: false },
			{ id: 804, name: "headbandMod4", type: "cost", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "wisdomTrain", targetID: null, runningCalcType: "div", baseValue: 2, value: 2, active: false },
			{ id: 805, name: "headbandMod5", type: "production", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "allTrain", targetID: null, runningCalcType: "add", baseValue: 1, value: 1, active: false },
			{ id: 806, name: "headbandMod6", type: "cost", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "allTrain", targetID: null, runningCalcType: "div", baseValue: 1, value: 1, active: false },
			{ id: 807, name: "headbandMod7", type: "cost", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "fighters", targetID: null, runningCalcType: "div", baseValue: 1, value: 1, active: false },
			{ id: 808, name: "headbandMod8", type: "production", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "zones", targetID: null, runningCalcType: "add", baseValue: 1, value: 1, active: false },
			{ id: 809, name: "headbandMod9", type: "cost", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "zones", targetID: null, runningCalcType: "div", baseValue: 1, value: 1, active: false },
			{ id: 810, name: "headbandMod10", type: "production", priority: null, sourceID: 800, sourceCalcType: "add", targetType: "allTrain", targetID: null, runningCalcType: "mult", baseValue: 1, value: 1, active: false },
		];

		for (const mod of modData){
			this.modsWaiting.push(mod);
		}
	}

	initHeadbandModUnlocks(){
		this.createUnlocks([
			//trainings unlocking trainings
			{ id: 821, category: "id", type: null,  dependentID: 851, targetID: 801, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
			{ id: 822, category: "id", type: null,  dependentID: 852, targetID: 802, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
			{ id: 823, category: "id", type: null,  dependentID: 853, targetID: 803, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
			{ id: 824, category: "id", type: null,  dependentID: 854, targetID: 804, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
			{ id: 825, category: "id", type: null,  dependentID: 855, targetID: 805, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
			{ id: 826, category: "id", type: null,  dependentID: 856, targetID: 806, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
			{ id: 827, category: "id", type: null,  dependentID: 857, targetID: 807, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
			{ id: 828, category: "id", type: null,  dependentID: 858, targetID: 808, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
			{ id: 829, category: "id", type: null,  dependentID: 859, targetID: 809, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
			{ id: 830, category: "id", type: null,  dependentID: 860, targetID: 810, conditionType: "isCompleted", conditionValue: true, triggerType: "headbandLevelActivate", triggerValue: null},
		]);
	}

	populateRanks(){
		//initForceTrainRanks
		this.initRanks(30000, 30001, 31000, this.trainings);

		//initWisdomGenRanks
		this.initRanks(32000, 32001, 33200, this.generators);
	}

	initRanks(sourceUpgradeID, modStartID, unlockStartID, features) {
		const RANKS = [DEC(10), DEC(25), DEC(50), DEC(100), DEC(250), DEC(500), DEC(1000), DEC(2500), DEC(5000), DEC(10000), DEC(25000), DEC(50000), DEC(100000), DEC(250000), DEC(500000), DEC(1000000)];
	
		// Hidden pseudoFeature object to act as source of rank mods
		const rankSource =
			{ id: sourceUpgradeID, name: "hidden rank pseudoFeature source", description: "hidden rank pseudoFeature source", level: DEC(1), maxLevel: DEC(1) };
		const { id, name, note, description, level, maxLevel,  active } = rankSource;

		const rankPseudoFeature = new PseudoFeature(this.eventManager, id, name, note, description, level, maxLevel, active);
		this.realmUpgrades.push(rankPseudoFeature);
		this.addObjectToIdMap(id, rankPseudoFeature);

		let unlockID = unlockStartID;
		let modID = modStartID;
		let rankLevel = DEC(0);
		for (const feature of features) {
			let modValue = DEC(2);

			//set feature initial nextRankLevel info
			feature.nextRankLevel = RANKS[0];
			feature.nextRankMult = modValue;

			for (let i = 0; i < RANKS.length; i++) {
				rankLevel = RANKS[i];

				const mod = new Mod(this.eventManager, modID, feature.id + "rank" + rankLevel.toString(), "production", null, sourceUpgradeID, "mult", null, feature.id, "mult", modValue, modValue, false);
				
				this.mods.push(mod);
				this.gameManager.gameContent.mods.push(mod);
				this.addObjectToIdMap(modID, mod);

				const unlock = new Unlock(unlockID, "id", null, feature.id, mod.id, "manualLevel", rankLevel, "setActive", null);

				this.unlocks.set(unlockID, unlock);
				this.gameManager.unlockManager.unlocks.set(unlockID, unlock);
				this.gameManager.gameContent.unlocks.set(unlockID, unlock);
				this.addObjectToIdMap(unlockID, unlock);

				unlockID++;
				modID++;
				modValue = modValue.plus(2);
			}
		}

	
		// Populate all included features with rank tiers
		for (const feature of features) {
			feature.rankTiers = RANKS;
			feature.setNextAffordableRankLevel();
		}
	}
	


	createModObjects(){
		this.createMods(this.modsWaiting);
	}

	assignModPriorities() {
		const typeValues = {
			"prodInit":1,
			"prodBase": 100,
			"production": 1000,
			"costInit":1,
			"costBase": 100,
			"cost": 1000,
		};

		const calcTypeValues = {
			"add": 100,
			"sub": 150,
			"mult": 200,
			"div": 250,
			"exp": 300,
			"log": 350,
			"tetra":400,
			"addPercent":900,
			"subPercent":900,
		};

		this.mods.forEach(mod => {
			//set priority unless manually assigned
			if (!mod.priority) {
				mod.priority = typeValues[mod.type] + calcTypeValues[mod.runningCalcType];
			}
		});
	}

	assignModReferences() {
		this.mods.forEach(mod => {
			if (mod.sourceID) {
				mod.source = this.findObjectById(mod.sourceID);
			}

			if (mod.targetID) {
				mod.target = this.findObjectById(mod.targetID);
			}
		});
	}

	registerModsToSources() {
		this.mods.forEach(mod => {
			this.registerModObserver(mod.source, mod);
			
			if (mod.specialActivator){
				this.registerModObserver(mod.specialActivator, mod);
			}
		});
	}

	//register mod observers and push to mod trees
	registerModObserversAndTrees() {
		let typeMods = [];

		this.mods.forEach(mod => {
		   if (mod.targetType) {
				typeMods.push(mod);
			}

			else if (mod.target) {
				this.addModToObjectModTree(mod.target, mod);
			}
		 
			else {
				console.error("mod",mod.name ,"is not initialized properly - missing: target type or target ID");
			}
		});

		this.typeModHandler(typeMods);
	}

	typeModHandler(typeMods) {
		typeMods.forEach(typeMod => {
			let featureLoop = null;

			//grab array of relevant targeted features
			if (typeMod.targetType === "forceTrain") {
				featureLoop = this.trainings.filter(training => training.realmID === 10);
			}
			else if (typeMod.targetType === "wisdomTrain") {
				// only affects the base generator, not all generators
				featureLoop = this.generators.filter(generator => 
					generator.parentGenChain.realmID === 20 && 
					generator.parentGenChain.generators[0] === generator);
				
			}
			if (typeMod.targetType === "energyTrain") {
				featureLoop = this.trainings.filter(training => training.realmID === 30);
			}
			else if (typeMod.targetType === "divineTrain") {
				// only affects the base generator, not all generators
				featureLoop = this.generators.filter(generator => 
					generator.parentGenChain.realmID === 40 && 
					generator.parentGenChain.generators[0] === generator);
			}
			else if (typeMod.targetType === "zones") {
				featureLoop = this.zones;
			}
			else if (typeMod.targetType === "fighters") {
				featureLoop = this.fighters;
			}
			else if (typeMod.targetType === "allTrain") {
				// only affects the base generator, not all generators
				let tempLoop = [];
				for (const chain of this.generatorChains){
					tempLoop.push(chain.generators[0]);
				}
				featureLoop = this.trainings.concat(tempLoop);
			}
			else if (typeMod.targetType === "artifacts") {
				featureLoop = this.artifacts;
			}

			//add type mod to relevant feature
			for (const feature of featureLoop) {
				//make sure typemod does not apply to itself (upgrade that buffs upgrades)
				if (typeMod.source !== feature) {
					this.addModToObjectModTree(feature, typeMod);
				}
			}
		});
	}

	addModToObjectModTree(targetObject,mod) {
		let tree = null;

		switch (mod.type) {
			case 'costInit':
			case 'costBase':
			case 'cost':
				if (targetObject.modTreesMap.get("cost")) {
					targetObject.modTreesMap.get("cost").addNode(mod);
					mod.modTreeReferences.push(targetObject.modTreesMap.get("cost"));
				}
				else {
					tree = new ModTree(targetObject, "cost");
					targetObject.modTreesMap.set("cost", tree);
					tree.addNode(mod);
					mod.modTreeReferences.push(targetObject.modTreesMap.get("cost"));
					this.modTrees.push(tree);
				}
				break;
			case 'prodInit':
			case 'prodBase':
			case 'production':
				if (targetObject.modTreesMap.get("production")) {
					targetObject.modTreesMap.get("production").addNode(mod);
					mod.modTreeReferences.push(targetObject.modTreesMap.get("production"));
				}
				else {
					tree = new ModTree(targetObject, "production");
					targetObject.modTreesMap.set("production", tree);
					tree.addNode(mod);
					mod.modTreeReferences.push(targetObject.modTreesMap.get("production"));
					this.modTrees.push(tree);
				}
				break;

			default:
				console.error(targetObject, mod, "addModtocalctree error");
		}
	}

	initModTrees() {
		this.modTrees.forEach(tree => {
			tree.buildTree();
		});
	}

	registerModObserver(sourceObject, mod) {
		if (sourceObject) {
			sourceObject.registerObserver(mod);
		}
	}

	pushTrainingsToRealms() {
		this.trainings.forEach(training => {
			const realm = this.realms.find(realm => realm.id === training.realmID);
			if (realm) {
				realm.trainings.push(training);
				training.realm = realm; // Assign the realm reference to the training's realm value
			} else {
				console.error(`No realm found with id ${training.realmID} for training ${training.name}`);
			}
		});
	}

	pushUpgradesToRealms() {
		this.realmUpgrades.forEach(upgrade => {
			const realm = this.realms.find(realm => realm.id === upgrade.realmID);
			if (realm) {
				realm.realmUpgrades.push(upgrade);
				upgrade.realm = realm; // Assign the realm reference to the training's realm value
			} else {
				console.error(`No realm found with id ${upgrade.realmID} for training ${upgrade.name}`);
			}
		});
	}

	assignUnlockReferences() {
		this.unlocks.forEach(unlock => {
			if (Array.isArray(unlock.targetID)) {
				unlock.target = unlock.targetID.map(id => this.findObjectById(id));
				if (unlock.target.includes(null)) {
					console.error(`Error: One or more target objects not found for targetID array: ${unlock.targetID}`);
				}
			} else if (unlock.targetID) {
				unlock.target = this.findObjectById(unlock.targetID);
				if (unlock.target === null) {
					console.error(`Error: Target object not found for targetID: ${unlock.targetID}`);
				}
			}
	
			if (Array.isArray(unlock.dependentID)) {
				unlock.dependent = unlock.dependentID.map(id => this.findObjectById(id));
				if (unlock.dependent.includes(null)) {
					console.error(`Error: One or more dependent objects not found for dependentID array: ${unlock.dependentID}`);
				}
			} else if (unlock.dependentID) {
				unlock.dependent = this.findObjectById(unlock.dependentID);
				if (unlock.dependent === null) {
					console.error(`Error: Dependent object not found for dependentID: ${unlock.dependentID}`);
				}
			}
		});
	}
	
	createMods(modData) {
		modData.forEach(data => {
			const { id, name, type, priority, sourceID, sourceCalcType, targetType, targetID, runningCalcType, baseValue, value, active, specialActivatorID = null } = data;
			const mod = new Mod(this.eventManager, id, name, type, priority, sourceID, sourceCalcType, targetType, targetID, runningCalcType, baseValue, value, active, specialActivatorID);
			this.mods.push(mod);
			this.gameManager.gameContent.mods.push(mod);
			this.addObjectToIdMap(id, mod);
			if (mod.specialActivatorID){
				mod.specialActivator = this.gameManager.findObjectById(specialActivatorID);
			}
		});
	}

	printTrainingInfo() {
		console.error("::::::::::::::::::::::::::");
		console.error(":::::   TRAININGS   :::::");
		console.error("::::::::::::::::::::::::::");
		this.trainings.forEach(training => {
			console.error(`Training ${training.id} - ${training.name}`);
			console.error(" Observers:");
			training.observers.forEach((observer, index) => {
				console.error(`  Observer ${index + 1}: ${observer.id} ${observer.name}`);
			});
			console.error(" Calc Trees:");
			training.modTreesMap.forEach((modTree, key) => {
				console.error(`  Mod tree: ${key}`);
				modTree.nodes.forEach((node, index) => {
					console.error(`   Node ${index + 1}: ${node.ref.id} ${node.ref.name} value: ${node.ref.value} srcCalc: ${node.ref.sourceCalcType} runCalc: ${node.ref.runningCalcType} previousNode: ${node.previousNode ? node.previousNode.ref.name : null} nextNode: ${node.nextNode ? node.nextNode.ref.name : null} active:${node.ref.active}`);

				});
			});
		});
	}

	printUpgradeInfo() {
		console.error("::::::::::::::::::::::::::");
		console.error("::::::   UPGRADES   ::::::");
		console.error("::::::::::::::::::::::::::");
		this.realmUpgrades.forEach(upgrade => {
			console.error(`Upgrade ${upgrade.id} - ${upgrade.name}`);
			console.error(" Observers:");
			upgrade.observers.forEach((observer, index) => {
				console.error(`  Observer ${index + 1}: ${observer.id} ${observer.name}`);
			});
			console.error(" Calc Trees:");
			upgrade.modTreesMap.forEach((modTree, key) => {
				console.error(`  Mod tree: ${key}`);
				modTree.nodes.forEach((node, index) => {
					console.error(`   Node ${index + 1}: ${node.ref.id} ${node.ref.name} value: ${node.ref.value} srcCalc: ${node.ref.sourceCalcType} runCalc: ${node.ref.runningCalcType} previousNode: ${node.previousNode ? node.previousNode.ref.name : null} nextNode: ${node.nextNode ? node.nextNode.ref.name : null}`);

				});
			});
		});
	}

	printZoneInfo() {
		console.error("::::::::::::::::::::::::::");
		console.error("::::::   ZONES   ::::::");
		console.error("::::::::::::::::::::::::::");
		this.zones.forEach(zone => {
			console.error(`Zone ${zone.id} - ${zone.name}`);
			console.error(" Observers:");
			zone.observers.forEach((observer, index) => {
				console.error(`  Observer ${index + 1}: ${observer.id} ${observer.name}`);
			});
			console.error(" Calc Trees:");
			zone.modTreesMap.forEach((modTree, key) => {
				console.error(`  Mod tree: ${key}`);
				modTree.nodes.forEach((node, index) => {
					console.error(`   Node ${index + 1}: ${node.ref.id} ${node.ref.name} value: ${node.ref.value} srcCalc: ${node.ref.sourceCalcType} runCalc: ${node.ref.runningCalcType} previousNode: ${node.previousNode ? node.previousNode.ref.name : null} nextNode: ${node.nextNode ? node.nextNode.ref.name : null}`);
				});
			});
		});
	}

	printUnlockInfo() {
		this.unlocksDiv = document.getElementById("unlocksList");
		this.unlocksDiv.innerHTML = `LIST OF UNLOCKS:<br>`;
		for (const unlock of this.gameManager.gameContent.unlocks) {
			this.unlocksDiv.innerHTML += `${unlock.dependent.name} unlocks ${unlock.target.name} via ${unlock.conditionType} ${unlock.conditionValue}<br>`;
		}
	}

	printWorldRegionZoneHeirarchy(){
		let str = "";
		let worldNum = 1;
		let zoneNames = [];
		for (const world of this.worlds){
			let regionNum = 1;
			str += `World ${worldNum}: ${world.name} \n`;
			for (const region of world.regions){
				let zoneNum = 1;
				str += `   Region ${regionNum}: ${region.name} \n`;
				for (const zone of region.zones){
					zoneNames.push(zone.name);
					if (zone.zoneType === "legendaryBoss"){
						str += `     Zone ${zoneNum}: Side Boss: ${zone.name} \n`;
					}
					else if (zone.zoneType === "boss"){
						str += `     Zone ${zoneNum}: Regional Boss: ${zone.name} \n`;
					}
					else {
						str += `     Zone ${zoneNum}: ${zone.name} \n`;
					}

					zoneNum++;
				}
				regionNum++;
			}
			str += '\n';
			worldNum++;
		}
		console.error(str);
		
		let duplicates = zoneNames.filter((value, index, self) => {
			return self.indexOf(value) !== index;
		});

		console.log("Duplicates:",duplicates); 
	}


}