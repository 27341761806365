export default class AchievementsTab{
    constructor(eventManager, ui, gameContent, tabManager){
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.tabManager = tabManager;

        this.achievements = this.gameContent.achievements;
        
        this.achievementSets = this.gameContent.achievementsGrid.achievementSets;
        
        this.targetCol = this.ui.achievementsCol1;
    }

    populateDisplay(){
        // let achievementsChecker = this.ui.createElement('div','achieve-checker');

        for (const achievementSet of this.achievementSets){			
            achievementSet.achievements.forEach(achievement => {
                const achievementID = `achievement-${achievement.id}`;
             
                let achievementCell = this.ui.createElement('button',achievementID,'achievement-cell');
                // achievementCell.style.color = `${achievement.set.color}`;
                achievementCell.style.border = `2px solid ${achievement.set.color}`;

                let achievementContent = this.ui.createElement('div',null,null);
                
                // Create a new element for the background image
                const backgroundElement = this.ui.createElement('div',null,'achievement-background');
                backgroundElement.style.backgroundSize = 'cover';
                backgroundElement.style.backgroundImage = `url(${achievement.image})`;
                achievementCell.appendChild(backgroundElement);

                // this.ui.updateElementTextContent(achievementContent,`${achievement.name}`);


                achievementCell.appendChild(achievementContent);

                // Named function for the click event
                const achievementClickHandler = () => {
                    this.claimAchievement(achievement);
                    
                    // Check if the achievement was successfully claimed and then animate
                    if (achievement.isClaimed) {
                        backgroundElement.classList.add('colorize');
                        backgroundElement.classList.add('burst');
                        achievementCell.classList.add('glow');
                        
                        // Named function for the animationend event
                        const animationEndHandler = () => {
                            backgroundElement.classList.remove('colorize');
                            backgroundElement.classList.remove('burst');
                            achievementCell.classList.remove('glow');
                            
                            // Remove the event listener after it fires
                            achievementCell.removeEventListener('animationend', animationEndHandler);
                        };

                        // Add the animationend listener directly since it's a one-time event
                        achievementCell.addEventListener('animationend', animationEndHandler);
                    }
                };

                // Use EventManager to add the click event listener
                this.eventManager.addDomListener(achievementCell, 'click', achievementClickHandler);


                this.ui.populateTooltip(achievement,achievementCell);
                this.ui.populateMouseOverZIndexEvents(achievementCell);
    
                this.targetCol.appendChild(achievementCell);
            });
		}

        // Create a button to claim all claimable and unclaimed achievements
        let claimAllButton = this.ui.createElement('button', 'claim-all-achievements-button', 'claim-all-achievements-button');
        claimAllButton.textContent = 'Claim All Claimable (r)';

        // Named function for the click event
        const claimAllClickHandler = () => {
            this.claimAllUnclaimed();
        };

        // Use EventManager to add the click event listener
        this.eventManager.addDomListener(claimAllButton, 'click', claimAllClickHandler);

        // Append the button to the target column
        this.targetCol.appendChild(claimAllButton);

        if (this.ui.gameManager.injectAdminSettings){
            //and create a second button that will claim all achievements regardless of if they are claimable or not
            let claimAllUnclaimedButton = this.ui.createElement('button', 'claim-all-unclaimed-achievements-button', 'claim-all-unclaimed-achievements-button');
            claimAllUnclaimedButton.textContent = 'Claim All Unclaimed';
            const claimAllUnclaimedClickHandler = () => {
                for (const achievementSet of this.achievementSets) {
                    achievementSet.achievements.forEach(achievement => {
                        if (!achievement.isClaimable){
                            achievement.setActive();
                        }
                        if (!achievement.isClaimed) {
                            this.claimAchievement(achievement);
                        }
                    });
                }
            };
            // Use EventManager to add the click event listener
            this.eventManager.addDomListener(claimAllUnclaimedButton, 'click', claimAllUnclaimedClickHandler);
            // Append the button to the target column
            this.targetCol.appendChild(claimAllUnclaimedButton);
        }
    }

    claimAllUnclaimed(){
        for (const achievementSet of this.achievementSets) {
            achievementSet.achievements.forEach(achievement => {
                if (achievement.isClaimable && !achievement.isClaimed) {
                    this.claimAchievement(achievement);
                }
            });
        }
    }
    
    updateDisplay(){
        for (const achievementSet of this.achievementSets){			
            achievementSet.achievements.forEach(achievement => {
                const achievementID = `achievement-${achievement.id}`;
                let achievementCell = this.targetCol.querySelector(`#${achievementID}`);
                let achievementBackground = achievementCell.querySelector('.achievement-background');
    
                this.ui.updateTooltip(achievement);
    
                if (achievement.isClaimed) {
                    achievementCell.disabled = true;
                    achievementCell.classList.remove('enabled','disabled', 'claimable-indicator');
                    achievementCell.style.fontWeight = 'bold';
                    achievementCell.style.border = `4px solid ${achievement.set.color}`;
                    
                    achievementBackground.style.filter = 'none';
                }
                else if (achievement.isClaimable && !achievement.isClaimed) {
                    achievementCell.disabled = false;
                    achievementCell.classList.add('enabled', 'claimable-indicator');
                    achievementCell.classList.remove('disabled');
                    achievementCell.style.border = `2px solid ${achievement.set.color}`;
                    achievementBackground.style.filter = 'grayscale(100%)';
                }
                else {
                    achievementCell.disabled = true;
                    achievementCell.classList.add('disabled');
                    achievementCell.classList.remove('enabled',  'claimable-indicator');
                    // achievementCell.style.border = '2px solid #ccc';
                    achievementCell.style.border = `1px solid ${achievement.set.color}`;
                    achievementBackground.style.filter = 'grayscale(100%)';
                }
    
                achievementCell.style.display = achievementSet.visible ? 'block' : 'none';
                achievementCell.style.pointerEvents = achievementSet.visible ? 'auto' : 'none';
            });
        }
    }
        
    

    claimAchievement(achievement) {
		achievement.claim();
	}
}