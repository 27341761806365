export default class ForgeUpgradeState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "forgeUpgrades";
        
        this.propertiesBase = ['baseLevel'];

        this.regularPropertiesFull = ['level', 'manualLevel', 'autoLevel',
		'active', 'prodCurrentGlobal', 'prodPrevious','prodNextMultPurchase','prodNextSingle','prodMult', 'costNextMultPurchase','costNextSingle','costMult','maxAffLvl',  'evolutionTier'];

        this.propertiesFull = this.propertiesBase.concat(this.regularPropertiesFull);
    }

    saveState(state){
        this.populateSaveDataBase();

        switch (state) {
			case 0:
				this.populateSaveDataFull();
				break;
			case 1:
				this.populateSaveDataRebirth1();
				break;
			case 2:
				this.populateSaveDataRebirth2();
				break;
			case 3:
				this.populateSaveDataRebirth3();
				break;
		}
    }
    
    loadState(state, gameData){
        this.applyLoadDataBase(gameData);

        switch (state) {
			case 0:
				this.applyLoadDataFull(gameData);
				break;
			case 1:
				this.applyLoadDataRebirth1(gameData);
				break;
			case 2:
				this.applyLoadDataRebirth2();
				break;
			case 3:
				this.applyLoadDataRebirth3();
				break;
		}
    }

    populateSaveDataBase(){}


    populateSaveDataFull() {
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
    }

    populateSaveDataRebirth1(){
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
	}
    populateSaveDataRebirth2(){}
    populateSaveDataRebirth3(){}

    

    applyLoadDataBase(gameData){}

    applyLoadDataFull(gameData) {
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);
		// Don't need this here after implementing ModState
		// as they propogate the ModTrees and update multipliers automatically
        // this.reApplyForgeUpgrades(gameData);
    }
    
    
    
    applyLoadDataRebirth1(gameData){
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);


		let forceTrainAuto = this.gameManager.findObjectById(10009);
		if (forceTrainAuto.level.gt(0)){
			for (const training of this.gameManager.gameContent.trainings.filter(training => training.realmID === 10)){
				training.autoUnlocked = true;
				training.autoToggle = true;
				this.gameManager.automationManager.forceHeap.add(training);
			}
		}

		let forceUpgradeAuto = this.gameManager.findObjectById(10010);
		if (forceUpgradeAuto.level.gt(0)){
			for (const upgrade of this.gameManager.gameContent.realmUpgrades.filter(upgrade => upgrade.realmID === 10)){
				upgrade.autoUnlocked = true;
				upgrade.autoToggle = true;
				this.gameManager.automationManager.forceHeap.add(upgrade);
			}
		}

		let wisdomTrainAuto = this.gameManager.findObjectById(10109);
		if (wisdomTrainAuto.level.gt(0)){
			for (const generator of this.gameManager.gameContent.generators.filter(generator => generator.realmID === 20)){
				generator.autoUnlocked = true;
				generator.autoToggle = true;
				this.gameManager.automationManager.wisdomHeap.add(generator);
			}
		}
		let wisdomUpgradeAuto = this.gameManager.findObjectById(10110);
		if (wisdomUpgradeAuto.level.gt(0)){
			for (const upgrade of this.gameManager.gameContent.realmUpgrades.filter(upgrade => upgrade.realmID === 20)){
				upgrade.autoUnlocked = true;
				upgrade.autoToggle = true;
				this.gameManager.automationManager.wisdomHeap.add(upgrade);
			}
		}
		let energyTrainAuto = this.gameManager.findObjectById(10209);
		if (energyTrainAuto.level.gt(0)) {
			for (const training of this.gameManager.gameContent.trainings.filter(training => training.realmID === 30)) {
				training.autoUnlocked = true;
				training.autoToggle = true;
				this.gameManager.automationManager.energyHeap.add(training);
			}
		}
		let energyUpgradeAuto = this.gameManager.findObjectById(10210);
		if (energyUpgradeAuto.level.gt(0)) {
			for (const upgrade of this.gameManager.gameContent.realmUpgrades.filter(upgrade => upgrade.realmID === 30)) {
				upgrade.autoUnlocked = true;
				upgrade.autoToggle = true;
				this.gameManager.automationManager.energyHeap.add(upgrade);
			}
		}
		let divineTrainAuto = this.gameManager.findObjectById(10309);
		if (divineTrainAuto.level.gt(0)) {
			for (const training of this.gameManager.gameContent.trainings.filter(training => training.realmID === 40)) {
				training.autoUnlocked = true;
				training.autoToggle = true;
				this.gameManager.automationManager.divineHeap.add(training);
			}
		}
		let divineUpgradeAuto = this.gameManager.findObjectById(10310);
		if (divineUpgradeAuto.level.gt(0)) {
			for (const upgrade of this.gameManager.gameContent.realmUpgrades.filter(upgrade => upgrade.realmID === 40)) {
				upgrade.autoUnlocked = true;
				upgrade.autoToggle = true;
				this.gameManager.automationManager.divineHeap.add(upgrade);
			}
		}

		
		let artifactAuto = this.gameManager.findObjectById(10416);
		if (artifactAuto.level.gt(0)) {
			for (const artifact of this.gameManager.gameContent.artifacts) {
				artifact.autoUnlocked = true;
				artifact.autoToggle = true;
				this.gameManager.automationManager.artifactAutobuys.push(artifact);
			}
		}

	}
    applyLoadDataRebirth2(){}
    applyLoadDataRebirth3(){}



	

    reApplyForgeUpgrades(gameData){
        gameData.forgeUpgrades.forEach(data => {
			for (const forgeUpgrade of this.gameManager.gameContent.forgeUpgrades )
			if (data.id === forgeUpgrade.id) {
				if (data.active){
					forgeUpgrade.setActive();
					forgeUpgrade.updateObservers();
				}
			}
		});
    }
}