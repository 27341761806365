export default class ProgressBarManager {
    constructor(ui, gameContent, worldDisplayManager) {
        this.ui = ui;
        this.gameContent = gameContent;
        this.worldManager = this.gameContent.worldManager;
        this.worldDisplayManager = worldDisplayManager;
    }

    createProgressBars(container) {
        let universeProgressBarContainer = this.ui.createElement('div', `universe-progress-bar-container`);
        let universeProgressBar = this.ui.createElement('div', 'universe-progress-bar');
        let universeProgressText = this.ui.createElement('div', null, 'progress-text');
        universeProgressBarContainer.appendChild(universeProgressBar);
        universeProgressBarContainer.appendChild(universeProgressText);
        container.appendChild(universeProgressBarContainer);
    
        let progressBarContainer = this.ui.createElement('div', `odyssey-progress-bar-container`);
        let currentViewProgressBar = this.ui.createElement('div', 'odyssey-progress-bar');
        let currentViewProgressText = this.ui.createElement('div', null, 'progress-text');
        progressBarContainer.appendChild(currentViewProgressBar);
        progressBarContainer.appendChild(currentViewProgressText);
        container.appendChild(progressBarContainer);
    }

    updateProgressBars() {
        let universeProgressBar = document.getElementById(`universe-progress-bar`);
        let universeProgressText = document.querySelector(`#universe-progress-bar-container .progress-text`);
        let currentViewProgressBar = document.getElementById(`odyssey-progress-bar`);
        let currentViewProgressText = document.querySelector(`#odyssey-progress-bar-container .progress-text`);
    
        let viewType = this.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentViewType;
    
        if (viewType === "region") {
            // Show World Progress and Region Progress when viewing a region
            let currentWorld = this.worldDisplayManager.currentlyViewedRegion.world;
            this.updateProgressBar(universeProgressBar, universeProgressText, currentWorld, "world");
            this.updateProgressBar(currentViewProgressBar, currentViewProgressText, this.worldDisplayManager.currentlyViewedRegion, "region");
        } else {
            // Show Universe Progress and World Progress when viewing a world
            this.updateProgressBar(universeProgressBar, universeProgressText, null, "universe");
            this.updateProgressBar(currentViewProgressBar, currentViewProgressText, this.worldDisplayManager.currentlyViewedWorld, "world");
        }
    }
    
    updateProgressBar(progressBar, progressText, feature, viewType) {
        let newWidth, newText;
        switch (viewType) {
            case "universe":
                newWidth = this.worldManager.progressPercentage + "%";
                newText = this.worldManager.progressPercentage.toFixed(2) + "% of the Universe complete";
                break;
            case "world":
                newWidth = feature.progressPercentage + "%";
                newText = feature.progressPercentage.toFixed(2) + "% of the World complete";
                break;
            case "region":
                newWidth = feature.progressPercentage + "%";
                newText = feature.progressPercentage.toFixed(2) + "% of the Region complete";
                break;
        }
        if (progressBar.style.width !== newWidth) {
            progressBar.style.width = newWidth;
        }
        progressText.textContent = newText;
    }
}