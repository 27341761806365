import { DEC } from '../../Utilities/decimal.js';

export default class Region {
	constructor(eventManager,id,worldID,name, mapFilename, note, shardType, costRanges, topLeftCornerX, topLeftCornerY, active) {
		this.eventManager = eventManager;
		this.id = id;
		this.worldID = worldID;
		this.world = null;
		this.name = name;
		this.mapFilename = mapFilename;
		this.note = note;

		this.featureType = "region";

		this.shardType = shardType;
		this.costRanges = costRanges;

		this.zones = [];

		this.active = active;
		this.isProgressed = false;
		this.isCompleted = false;

		this.totalZoneCount = 0;
		this.totalZonesCompleted = 0;
		this.progressPercentage = 0;

		this.currentlyViewing = false;

		this.topLeftCornerX = topLeftCornerX;
		this.topLeftCornerY = topLeftCornerY;

		this.image = null;

		this.zoneAvailable = null; //for tracking current zone availability/activity
	}

	setCompleted() {
		this.isCompleted = true;
		this.world.regionCompleted();
	}

	setProgressed(){
		this.isProgressed = true;
		
		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: "lifetimeRegionProgressions",
			value: DEC(1),
			operation: 'add'
		});

		//is this needed - this breaks "complete next world" functionality
		// this.world.regionProgressed();
	}

	setActive() {
		this.active = true;
		this.eventManager.dispatchEvent('updateMiniMap');
		// if (this.zones[0]){
		// 	this.zones[0].setActive();
		// }
	}

	updateZoneAvailability() {
		// Check if any zone is currently in conquesting state
		if (this.zones.some(zone => zone.isConquesting)) {
			this.zoneAvailable = 'conquesting';
		} 
		// Check if any zone is active, unlocked, and not defeated
		else if (this.zones.some(zone => zone.active && zone.unlocked && !zone.isDefeated && zone.isAffordable)) {
			this.zoneAvailable = 'available';
		} 
		// Default to not available
		else {
			this.zoneAvailable = 'notAvailable';
		}
	}
	

	checkAllZonesCompleted() {
		const allZonesDefeated = this.zones.every(zone => zone.isDefeated);
		if (allZonesDefeated) {
			this.setCompleted();
		}
	}
	
	zoneCompleted(zone){
		this.totalZonesCompleted++;
		this.progressPercentage = this.totalZonesCompleted / this.totalZoneCount * 100;

		this.world.zoneCompleted();
		if (zone.zoneType === "boss" && !this.isProgressed){
			this.setProgressed();
		}
		
		if (!this.isCompleted){
			this.checkAllZonesCompleted();
		}
	}
}
