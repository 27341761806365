export default class TrainingState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "trainings";


		// took these out cuz realm evolution writes over cost/prodbase
        // this.baseFeatureProperties = ['baseLevel', 'costBase', 'prodBase'];
        this.propertiesBase = ['baseLevel'];


        this.autoFeatureProperties = ['autoUnlocked','autoToggle'];

        this.rankPropertiesFull = ['nextRankMult','nextRankLevel','nextAffordableRankLevel','ranksAchieved'];

        this.regularPropertiesFull = ['level', 'manualLevel', 'autoLevel',
		'active', 'prodCurrentGlobal', 'prodPrevious','prodNextMultPurchase','prodNextSingle','prodMult', 'costNextMultPurchase','costNextSingle','costMult','maxAffLvl',  'evolutionTier','nextLevelIncrement'];

        this.propertiesFull = this.propertiesBase.concat(this.regularPropertiesFull).concat(this.rankPropertiesFull).concat(this.autoFeatureProperties);
    }

    saveState(state){
        this.populateSaveDataBase();

        switch (state) {
			case 0:
				this.populateSaveDataFull();
				break;
			case 1:
				this.populateSaveDataRebirth1();
				break;
			case 2:
				this.populateSaveDataRebirth2();
				break;
			case 3:
				this.populateSaveDataRebirth3();
				break;
		}
    }
    
    loadState(state){
        this.applyLoadDataBase();

        switch (state) {
			case 0:
				this.applyLoadDataFull();
				break;
			case 1:
				this.applyLoadDataRebirth1();
				break;
			case 2:
				this.applyLoadDataRebirth2();
				break;
			case 3:
				this.applyLoadDataRebirth3();
				break;
		}
    }

    populateSaveDataBase(){}

    populateSaveDataFull() {
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
    }

    populateSaveDataRebirth1(){}
    populateSaveDataRebirth2(){}
    populateSaveDataRebirth3(){}

    

    applyLoadDataBase(){}

    applyLoadDataFull() {
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);

        // apply realm evolutions
		for(const feature of this.gameManager.gameContent.trainings){
			feature.evolve(feature.evolutionTier);
		}

        this.initializeTrainingIncome();
    }
    
    
    
    applyLoadDataRebirth1(){}
    applyLoadDataRebirth2(){}
    applyLoadDataRebirth3(){}

    initializeTrainingIncome(){
        this.gameManager.gameContent.trainings.forEach((training) => {
            if (training.active) {
                let incomeType = training.prodType;
                if (this.gameManager.gameContent.hasOwnProperty(incomeType)) {
                    this.gameManager.gameContent[incomeType] = this.gameManager.gameContent[incomeType].plus(training.prodCurrentGlobal);
                } else {
                    console.error(`Income type ${incomeType} does not exist on gameManager.gameContent`);
                }
            }
        });
    }

}