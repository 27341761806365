import Decimal from '../Utilities/break_eternity.min.js'; // large number library
import { DEC } from '../Utilities/decimal.js';
import Observable from './Observable.js';
import { floor } from '../Utilities/break_eternity.min.js';

export default class GameFeature extends Observable {
	constructor(eventManager, id, name, note, description, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active = false) {
		super();

		this.eventManager = eventManager;
		this.id = id;
		this.featureType = null;
		this.name = name;
		this.note = note;
		this.description = description;

		this.maxLevel = DEC(maxLevel);
		this.autoLevel = DEC(0);
		this.manualLevel = DEC(0);
		this.baseLevel = DEC(0);
		this.level = DEC(level);

		this.maxAffLvl = DEC(0);
		this.nextAffordableRankLevel = DEC(0);
		this.nextRankLevel = DEC(0);
		this.nextRankMult = DEC(0);

		this.nextLevelIncrement = DEC(0);

		this.costType = costType;
		this.costGrowthRate = DEC(costGrowthRate);
		this.costMultBase = DEC(1);
		this.costMult = DEC(1);
		this.costBase = DEC(costBase);
		this.costNextMultPurchase = DEC(0);
		this.costNextSingle = this.calcCostNextSingle();

		this.prodType = prodType;
		this.prodGrowthRate = DEC(prodGrowthRate);
		this.prodMultBase = DEC(1);
		this.prodMult = DEC(1);
		this.prodPrevious = DEC(0);
		this.prodBase = DEC(prodBase);
		this.prodCurrentGlobal = DEC(0);
		this.prodNextMultPurchase = DEC(0);
		this.prodNextSingle = this.calcProdNextSingle();

		this.prodCurrentGlobal = DEC(0);

		this.modTreesMap = new Map();
		this.active = active;

		this.autoUnlocked = false;
		this.autoToggle = false;
		this.currentAutoHeap = null;

		this.ranksAchieved = DEC(0);

		this.buttonStatusOption = 0;

	}
	
	calcGeometricSum(a, r, n) {
		var a_dec = DEC(a); // next single value (cost or prod)
		var r_dec = DEC(r); // growth-rate
		var n_dec = DEC(n); // count to calculate

		var numerator = Decimal.sub(1, Decimal.pow(r_dec, n_dec));
		var denominator = Decimal.sub(1, r_dec);

		var n = Decimal.div(Decimal.mul(a_dec, numerator), denominator);
		return n;
	}

	calcCostNextSingle(){
		const cost =  DEC(this.costBase).mul(this.costMult).mul(Decimal.pow(this.costGrowthRate, this.manualLevel === 0 ? DEC(1) : this.manualLevel))
		.floor(); // Round down to the nearest whole number
		
		return cost.gt(0) ? cost : DEC(1);
	}

	calcProdNextSingle() {
		const cost = DEC(this.prodBase).mul(this.prodMult).mul(Decimal.pow(this.prodGrowthRate, this.level === 0 ? DEC(0) : this.level));

		return cost.gt(0) ? cost : DEC(1);
	}

	calculateCostN(n, startLevel = this.manualLevel) {
		const cost =  this.calcGeometricSum(this.costNextSingle, this.costGrowthRate, n, startLevel).floor();
		
		return cost.gt(0) ? cost : DEC(1);
	}

	calculateProdN(n, startLevel = this.level) {
		if (this.featureType === "generator") {
			return this.prodBase.times(n).times(this.prodGrowthRate).times(this.prodMult);
		}
		else {
			var nextPurchaseProduction = DEC(this.prodBase).mul(this.prodMult).mul(Decimal.pow(this.prodGrowthRate, startLevel));

			return this.calcGeometricSum(nextPurchaseProduction, this.prodGrowthRate, n);
		}
	}

	updateValuesDigit(n) {
		// Adjust n if it would take the level above the max
		if ((n.plus(this.level)).gt(this.maxLevel)) {
			n = this.maxLevel.minus(this.level);
		}

		this.costNextMultPurchase = this.calculateCostN(n, this.manualLevel);
		
		if (this.prodType) {
			this.prodNextMultPurchase = this.calculateProdN(n, this.manualLevel);
		}
		this.nextLevelIncrement = n;
	}

	updateValuesRank() {
		this.setNextAffordableRankLevel();
		if (this.nextAffordableRankLevel) {

			let levelDif = this.nextAffordableRankLevel.minus(this.manualLevel);
			this.costNextMultPurchase = this.calculateCostN(levelDif, this.manualLevel);
			this.prodNextMultPurchase = this.calculateProdN(levelDif, this.manualLevel);
			this.nextLevelIncrement = levelDif;
		}
		else {
			let n = DEC(1);
			this.nextLevelIncrement = n;
			this.costNextMultPurchase = this.calculateCostN(n);
			this.prodNextMultPurchase = this.calculateProdN(n);
		}
	}

	updateValuesMax(resource) {
		if (resource.lt(this.costNextSingle)) {
			this.maxAffLvl = DEC(0);
			this.nextLevelIncrement = DEC(1);
			this.costNextMultPurchase = this.costNextSingle;
			this.prodNextMultPurchase = this.prodNextSingle;
		}
		else {
			this.maxAffLvl = this.calculateMaxAffordable(resource);
			this.nextLevelIncrement = this.maxAffLvl;
			this.costNextMultPurchase = this.calculateCostN(this.maxAffLvl);
			this.prodNextMultPurchase = this.calculateProdN(this.maxAffLvl);
		}
	}

	calculateMaxAffordable(resource) {
		var S_dec = DEC(resource);
		var a_dec = this.costNextSingle;
		var r_dec = DEC(this.costGrowthRate);

		if (!r_dec.gt(1) || !S_dec.gte(a_dec)) {
			console.error(this.name, 'Invalid inputs:', S_dec.toString(), a_dec.toString(), r_dec.toString());
			return DEC(0);
		}

		var inner = Decimal.mul(S_dec, Decimal.sub(r_dec, 1));
		inner = Decimal.div(inner, a_dec);
		inner = Decimal.add(inner, 1);

		if (inner.lt(0)) {
			console.error(this.name, 'Negative inner:', inner.toString());
			return DEC(0);
		}

		var n = Decimal.log(inner, r_dec).floor();


		if (n.gt(this.maxLevel.minus(this.manualLevel))) {
			n = this.maxLevel.minus(this.manualLevel);
		}

		return n;
	}

	rebuildModTrees(){
		this.modTreesMap.forEach((targetModTree) => {
			targetModTree.buildTree();
		});
	}

	applyGlobalMultsToMultBases(globalFeatureMult){
		//dont update value if global mult is unchanged
		if (globalFeatureMult.neq(this.prodMultBase)){
			this.prodMultBase = globalFeatureMult;
			this.rebuildModTrees();
			this.updateFeatureValues(false);
		}
	}

	setActive() {
		this.active = true;
	}

	setInactive(){
		this.active = false;
	}

	deactivateObservers(){
		for (const observer of this.observers) {
			if (observer.active) {
				observer.active = false;
				for (const targetTree of observer.modTreeReferences){
					targetTree.buildTree();
					this.eventManager.dispatchEvent('updateFeatureValues', { target: targetTree.parent, isNewLvl: false });
					
					this.eventManager.dispatchEvent('updateNewMultiplierValues', { feature: targetTree.parent });
				}
			}
		}
	}

	levelUp(auto, count = null) {
		if (this.level.eq(0)) {
			this.setActive();
		}

		if (auto === "manual") {
			let levelUpCount = this.nextLevelIncrement;
			if (count){
				levelUpCount = DEC(count);
			}
			this.manualLevel = this.manualLevel.plus(levelUpCount);
			this.level = this.level.plus(levelUpCount);
		}
		else if (auto === "auto") {
			this.autoLevel = this.autoLevel.plus(count);
			this.level = this.level.plus(count);
		}

		this.updateObservers();

		if (this.rankTiers && this.manualLevel.gte(this.nextRankLevel)){
			this.setNextRankLevel();
			this.eventManager.dispatchEvent('rankAchieved',this);
		}

		if (this.featureType === "artifact" && this.level.gte(this.maxLevel)){
			this.evolve();
		}
	}
	
	updateObservers() {
		// console.error("uo",this.name);
		for (const observer of this.observers) {
			// check if this mod needs special activation from another source, and if that source is active
			if (!observer.specialActivatorID && observer.source.active){
				observer.active = true;
			}
			else if (observer.specialActivatorID === this.id){
				observer.active = true;
			}
			// if it matched either of this, it skips this mod(observer), that mod is not activated yet
			if (!observer.active) continue;

			// Handle single target mods
			if (observer.source && observer.target && observer.target !== observer.source) {
				// ^ third condition is for essence upgrades to work
				observer.target.modTreesMap.forEach((targetModTree) => {
					targetModTree.buildTree();
					// console.error(targetModTree);
				});

				observer.target.updateFeatureValues(false);
				// this.eventManager.dispatchEvent('updateFeatureValues', { target: observer.target, isNewLvl: false });
			}

			// Handle Type Target Mods
			else if (observer.targetType) {
				for (const targetModTree of observer.modTreeReferences) {
					targetModTree.buildTree();
					if (targetModTree.parent.active) {
						targetModTree.parent.updateFeatureValues(false);
						// this.eventManager.dispatchEvent('updateFeatureValues', { target: targetModTree.parent, isNewLvl: false });
					}
				}
			}
		}
	}
	
	setNextRankLevel(){
		this.nextRankLevel = this.rankTiers.find(tier => tier.gt(this.manualLevel));
		if (this.nextRankLevel){
			this.nextRankMult = this.modTreesMap.get("production").nodes.find(t => t.ref.name === this.id + "rank" + this.nextRankLevel.toString()).ref.value;
		}

		//set ranksAchieved equal to its index location in rankTiers
		this.ranksAchieved = this.rankTiers.indexOf(this.nextRankLevel);
	}

	setNextAffordableRankLevel() {
		for (let i = 0; i < this.rankTiers.length; i++) {
			let rank = this.rankTiers[i];
			if (this.manualLevel.lt(rank)) {
				this.nextAffordableRankLevel = DEC(rank);
				return;
			}
		}
		this.nextAffordableRankLevel = null;
	}
}