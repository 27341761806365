export default class FighterTierState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "fighterTiers";


        this.regularPropertiesFull = ['isCompleted','active'];

        this.propertiesFull = this.regularPropertiesFull;

    }

    saveState(state){
        this.populateSaveDataBase();

        switch (state) {
			case 0:
				this.populateSaveDataFull();
				break;
			case 1:
				this.populateSaveDataRebirth1();
				break;
			case 2:
				this.populateSaveDataRebirth2();
				break;
			case 3:
				this.populateSaveDataRebirth3();
				break;
		}
    }
    
    loadState(state){
        this.applyLoadDataBase();

        switch (state) {
			case 0:
				this.applyLoadDataFull();
				break;
			case 1:
				this.applyLoadDataRebirth1();
				break;
			case 2:
				this.applyLoadDataRebirth2();
				break;
			case 3:
				this.applyLoadDataRebirth3();
				break;
		}
    }

    populateSaveDataBase(){}

    populateSaveDataFull() {
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
    }

    populateSaveDataRebirth1(){
    }
    populateSaveDataRebirth2(){}
    populateSaveDataRebirth3(){}

    

    applyLoadDataBase(){}

    applyLoadDataFull() {
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);
    }
    
    applyLoadDataRebirth1(){
    }
    applyLoadDataRebirth2(){}
    applyLoadDataRebirth3(){}


}