import Decimal from '../Utilities/break_eternity.min.js'; // large number library
import { DEC } from '../Utilities/decimal.js';

import SkillTree from '../Features/Skills/SkillTree.js';
import AchievementGrid from '../Features/Achievements/AchievementGrid.js';
import WorldManager from '../Features/Odyssey/WorldManager.js';

export default class GameContent {
	constructor(eventManager) {
		this.eventManager = eventManager;

		this.idToObjectMap = new Map();

		this.playerName = "Player";

		this.realms = [];
		this.trainings = [];
		this.realmUpgrades = [];
		this.generators = [];
		this.generatorChains = [];
		this.skillTree = new SkillTree(eventManager);
		this.skills = [];
		this.unlocks = new Map();
		this.essenceUpgrades = [];
		this.forgeUpgrades = [];
		this.radianceUpgrades = [];

		this.achievementsGrid = new AchievementGrid(eventManager);
		this.achievementSets = [];
		this.achievements = [];


		this.worldManager = new WorldManager(eventManager, this);
		this.worlds = [];
		this.regions = [];
		this.zones = [];
		
		this.artifacts = [];

		this.tournament;
		this.fighters = [];
		this.fighterTiers = [];

		this.mods = [];

		
		this.powerLevel = DEC(1);

		this.baseForce = DEC(10);
		this.force = this.baseForce;
		this.forceIncome = DEC(0);
		this.forceSynergyMult = DEC(1);
		this.baseForcePowerLevelMultiplier = DEC(1);
		this.forcePowerLevelMultiplier = this.baseForcePowerLevelMultiplier;
		this.powerLevelFromForce = DEC(0);

		this.baseWisdom = DEC(0);
		this.wisdom = this.baseWisdom;
		this.wisdomIncome = DEC(0);
		this.wisdomSynergyMult = DEC(1);
		this.baseWisdomPowerLevelMultiplier = DEC(10);
		this.wisdomPowerLevelMultiplier = this.baseWisdomPowerLevelMultiplier;
		this.powerLevelFromWisdom = DEC(0);

		this.baseEnergy = DEC(0);
		this.energy = this.baseEnergy;
		this.energyIncome = DEC(0);
		this.energySynergyMult = DEC(1);
		this.baseEnergyPowerLevelMultiplier = DEC(100);
		this.energyPowerLevelMultiplier = this.baseEnergyPowerLevelMultiplier;
		this.powerLevelFromEnergy = DEC(0);

		this.baseDivine = DEC(0);
		this.divine = this.baseDivine;
		this.divineIncome = DEC(0);
		this.divineSynergyMult = DEC(1);
		this.baseDivinePowerLevelMultiplier = DEC(1000);
		this.divinePowerLevelMultiplier = this.baseDivinePowerLevelMultiplier;
		this.powerLevelFromDivine = DEC(0);
		
		this.forceIncomeDisplay = DEC(0);
		this.wisdomIncomeDisplay = DEC(0);
		this.energyIncomeDisplay = DEC(0);
		this.divineIncomeDisplay = DEC(0);
		

		this.timeModifierUpgrade = DEC(1);
		
		this.synergyUpgrades = {
			unspentForceToWisdom: DEC(0),
			unspentForceToEnergy: DEC(0),
			unspentForceToDivine: DEC(0),
			unspentWisdomToForce: DEC(0),
			unspentWisdomToEnergy: DEC(0),
			unspentWisdomToDivine: DEC(0),
			unspentEnergyToForce: DEC(0),
			unspentEnergyToWisdom: DEC(0),
			unspentEnergyToDivine: DEC(0),
			unspentDivineToForce: DEC(0),
			unspentDivineToWisdom: DEC(0),
			unspentDivineToEnergy: DEC(0)
		};

		this.baseCrystal = DEC(0);
		this.crystal = this.baseCrystal;

		this.radiance = DEC(0);

		this.shards = new Map();
			this.shards.set("alphaShard", DEC(0));
			this.shards.set("betaShard", DEC(0));
			this.shards.set("gammaShard", DEC(0));
			this.shards.set("deltaShard", DEC(0));
			this.shards.set("epsilonShard", DEC(0));
			this.shards.set("zetaShard", DEC(0));
			this.shards.set("etaShard", DEC(0));
			this.shards.set("thetaShard", DEC(0));
			this.shards.set("iotaShard", DEC(0));
			this.shards.set("kappaShard", DEC(0));
			this.shards.set("lambdaShard", DEC(0));
			this.shards.set("muShard", DEC(0));
			this.shards.set("nuShard", DEC(0));
			this.shards.set("xiShard", DEC(0));
			this.shards.set("omicronShard", DEC(0));
		
		this.shardBonusMultipliers = {
			alphaShard: DEC(0.01),
			betaShard: DEC(0.05),
			gammaShard: DEC(0.2),
			deltaShard: DEC(0.5),
			epsilonShard: DEC(1),
			zetaShard: DEC(1.5),
			etaShard: DEC(2),
			thetaShard: DEC(3),
			iotaShard: DEC(5),
			kappaShard: DEC(10),
			lambdaShard: DEC(20),
			muShard: DEC(50),
			nuShard: DEC(75),
			xiShard: DEC(100),
			omicronShard: DEC(1000)
		};
			
		this.previousShardCounts = {};
		this.computedShardMultipliers = {};

		this.overallShardBonusMultiplier = DEC(1);

		this.baseShardRarities = [
			{ type: 'alphaShard', rarity: DEC(1) },
			{ type: 'betaShard', rarity: DEC(0.5) },
			{ type: 'gammaShard', rarity: DEC(0.2) },
			{ type: 'deltaShard', rarity: DEC(0.1) },
			{ type: 'epsilonShard', rarity: DEC(0.2) },
			{ type: 'zetaShard', rarity: DEC(0.05) },
			{ type: 'etaShard', rarity: DEC(0.01) },
			{ type: 'thetaShard', rarity: DEC(0.05) },
			{ type: 'iotaShard', rarity: DEC(0.01) },
			{ type: 'kappaShard', rarity: DEC(0.05) },
			{ type: 'lambdaShard', rarity: DEC(0.005) },
			{ type: 'muShard', rarity: DEC(0.001) },
			{ type: 'nuShard', rarity: DEC(0.001) },
			{ type: 'xiShard', rarity: DEC(0.001) },
			{ type: 'omicronShard', rarity: DEC(0.0005) }
		];

		this.baseEssence = DEC(0);
		this.essence = this.baseEssence;
		this.essenceIncome = DEC(0);

		this.baseSkillpoint = DEC(0);
		this.skillpoints = this.baseSkillpoint;


		this.lifetimeForceEarned = DEC(0);
		this.lifetimeWisdomEarned = DEC(0);
		this.lifetimeEnergyEarned = DEC(0);
		this.lifetimeDivineEarned = DEC(0);
		this.lifetimeEssenceEarned = DEC(0);
		this.lifetimeCrystalEarned = DEC(0);
		this.maxPowerLevelAchieved = DEC(0);
		
		this.lifetimeZoneCompletions = DEC(0);
		this.lifetimeRegionProgressions = DEC(0);
		this.lifetimeWorldProgressions = DEC(0);
		
		this.maxProgressionWorld = DEC(0);
		this.maxProgressionRegion = DEC(0);
		
		this.maxTournamentRank = DEC(201); //top rank within max stage
		this.lifetimeKills = DEC(0);
		this.lifetimeFighterTiersDefeated = DEC(0);


		this.lastRebirth1 = Date.now();
		this.rebirth1Count = DEC(0);
		this.originalStartDateTime = new Date();
		
		this.totalPlaytime = DEC(0);

		this.essenceGainOnNextRebirth1 = DEC(0);

		this.eventManager.addListener('updateCurrencyMult', (data) => {
			this.updateCurrencyMult(data);
		});

		this.eventManager.addListener('updatePropertyValue', data => {
			this.updatePropertyValue(data.property, data.value, data.operation);
		});

		this.eventManager.addListener('batchUpdatePropertyValues', batchData => {
            for (let data of batchData) {
                this.updatePropertyValue(data.property, data.value, data.operation);
            }
        });

		this.eventManager.addListener('queryPropertyValue', (data, respond) => {
			const result = this.queryPropertyValue(data);
			if (respond) {
				respond(result);
			}
		});
	}



	processTrainingIncome(deltaTimeSeconds){
		this.processTrainingCurrencyIncome('force', deltaTimeSeconds);
		this.processTrainingCurrencyIncome('wisdom', deltaTimeSeconds);
		this.processTrainingCurrencyIncome('energy', deltaTimeSeconds);
		this.processTrainingCurrencyIncome('divine', deltaTimeSeconds);
	}

	processTrainingCurrencyIncome(currency, deltaTimeSeconds){
		let newIncome = DEC(0);

		// handle generators
		if (currency === 'wisdom' || currency === 'divine'){
			let generatorChain = this.generatorChains.filter(generatorChain => generatorChain.realm.type == currency);
			
			for (const chain of generatorChain){
				let baseGenerator = chain.generators[0];

				if (chain.active && baseGenerator.active && baseGenerator.level.gt(0)){
					// apply synergy multipliers
					let currentSynergyMult = this[chain.realm.type + 'SynergyMult'];
					
					let globalFeatureMult = currentSynergyMult.times(this.overallShardBonusMultiplier);

					baseGenerator.applyGlobalMultsToMultBases(globalFeatureMult);

					newIncome = baseGenerator.prodCurrentGlobal;
				}
			}
		}

		// handle trainings
		else {
			let filteredTrainings = this.trainings.filter(training => training.costType == currency);

			for (const training of filteredTrainings){
				if (training.active && training.level.gt(0)){
					let currentSynergyMult = this[training.realm.type + 'SynergyMult'];

					let globalFeatureMult = currentSynergyMult.times(this.overallShardBonusMultiplier);

					training.applyGlobalMultsToMultBases(globalFeatureMult);

					newIncome = newIncome.plus(training.prodCurrentGlobal);
				}
				
			}
		}

		// add currency and set the overall currency income value
		if (newIncome.gt(0)) {
			this[currency + 'Income'] = newIncome.times(this.timeModifierUpgrade);
			this[currency] = this[currency].plus(newIncome.times(deltaTimeSeconds));
		  }
	}

	updateLifetimeValues(deltaTimeSeconds){
		this.lifetimeForceEarned = this.lifetimeForceEarned.plus(this.forceIncome.times(deltaTimeSeconds));
		this.lifetimeWisdomEarned = this.lifetimeWisdomEarned.plus(this.wisdomIncome.times(deltaTimeSeconds));
		this.lifetimeEnergyEarned = this.lifetimeEnergyEarned.plus(this.energyIncome.times(deltaTimeSeconds));
		this.lifetimeDivineEarned = this.lifetimeDivineEarned.plus(this.divineIncome.times(deltaTimeSeconds));
	}

	updateShardBonusMultipliers() {
		let overallMultiplier = DEC(1);
		let shardCountChanged = false;
	  
		for (let [shardType, baseMultiplier] of Object.entries(this.shardBonusMultipliers)) {
		  let currentShardCount = this.shards.get(shardType);
	  
		  if (!this.previousShardCounts.hasOwnProperty(shardType) || !currentShardCount.equals(this.previousShardCounts[shardType])) {
			shardCountChanged = true;
			this.previousShardCounts[shardType] = currentShardCount;
	  
			if (currentShardCount.gt(0)) {
			  let computedMultiplier = currentShardCount.times(baseMultiplier);
			  this.computedShardMultipliers[shardType] = computedMultiplier;
			  overallMultiplier = overallMultiplier.plus(computedMultiplier);
			} else {
			  this.computedShardMultipliers[shardType] = DEC(0);
			}
		  } else {
			overallMultiplier = overallMultiplier.plus(this.computedShardMultipliers[shardType]);
		  }
		}
	  
		if (shardCountChanged) {
		  this.overallShardBonusMultiplier = overallMultiplier;
		}
	  }

	// updateShardBonusMultipliers() {
	// 	let overallMultiplier = DEC(1);
	  
	// 	for (let [shardType, baseMultiplier] of Object.entries(this.shardBonusMultipliers)) {
	// 	  let shardCount = this.shards.get(shardType);
	  
	// 	  	if (shardCount.gt(0)) {
	// 			overallMultiplier = overallMultiplier.plus(shardCount.times(baseMultiplier));
	// 	 	}
	// 	}
	  
	// 	this.overallShardBonusMultiplier = overallMultiplier;
	// }

	updateCurrencySynergyMultipliers() {
		// Reset the multipliers to 1
		this.forceSynergyMult = DEC(1);
		this.wisdomSynergyMult = DEC(1);
		this.energySynergyMult = DEC(1);
		this.divineSynergyMult = DEC(1);
	  
		for (let synergy in this.synergyUpgrades) {
			let multiplier = this.synergyUpgrades[synergy];
			if (multiplier.gt(0)) {
				if (synergy === 'unspentForceToWisdom') {
				this.wisdomSynergyMult = this.wisdomSynergyMult.times(this.force.times(multiplier));
				} else if (synergy === 'unspentForceToEnergy') {
				this.energySynergyMult = this.energySynergyMult.times(this.force.times(multiplier));
				} else if (synergy === 'unspentForceToDivine') {
				this.divineSynergyMult = this.divineSynergyMult.times(this.force.times(multiplier));
				} else if (synergy === 'unspentWisdomToForce') {
				this.forceSynergyMult = this.forceSynergyMult.times(this.wisdom.times(multiplier));
				} else if (synergy === 'unspentWisdomToEnergy') {
				this.energySynergyMult = this.energySynergyMult.times(this.wisdom.times(multiplier));
				} else if (synergy === 'unspentWisdomToDivine') {
				this.divineSynergyMult = this.divineSynergyMult.times(this.wisdom.times(multiplier));
				} else if (synergy === 'unspentEnergyToForce') {
				this.forceSynergyMult = this.forceSynergyMult.times(this.energy.times(multiplier));
				} else if (synergy === 'unspentEnergyToWisdom') {
				this.wisdomSynergyMult = this.wisdomSynergyMult.times(this.energy.times(multiplier));
				} else if (synergy === 'unspentEnergyToDivine') {
				this.divineSynergyMult = this.divineSynergyMult.times(this.energy.times(multiplier));
				} else if (synergy === 'unspentDivineToForce') {
				this.forceSynergyMult = this.forceSynergyMult.times(this.divine.times(multiplier));
				} else if (synergy === 'unspentDivineToWisdom') {
				this.wisdomSynergyMult = this.wisdomSynergyMult.times(this.divine.times(multiplier));
				} else if (synergy === 'unspentDivineToEnergy') {
				this.energySynergyMult = this.energySynergyMult.times(this.divine.times(multiplier));
				}
		
				// If you wanted to update the training
				// const targetRealm = this.realms.filter(realm => realm.type === currency);
				// // will always return one realm
				// for (const training of targetRealm[0].trainings) {
				//   training.prodCurrentGlobal = training.prodCurrentGlobal.times(this[currency + 'SynergyMult']);
				// }
			}
		}
	}

	updatePowerLevel(deltaTimeSeconds){
		let powerLevelAddedFromForce = this.forceIncome.times(this.forceSynergyMult).times(this.forcePowerLevelMultiplier).times(deltaTimeSeconds);
		let powerLevelAddedFromWisdom = this.wisdomIncome.times(this.wisdomSynergyMult).times(this.wisdomPowerLevelMultiplier).times(deltaTimeSeconds);
		let powerLevelAddedFromEnergy = this.energyIncome.times(this.energySynergyMult).times(this.energyPowerLevelMultiplier).times(deltaTimeSeconds);
		let powerLevelAddedFromDivine = this.divineIncome.times(this.divineSynergyMult).times(this.divinePowerLevelMultiplier).times(deltaTimeSeconds);
		
		this.powerLevel = this.powerLevel.plus(powerLevelAddedFromForce).plus(powerLevelAddedFromWisdom).plus(powerLevelAddedFromEnergy).plus(powerLevelAddedFromDivine);

		if (this.powerLevel.gt(this.maxPowerLevelAchieved)){
			this.maxPowerLevelAchieved = this.powerLevel;
		}
		
		this.powerLevelFromForce = this.powerLevelFromForce.plus(powerLevelAddedFromForce);
		this.powerLevelFromWisdom = this.powerLevelFromWisdom.plus(powerLevelAddedFromWisdom);
		this.powerLevelFromEnergy = this.powerLevelFromEnergy.plus(powerLevelAddedFromEnergy);
		this.powerLevelFromDivine = this.powerLevelFromDivine.plus(powerLevelAddedFromDivine);
	}

	updateRebirthGainCalculation() {
		let powerLevel = this.powerLevel;
		let essenceGain;
	
		// Check if power level is below 1e60
		if (powerLevel.lt(1e60)) {
			// Set essence gain to 0 for power levels less than 1e60
			essenceGain = DEC(0);
		} else {
			// Base essence gain with logarithmic and diminishing returns
			essenceGain = powerLevel.dividedBy(1e60).log(100).plus(1).pow(0.5).max(1).round();
		}
	
		let newRebirthTime = Date.now();
		let timeSinceLastRebirth = newRebirthTime - this.lastRebirth1;
		// Convert timeSinceLastRebirth from milliseconds to hours
		let timeSinceLastRebirthHours = timeSinceLastRebirth / 3600000;  // 1 hour = 3600000 milliseconds
		// Increase essenceGain by 10% for each hour since the last rebirth
		essenceGain = essenceGain.times(1 + 0.1 * timeSinceLastRebirthHours);
	
		// Ensure the final essence gain is valid and not negative
		if (!essenceGain.isFinite() || essenceGain.lt(0)) {
			essenceGain = DEC(0);
		}
	
		this.essenceGainOnNextRebirth1 = DEC(essenceGain);
	}
	
	

	updateCurrencyMult(data){
		let valueType = data.valueType;
		let valueAmount = data.valueAmount;
	
		const valueTypeMap = {
			'forcePowerLevelMultiplier': { 'powerLevelFrom': 'powerLevelFromForce', 'multiplier': 'forcePowerLevelMultiplier' },
			'wisdomPowerLevelMultiplier': { 'powerLevelFrom': 'powerLevelFromWisdom', 'multiplier': 'wisdomPowerLevelMultiplier' },
			'energyPowerLevelMultiplier': { 'powerLevelFrom': 'powerLevelFromEnergy', 'multiplier': 'energyPowerLevelMultiplier' },
			'divinePowerLevelMultiplier': { 'powerLevelFrom': 'powerLevelFromDivine', 'multiplier': 'divinePowerLevelMultiplier' }
		};
	
		let mappedValues = valueTypeMap[valueType];
	
		if(mappedValues){
			//set power level from source to base level with no multiplier
			let newPowerLevelFromSource = this[mappedValues.powerLevelFrom].div(this[mappedValues.multiplier]);
	
			//update new multiplier
			this[mappedValues.multiplier] = this[mappedValues.multiplier].times(valueAmount);
	
			//calculate new power level contribution from source
			newPowerLevelFromSource = newPowerLevelFromSource.times(this[mappedValues.multiplier]);
	
			//subtract the old powerLevelFromSource value from overall powerLevel
			let newPowerLevel = this.powerLevel.minus(this[mappedValues.powerLevelFrom]);
	
			//add the new powerLevelFromSourceValue to power level
			newPowerLevel = newPowerLevel.plus(newPowerLevelFromSource);
	
			//update powerLevelFromSource and powerLevel with new values
			this[mappedValues.powerLevelFrom] = newPowerLevelFromSource;
			this.powerLevel = newPowerLevel;
		}
	}

	updatePropertyValue(property, value, operation) {
		// Check if property is a shard
		if (this.shards.has(property)) {
			let currentShardValue = this.shards.get(property);
	
			if (operation === "add") {
				this.shards.set(property, currentShardValue.plus(value));
			} 
			else if (operation === "subtract") {
				if (currentShardValue.lt(value)) {
					console.error(`Not enough ${property} to subtract.`);
					return;
				}
				this.shards.set(property, currentShardValue.minus(value));
			}
		} 
		// If not a shard, check if it's a valid property
		else if (this.hasOwnProperty(property)) {
			let currentProperty = this[property];
			if (!(currentProperty instanceof Decimal)) {
				console.error(`Property ${property} is not a valid Decimal property.`);
				return;
			}
			if (operation === "add") {
				this[property] = currentProperty.plus(value);
			} 
			else if (operation === "subtract") {
				if (currentProperty.lt(value)) {
					// console.error(`Not enough ${property} to subtract.`);
					return;
				}
				this[property] = currentProperty.minus(value);
			}
			else if (operation === "replaceIfGreater"){
				if (value.gt(currentProperty)) {
					this[property] = value;
				}
			}
			else if (operation === "replaceIfLesser"){
				if (value.lt(currentProperty)) {
					this[property] = value;
				}
			}
		} 
		// If it's not a valid property or shard, throw error
		else {
			console.error(`Property ${property} does not exist.`);
			return;
		}
	}

	queryPropertyValues(type) {
		const properties = [
			// currencies
			'force', 'wisdom', 'energy', 'divine', 'essence', 'skillpoints', 'crystal', 'radiance', 'powerLevel', 
		
			// lifetime stats
			'lifetimeForceEarned', 'lifetimeWisdomEarned', 'lifetimeEnergyEarned', 'lifetimeDivineEarned', 'lifetimeCrystalEarned', 'lifetimeEssenceEarned','totalPlaytime',
			
			//exploration stats
			'maxProgressionWorld','maxProgressionRegion','lifetimeZoneCompletions','lifetimeRegionProgressions','lifetimeWorldProgressions',
			
			//tournament stats
			'maxTournamentRank','lifetimeKills','lifetimeFighterTiersDefeated','rebirth1Count'
		];

		if (type === 'all') {
			let allResources = [];
			properties.forEach(prop => {
				if (prop in this) {
					allResources.push({ 'type': prop, 'value': this[prop] });
				}
			});
			this.shards.forEach((value, key) => allResources.push({ 'type': key, 'value': value }));
			return allResources;
		} 
		else if (properties.includes(type) && type in this) {
			return this[type];
		} 
		else if (this.shards.has(type)) {
			return this.shards.get(type);
		}
	}

}