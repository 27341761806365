export default class AchievementState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "achievements";

        this.regularPropertiesFull = ['level', 'manualLevel', 'autoLevel',
		'active', 'prodCurrentGlobal', 'prodPrevious','prodNextMultPurchase','prodNextSingle','prodMult', 'costNextMultPurchase','costNextSingle','costMult','maxAffLvl',  'evolutionTier'];

        this.claimPropertiesFull = ['isClaimable','isClaimed'];

        this.propertiesFull = this.regularPropertiesFull.concat(this.claimPropertiesFull);
    }

    saveState(state){
        this.populateSaveDataBase();

        switch (state) {
			case 0:
				this.populateSaveDataFull();
				break;
			case 1:
				this.populateSaveDataRebirth1();
				break;
			case 2:
				this.populateSaveDataRebirth2();
				break;
			case 3:
				this.populateSaveDataRebirth3();
				break;
		}
    }
    
    loadState(state){
        this.applyLoadDataBase();

        switch (state) {
			case 0:
				this.applyLoadDataFull();
				break;
			case 1:
				this.applyLoadDataRebirth1();
				break;
			case 2:
				this.applyLoadDataRebirth2();
				break;
			case 3:
				this.applyLoadDataRebirth3();
				break;
		}
    }

    populateSaveDataBase(){}

    populateSaveDataFull() {
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
    }

    populateSaveDataRebirth1(){
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
    }

    populateSaveDataRebirth2(){}

    populateSaveDataRebirth3(){}

    

    applyLoadDataBase(){}

    applyLoadDataFull() {
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);
        this.reApplyAchievements();
    }
    
    applyLoadDataRebirth1(){
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);
        this.reApplyAchievements();
    }

    applyLoadDataRebirth2(){}
    applyLoadDataRebirth3(){}


    reApplyAchievements(){
		for (const achievement of this.gameManager.gameContent.achievements){
			if (achievement.isClaimed){
				achievement.setActive();
				achievement.updateObservers();
				achievement.set.checkCompletion();

				//not doing anything? hmmmmm
				// for (const unlock of this.gameContent.unlocks){
				// 	if (unlock.target === achievement){
				// 		this.gameContent.unlockManager.transferUnlockToCompleted(unlock);
				// 	}
				// }
			}
			if (achievement.isClaimed || achievement.isClaimable){
				achievement.set.visible = true;
			}
		}
    }
}