import MiniMap from './MiniMap.js';

export default class MapViewManager {
    constructor(eventManager, ui, gameContent, worldDisplayManager) {
        this.eventManager = eventManager;
        this.ui = ui;
        this.gameContent = gameContent;
        this.worldManager = this.gameContent.worldManager;
        this.worldDisplayManager = worldDisplayManager;
        
        this.miniMap = new MiniMap(eventManager, ui, this.worldManager, this.worldDisplayManager);

        // Listening for view type changes to update the map view accordingly.
        this.eventManager.addListener('updateMapView', this.updateMapView.bind(this));
    }

    initializeMapView(container) {
        let mapView = this.ui.createElement('div', 'odyssey-current-view');
        let mapTitle = this.ui.createElement('div', 'odyssey-current-name');
        
        mapView.appendChild(mapTitle);
        container.appendChild(mapView);
        
        this.miniMap.createMiniMap(mapView);
    }

    setInitialView() {
        let firstWorld = this.worldManager.worlds[0];
        this.worldDisplayManager.currentlyViewedRegion = firstWorld.regions[0];
        this.worldDisplayManager.currentViewType = "region";
        firstWorld.regions[0].currentlyViewing = true;
        this.updateMapView(firstWorld.regions[0]);
    }

    updateMapView(newMapView) {
        const mapView = document.getElementById('odyssey-current-view');
        const mapTitle = document.getElementById('odyssey-current-name');

        this.ui.hideChildren(mapView);
        this.clearCurrentView();

        newMapView.currentlyViewing = true;

        if (newMapView.featureType === "world") {
            this.showWorldView(newMapView);
            this.ui.tabManager.explorationTab.odysseySubTab.legendContainer.style.display = 'none';
        } else if (newMapView.featureType === "region") {
            this.showRegionView(newMapView);
            this.ui.tabManager.explorationTab.odysseySubTab.legendContainer.style.display = 'block';
        }

        this.updateMapTitle(mapTitle, newMapView);
        this.worldDisplayManager.currentViewType = newMapView.featureType;
    }

    clearCurrentView() {
        if (this.worldDisplayManager.currentlyViewedWorld) {
            this.worldDisplayManager.currentlyViewedWorld.currentlyViewing = false;
        }
        if (this.worldDisplayManager.currentlyViewedRegion) {
            this.worldDisplayManager.currentlyViewedRegion.currentlyViewing = false;
        }
    }

    showWorldView(world) {
        this.worldDisplayManager.currentlyViewedWorld = world;
        this.worldDisplayManager.currentlyViewedRegion = null;
        world.regions.forEach(region => (region.DOMContent.style.display = 'flex'));
        
        this.miniMap.toggleMiniMapVisibility('world');
    }

    showRegionView(region) {
        region.world.currentlyViewing = true;
        this.worldDisplayManager.currentlyViewedRegion = region;
        this.worldDisplayManager.currentlyViewedWorld = null;
        region.zoneDOMContent.style.display = 'flex';
        Array.from(region.zoneDOMContent.children).forEach(child => (child.style.display = 'flex'));
        
        this.miniMap.toggleMiniMapVisibility('region');
        this.miniMap.updateMiniMap();
    }

    updateMapTitle(element, feature) {
        element.style.display = 'flex';
        const titleText = `${feature.featureType.charAt(0).toUpperCase() + feature.featureType.slice(1)}: ${feature.name}`;
        this.ui.updateElementTextContent(element, titleText);
    }
}
