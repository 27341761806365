import { DEC } from '../Utilities/decimal.js';
import ModTreeNode from './ModTreeNode.js';

export default class ModTree {
	constructor(parent, type) {
		this.nodes = [];
		this.parent = parent;
		this.type = type;
		this.currentRunningResult = DEC(0);
	}

	buildTree() {
		// console.error("running buildtree for",this.parent.name);
		let firstActiveNode = this.setFirstActiveNode();
		if (!firstActiveNode) {
			// console.log("!firstActiveNode");
			if (this.type === "cost") {
				// console.log("cost");
				this.parent.costMult = this.parent.costMultBase;
			}
			else if (this.type === "production") {
				// console.log("prod");
				this.parent.prodMult = this.parent.prodMultBase;
			}
			return;
		}
		else {
			
			// console.log("updateDownStream");
			this.updateDownstreamNodes(firstActiveNode);
		}

		if (this.type === "cost") {
			this.parent.costMult = this.currentRunningResult;
		}
		else if (this.type === "production") {
			this.parent.prodMult = this.currentRunningResult;
		}
		
		// console.log("endBuildTree");
	}

	setFirstActiveNode() {
		let currentNode = this.nodes[0];

		let baseMult = DEC(0);
		if (this.type === "cost") {
			baseMult = this.parent.costMultBase;
		}
		else if (this.type === "production") {
			baseMult = this.parent.prodMultBase;
		}

		while (currentNode) {
			if (currentNode.ref.active && currentNode.ref.source.level.gt(0)) {
				currentNode.result = this.calcNodeResult(currentNode, currentNode.ref.source.level);
				currentNode.runningResult = this.calcNodeRunningResult(currentNode, baseMult);

				return currentNode;
			}

			currentNode = currentNode.nextNode;
		}
		return null;
	}

	updateDownstreamNodes(firstActiveNode) {
		let currentNode = firstActiveNode.nextNode;
		let lastActiveNode = firstActiveNode;
		while (currentNode) {
			if (currentNode.ref.active && currentNode.ref.source.level.gt(0)) {
				currentNode.result = this.calcNodeResult(currentNode, currentNode.ref.source.level);

				currentNode.runningResult = this.calcNodeRunningResult(currentNode, lastActiveNode.runningResult);

				lastActiveNode = currentNode;
			}
			currentNode = currentNode.nextNode;
		}
		this.currentRunningResult = lastActiveNode.runningResult;
	}

	calcNodeResult(node, sourceLevel) {
		let calcType = node.ref.sourceCalcType;
		let nodeValue = DEC(node.ref.value);
		sourceLevel = DEC(sourceLevel);

		//for some reason i wanted to bypass the rest of the operations below if these conditions are met
		if (this.parent.featureType === "zone" && calcType === 'mult') {
			return this.performCalculation(calcType, nodeValue, sourceLevel);
		}

		if (node.ref.sourceCalcType === 'add' && this.type === "production") {
			sourceLevel = sourceLevel.minus(1);
		}

		if (node.ref.sourceCalcType === 'add' && this.type === "cost") {
			sourceLevel = sourceLevel.minus(1);
		}

		if (sourceLevel.eq(0)) {
			if (node.ref.sourceCalcType === 'sub') {
				sourceLevel = DEC(0);
			}
			else if (node.ref.sourceCalcType === 'exp') {
				sourceLevel = DEC(1);
			}
		}

		// if (node.ref.source.id === 10415 && this.parent.id === 1001){
		// 	console.error(node);
		// 	console.error(this.performCalculation(calcType,nodeValue,sourceLevel));
		// 	console.error("runResult:",this.currentRunningResult);
		// }

		return this.performCalculation(calcType, nodeValue, sourceLevel);
	}

	calcNodeRunningResult(node, prevRunningResult) {
		let res = DEC(node.result);
		let prevRes = DEC(prevRunningResult);

		
		// if (node.ref.source.id === 10415 && this.parent.id === 1001){
		// 	console.error(node);
		// 	console.error("runResult:",this.currentRunningResult);
		// 	console.error(this.performCalculation(node.ref.runningCalcType,res,prevRes));
		// }

		return this.performCalculation(node.ref.runningCalcType, res, prevRes);
	}

	performCalculation(type, val1, val2) {
		const CALCULATION_TYPES = {
			'add': (val1, val2) => val1.plus(val2),
			'sub': (val1, val2) => val2.minus(val1),
			'mult': (val1, val2) => val1.times(val2),
			'div': (val1, val2) => val2.dividedBy(val1),
			'addPercent': (val1, val2) => val1.times(val2),
			'subPercent': (val1, val2) => val2.times(val1),
			'exp': (val1, val2) => val2.pow(val1),
			'tetra': (val1, val2) => val2.tetrate(val1),
			'log': (val1, val2) => val2.eq(0) ? DEC(0) : val2.log(DEC(val1))
		}
		const calculation = CALCULATION_TYPES[type];

		if (!calculation) {
			throw new Error('Unknown calculation type: ' + type);
		}

		return calculation(val1, val2);
	}

	addNode(mod) {
		let newNode = new ModTreeNode(mod, this);
		let insertIndex = this.nodes.length;

		for (let i = 0; i < this.nodes.length; i++) {
			if (this.nodes[i].priority > newNode.priority) {
				insertIndex = i;
				break;
			}
		}

		this.nodes.splice(insertIndex, 0, newNode);

		if (insertIndex > 0) {
			newNode.previousNode = this.nodes[insertIndex - 1];
			this.nodes[insertIndex - 1].nextNode = newNode;
		}
		if (insertIndex < this.nodes.length - 1) {
			newNode.nextNode = this.nodes[insertIndex + 1];
			this.nodes[insertIndex + 1].previousNode = newNode;
		}
	}
}