export default class MultiplierManager {
    constructor(eventManager, ui) {
		this.eventManager = eventManager;
        this.ui = ui;
        this.tabs;

		
        this.eventManager.addListener('updateMultiplier', this.updateMultiplier.bind(this));
    }

    setupMultiplierButtons(tabs){
        this.tabs = tabs;
		const tabNamesToShowOn = ['force', 'wisdom', 'energy','divine','artifacts','radiance','essence'];
		let tabsToShowOn = this.ui.tabManager.tabs.filter(tab => tabNamesToShowOn.includes(tab.name));
	
		for (const tab of tabsToShowOn){
			// Initialize tab-specific multiplier buttons and multiplier value
			tab.multiplierButtons = {};
			tab.multiplierString = "1";
			
			let multContainer = this.ui.createElement('div', tab.name + 'MultContainer','mult-container');
			this.multiplierValues = ["1", "5", "10", "100", "Rank", "Max"];
	
			for (const multValue of this.multiplierValues) {
				let multButton = this.ui.createElement('button', multValue + 'Mult', 'mult-button', multValue);
				const multButtonClickHandler = this.updateMultiplier.bind(this, tab, multValue);
				this.eventManager.addDomListener(multButton, 'click', multButtonClickHandler);
				tab.multiplierButtons[multValue] = multButton;
				multContainer.append(multButton);
			}
			
	
			tab.multiplierContainer = multContainer; // Save the container in the tab so you can show/hide it later
			multContainer.style.display = 'none';
			this.ui.mainWindow.prepend(multContainer);
			this.updateMultiplier(tab, tab.multiplierString);
		}
	}
	
	updateMultiplier(tab, newValue = null) {
		//check if this is being sent by loadState, which sets this value and then nullifies it after
		if (tab.tempMultString && !newValue){
			newValue = tab.tempMultString;
		}
		// Remove the class from the previous button, if any
		if (tab.multiplierString) {
			tab.multiplierButtons[tab.multiplierString].classList.remove('selected-mult-button');
		}
	
		// Add the class to the new button
		tab.multiplierButtons[newValue].classList.add('selected-mult-button');
	
		tab.multiplierString = newValue;
        this.eventManager.dispatchEvent('updateNewMultiplierValues', { multiplierString: tab.multiplierString });
	}

    toggleMultiplierContainer(tabObject, displayStyle) {
		if (tabObject.multiplierContainer){
			tabObject.multiplierContainer.style.display = displayStyle;
		}
	}
}
