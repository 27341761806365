import { DEC } from '../Utilities/decimal.js';
import achievementIconImages from '../../assets/icons//achievementIcons/*.png';


import achieveData from '../../assets/gameData/achieveData.json';
import achieveSetData from '../../assets/gameData/achieveSetData.json';

import Achievement from '../Features/Achievements/Achievement.js';
import AchievementSet from '../Features/Achievements/AchievementSet.js';

export default class AchievementBuilder {
	constructor(builder) {
		this.builder = builder;
        this.achievements = [];
        this.achievementSets = [];
    }

    

	initAchievements() {
		this.initAchievementObjects();
		this.initAchievementSets();
		this.assignAchievementsToSets();
	}

    initAchievementObjects() {
        let achievementUnlockData = [];
        let unlockID = 6501;

        achieveData.forEach(data => {
            const { id, name, note, iconFilename, description, unlockCategory, conditionType, dependentID, radianceReward, triggerType, triggerCategory, conditionValue, setID, mods } = data;

            //allows for huge values defined as strings
            const conditionValueDecimal = DEC(conditionValue);

            const achievement = new Achievement(this.builder.eventManager, id, name, note, iconFilename, description, unlockCategory,conditionType, dependentID, radianceReward, triggerType, triggerCategory, conditionValueDecimal, setID );
            if (achievement.targetID) {
                achievement.target = this.builder.findObjectById(achievement.targetID);
            }
            if (achievement.dependentID) {
                achievement.dependent = this.builder.findObjectById(achievement.dependentID);
            }

            if (achievement.iconFilename){
                achievement.image = achievementIconImages[iconFilename];
            }
            else {
                console.error("achievement has no corresponding image for " + achievement.id);
            }
            

            //grab mods declared in JSON and add them to the mod waiting array to be processed after all objects are initialized
            if (mods){
                mods.forEach(modData => {
                    modData.id = achievement.id + 1000;
                    modData.name = "achievementMod" + achievement.id;
                    if (!modData.sourceID){
                        modData.sourceID = achievement.id;
                    }

                    this.builder.modsWaiting.push(modData);

                    // if (achievement.dependent){
                    //     achievement.description = `<b>Condition:</b> ${achievement.dependent.name} ${achievement.conditionType} ${achievement.conditionValue}`;
                    // }
                    // else{
                    //     achievement.description = `<b>Condition:</b> ${DEC(achievement.conditionValue).toFixed(0)} ${achievement.conditionType}`;
                    // }
                    // achievement.description += `\n<b>Reward:</b> ${modData.targetType} ${modData.type} multiplier ${modData.runningCalcType} ${modData.value} `;
                    
                });
            }

            //populate unlocks for each achievement, to be processed after loop
            achievementUnlockData.push({
                id: unlockID,
                category: achievement.unlockCategory,
                type: null,
                dependentID: achievement.dependentID,
                targetID: achievement.id,
                conditionType: achievement.conditionType,
                conditionValue: achievement.conditionValue,
                triggerType: achievement.triggerType,
                triggerValue: achievement.triggerValue
            });
            

            unlockID++;

            this.achievements.push(achievement);
            this.builder.gameManager.gameContent.achievements.push(achievement);
            this.builder.gameManager.gameContent.achievementsGrid.achievements.push(achievement);
            this.builder.gameManager.gameContent.idToObjectMap.set(id, achievement);
        });

        this.builder.createUnlocks(achievementUnlockData);

        // DIAGNOSTIC FUNCTIONS
        // this.printDuplicateIconFilenames();
        // this.printUnusedIconImageNumbers();
    }

    printDuplicateIconFilenames(){
        let iconFilenames = [];
        let duplicates = [];
        achieveData.forEach(data => {
            const { id, iconFilename } = data;
            if (iconFilenames.includes(iconFilename)){
                duplicates.push(iconFilename);
            }
            else{
                iconFilenames.push(iconFilename);
            }
        });
        //if any duplicates were found, print them to console
        if (duplicates.length > 0){
            console.log("Duplicate icon filenames found: " + duplicates);
        }
    }

    printUnusedIconImageNumbers() {
        let minIconNumber = 1;
        let maxIconNumber = 170;
    
        for (let i = minIconNumber; i <= maxIconNumber; i++) {
            let iconNumberStr = i < 10 ? `0${i}` : i.toString(); // Ensure leading zero for numbers 1-9
            let iconName = `AchievementIcon_${iconNumberStr}`;
            
            let found = false;
            for (let j = 0; j < achieveData.length; j++) {
                if (achieveData[j].iconFilename == iconName) {
                    found = true;
                    break; // No need to continue checking once found
                }
            }
    
            if (!found) {
                console.log(i);
            }
        }
    }
    

    initAchievementSets(){

        achieveSetData.forEach(data => {
            const { id, name,  color, bonusType, bonusValue, mods } = data;
            const achievementSet = new AchievementSet(this.builder.eventManager, id, name, color, bonusType, bonusValue );

            //grab mods declared in JSON and add them to the mod waiting array to be processed after all objects are initialized
            if (mods){
                mods.forEach(modData => {
                    modData.id = achievementSet.id + 200;
                    modData.name = "achieveSetMod" + achievementSet.id;
                    if (!modData.sourceID){
                        modData.sourceID = achievementSet.id;
                    }
                    this.builder.modsWaiting.push(modData);

                    //create artifact description based off of mod values
                    achievementSet.description = `${modData.targetType} ${modData.type} ${modData.runningCalcType} ${modData.value}`;
                });
            }

            this.achievementSets.push(achievementSet);
            this.builder.gameManager.gameContent.achievementSets.push(achievementSet);
            this.builder.gameManager.gameContent.achievementsGrid.achievementSets.push(achievementSet);
            this.builder.gameManager.gameContent.idToObjectMap.set(id, achievementSet);
        });
    }


    assignAchievementsToSets(){
        for (const achievement of this.achievements){
            let achievementSet = this.builder.findObjectById(achievement.setID);
            achievement.set = achievementSet;
            achievementSet.achievements.push(achievement);
        }
    }
}