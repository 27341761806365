import { DEC } from '../../Utilities/decimal.js';
import GameFeature from '../../Core/GameFeature.js';

export default class Skill extends GameFeature {
	constructor(eventManager, id, name, skillType, note, iconFilename = null, description, color, level, maxLevel, costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate, active, unlocked, connections) {
		super(
			eventManager,
			id,
			name,
            note,
			description, // description
			level, // level
			maxLevel, // maxLevel
			costType, costBase, costGrowthRate, prodType, prodBase, prodGrowthRate,
			active
		);
		this.iconFilename = iconFilename;
		this.featureType = "skill";
		this.skillType = skillType;
		this.connections = connections;
		this.connectionSkillIds = [];
		this.unlocked = unlocked;
		this.unlockingID;
		this.unlockedConnections = [];
		this.color = color;
	}

	setUnlocked(){
		this.unlocked = true;
	}
	
	updateFeatureValues(isNewLvl, gameManager){
		
		this.costNextSingle = this.calcCostNextSingle();
		this.prodNextSingle = this.calcProdNextSingle();

		//dont update values if inactive || (level 0 & not being levelled up)
		if (!this.active || (this.level.eq(0) && !isNewLvl)) {
			return;
		}
		// handle if an active feature's multiplier is being updated but not its level
			// aka being upgraded or a mod is activated
		// also handles evolutions
		else if (this.active && !isNewLvl) {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
		//handle features that are being levelled manually or generator autopurchase
		else {
			this.prodPrevious = this.prodCurrentGlobal;
			this.prodCurrentGlobal = this.calculateProdN(this.level, DEC(0));
		}
	}

	refundSkill(){
		this.level = DEC(0);
		this.manualLevel = DEC(0);
		this.costNextSingle = this.costBase;
		// this.active = false;

		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: this.costType,
			value: this.costBase,
			operation: 'add'
		});

		for (const connection of this.unlockedConnections) {
			this.eventManager.dispatchEvent('reEngage-unlock', { detail: { id: connection.id } });
			connection.active = false;
		}

		this.deactivateObservers();
	}
}