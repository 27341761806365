export default class DisplayState {
	constructor(gameManager, eventManager, stateManager) {
        this.gameManager = gameManager;
		this.eventManager = eventManager;
        this.stateManager = stateManager;

		this.tabs;
    }

    saveState(state, gameData){
        this.populateSaveDataBase(gameData);

        switch (state) {
			case 0:
				this.populateSaveDataFull(gameData);
				break;
			case 1:
				this.populateSaveDataRebirth1(gameData);
				break;
			case 2:
				this.populateSaveDataRebirth2(gameData);
				break;
			case 3:
				this.populateSaveDataRebirth3(gameData);
				break;
		}
    }
    
    loadState(state, gameData){
        this.applyLoadDataBase(gameData);

        switch (state) {
			case 0:
				this.applyLoadDataFull(gameData);
				break;
			case 1:
				this.applyLoadDataRebirth1(gameData);
				break;
			case 2:
				this.applyLoadDataRebirth2(gameData);
				break;
			case 3:
				this.applyLoadDataRebirth3(gameData);
				break;
		}
    }

    populateSaveDataBase(gameData){
		gameData.essenceTab = this.saveTabData('essence');
		gameData.explorationTab = this.saveTabData('exploration');
		gameData.artifactsTab = this.saveTabData('artifacts');
		gameData.forgeTab = this.saveTabData('forge');
		gameData.skillsTab = this.saveTabData('skills');
		gameData.achievementsTab = this.saveTabData('achievements');
		gameData.radianceTab = this.saveTabData('radiance');
		
		// save number notation setting
		let numberSettingsButton = document.getElementById("numberSettings");
		gameData.notationSetting = numberSettingsButton.value;

		// save tooltips toggle setting
		let tooltipToggle = document.getElementById("checkbox-tooltips");
		gameData.tooltipToggleSetting = tooltipToggle.checked;

		// save notification toggle setting
		let notificationToggle = document.getElementById("checkbox-notifications");
		gameData.notificationToggleSetting = notificationToggle.checked;

		// save hotkey toggle setting
		let hotkeyToggle = document.getElementById("checkbox-hotkeys");
		gameData.hotkeyToggleSetting = hotkeyToggle.checked;

		// save tab multiplier button state
		gameData.tabMultData = {};
		for (const tab of this.tabs) {
			if (tab.multiplierString){
				gameData.tabMultData[tab.name] = { multiplierString: tab.multiplierString };
			}
		}

		// save tab hasBeenOpened value
		gameData.tabUnvisitedData = {};
		for (const tab of this.tabs) {
			if (tab.hasBeenOpened){
				gameData.tabUnvisitedData[tab.name] = { hasBeenOpened: tab.hasBeenOpened };
			}
		}
	}

    populateSaveDataFull(gameData) {
		// save essence tab so the tab is available if it's active
		gameData.essenceTab = this.saveTabData('essence');
		gameData.explorationTab = this.saveTabData('exploration');
		gameData.artifactsTab = this.saveTabData('artifacts');
		
		// save current tab for gameManager
		gameData.currentTabID = this.gameManager.currentTab.id;
		if (this.gameManager.currentSubTab){
			gameData.currentSubTabID = this.gameManager.currentSubTab.id;
		}

		// save odyssey current view data
		if (this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedWorld){
			gameData.currentlyViewedWorldId = this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedWorld.id;
		}
		else if (this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedRegion){
			gameData.currentlyViewedRegionId = this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedRegion.id;
		}
		gameData.currentViewType = this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentViewType;

    }

    populateSaveDataRebirth1(gameData){
	}
    populateSaveDataRebirth2(gameData){}
    populateSaveDataRebirth3(gameData){}

    

    applyLoadDataBase(gameData){


		this.loadTabData('essence', gameData.essenceTab);
		this.loadTabData('exploration', gameData.explorationTab);
		this.loadTabData('artifacts', gameData.artifactsTab);
		this.loadTabData('forge', gameData.forgeTab);
		this.loadTabData('skills', gameData.skillsTab);
		this.loadTabData('achievements', gameData.achievementsTab);
		this.loadTabData('radiance', gameData.radianceTab);


		// load number notation setting
		let numberSettingsButton = document.getElementById("numberSettings");
		numberSettingsButton.value = gameData.notationSetting;
		this.eventManager.dispatchEvent('updateNumberNotation', { value: gameData.notationSetting });


		// load tooltips toggle setting
		let tooltipToggle = document.getElementById("checkbox-tooltips");
		tooltipToggle.checked = gameData.tooltipToggleSetting;
		if (tooltipToggle.checked) {
			document.querySelector('#training').classList.remove('tooltips-off');
		} 
		else {
			document.querySelector('#training').classList.add('tooltips-off');
		}

		// load notification toggle setting
		let notificationToggle = document.getElementById("checkbox-notifications");
		notificationToggle.checked = gameData.notificationToggleSetting;
		if (notificationToggle.checked) {
			this.gameManager.notificationsToggled = true;
		} 
		else {
			this.gameManager.notificationsToggled = false;
		}


		// load hotkey toggle setting
		let hotkeyToggle = document.getElementById("checkbox-hotkeys");
		hotkeyToggle.checked = gameData.hotkeyToggleSetting;
		if (hotkeyToggle.checked) {
			this.eventManager.dispatchEvent('hotkeyEnabled');
		} 
		else {
			this.eventManager.dispatchEvent('hotkeyDisabled');
		}


		// load tab multiplier button state
		for (const tabName in gameData.tabMultData) {
			const savedTabData = gameData.tabMultData[tabName];
			
			// Find the matching tab in your game
			const gameTab = this.tabs.find(tab => tab.name === tabName);
			
			// Check if the tab exists and update the multiplierString
			if (gameTab) {
				gameTab.tempMultString = savedTabData.multiplierString;
				this.eventManager.dispatchEvent('updateMultiplier', gameTab);
				gameTab.tempMultString = null;
			} else {
				console.error(`Tab with name ${tabName} not found in game.`);
			}
		}

		
		// load tab hasBeenOpened value
		for (const tabName in gameData.tabUnvisitedData) {
			const savedTabOpenStatus = gameData.tabUnvisitedData[tabName];

			// Find the matching tab in your game
			const gameTab = this.tabs.find(tab => tab.name === tabName);

			// Check if the tab exists and update the hasBeenOpened value
			if (gameTab) {
				gameTab.hasBeenOpened = savedTabOpenStatus.hasBeenOpened;
			} else{
				console.error(`Tab with name ${tabName} not found in game.`);
			}
		}
	}

    applyLoadDataFull(gameData) {
		this.loadTabData('exploration', gameData.explorationTab);
		this.loadTabData('artifacts', gameData.artifactsTab);
		this.loadTabData('essence', gameData.essenceTab);
		
		// load current tab for gameManager
		this.gameManager.currentTab = this.tabs.find(tab => tab.id === gameData.currentTabID);
		this.gameManager.currentSubTab = this.tabs.find(subTab => subTab.id === gameData.currentSubTabID);
		this.eventManager.dispatchEvent('changeTab', this.gameManager.currentTab.name);

		// load odyssey current view data
		if (gameData.currentlyViewedWorldId){
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedWorld = this.gameManager.findObjectById(gameData.currentlyViewedWorldId);
		}
		if (gameData.currentlyViewedRegionId){
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedRegion = this.gameManager.findObjectById(gameData.currentlyViewedRegionId);
		}
		this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentViewType = gameData.currentViewType;
		let viewedRegion = this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedRegion;
		let viewedWorld = this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedWorld;

		if (viewedRegion){
			// Set the currentlyViewedRegion and currentlyViewedWorld properties
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedRegion = viewedRegion;
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedWorld = viewedRegion.world;
			
			// Set the currentViewType to "region"
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentViewType = "region";
			
			// Update the map view with the viewedRegion
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.mapViewManager.updateMapView(viewedRegion);
			
			// Dispatch the 'changeWorldMap' event with the viewedRegion's world
			this.eventManager.dispatchEvent('changeWorldMap', viewedRegion.world);
			
			// Show the region view
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.mapViewManager.showRegionView(viewedRegion);
		}
		else if (viewedWorld){
			// Set the currentlyViewedWorld property
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentlyViewedWorld = viewedWorld;
			
			// Set the currentViewType to "world"
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.worldDisplayManager.currentViewType = "world";
			
			// Update the map view with the viewedWorld
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.mapViewManager.updateMapView(viewedWorld);
			
			// Dispatch the 'changeWorldMap' event with the viewedWorld
			this.eventManager.dispatchEvent('changeWorldMap', viewedWorld);
			
			// Show the world view
			this.stateManager.ui.tabManager.explorationTab.odysseySubTab.mapViewManager.showWorldView(viewedWorld);
		}

		if (gameData.currentlyViewedWorldId) {
			const currentlyViewedWorld = this.gameManager.findObjectById(gameData.currentlyViewedWorldId);
			this.eventManager.dispatchEvent('changeWorldMap', currentlyViewedWorld);
		} else if (gameData.currentlyViewedRegionId) {
			const currentlyViewedRegion = this.gameManager.findObjectById(gameData.currentlyViewedRegionId);
			this.eventManager.dispatchEvent('changeWorldMap', currentlyViewedRegion.world);
		}
    }
    
    applyLoadDataRebirth1(gameData){
	}
    applyLoadDataRebirth2(gameData){}
    applyLoadDataRebirth3(gameData){}



	saveTabData(tabName, allTabs = null) {
		if (allTabs){

		}
		else {
			let tab = this.tabs.find(tab => tab.name === tabName);
			if (tab) {
				return { 
					active: tab.active, 
				};
			}
			else {
				console.error(`Tab with name ${tabName} not found in game.`);
			}
			return null;
		}
	}

	
	loadTabData(tabName, tabData) {
		let tab = this.tabs.find(tab => tab.name === tabName);
		if (tab && tabData) {
			tab.active = tabData.active;
		}
		else {
			console.error(`Tab with name ${tabName} not found in game.`);
		}
	}
}