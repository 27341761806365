export default class ZoneState {
	constructor(gameManager, stateManager) {
        this.gameManager = gameManager;
        this.stateManager = stateManager;
        this.featureType = "zones";


        this.propertiesBase = ['baseLevel'];


        this.autoFeatureProperties = ['autoUnlocked','autoToggle'];


        this.regularPropertiesFull = ['level', 'manualLevel', 'autoLevel',
		'active', 'prodCurrentGlobal', 'prodPrevious','prodNextMultPurchase','prodNextSingle','prodMult', 'costNextMultPurchase','costNextSingle','costMult','maxAffLvl',  'evolutionTier', 'isDefeated','defeatCount','baseConquestTime','conquestTime','isConquesting','progress','unlocked'];

        this.rebirthProperties = ['defeatCount'];

        this.propertiesFull = this.propertiesBase.concat(this.regularPropertiesFull);
    }

    saveState(state){
        this.populateSaveDataBase();

        switch (state) {
			case 0:
				this.populateSaveDataFull();
				break;
			case 1:
				this.populateSaveDataRebirth1();
				break;
			case 2:
				this.populateSaveDataRebirth2();
				break;
			case 3:
				this.populateSaveDataRebirth3();
				break;
		}
    }
    
    loadState(state){
        this.applyLoadDataBase();

        switch (state) {
			case 0:
				this.applyLoadDataFull();
				break;
			case 1:
				this.applyLoadDataRebirth1();
				break;
			case 2:
				this.applyLoadDataRebirth2();
				break;
			case 3:
				this.applyLoadDataRebirth3();
				break;
		}
    }

    populateSaveDataBase(){}

    populateSaveDataFull() {
        this.stateManager.populateSaveData(this.featureType, this.propertiesFull);
    }

    populateSaveDataRebirth1(){
        
        this.stateManager.populateSaveData(this.featureType, this.rebirthProperties);
    }
    populateSaveDataRebirth2(){}
    populateSaveDataRebirth3(){}

    

    applyLoadDataBase(){}

    applyLoadDataFull() {
        this.stateManager.applyLoadData(this.featureType, this.propertiesFull);
    }
    
    
    
    applyLoadDataRebirth1(){
        this.stateManager.applyLoadData(this.featureType, this.rebirthProperties);
    }
    applyLoadDataRebirth2(){}
    applyLoadDataRebirth3(){}


}