import { DEC } from '../Utilities/decimal.js';

export default class RewardManager {
	constructor(eventManager, gameManager) {
		this.eventManager = eventManager;
		this.gameManager = gameManager;
		
		// Initialize last reward times to current time
		this.lastHourlyRewardTime = new Date().getTime();
		this.lastDailyRewardTime = new Date().getTime();

		this.oneHourInMilliseconds = 3600 * 1000;
		this.oneDayInMilliseconds = 24 * this.oneHourInMilliseconds;

		this.baseDailyRewardValue = DEC(100);
		this.dailyRewardValue = this.baseDailyRewardValue;
		this.dailyRewardType = "radiance";

		this.dailyRewardClaimable = false;
		this.hourlyRewardClaimable = false;

		this.baseHourlyRewardValue = DEC(10);
		this.hourlyRewardValue = this.baseHourlyRewardValue;
		this.hourlyRewardType = "radiance";

		this.currentHourlyRewardsClaimable = DEC(0);
		this.baseHourlyRewardCap = DEC(4);
		this.hourlyRewardCap = this.baseHourlyRewardCap;
	}

	processBoost(){
		console.error("boost");
	}

	checkRewards() {
		let currentTime = Date.now();
		
		//check hourly reward
		if (currentTime - this.lastHourlyRewardTime >= this.oneHourInMilliseconds && this.currentHourlyRewardsClaimable.lt(this.hourlyRewardCap)) {

			this.currentHourlyRewardsClaimable = this.currentHourlyRewardsClaimable.plus(1);

			this.hourlyRewardClaimable = true;	
			
			// Update last hourly reward time
			this.lastHourlyRewardTime = currentTime;
		}

		//check daily reward
		if (currentTime - this.lastDailyRewardTime >= this.oneDayInMilliseconds) {
			this.dailyRewardClaimable = true;
			
			// Update last daily reward time
			this.lastDailyRewardTime = currentTime;
		}
	}

	giveHourlyReward() {
		//reset time if rewards were capped
		if (this.currentHourlyRewardsClaimable.eq(this.hourlyRewardCap)){
			this.lastHourlyRewardTime = Date.now();
		}

		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: this.hourlyRewardType,
			value: this.hourlyRewardValue,
			operation: 'add'
		});

		this.currentHourlyRewardsClaimable = this.currentHourlyRewardsClaimable.minus(1);

		if (this.currentHourlyRewardsClaimable.eq(0)){
			this.hourlyRewardClaimable = false;
		}
	}

	giveDailyReward() {
		this.lastDailyRewardTime = Date.now();

		this.eventManager.dispatchEvent('updatePropertyValue', {
			property: this.dailyRewardType,
			value: this.dailyRewardValue,
			operation: 'add'
		});
		this.dailyRewardClaimable = false;
	}

	checkHourlyReward() {
		//do not increment time if capped claimable
		if (this.currentHourlyRewardsClaimable.eq(this.hourlyRewardCap)){
			return this.oneHourInMilliseconds;
		}
        let currentTime = Date.now();
        
        let timeSinceLastReward = currentTime - this.lastHourlyRewardTime;
        if (timeSinceLastReward >= this.oneHourInMilliseconds) {
            return 0;  // The reward is ready to claim
        } else {
            return this.oneHourInMilliseconds - timeSinceLastReward;  // Return time left until next reward
        }
    }

    checkDailyReward() {
		//do not increment time if claimable
		if (this.dailyRewardClaimable === true){
			return this.oneDayInMilliseconds;
		}

        let currentTime = Date.now();
        
        let timeSinceLastReward = currentTime - this.lastDailyRewardTime;
        if (timeSinceLastReward >= this.oneDayInMilliseconds) {
            return 0;  // The reward is ready to claim
        } else {
            return this.oneDayInMilliseconds - timeSinceLastReward;  // Return time left until next reward
        }
    }
}