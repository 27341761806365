export default class SkillNode {
	constructor(skill, x, y) {
		this.skill = skill;
		this.x = x;
		this.y = y;
		this.connections = {
			north: null,
			east: null,
			south: null,
			west: null,
		};
	}
}