import { DEC } from '../Utilities/decimal.js';

import Observable from './Observable.js';

export default class Mod extends Observable {
	constructor(eventManager, id, name, type, priority, sourceID, sourceCalcType, targetType, targetID, runningCalcType, baseValue, value, active, specialActivatorID) {
		super();
		this.eventManager = eventManager;
		this.id = id;
		this.name = name;
		this.type = type;
		this.priority = priority;

		this.source = null;

		this.sourceID = sourceID;
		this.sourceCalcType = sourceCalcType;

		this.target = null;
		this.targetID = targetID;
		this.runningCalcType = runningCalcType;
		this.targetType = targetType;

		this.baseValue = DEC(baseValue);
		this.value = DEC(baseValue);

		this.modTreeReferences = [];

		this.active = active;

		this.specialActivatorID = specialActivatorID;
		this.specialActivator = null;
	}

	setActive() {
		this.active = true;
		
		//dont continue if this is a headband mod, as this will cause headband pseudo object to set all of its mods active
		if (this.name.includes("headband")) {
			return;
		}

		this.source.updateObservers();
	}
}